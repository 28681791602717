import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import COLORS from '../../variables/colors';

const useStyles = makeStyles({
  noRecords: {
    background: COLORS.lightGreen,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    marginTop: 20,
    '& span': {
      color: COLORS.medGray,
      fontSize: 19,
      fontWeight: 'normal',
    },
  },
});
const NoRecords = () => {
  const classes = useStyles();
  return (
    <Box className={classes.noRecords}>
      <img
        src="/llama-head.svg"
      />
      <span>Nothing to see here...Oooooooommmmmmm</span>
    </Box>
  );
};

export default NoRecords;
