import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';

import COLORS from '../../variables/colors';

export const StyledFormControl = styled(FormControl)({
  margin: 4,
  width: '100%',
  minWidth: 500,
});

export const StyledFormHelperText = styled(FormHelperText)({
  color: COLORS.violet,
  paddingLeft: 4,
  marginTop: 4,
});

export const StyledFormLabel = styled(Typography)({
  padding: 0,
  paddingLeft: 4,
  paddingBottom: 4,
  marginTop: 8,
  marginBottom: 0,
  color: COLORS.lightGray,
  fontSize: 18,
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
});

export const StyledInputLabel = styled(Typography)({
  padding: 0,
  paddingLeft: 4,
  paddingBottom: 4,
  marginTop: 8,
  marginBottom: 0,
  color: COLORS.lightGray,
  fontSize: 11,
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: '16px',
  textTransform: 'uppercase',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
});

export const StyledInputLabelRequired = styled('span')({
  color: COLORS.violet,
  margin: 0,
  marginRight: 4,
});
