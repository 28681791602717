/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useState, useContext } from 'react';
import { AccountInfo } from '../variables/types';

// @ts-ignore
const AccountContext = createContext();

export default (props: any) => {
  const [account, setAccount] = useState<AccountInfo | null >();

  const value = React.useMemo(() => ({
    account,
    setAccount,
  }), [account]);

  return <AccountContext.Provider value={value} {...props} />;
};

export const useAccountProvider = () => {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error('useAccountProvider() has to be used within a child of the Account Provider');
  }

  const {
    account,
    setAccount,
  }:any = context;

  return {
    account,
    setAccount,
  };
};
