/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NavOpenIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    {...props}
  >
    <g id="icon / nav open">
      <g id="eva:arrow-ios-forward-fill">
        <path id="Vector" d="M5.89775 11.817C5.70094 11.8174 5.51021 11.7489 5.35867 11.6233C5.27338 11.5526 5.20288 11.4657 5.1512 11.3677C5.09952 11.2697 5.06768 11.1625 5.05751 11.0522C5.04734 10.9419 5.05903 10.8306 5.09192 10.7248C5.12481 10.619 5.17825 10.5208 5.24917 10.4356L9.02274 5.92083L5.38394 1.3976C5.31397 1.31144 5.26172 1.2123 5.23019 1.10589C5.19866 0.99947 5.18848 0.88787 5.20022 0.777503C5.21196 0.667136 5.2454 0.560177 5.29861 0.462775C5.35182 0.365372 5.42376 0.279446 5.51029 0.209936C5.59744 0.133255 5.6995 0.0754153 5.81006 0.0400473C5.92063 0.00467928 6.03731 -0.00745317 6.15278 0.00441074C6.26826 0.0162747 6.38004 0.0518791 6.4811 0.10899C6.58216 0.1661 6.67033 0.243484 6.74007 0.336284L10.8084 5.39017C10.9323 5.54089 11.0001 5.72994 11.0001 5.92504C11.0001 6.12014 10.9323 6.3092 10.8084 6.45991L6.59687 11.5138C6.51238 11.6157 6.40504 11.6963 6.28357 11.749C6.1621 11.8017 6.02992 11.825 5.89775 11.817Z" fill={props.fill} />
      </g>
      <g id="eva:arrow-ios-forward-fill_2">
        <path id="Vector_2" d="M0.843798 11.817C0.646989 11.8174 0.456258 11.7489 0.304716 11.6233C0.219424 11.5526 0.148921 11.4657 0.0972433 11.3677C0.0455655 11.2697 0.0137291 11.1625 0.00355745 11.0522C-0.00661423 10.9419 0.00507881 10.8306 0.0379666 10.7248C0.0708543 10.619 0.12429 10.5208 0.195215 10.4356L3.96879 5.92083L0.329986 1.3976C0.260018 1.31144 0.207768 1.2123 0.176238 1.10589C0.144709 0.99947 0.134522 0.88787 0.146263 0.777503C0.158005 0.667136 0.191442 0.560177 0.244654 0.462775C0.297866 0.365372 0.369804 0.279446 0.456333 0.209936C0.543484 0.133255 0.645543 0.0754153 0.756108 0.0400473C0.866672 0.00467928 0.983354 -0.00745317 1.09883 0.00441074C1.2143 0.0162747 1.32608 0.0518791 1.42714 0.10899C1.52821 0.1661 1.61637 0.243484 1.68611 0.336284L5.75449 5.39017C5.87838 5.54089 5.94611 5.72994 5.94611 5.92504C5.94611 6.12014 5.87838 6.3092 5.75449 6.45991L1.54292 11.5138C1.45842 11.6157 1.35108 11.6963 1.22961 11.749C1.10814 11.8017 0.975962 11.825 0.843798 11.817Z" fill={props.fill} />
      </g>
    </g>
  </SvgIcon>
);

export default NavOpenIcon;
