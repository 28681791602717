import InputBase from '@material-ui/core/InputBase';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import COLORS from '../../variables/colors';

const InputBox = styled(InputBase)({
  background: COLORS.white,
  border: '1px solid rgba(207, 211, 218, 0.6)',
  borderRadius: 2,
  color: COLORS.deepGray,
  height: 32,
  padding: '8px',
  fontSize: 13,
  '&.Mui-error': {
    border: `1px solid ${COLORS.violet}`,
  },
  '&.Mui-focused': {
    boxShadow: COLORS.focusBoxShadow,
  },
});

export default React.memo(InputBox);
