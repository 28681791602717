export enum NotifierType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export enum ScheduleType {
  Monthly = 'MONTHLY',
  Daily = 'DAILY',
  Manual = 'MANUAL',
}

export enum ReportType {
  Detail = 'DETAIL',
  Summary = 'SUMMARY',
}

export enum AssetType {
  ReceivePayment = 'Receive Payment',
  JournalEntry = 'Journal Entry',
  Bill = 'Bill',
  BillPayment = 'Bill Payment',
  BillPaymentCheck = 'Bill Payment (Check)',
  BillPaymentCreditCard = 'Bill Payment (Credit Card)',
  VendorCredit = 'Vendor Credit',
  Invoice = 'Invoice',
  CashExpense = 'Cash Expense',
  Cash = 'Cash',
  Check = 'Check',
  Expense = 'Expense',
  SalesReceipt = 'Sales Receipt',
  Refund = 'Refund',
  Deposit = 'Deposit',
  CreditCardPayment = 'Credit Card Payment',
  PurchaseOrder = 'Purchase Order',
  PayDownCreditCard = 'Pay down credit card',
  CreditCardCredit = 'Credit Card Credit',
}

export interface JournalEntry {
  id: string;
  quickbookId: string;
  status: string;
  subledgerId: string;
  txnDate: Date | string;
  description: string;
  amount: number;
  jeNumber: string;
  userId: string;
  journalEntryLineItems?: Array<JournalEntryLineItem>;
  subledger?:Subledger;
  user: User;
}

export interface AccountInfo {
  user: User;
  companyId: string;
  companyName: string;
  lastSync: Date | null;
  bookCloseDate: string;
  classTrackingPerTxnLine: boolean;
  classTrackingPerTxn: boolean;
  accountIncomes: Array<Account>;
  accountClasses: Array<AccountClass>;
  accountBalances: Array<Account>;
  vendors: Array<Vendor>;
  companies: Array<Company>;
}

export interface SubledgerAmortizationLog {
  date: Date | null;
  scheduleDate: Date | null;
  openingBalance: number;
  closingBalance: number;
}

export interface Subledger {
  id: string;
  glBalance: number;
  openingBalance: number;
  factaStartDate: Date | null;
  status?: string;
  accountId: string;
  account: Account
  prepaidAssets: Array<PrepaidAsset>;
  subledgerAmortizationLogs: Array<SubledgerAmortizationLog>;
  factaDate: FactaDate;
  journalEntries: Array<JournalEntry>
}

export interface JournalEntryLineItem {
  id: string;
  journalEntryId: string;
  accountId: string;
  postingType: string;
  amount: number;
  description: string;
  quickbookId: string;
  vendorId?: string;
  classId?: string;
  assetId?: string;
}

export interface Account {
  id: string;
  name: string;
  accountType: string;
  accountDetail: string;
  active: boolean;
  glBalance: number;
  companyId: string;
  visited: string;
  enable: boolean;
  accNum?: string;
  scheduleType: string;
  subledger: Subledger
}

export interface Vendor {
  id: string;
  displayName: string;
}

export interface AccountClass {
  id: string;
  quickbookId: string;
  className: string;
  active: boolean;
  enable: boolean;
  new: boolean;
  visited?: any;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  officePhone: string;
  mobilePhone: string;
}

export interface PrepaidAssetsAmortizationScheduleDetail {
  id?: string;
  prepaidAssetId: string;
  scheduleDate: Date;
  status?: string;
  amount: number | string;
  journalEntryId?: string;
  journalEntry?: string;
  error?: boolean;
  prepaidAssets?: PrepaidAsset | null;
}

export interface FactaDate {
  startDate: Date | null;
  endDate: Date | null;
}

export interface PrepaidSchedule {
  expenseAccountId?: string;
  classId?: string;
  amortizationStartDate: Date | null;
  amortizationEndDate: Date | null;
  amortizationScheduleType: string;
  prepaidAssetsAmortizationScheduleDetails?: Array<PrepaidAssetsAmortizationScheduleDetail> | null;
}

export interface PrepaidAsset {
  id: string;
  internalId: string;
  assetId: string;
  assetType: AssetType;
  description: string;
  vendorId: string;
  startingBalance: number;
  amortizationToDateBalance: number;
  parentId: string;
  status: string;
  assetCreationDate: Date | null;
  parent: PrepaidAsset;
  children?: Array<PrepaidAsset>;
  prepaidSchedule: PrepaidSchedule;
  source?: string;
}

export enum ErrorPageType {
  NotFound = '404',
  Error = 'error',
}

export interface JournalEntryFilterDates {
  startDate : Date;
  endDate : Date;
}

export interface Company {
  id: string
  companyName: string
  legalName: string
}
