import { Box, TableCell, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import ExpenseAccountMenu from './ExpenseAccountMenu';
import { getAccountNameFromList } from './common';
import { useTableStyles } from './Subledger.styled';
import { PrepaidAsset } from '../../variables/types';
import { useAccountProvider } from '../../core/accountContext';

interface Props {
  hasChildren?: boolean;
  lastChild: boolean;
  asset: PrepaidAsset;
  collapses?: Array<string>;
  isRowSelected: boolean;
  onSelectChange: (propertyName: string, internalId: string) => (value: string) => void;
  isBoldEnable?: boolean;
  onClick?: () => void
}

const ExpenseAccountEditableCell = ({
  hasChildren,
  lastChild,
  asset,
  collapses,
  isRowSelected,
  onSelectChange,
  isBoldEnable = false,
  onClick = () => {},
}: Props) => {
  const { account: { accountIncomes } } = useAccountProvider();
  const tableClasses = useTableStyles();
  return (
    <TableCell
      className={clsx(tableClasses.cursorPointer, tableClasses.expenseAccountCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.bold]: isBoldEnable && !asset.status,
      })}
      onClick={onClick}
    >
      {
        hasChildren
                && (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography className={tableClasses.fontSize13}>Multiple</Typography>
                    {
                      collapses?.includes(asset.internalId) && (
                        <ArrowRightIcon />
                      )
                    }
                    {
                      !collapses?.includes(asset.internalId) && (
                        <ArrowDropDownIcon />
                      )
                    }
                  </Box>
                )
      }
      {
        !hasChildren && isRowSelected && (
          <ExpenseAccountMenu
            className={clsx(tableClasses.expenseAccountSelectBox)}
            defaultValue={asset?.prepaidSchedule?.expenseAccountId!}
            value={asset?.prepaidSchedule.expenseAccountId!}
            onChange={onSelectChange('expenseAccountId', asset.internalId)}
          />
        )
      }
      {
        !hasChildren && !isRowSelected && getAccountNameFromList(asset?.prepaidSchedule?.expenseAccountId!, accountIncomes)
      }
    </TableCell>
  );
};

export default React.memo(ExpenseAccountEditableCell);
