import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import useFetch from 'use-http';

import Login from './Login';
import { useAccountProvider } from '../core/accountContext';
import Loader from '../components/Loader/loader';
import PrivateRoute from './PrivateRoute';
import { deleteToken, getToken } from '../util/auth';
import ErrorPage from '../components/ErrorPage';
import ProtectedRoutes from './ProtectedRoutes';

const AppRouter = () => {
  const [loadingAccountInfo, setLoadingAccountInfo] = useState<boolean>(true);
  const { account, setAccount } = useAccountProvider();
  const { get, loading, error, response } = useFetch();

  const navigateToLogin = async () => {
    if (response?.ok === undefined && !response?.status) {
      deleteToken();
      window.location.href = '/login';
      return;
    }
    if (response?.text) {
      const text = await response.text();
      if (response?.status === 401 || text?.toLowerCase()?.includes('token')) {
        deleteToken();
        window.location.href = '/login';
      }
    }
  };

  const checkAccountInfo = useCallback(async () => {
    if (!account && getToken()) {
      try {
        const accountInfo = await get('/account/sync/accounts');
        if (response.ok) {
          setAccount(accountInfo);
        }
        await navigateToLogin();
      } catch (err) {
        await navigateToLogin();
      }
    }
    setLoadingAccountInfo(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkAccountInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || loadingAccountInfo) {
    return <Loader open />;
  }

  // TODO need to handle error pages
  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        alignContent="center"
      >
        <Box width="70%">
          <ErrorPage />
        </Box>
      </Box>
    );
  }

  if (!response) {
    return null;
  }

  return (
    <Box display="flex" width="100%" height="100%">
      <Router>
        <Switch>
          <Route path="/login" component={Login} exact />
          <PrivateRoute path="/">
            <ProtectedRoutes />
          </PrivateRoute>
        </Switch>
      </Router>
    </Box>
  );
};
export default AppRouter;
