import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Divider from '@material-ui/core/Divider';
import COLORS from '../../variables/colors';

export const StyledNavigationButton = styled(Button)({
  color: COLORS.white,
  marginTop: 20,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  textTransform: 'none',
  fontSize: 11,
  fontWeight: 500,
  textAlign: 'left',
  paddingLeft: 7,
  paddingRight: 7,
  height: 32,
});

export const StyledNavigationButtonBox = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 7,
  borderRadius: 4,
  fontFamily: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
});

export const StyledDivider = styled(Divider)({
  backgroundColor: COLORS.white,
  opacity: 0.2,
  width: '94%',
  marginLeft: 5,
  marginRight: 5,
});
