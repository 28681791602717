import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import { format, lastDayOfMonth, parse } from 'date-fns';
import { ButtonBase, createStyles, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DAY_SHORT_FORMAT, MONTH_SHORT_FORMAT } from '../../variables/constants';
import COLORS from '../../variables/colors';

const useStyles = makeStyles(() => createStyles({
  filterMenu: {
    border: `1px solid ${COLORS.lightGray2}`,
    borderRadius: 4,
    boxShadow: COLORS.selectBoxShadow,
    '& li': {
      fontSize: 13,
      color: COLORS.medGray,
      width: 204,
      justifyContent: 'center',
    },
  },
  fontSize13: {
    fontSize: 13,
  },
  displayFlex: {
    display: 'flex',
  },
  dropDownIcon: {
    marginTop: -1,
  },
  subledgerNavigator: {
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 0,
    color: COLORS.white,
    paddingRight: 10,
    paddingLeft: 10,
    position: 'absolute',
    left: '50%',
    marginTop: -8,
    '& p': {
      fontSize: 13,
      marginTop: 8,
      marginBottom: 3,
      '& span': {
        fontWeight: 'bold',
        marginLeft: 5,
      },
    },
  },
}));
interface Props {
  onDateChange: (dateItem: Date) => void;
  periodContext: string;
  scheduleDate: string;
  subledgerInterval: Array<Date>;
  color?: string;
}

const CalendarNavigation = ({ onDateChange, periodContext, scheduleDate, subledgerInterval, color }:Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMonthChange = (navigationDate: Date) => () => {
    handleClose();
    onDateChange(navigationDate);
  };

  return (
    <Box className={classes.subledgerNavigator} style={{ background: color }}>
      <ButtonBase onClick={handleOpen}>
        <Typography
          className={clsx(classes.fontSize13, classes.displayFlex)}
        >
          {periodContext}
          <span>{format(parse(scheduleDate, DAY_SHORT_FORMAT, new Date()), MONTH_SHORT_FORMAT)}</span>
          <ArrowDropDownIcon className={classes.dropDownIcon} />
        </Typography>
      </ButtonBase>
      <Menu
        className={classes.filterMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -10,
        }}
        transformOrigin={{
          vertical: -35,
          horizontal: 'left',
        }}
      >
        {subledgerInterval.map((dateItem) => (
          <MenuItem onClick={handleMonthChange(lastDayOfMonth(dateItem))}>
            { format(dateItem, MONTH_SHORT_FORMAT) }
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default React.memo(CalendarNavigation);
