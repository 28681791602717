import React from 'react';
import Table from '@material-ui/core/Table';
import {
  Box,
  TableCell,
  TableHead,
  TableRow, Typography,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import NoRecords from './NoRecords';
import { PrepaidAsset, Subledger } from '../../variables/types';
import getScheduleDate from '../../util/getScheduleDate';
import { getAccountName } from '../../components/Subledger/common';
import UnScheduleAssets from '../../components/Subledger/UnScheduledAssets';
import { useTableStyles } from '../../components/Subledger/Subledger.styled';
import { useAccountProvider } from '../../core/accountContext';

const useStyles = makeStyles({
  cardRecordsStyles: {
    marginLeft: '1.5%',
    marginTop: 50,
  },
  tableStyle: {
    position: 'relative',
    width: '100%',
  },
});

interface Props {
  subledger: Subledger;
  selectedDate: Date | null;
  dispatch: any;
  selectedRow: string;
}

const SubledgerDetails = ({
  subledger,
  selectedRow,
  dispatch,
}: Props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const { account } = useAccountProvider();
  const scheduleDate = getScheduleDate(subledger);

  const unScheduleAssets = subledger?.prepaidAssets?.filter((asset:PrepaidAsset) => !asset.status);
  return (
    <Box
      width="100%"
      className={classes.cardRecordsStyles}
    >
      <Box padding={1}>
        <Typography variant="body1" style={{ fontSize: 16, marginBottom: 14 }}>{getAccountName(subledger?.account)}</Typography>
        <Table
          size="small"
          className={classes.tableStyle}
        >
          <TableHead>
            <TableRow
              hover={false}
            >
              <TableCell className={tableClasses.assetCell}>
                JE Date
              </TableCell>
              <TableCell className={tableClasses.assetCell}>
                GL JE ID
              </TableCell>
              <TableCell className={tableClasses.descriptionCell}>
                Description
              </TableCell>
              <TableCell className={tableClasses.customerCell}>
                Vendor
              </TableCell>
              <TableCell className={tableClasses.expenseAccountCell}>
                Expense Account
              </TableCell>
              {
                (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
                  <TableCell className={tableClasses.classesCell}>
                    Class
                  </TableCell>
                )
              }
              <TableCell className={tableClasses.datePickerCell}>
                Amortization Start Date
              </TableCell>
              <TableCell className={tableClasses.datePickerCell}>
                Amortization End Date
              </TableCell>
              <TableCell className={tableClasses.scheduleTypeCell}>
                Amort. Schedule
              </TableCell>
              <TableCell className={tableClasses.currencyCell}>
                Amount
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              unScheduleAssets?.length === 0 && <NoRecords />
            }
            <UnScheduleAssets
              selectedRow={selectedRow}
              showRemainingBalance={false}
              subledger={subledger}
              dispatch={dispatch}
              scheduleDate={scheduleDate!}
              unScheduledAssets={unScheduleAssets}
            />
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default SubledgerDetails;
