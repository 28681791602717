import React, { ReactNode } from 'react';
import { Box, createStyles, Theme } from '@material-ui/core';

import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { useNavBarContext } from '../../core/navbarContext';

interface Props {
  children?: ReactNode;
  title: string;
  showCompanyInfo?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  contentPanelSideBarOpen: {
    paddingLeft: 162,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  contentPanelSideBarClosed: {
    paddingLeft: '52',
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  innerContentClosed: {
    paddingLeft: 52,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  innerContentOpen: {
    paddingLeft: 0,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const Layout = ({ children, title, showCompanyInfo }: Props) => {
  const classes = useStyles();
  const { navBarOpen } = useNavBarContext();
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
      mb={5}
    >
      <Sidebar />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        className={clsx({
          [classes.contentPanelSideBarOpen]: navBarOpen,
          [classes.contentPanelSideBarClosed]: !navBarOpen,
        })}
      >
        <TopBar title={title} showCompanyInfo={showCompanyInfo} />
        <Box
          display="flex"
          flexGrow={1}
          width="100%"
          flexDirection="column"
          alignItems="flex-start"
          mt="80px"
          className={clsx({
            [classes.innerContentOpen]: navBarOpen,
            [classes.innerContentClosed]: !navBarOpen,
          })}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
