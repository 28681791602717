/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { isAfter, isValid, startOfDay } from 'date-fns';
import Input from '@material-ui/core/Input';
import StyledCalendarIcon from './DatePicker.styled';
import { FULL_DATE_FORMAT, MIN_SYSTEM_DATE } from '../../variables/constants';
import COLORS from '../../variables/colors';
import DatePickerMask from './DatePickerMask';

interface Props {
  selectedDate: Date | null;
  onDateChange: (date: Date | null, keyboardString?: string) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  disabled?: boolean;
  error?: boolean | null;
  className?: string
  readOnly?: boolean;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    fontWeight: 'normal',
    fontSize: 12,
    margin: 0,
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    color: COLORS.deepGray,
    minWidth: 81,
    height: 32,
    border: 'none',
    backgroundColor: COLORS.lightGray3,
    boxShadow: COLORS.selectBoxShadow,
    '&.Mui-error': {
      border: `1px solid ${COLORS.violet}`,
    },
    '&.Mui-focused': {
      boxShadow: COLORS.focusBoxShadow,
    },
  },
  readOnly: {
    '&.Mui-focused': {
      boxShadow: COLORS.selectBoxShadow,
    },
  },
}));

const FactaDatePicker = ({
  selectedDate,
  onDateChange,
  minDate = new Date(MIN_SYSTEM_DATE),
  maxDate,
  disabled,
  readOnly,
  error,
  className,
}: Props) => {
  const classes = useStyles();
  const [applicationError, setApplicationError] = useState(Boolean(error));
  const onChange = (date: Date | null, keyboardInputValue?: string) => {
    if (isValid(date) && isAfter(date!, new Date(MIN_SYSTEM_DATE))) {
      setApplicationError(Boolean(error));
      onDateChange(date, keyboardInputValue);
    } else {
      setApplicationError(true);
    }
  };

  const onError = () => {
    setApplicationError(true);
  };

  const onFocus = (event: any) => {
    event.preventDefault();
    const { target } = event;
    const extensionStarts = target.value.lastIndexOf('.');
    target.focus();
    target.setSelectionRange(0, extensionStarts);
  };

  return (
    <DatePicker
      value={!disabled ? selectedDate ?? startOfDay(new Date()) : null}
      openPickerIcon={<StyledCalendarIcon />}
      views={['date']}
      onChange={onChange}
      onError={onError}
      minDate={minDate}
      disableMaskedInput
      maxDate={maxDate}
      allowSameDateSelection
      disabled={disabled || readOnly}
      inputFormat={FULL_DATE_FORMAT}
      readOnly={readOnly}
      renderInput={({ inputProps, inputRef, InputProps }) => (
        <Input
          className={clsx(classes.root, className)}
          readOnly={readOnly}
          disableUnderline
          inputProps={inputProps}
          inputRef={inputRef}
          onFocus={onFocus}
          endAdornment={InputProps?.endAdornment}
          error={!readOnly && applicationError}
          inputComponent={DatePickerMask as any}
        />
      )}
    />
  );
};
export default FactaDatePicker;
