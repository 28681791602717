/* eslint-disable indent */
import { AssetType } from '../variables/types';

const getQuickbookLink = (assetId: string, assetType: AssetType) => {
  switch (assetType) {
    case AssetType.ReceivePayment:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/recvpayment?txnId=${assetId}`;
    case AssetType.JournalEntry:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/journal?txnId=${assetId}`;
    case AssetType.Bill:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/bill?txnId=${assetId}`;
    case AssetType.BillPayment:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/billpayment?txnId=${assetId}`;
    case AssetType.BillPaymentCheck:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/billpayment?txnId=${assetId}`;
    case AssetType.BillPaymentCreditCard:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/billpaymentcc?txnId=${assetId}`;
    case AssetType.VendorCredit:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/vendorcredit?txnId=${assetId}`;
    case AssetType.Invoice:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/invoice?txnId=${assetId}`;
    case AssetType.Cash:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/cashexpense?txnId=${assetId}`;
    case AssetType.CashExpense:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/cashexpense?txnId=${assetId}`;
    case AssetType.Check:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/check?txnId=${assetId}`;
    case AssetType.Expense:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/expense?txnId=${assetId}`;
    case AssetType.SalesReceipt:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/salesreceipt?txnId=${assetId}`;
    case AssetType.Refund:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/refundreceipt?txnId=${assetId}`;
    case AssetType.Deposit:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/deposit?txnId=${assetId}`;
    case AssetType.CreditCardPayment:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/creditcardcredit?txnId=${assetId}`; // TODO -- need to recheck
    case AssetType.PurchaseOrder:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/purchaseorder?txnId=${assetId}`;
    case AssetType.PayDownCreditCard:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/creditcardpayment?txnId=${assetId}`; // TODO -- need to recheck
    case AssetType.CreditCardCredit:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/creditcardcredit?txnId=${assetId}`;
  }
  return null;
};

export default getQuickbookLink;
