import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import COLORS from '../../variables/colors';

export const FactaLoginBackgroundLogo = styled('img')({
  position: 'absolute',
  left: 0,
  bottom: 0,
  opacity: 0.1,
  height: '80%',
});

export const LoginContainer = styled(Box)({
  background: COLORS.navyBlackGradient,
});

export const LoginDivider = styled(Divider)({
  border: `3px solid ${COLORS.violet}`,
  marginTop: 24,
  marginBottom: 24,
  width: '100%',
});

export const FactaLoginMessage = styled(Typography)({
  color: COLORS.white,
  ontSize: 24,
});

export const FactaConnectMessage = styled(Typography)({
  color: COLORS.white,
  ontSize: 16,
  marginBottom: 24,
});
