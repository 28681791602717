/* eslint-disable react/jsx-props-no-spreading */
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { Vendor } from '../../variables/types';
import { useAccountProvider } from '../../core/accountContext';
import { getVendorById } from './common';
import { AutocompleteBox, AutoCompleteTextField } from '../AutoComplete';
import COLORS from '../../variables/colors';

interface Props {
  error?: boolean;
  className: string;
  defaultValue: string;
  value: string;
  onChange: (value: string) => void;
}

const useStyles = makeStyles(() => createStyles({
  error: {
    border: `1px solid ${COLORS.violet}`,
  },
}));

const VendorMenu = ({
  error,
  className,
  value,
  onChange,
}: Props) => {
  const classes = useStyles();
  const { account: { vendors } } = useAccountProvider();
  const selectedVendor = getVendorById(value, vendors);
  const onSelectChange = (_: any, vendor: any) => {
    onChange(vendor?.id);
  };
  return (
    <AutocompleteBox
      className={clsx(className, { [classes.error]: Boolean(error) })}
      options={vendors as Vendor[] ?? []}
      getOptionLabel={(option: any) => option?.displayName}
      onChange={onSelectChange}
      value={selectedVendor ?? ''}
      openOnFocus
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          hiddenLabel
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default React.memo(VendorMenu);
