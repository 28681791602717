import React, { useCallback, useEffect, useState } from 'react';
import useFetch from 'use-http';
import Table from '@material-ui/core/Table';
import {
  Box,
  ButtonBase,
  createStyles,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/styles/makeStyles';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import Loader from '../../components/Loader/loader';
import { AccountClass } from '../../variables/types';
import SortNoneIcon from '../../components/Icons/SortNonecon';
import SortUpIcon from '../../components/Icons/SortUpIcon';
import SortDownIcon from '../../components/Icons/SortDownIcon';
import { Direction, Sort, sortAccountData } from '../../util/sort';
import ExportIcon from '../../components/Icons/ExportIcon';
import COLORS from '../../variables/colors';
import VisibilityIcon from '../../components/Icons/VisibilityIcon';
import HideVisibilityIcon from '../../components/Icons/HideVisibilityIcon';
import { useAccountProvider } from '../../core/accountContext';
import ErrorPage from '../../components/ErrorPage';

const useStyles = makeStyles(() => createStyles({
  opacity4: {
    opacity: 0.4,
  },
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
  fontSize13: {
    fontSize: 13,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const Classes = () => {
  // eslint-disable-next-line no-unused-vars
  const { get, response: fetchResponse, loading, error } = useFetch();
  const { put, error: requestError, response } = useFetch();
  const { account } = useAccountProvider();
  const [accountClasses, setAccountClasses] = useState<Array<AccountClass>>([]);
  const classes = useStyles();
  const [sortBy, setSortBy] = useState<Sort>({ name: 'className', direction: Direction.Down });
  const getSortIcon = (name: string) => {
    if (name === sortBy?.name) {
      if (sortBy.direction === Direction.Down) {
        return SortUpIcon;
      }
      return SortDownIcon;
    }
    return SortNoneIcon;
  };

  const getData = useCallback(async () => {
    if (!loading && !error) {
      const result = await get('/account/classes');
      if (fetchResponse.ok) {
        setAccountClasses(result ?? []);
        await put('/account/actions/classes/visited');
      } else {
        // TODO show toast
      }
    }
  }, [error, fetchResponse.ok, get, loading, put]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const changeVisibility = (id: string, enable: boolean) => async () => {
    await put(`/account/classes/${id}`, { enable: !enable });
    if (response.ok) {
      const acctClasses = accountClasses?.map((accountClass) => {
        if (accountClass?.id === id) {
          return {
            ...accountClass,
            enable: !enable,
          };
        }
        return { ...accountClass };
      });
      setAccountClasses(acctClasses);
    }
  };

  const onSortClick = (name: string) => () => {
    if (sortBy?.name === name) {
      setSortBy({
        name,
        direction: sortBy?.direction === Direction.Up ? Direction.Down : Direction.Up,
      });
    } else {
      setSortBy({
        name,
        direction: Direction.Up,
      });
    }
  };

  if (error) {
    return <ErrorPage />;
  }
  if (loading && !accountClasses?.length) {
    return <Loader open />;
  }

  const notVisitedClasses = accountClasses?.filter((item) => !item.visited)?.sort(sortAccountData(sortBy)) ?? [];
  const currentClasses = accountClasses?.filter((item) => !!item.visited)?.sort(sortAccountData(sortBy)) ?? [];

  const onDownload = () => {
    const allClasses = [...notVisitedClasses, ...currentClasses];
    if (allClasses?.length) {
      const acctBalances = allClasses.map((accountClass: any) => ({
        ...accountClass,
        active: accountClass.active ? 'Active' : 'Inactive',
      }));
      const csvData = Papa.unparse({
        data: acctBalances,
        fields: ['quickbookId', 'className', 'active'],
      }, {
        header: false,
      });
      const downloadData = `${['Class Id', 'Class Name', 'Active'].join(',')}\n${csvData}`;
      fileDownload(downloadData, 'classes.csv');
    }
  };

  const renderClass = (accountClass: AccountClass) => (
    <TableRow
      key={accountClass.id}
      className={!accountClass.active ? classes.opacity4 : ''}
      hover
    >
      <TableCell className={!accountClass.visited ? classes.bold : ''}>
        {accountClass.quickbookId}
      </TableCell>
      <TableCell className={!accountClass.visited ? classes.bold : ''}>
        {accountClass.className}
      </TableCell>
      <TableCell className={!accountClass.visited ? classes.bold : ''}>
        {accountClass.active ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell align="left">
        <IconButton
          className={classes.noOffset}
          disabled={!accountClass.active}
          onClick={changeVisibility(accountClass.id, accountClass.enable)}
        >
          {
            !accountClass.enable && (
              <HideVisibilityIcon />
            )
          }
          {
            accountClass.enable && (
              <VisibilityIcon />
            )
          }
        </IconButton>
      </TableCell>
    </TableRow>
  );

  return (
    <Box
      padding={2}
      width="100%"
    >
      <Loader open={loading} />
      <Snackbar
        open={Boolean(requestError)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">
          {requestError?.message}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <ButtonBase onClick={onDownload}>
          <Typography color="primary" className={classes.fontSize13}>Export</Typography>
          <ExportIcon fill={COLORS.skyBlue} className={classes.exportIcon} fontSize="small" />
        </ButtonBase>
      </Box>
      <Table
        size="small"
      >
        <TableHead>
          <TableRow
            hover={false}
          >
            <TableCell>
              <TableSortLabel IconComponent={getSortIcon('quickbookId')} onClick={onSortClick('quickbookId')}>
                CLASS ID
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel IconComponent={getSortIcon('className')} onClick={onSortClick('className')}>
                Class Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              Active/Inactive
            </TableCell>
            <TableCell>
              <TableSortLabel IconComponent={getSortIcon('enable')} onClick={onSortClick('enable')}>
                Visible
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notVisitedClasses.map((accountClass: AccountClass) => renderClass(accountClass))}
          {currentClasses.map((accountClass: AccountClass) => renderClass(accountClass))}
        </TableBody>
      </Table>
    </Box>

  );
};

export default React.memo(Classes);
