import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { createStyles, TextField } from '@material-ui/core';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';

import { lastDayOfMonth, startOfMonth } from 'date-fns';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { JournalEntryTop, JournalEntryLabel } from '../JournalEntryListing/JournalEntryHeader.styled';
import COLORS from '../../variables/colors';
import Loader from '../../components/Loader/loader';
import FactaDatePicker from '../../components/DatePicker';
import { PrimaryButton } from '../../components/Buttons';
import { JournalEntry } from '../../variables/types';
import SubledgerAccountName from '../../components/Subledger/SubledgerAccountName';
import { fromUTC } from '../../util/timezone';

const useStyles = makeStyles(() => createStyles({
  datepicker: {
    background: COLORS.white,
    borderRadius: 2,
    width: 140,
    maxWidth: 150,
    '& input': {
      background: COLORS.white,
      maxWidth: '90px',
    },
  },
  fullWidth: {
    width: '100%',
  },
  jeDescription: {
    background: COLORS.lightGray3,
    margin: 0,
    width: 400,
    '& p': {
      position: 'absolute',
      top: -25,
      right: 0,
    },
    '& input': {
      paddingTop: 14.5,
      paddingBottom: 14.5,
    },
    '& fieldset': {
      border: 0,
    },
  },
  margin0: {
    margin: 0,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  navigateBack: {
    textTransform: 'none',
    paddingBottom: 0,
    marginBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    fontWeight: 'normal',
  },
}));
interface Props {
  journalEntry?: JournalEntry | null;
}
const JournalEntryViewHeader = ({ journalEntry }:Props) => {
  const { loading } = useFetch();
  const classes = useStyles();
  const history = useHistory();
  const CHARACTER_LIMIT = 140;
  const [values, setValues] = useState(journalEntry?.description!);
  const [selectedDate, handleDateChange] = useState(fromUTC(journalEntry?.txnDate as string));

  const changeDate = (date: Date | ((prevState: Date) => Date) | null) => {
    // @ts-ignore
    handleDateChange(date);
  };

  const navigateToJournalEntries = () => {
    history.goBack();
  };

  // @ts-ignore
  return (
    <>
      <Loader open={loading} />
      <PrimaryButton
        onClick={navigateToJournalEntries}
        color="primary"
        style={{ color: COLORS.skyBlue }}
        className={classes.navigateBack}
      >
        <ArrowBackIcon />
        Back to All Journal Entries
      </PrimaryButton>
      <JournalEntryTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        paddingTop="0px !important"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderRight="1px solid #CFD3DA"
          paddingLeft="38px"
          paddingRight="38px"
          height="70px"
          paddingTop="0px"
        >
          <SubledgerAccountName account={journalEntry?.subledger?.account!} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            marginTop="10px"
            height="70px"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
          height="70px"
        >
          <JournalEntryLabel>
            JE Number:
          </JournalEntryLabel>
          <p>
            {journalEntry?.jeNumber}
          </p>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
          height="70px"
        >

          <JournalEntryLabel>
            Posting Date
          </JournalEntryLabel>
          <FactaDatePicker
            selectedDate={selectedDate}
            onDateChange={(date) => changeDate(date)}
            className={classes.datepicker}
            minDate={startOfMonth(new Date())}
            maxDate={lastDayOfMonth(new Date())}
            readOnly
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          paddingLeft="19px"
          width="400px"
          height="70px"
        >
          <JournalEntryLabel>
            JE Description
          </JournalEntryLabel>
          <TextField
            inputProps={{
              maxlength: CHARACTER_LIMIT,
              readOnly: Boolean(true),
              disabled: Boolean(true),
            }}
            value={values}
            helperText={`${values.length}/${CHARACTER_LIMIT}`}
            margin="normal"
            variant="outlined"
            className={classes.jeDescription}
            onChange={(e) => setValues(e.target.value)}
          />
        </Box>
      </JournalEntryTop>
    </>
  );
};

export default JournalEntryViewHeader;
