import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import COLORS from '../../variables/colors';

export const JournalEntryTop = styled(Box)({
  paddingTop: 20, paddingBottom: 20,
});

export const JournalEntryLabel = styled(Typography)({
  fontSize: 13,
  marginRight: 9,
  color: COLORS.medGray,
});
export const CurrencyLabel = styled(Typography)({
  fontSize: 18,
  fontWeight: 500,
  color: COLORS.medGray,
});
