import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { createStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import COLORS from '../../variables/colors';
import { PrimaryButton } from '../../components/Buttons';
import { JournalEntryLabel } from './JournalEntryHeader.styled';
import FactaDatePicker from '../../components/DatePicker';
import { JournalEntryFilterDates } from '../../variables/types';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: '100%',
    marginTop: 5,
  },
  fullWidth: {
    width: '100%',
  },
  datepicker: {
    background: COLORS.white,
    borderRadius: 2,
    width: 140,
    maxWidth: 150,
    '& input': {
      background: COLORS.white,
      maxWidth: '90px',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    marginRight: 24,
    marginTop: 16,
    color: COLORS.lightGray2,
    fontSize: 30,
  },
  filterTitle: {
    fontSize: 20,
    color: COLORS.lightGray,
    fontWeight: 'normal',
  },
  marginSpan: {
    marginLeft: 10,
    marginRight: 10,
  },
  marginTopTypo: {
    marginTop: 10,
  },
  colorBlue: {
    color: `${COLORS.skyBlue} !important`,
    textTransform: 'none',
  },
  viewDatesButton: {
    marginLeft: 0,
    paddingLeft: 0,
    fontSize: 12,
  },
  drawerPaper: {
    minWidth: 300,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 0,
    boxShadow: COLORS.filterBoxShadow,
    border: 'none',
  },
  applyButton: {
    position: 'absolute',
    bottom: '3%',
  },
}));

interface Props {
  open: boolean
  setOpen: any;
  filterDates: JournalEntryFilterDates | null;
  setDates: (dates: JournalEntryFilterDates| null) => void,
  startDate: Date;
  endDate: Date;
}

const JournalEntryFilter = ({ open, setOpen, setDates, filterDates, startDate, endDate }:Props) => {
  const [start, setStartDate] = useState<Date | null>(filterDates?.startDate ?? startDate);
  const [end, setEndDate] = useState<Date | null>(filterDates?.endDate ?? endDate);
  const classes = useStyles();

  const changeStartDate = (date: Date | null) => {
    setStartDate(date);
  };
  const changeEndDate = (date: Date | null) => {
    // @ts-ignore
    setEndDate(date);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ classes: { root: classes.drawerPaper } }}
      disableEnforceFocus
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        flexDirection="row"
        borderBottom="1px solid #CFD3DA"
      >
        <h2 className={classes.filterTitle}>
          FILTER
        </h2>
        <CloseIcon onClick={() => setOpen(false)} className={classes.closeIcon} />
      </Box>

      <JournalEntryLabel className={classes.marginTopTypo}>
        PERIOD
      </JournalEntryLabel>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        flexDirection="row"
        alignItems="center"
        marginTop="5px"
      >
        <FactaDatePicker
          selectedDate={start}
          onDateChange={changeStartDate}
          className={classes.datepicker}
          minDate={startDate}
        />
        <span className={classes.marginSpan}> to </span>
        <FactaDatePicker
          selectedDate={end}
          onDateChange={changeEndDate}
          className={classes.datepicker}
          minDate={startDate}
        />
      </Box>
      <Box>
        <PrimaryButton
          color="primary"
          className={clsx(classes.colorBlue, classes.viewDatesButton)}
          onClick={() => setDates({ startDate, endDate })}
        >
          View All Dates
        </PrimaryButton>
      </Box>
      <PrimaryButton
        variant="contained"
        color="primary"
        className={classes.applyButton}
        onClick={() => {
          setDates({ startDate: start!, endDate: end! });
        }}
      >
        APPLY
      </PrimaryButton>
    </Drawer>
  );
};
export default JournalEntryFilter;
