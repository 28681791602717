import { makeStyles, styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import COLORS from '../../../variables/colors';

const cardStyles = makeStyles({
  root: {
    marginLeft: '2.5%',
    marginTop: '2.5%',
    textAlign: 'left',
  },
  card: {
    minWidth: 360,
    boxShadow: '0px 2px 12px rgba(24, 43, 88, 0.13)',
  },
  title: {
    fontSize: 14,
    color: COLORS.deepGray,
    fontWeight: 600,
    paddingTop: 20,
  },
  accountStatus: {
    height: 22,
    width: 22,
    background: COLORS.successGreen,
    borderRadius: 12,
    margin: 0,
    marginTop: '-5px',
    marginRight: '-12px',
    color: COLORS.white,
    position: 'relative',
    fontSize: 11,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pendingAssets: {
    background: COLORS.violet,
  },
  cardType: {
    display: 'flex',
    justifyContent: 'space-between',
    '& h4': {
      margin: 0,
      fontSize: 10,
      fontWeight: 'normal',
      color: COLORS.lightGray,
    },
  },
  factaBalance: {
    color: COLORS.violet,
  },
  schedule: {
    color: COLORS.deepGray,
  },
  amortization: {
    fontSize: 13,
    color: COLORS.lightGray,
  },
  pendingTasks: {
    background: COLORS.violet,
    border: `1px solid ${COLORS.violet}`,
    '&:hover': {
      border: `1px solid ${COLORS.hoverBlue}`,
    },
  },
  completedTasks: {
    background: COLORS.successGreen,
    border: `1px solid ${COLORS.successGreen}`,
  },
  checkComplete: {
    fontSize: 20,
    color: COLORS.white,
  },
  checkCompleted: {
    fontSize: 14,
    marginLeft: 9,
    color: COLORS.successGreen,
  },
  closedTag: {
    display: 'flex',
    fontSize: 10,
    fontWeight: 500,
    color: COLORS.successGreen,
    '& span': {
      marginRight: 14,
    },
  },
  active: {
    borderLeft: `4px solid ${COLORS.violet}`,
  },
  disabled: {
    opacity: 0.5,
  },
  navigatePrepaid: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  postDisabled: {
    background: `${COLORS.lightGray2} !important`,
    color: `${COLORS.white} !important`,
  },
  padding15: {
    paddingLeft: 15,
    paddingRight: 15,
  },
});

export const AccountLabel = styled(Typography)({
  margin: 0,
  fontSize: 10,
  fontWeight: 'normal',
  color: COLORS.lightGray,
});

export default cardStyles;
