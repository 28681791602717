/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BalanceIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    height={20}
    width={24}
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.17752 14.6782L9.41072 14.422V13.1577L5.59373 5.09912H3.81699L0 13.1577V14.4837L0.207059 14.7085C0.776557 15.3264 1.46793 15.8196 2.23757 16.1569C3.0072 16.4942 3.83839 16.6683 4.67869 16.6683H4.69961C5.54238 16.6654 6.37535 16.4874 7.14567 16.1455C7.91599 15.8037 8.60684 15.3054 9.17438 14.6824L9.17856 14.6772L9.17752 14.6782ZM7.84209 13.806C7.00774 14.6366 5.87794 15.1022 4.70065 15.1007H4.65464C3.50249 15.1023 2.39481 14.656 1.56549 13.8562L1.56654 13.8573V13.5111L4.70379 6.8884L7.84105 13.5111L7.84209 13.806Z"
    />
    <path
      d="M19.7115 5.09912H17.9347L14.1177 13.1577V14.4837L14.3248 14.7085C14.8943 15.3264 15.5857 15.8196 16.3553 16.1569C17.1249 16.4942 17.9561 16.6683 18.7964 16.6683H18.8174C19.6601 16.6654 20.4931 16.4874 21.2634 16.1455C22.0337 15.8037 22.7246 15.3054 23.2921 14.6824L23.2963 14.6772L23.5295 14.421V13.1566L19.7115 5.09912ZM21.9598 13.806C21.1255 14.6366 19.9957 15.1022 18.8184 15.1007H18.7724C17.6202 15.1023 16.5126 14.656 15.6832 13.8562L15.6843 13.8573V13.5111L18.8215 6.8884L21.9588 13.5111L21.9598 13.806Z"
    />
    <path
      d="M12.5479 5.37621C12.9805 5.24513 13.3745 5.01008 13.6953 4.69161C14.0162 4.37314 14.2541 3.98094 14.3884 3.54928L14.3936 3.52941H19.6067V1.96078H14.3936C14.0454 0.817778 12.9996 0 11.7636 0C10.5275 0 9.48173 0.817778 9.13873 1.94092L9.1335 1.96078H3.92043V3.52941H9.1335C9.26458 3.96205 9.49962 4.35601 9.81809 4.67685C10.1366 4.99769 10.5288 5.23565 10.9604 5.36993L10.9803 5.37516V18.4314H5.88226V20H17.647V18.4314H12.5489L12.5479 5.37621ZM10.5871 2.74614C10.5871 2.51346 10.6561 2.286 10.7854 2.09253C10.9146 1.89906 11.0984 1.74827 11.3133 1.65923C11.5283 1.57018 11.7649 1.54688 11.9931 1.59228C12.2213 1.63767 12.4309 1.74972 12.5955 1.91425C12.76 2.07879 12.872 2.28841 12.9174 2.51663C12.9628 2.74484 12.9395 2.98139 12.8505 3.19636C12.7614 3.41133 12.6106 3.59507 12.4172 3.72434C12.2237 3.85362 11.9962 3.92261 11.7636 3.92261C11.4515 3.92261 11.1523 3.79867 10.9317 3.57803C10.711 3.3574 10.5871 3.05816 10.5871 2.74614Z"
    />
  </SvgIcon>
);

export default BalanceIcon;
