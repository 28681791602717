import { SUBLEDGER_STORAGE_KEY } from '../variables/constants';
import { PrepaidAsset, Subledger } from '../variables/types';

interface State {
  subledger: Subledger;
  historicalUpdatedAssets: Array<PrepaidAsset>;
}

export const setSubledger = (state: State) => {
  localStorage.setItem(SUBLEDGER_STORAGE_KEY, JSON.stringify(state));
};

export const getSubledger = () => (localStorage.getItem(SUBLEDGER_STORAGE_KEY)
// @ts-ignore
  ? JSON.parse(localStorage.getItem(SUBLEDGER_STORAGE_KEY)) as State : { subledger: {}, historicalUpdatedAssets: null });

export const deleteSubledger = () => localStorage.removeItem(SUBLEDGER_STORAGE_KEY);
