import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import COLORS from '../../../variables/colors';
import { FREEZE_PANEL_Z_INDEX } from '../../../variables/constants';

export const SchedulerTop = styled(Box)({
  paddingTop: 20,
  paddingBottom: 20,
  background: COLORS.white,
  zIndex: FREEZE_PANEL_Z_INDEX,
});

export const SchedulerLabel = styled(Typography)({
  fontSize: 13,
  marginRight: 9,
  color: COLORS.medGray,
});
export const CurrencyLabel = styled(Typography)({
  fontSize: 18,
  fontWeight: 500,
  color: COLORS.medGray,
});
