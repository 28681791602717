import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Table from '@material-ui/core/Table';
import {
  Box,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import cloneDeep from 'lodash.clonedeep';
import Loader from '../../../components/Loader/loader';
import {
  NotifierType,
  PrepaidAsset, ScheduleType,
} from '../../../variables/types';
import {
  getChildren,
  getTotalAndRowsCount,
  updateAssetsForSaving,
  updateHistoricalSubledgerData,
} from '../../../components/Subledger/common';
import PostJEHeader from './PostJEHeader';
import reducer from '../../../components/Subledger/reducer';
import isEmpty from '../../../util/isEmpty';
import PostJETableHeader from '../../../components/Subledger/PostJETableHeader';
import PostJERow from '../../../components/Subledger/PostJERow';
import PostJETableFooter from '../../../components/Subledger/PostJETableFooter';
import PostJEPrepaidAccountRow from '../../../components/Subledger/PostJEPrepaidAccountRow';
import { deleteSubledger, getSubledger } from '../../../util/subledger';
import DialogBox from '../../../components/DialogBox';
import ErrorPage from '../../../components/ErrorPage';
import { openSnackbar } from '../../../components/Notifier';
import {
  ASSET_SCHEDULED_STATUS,
  ASSET_TEMP_SCHEDULED_STATUS, INITIALED,
  INITIALED_WITH_LOCAL_STORAGE,
} from '../../../variables/constants';

interface Props {
  scheduleDate: string;
}

const PostJE = ({ scheduleDate }: Props) => {
  const { id: subledgerId } = useParams();
  const currentMonth = new Date(scheduleDate).toLocaleString('en-us', { month: 'long' });
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);
  const [openPostJEAlert, setPostJEAlert] = useState<boolean>(false);
  const history = useHistory();
  const { loading, error, data: journalEntryData } = useFetch(`journal-entries/${subledgerId}/subledger?scheduleDate=${scheduleDate}`, []);
  const { patch, loading: onUpdating, response: updateResponse } = useFetch(`/subledgers/${subledgerId}/historical/assets`);
  const { get, loading: subledgerLoading, error: subLedgerError, data, response } = useFetch(`/subledgers/${subledgerId}/details`);
  const location = useLocation();
  const useQuery = () => new URLSearchParams(location.search);
  const fromDashboard = Boolean(useQuery().get('fromDashboard'));

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {} });

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subLedgerError) {
      const result = await get(`?scheduleDate=${scheduleDate}`);
      if (response.ok) {
        dispatch({
          type: INITIALED,
          payload: {
            subledger: result,
          },
        });
      }
    }
  }, [get, response.ok, scheduleDate, subLedgerError, subledgerLoading]);

  const getDataFromLocalStorage = useCallback(() => {
    const { subledger: localSubledger, historicalUpdatedAssets: localHistoricalPrepaidAssets } = getSubledger();
    dispatch({
      type: INITIALED_WITH_LOCAL_STORAGE,
      payload: {
        subledger: localSubledger,
        historicalUpdatedAssets: localHistoricalPrepaidAssets,
        scheduleDate,
      },
    });
  }, [scheduleDate]);

  useEffect(() => {
    if (fromDashboard) {
      getData();
    } else {
      getDataFromLocalStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { subledger, historicalUpdatedAssets } = state;

  if (error || subLedgerError) {
    return <ErrorPage />;
  }

  if (isEmpty(subledger) || loading || subledgerLoading) {
    return <Loader open />;
  }

  const onCancel = () => {
    if (historicalUpdatedAssets?.length > 0) {
      setOpenSubledgerDialog(true);
    } else {
      navigateBack();
    }
  };

  const onPostJE = async () => {
    try {
      setPostJEAlert(false);
      // get list of manual assets
      const subledgerPrepaidAssets = subledger?.prepaidAssets;
      const manualAssets = subledgerPrepaidAssets
          ?.filter((asset: PrepaidAsset) => asset?.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual
              || asset?.status === ASSET_TEMP_SCHEDULED_STATUS);

      let assetsForUpdate = cloneDeep(historicalUpdatedAssets ?? []);

      manualAssets?.forEach((manualAsset: PrepaidAsset) => {
        if (!assetsForUpdate?.some((asset: PrepaidAsset) => asset.internalId === manualAsset.internalId)) {
          if (manualAsset?.parentId) {
            const parentAsset = subledgerPrepaidAssets
                ?.find((asset: PrepaidAsset) => asset.internalId === manualAsset?.parentId);
            assetsForUpdate = assetsForUpdate
                ?.filter((asset: PrepaidAsset) => !(asset.internalId === parentAsset.internalId || asset.parentId === parentAsset.internalId));
            // update historical assets
            assetsForUpdate.push(cloneDeep(parentAsset));
            const children = getChildren(parentAsset.internalId, state?.subledger?.prepaidAssets);
            children?.forEach((child) => assetsForUpdate.push(cloneDeep(child)));
          } else {
            // update historical assets
            assetsForUpdate.push(cloneDeep(manualAsset));
          }
        }
      });
      const prepaidAssets = cloneDeep(assetsForUpdate);
      updateAssetsForSaving(prepaidAssets);
      await patch(prepaidAssets);
      if (updateResponse.ok) {
        history.push('/');
      } else {
        openSnackbar({ message: updateResponse.data }, NotifierType.Error);
      }
    } catch (err) {
      openSnackbar({ message: updateResponse.data }, NotifierType.Error);
    }
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
    setPostJEAlert(false);
  };

  const navigateBack = () => {
    deleteSubledger();
    setOpenSubledgerDialog(false);
    history.push(`/journal-entries/?account_id=${subledger?.account?.id}`);
  };

  const setAlertDialog = () => {
    setPostJEAlert(true);
  };

  const renderAsset = () => (
    <>
      {
        subledger?.prepaidAssets?.filter((asset: PrepaidAsset) => {
          const children = getChildren(asset?.id, subledger?.prepaidAssets!);
          return children?.length === 0;
        }).map((asset: PrepaidAsset, index: number) => (
          <PostJERow
            key={asset?.internalId}
            asset={asset}
            index={index}
            prepaidAssets={subledger?.prepaidAssets}
            scheduleDate={scheduleDate}
          />
        ))
      }
    </>
  );

  const navigateToPrepareJE = () => {
    updateHistoricalSubledgerData(historicalUpdatedAssets, subledger, data);
    history.push(`/historical/subledgers/schedule/${subledger.id}/prepare-je/?scheduleDate=${scheduleDate}`);
  };

  const filterPrepaidAssets = subledger?.prepaidAssets?.map((asset:PrepaidAsset) => ({
    ...asset,
    status: ASSET_SCHEDULED_STATUS,
  }));

  const { totalPrepaidAccount, rowsCount, total } = (
    getTotalAndRowsCount(filterPrepaidAssets, scheduleDate) ?? { totalPrepaidAccount: 0, rowsCount: 0, total: 0 }
  );

  return (
    <>
      <Loader open={onUpdating} />
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page? \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{ title: 'YES', event: navigateBack }]}
      />
      <DialogBox
        openDialog={openPostJEAlert}
        closeDialog={closeDialog}
        dialogContext={'You are about to edit a historical period. \n Do you want to proceed?'}
        dialogTitle="ALERT"
        dismissContext="Cancel"
        actions={[{ title: 'YES', event: onPostJE }]}
      />
      <Box
        width="100%"
      >
        <PostJEHeader
          subledger={subledger}
          scheduleDate={scheduleDate}
          currentMonth={currentMonth}
          onCancel={onCancel}
          journalEntry={journalEntryData}
          onPostJE={setAlertDialog}
          navigateToPrepareJE={navigateToPrepareJE}
        />
        <Box padding={1}>
          <Table
            size="small"
            id="journalEntryTable"
          >
            <PostJETableHeader />
            <TableBody>
              {
                renderAsset()
              }
              <PostJEPrepaidAccountRow
                rowsCount={rowsCount}
                account={subledger?.account}
                currentMonth={currentMonth}
                totalPrepaidAccount={totalPrepaidAccount}
              />
            </TableBody>
            <PostJETableFooter total={total} />
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default PostJE;
