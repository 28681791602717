import React, { useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import { useHistory } from 'react-router-dom';
import { Box, createStyles, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import useFetch from 'use-http';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import { endOfMonth, format, lastDayOfMonth, startOfMonth, subDays } from 'date-fns';
import roundTo from 'round-to';
import { useLocation } from 'react-router';

import JournalEntryHeader from './JournalEntryHeader';
import COLORS from '../../variables/colors';
import JournalEntryFilter from './JournalEntryFilter';
import { PrimaryButton } from '../../components/Buttons';
import ErrorPage from '../../components/ErrorPage';
import {
  AccountClass,
  ErrorPageType,
  JournalEntryLineItem,
  NotifierType,
  ReportType,
  Subledger,
  Vendor,
  Account,
  JournalEntryFilterDates, JournalEntry,
} from '../../variables/types';
import Loader from '../../components/Loader/loader';
import { openSnackbar } from '../../components/Notifier';
import { CREDIT, FULL_DAY_FORMAT, DAY_SHORT_FORMAT, DEBIT } from '../../variables/constants';

import { fromUTC } from '../../util/timezone';
import {
  getAccountName,
  getAccountNameFromList,
  getClassName,
  getVendorName, initializedSubledger,
} from '../../components/Subledger/common';
import { useAccountProvider } from '../../core/accountContext';
import getFullName from '../../util/user';
import currencyFormatter from '../../util/currencyFormatter';
import NoRecords from '../Dashboard/NoRecords';
import getSubledgerStartEndDate from '../../util/getSubledgerStartEndDate';
import { deleteSubledger } from '../../util/subledger';
import isEmpty from '../../util/isEmpty';

const useStyles = makeStyles(() => createStyles({
  root: {
    '& td': {
      color: COLORS.deepGray,
    },
  },
  fontSize13: {
    fontSize: 13,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  editIcon: {
    height: 12,
    width: 12,
    marginLeft: 5,
  },
  colorBlue: {
    color: `${COLORS.skyBlue} !important`,
    textTransform: 'none',
  },
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
  width5: {
    width: '5%',
  },
  positionRelative: {
    position: 'relative',
  },
}));

const downloadJournalEntrySummary = (data: Subledger) => {
  const journalEntries = data?.journalEntries.map((journalEntry: any) => ({
    ...journalEntry,
    status: 'Posted',
    amount: roundTo(journalEntry.amount, 2),
    txnDate: format(fromUTC(journalEntry.txnDate), FULL_DAY_FORMAT),
    glAccount: getAccountName(data?.account),
    user: getFullName(journalEntry.user),
  }));
  const csvData = Papa.unparse({
    data: journalEntries,
    fields: ['txnDate', 'jeNumber', 'jeNumber', 'glAccount', 'description', 'user', 'amount', 'status'],
  }, {
    header: false,
  });
  const downloadData = `${['JE Date', 'Facta JE #', 'GL JE #', 'GL Account', 'JE Description', 'User', 'Amount', 'Status'].join(',')}\n${csvData}`;
  fileDownload(downloadData, 'Journal Entries Summary.csv');
};

const downloadJournalEntryDetail = (data: Subledger, vendors: Array<Vendor>,
  accountClasses: Array<AccountClass>, accountIncomes:Array<Account>) => {
  const journalEntries: any = [];
  data?.journalEntries.forEach((journalEntry: any) => {
    journalEntry?.journalEntryLineItems?.forEach((lineItem: JournalEntryLineItem, index: number) => {
      journalEntries.push({
        jeNumber: journalEntry.jeNumber,
        txnDate: format(fromUTC(journalEntry.txnDate), FULL_DAY_FORMAT),
        amount: roundTo(journalEntry.amount, 2),
        glAccount: getAccountName(data?.account),
        user: getFullName(journalEntry.user),
        description: journalEntry.description,
        lineNumber: index + 1,
        lineDescription: lineItem.description,
        // @ts-ignore
        vendor: getVendorName(lineItem?.vendorId, vendors),
        expenseAccount: getAccountNameFromList(lineItem?.accountId, accountIncomes),
        class: getClassName(lineItem?.classId!, accountClasses),
        dr: lineItem?.postingType === DEBIT ? lineItem.amount : '',
        cr: lineItem?.postingType === CREDIT ? lineItem.amount : '',
      });
    });
  });
  const csvData = Papa.unparse({
    data: journalEntries,
    fields: ['txnDate', 'jeNumber', 'jeNumber', 'glAccount', 'description', 'lineNumber',
      'lineDescription', 'vendor', 'expenseAccount', 'class', 'user', 'amount', 'dr', 'cr'],
  }, {
    header: false,
  });
  const downloadData = `${['JE Date', 'Facta JE #', 'GL JE #', 'GL Account', 'JE Description', 'Line Number',
    'Line Description', 'Vendor', 'GL Expense Account', 'Class', 'User', 'Amount', 'DR', 'CR'].join(',')}\n${csvData}`;
  fileDownload(downloadData, 'Journal Entries Detail.csv');
};

const JournalEntryListView = () => {
  // eslint-disable-next-line no-debugger
  const classes = useStyles();
  const history = useHistory();
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [filterDates, setFilterDates] = useState<JournalEntryFilterDates | null>(null);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const [accountId, setAccountId] = useState<string>(useQuery().get('accountId') ?? '');
  const [filterApplied, setFilterApplied] = useState(false);
  const [subledger, setSubledger] = useState<Subledger | null>(null);

  // @ts-ignore
  const { get, loading, error, response, abort } = useFetch();
  const { get: getCSV, loading: csvLoading } = useFetch('/journal-entries', []);
  const { data: accountData, loading: loadingAccount } = useFetch('/account/balances?accountDetail=PrepaidExpenses&checkSubledgers=true', []);
  const { account } = useAccountProvider();
  const { vendors, accountClasses, accountIncomes } = account;

  const { startDate, endDate } = getSubledgerStartEndDate(subledger);
  const previousMonth = subledger?.subledgerAmortizationLogs?.length ? endOfMonth(subDays(startOfMonth(endDate), 1)) : endDate;
  const getData = useCallback(async () => {
    if (!loading && !error && (!accountId || filterApplied || accountId !== subledger?.accountId)) {
      abort();
      const url = `/journal-entries?account_id=${accountId}${filterDates && filterApplied ? `&start=${format(filterDates?.startDate!, 'yyyy-LL-dd')}&end=${format(filterDates?.endDate!, 'yyyy-LL-dd')}` : ''}`;
      const result = await get(url);
      if (response.ok) {
        const data = initializedSubledger({ subledger: result });
        setSubledger(data);
        const { startDate: subledgerStartDate, endDate: subledgerEndDate } = getSubledgerStartEndDate(data);
        const subledgerLastClosingMonth = data?.subledgerAmortizationLogs?.length ? endOfMonth(subDays(startOfMonth(subledgerEndDate), 1)) : subledgerEndDate;
        if (!filterDates) {
          const journalEntryFilterDates: JournalEntryFilterDates = { startDate: subledgerStartDate, endDate: subledgerLastClosingMonth };
          setFilterDates(journalEntryFilterDates);
        }
      }
    }
  }, [abort, accountId, error, filterApplied, filterDates, get, loading, response.ok, subledger]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, filterDates]);

  if (error) {
    return (
      <ErrorPage variant={ErrorPageType.Error} />
    );
  }

  if (loading || loadingAccount) {
    return <Loader open />;
  }

  if (isEmpty(subledger) || !subledger) {
    return <NoRecords />;
  }

  const onDateChange = (journalEntryFilterDates: JournalEntryFilterDates | null) => {
    setAccountId(subledger?.account?.id!);
    setFilterVisibility(false);
    setFilterDates(journalEntryFilterDates);
    setFilterApplied((journalEntryFilterDates?.startDate !== startDate || journalEntryFilterDates?.endDate !== previousMonth));
  };

  const onAccountChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    // @ts-ignore
    setFilterDates(null);
    setFilterApplied(false);
    history.replace(`/journal-entries/?accountId=${event.target.value}`);
    setAccountId(event.target.value as string);
  };

  const onCSVExport = async (type: ReportType) => {
    if (isEmpty(subledger) || !subledger?.account?.id) {
      return;
    }
    if (type === ReportType.Detail) {
      try {
        const result = await getCSV(`?account_id=${subledger?.account?.id}&type=detail`);
        if (!response.ok) {
          const errorMessage = response.data;
          openSnackbar({ message: errorMessage }, NotifierType.Error);
        }
        downloadJournalEntryDetail(result, vendors, accountClasses, accountIncomes);
      } catch (err) {
        const errorMessage = response.data;
        openSnackbar({ message: errorMessage }, NotifierType.Error);
      }
    } else if (subledger?.journalEntries && subledger?.journalEntries.length) {
      downloadJournalEntrySummary(subledger);
    }
  };

  const navigateToViewJournalEntry = (id: string) => () => {
    history.push(`/journal-entries/${id}`);
  };

  const navigateToHistoricalEditingPage = (txtDate: string) => () => {
    deleteSubledger();
    const scheduleDate = lastDayOfMonth(fromUTC(txtDate));
    history.push(`/historical/subledgers/schedule/${subledger?.id}/?scheduleDate=${format(scheduleDate, DAY_SHORT_FORMAT)}`);
  };

  const renderRow = (journalEntry: JournalEntry) => (
    <TableRow hover className={classes.root}>
      <TableCell>
        {journalEntry?.jeNumber}
      </TableCell>
      <TableCell>
        {journalEntry?.jeNumber}
      </TableCell>
      <TableCell>
        {getAccountName(subledger?.account!)}
      </TableCell>
      <TableCell>
        {format(fromUTC(journalEntry.txnDate as string), FULL_DAY_FORMAT)}
      </TableCell>
      <TableCell>
        {journalEntry?.description}
      </TableCell>
      <TableCell>
        {getFullName(journalEntry?.user)}
      </TableCell>
      <TableCell>
        {currencyFormatter.format(journalEntry.amount)}
      </TableCell>
      <TableCell>
        Posted
      </TableCell>
      <TableCell className={clsx(classes.colorBlue, classes.width5)}>
        <PrimaryButton
          onClick={navigateToViewJournalEntry(journalEntry?.id)}
          color="primary"
          className={classes.colorBlue}
        >
          View
        </PrimaryButton>
      </TableCell>
      <TableCell className={clsx(classes.colorBlue, classes.width5)}>
        <PrimaryButton
          color="primary"
          className={classes.colorBlue}
          onClick={navigateToHistoricalEditingPage(journalEntry.txnDate as string)}
        >
          Edit
          <EditOutlinedIcon className={clsx(classes.colorBlue, classes.editIcon)} />
        </PrimaryButton>
      </TableCell>
      <TableCell />
    </TableRow>
  );

  const renderAsset = () => (
    <>
      {
          subledger?.journalEntries ? subledger
              ?.journalEntries?.map((journalEntry: JournalEntry) => renderRow(journalEntry)) : <NoRecords />
      }
    </>
  );

  // @ts-ignore
  return (
    <>
      {
        filterVisibility && (
          <JournalEntryFilter
            open={filterVisibility}
            setOpen={setFilterVisibility}
            setDates={onDateChange}
            filterDates={filterDates}
            startDate={startDate}
            endDate={previousMonth}
          />
        )
      }
      <Box
        width="100%"
      >
        <Loader open={csvLoading} />
        <JournalEntryHeader
          downloadCSV={onCSVExport}
          toggleFilter={setFilterVisibility}
          subledger={subledger!}
          accounts={accountData}
          onAccountChange={onAccountChange}
          filterApplied={filterApplied}
        />
        <Box padding={1}>
          <Table
            size="small"
            className={classes.positionRelative}
          >
            <TableHead>
              <TableRow
                hover={false}
              >
                <TableCell>
                  FACTA JE #
                </TableCell>
                <TableCell>
                  GL JE #
                </TableCell>
                <TableCell>
                  GL ACCOUNT
                </TableCell>
                <TableCell>
                  JE DATE
                </TableCell>
                <TableCell>
                  JE DESCRIPTION
                </TableCell>
                <TableCell>
                  USER
                </TableCell>
                <TableCell>
                  AMOUNT
                </TableCell>
                <TableCell>
                  STATUS
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {renderAsset()}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default JournalEntryListView;
