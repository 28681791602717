/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState, useMemo } from 'react';
// @ts-ignore
const RefreshDataContext = createContext();

export default (props: any) => {
  const [refreshDate, setRefreshDate] = useState<Date | null>();

  const value = useMemo(() => ({
    refreshDate,
    setRefreshDate,
  }), [refreshDate]);
  return <RefreshDataContext.Provider value={value} {...props} />;
};

export const useRefreshDataProvider = () => {
  const context = useContext(RefreshDataContext);

  if (!context) {
    throw new Error('useRefreshDataProvider() has to be used within a child of the Refresh Provider');
  }

  const {
    refreshDate,
    setRefreshDate,
  }:any = context;

  return {
    refreshDate,
    setRefreshDate,
  };
};
