import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, createStyles, Tooltip } from '@material-ui/core';
import useFetch from 'use-http';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router';
import { ScheduleTop, ScheduleLabel, CurrencyLabel } from '../../../components/Subledger/Subledger.styled';
import Loader from '../../../components/Loader/loader';
import COLORS from '../../../variables/colors';
import currencyFormatter from '../../../util/currencyFormatter';
import { Account, Subledger } from '../../../variables/types';
import { PrimaryButton } from '../../../components/Buttons';
import isEmpty from '../../../util/isEmpty';
import SubledgerMonthIndicator from '../../../components/Subledger/SubledgerMonthIndicator';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  periodBox: {
    width: 100,
    marginRight: 20,
  },
  subledgerBalance: {
    color: COLORS.violet,
  },
  fullWidth: {
    width: '100%',
  },
  whiteFont: {
    color: COLORS.white,
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 8,
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  postJEEnabled: boolean;
  onPostJE: () => void;
  accountBalance: Account;
}

const ScheduleHeader = ({ subledger, scheduleDate, postJEEnabled, onPostJE, accountBalance }: Props) => {
  const { loading } = useFetch();
  const classes = useStyles();
  const history = useHistory();

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const navigateToSchedulePrepaid = () => {
    history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${scheduleDate}`);
  };

  const difference = accountBalance?.glBalance - subledger?.openingBalance;

  return (
    <>
      <Loader open={loading} />
      <SubledgerMonthIndicator
        title="Current Period:"
        scheduleDate={scheduleDate}
      />
      <img
        src="/prepareJENav.svg"
        className={classes.navigation}
      />
      <ScheduleTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          borderRight="1px solid #CFD3DA"
          paddingLeft="38px"
          paddingRight="38px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
            marginTop="10px"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
        >
          <ScheduleLabel>
            Current GL Balance:
          </ScheduleLabel>
          <CurrencyLabel>
            {currencyFormatter.format(accountBalance?.glBalance)}
          </CurrencyLabel>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
        >

          <ScheduleLabel>
            Current Facta Balance:
          </ScheduleLabel>
          <CurrencyLabel className={classes.subledgerBalance}>
            {currencyFormatter.format(subledger.openingBalance)}
          </CurrencyLabel>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          marginTop="10px"
          paddingLeft="19px"
          width="200px"
        >
          <ScheduleLabel>
            Difference
          </ScheduleLabel>
          <CurrencyLabel>
            {currencyFormatter.format(difference)}
          </CurrencyLabel>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ChevronLeftIcon />}
          onClick={navigateToSchedulePrepaid}
        >
          SCHEDULE PREPAID
        </Button>
        {
          !postJEEnabled && (
            <Tooltip
              title="Please ensure all Manual Prepaids have a $0.00 or greater value."
              arrow
              placement="top"
            >
              <Box>
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  onClick={onPostJE}
                  disabled
                >
                  POST JE
                </PrimaryButton>
              </Box>
            </Tooltip>

          )
        }
        {
          postJEEnabled && (
            <Box>
              <PrimaryButton
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                onClick={onPostJE}
                disabled={!postJEEnabled}
              >
                POST JE
              </PrimaryButton>
            </Box>
          )
        }

      </ScheduleTop>
    </>
  );
};

export default ScheduleHeader;
