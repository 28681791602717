import { isAfter, isBefore, lastDayOfMonth, parse, startOfMonth } from 'date-fns';
import { Subledger } from '../variables/types';
import getScheduleDate from './getScheduleDate';
import { DAY_SHORT_FORMAT } from '../variables/constants';

const getSubledgersStartEndDate = (subledgers: Array<Subledger>) => {
  let startDate: Date | null = null;
  let endDate: Date | null = null;
    subledgers?.forEach((subledger: Subledger) => {
      // @ts-ignore
      const factaStartDate = startOfMonth(subledger?.factaStartDate!);
      if (startDate === null) {
        startDate = factaStartDate;
      } else if (isAfter(startDate, factaStartDate)) {
        startDate = factaStartDate;
      }

      const scheduleDate = getScheduleDate(subledger);
      if (scheduleDate) {
        const date = parse(scheduleDate, DAY_SHORT_FORMAT, new Date());
        if (endDate === null) {
          endDate = lastDayOfMonth(date);
        } else if (isBefore(date, endDate)) {
          endDate = lastDayOfMonth(date);
        }
      }
    });
    return { startDate, endDate };
};

export default getSubledgersStartEndDate;
