import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import {
  ADD_ASSET,
  ADD_SPLIT,
  ADD_SPLIT_ASSET,
  DATE_CHANGE,
  INPUT_CHANGE,
  REMOVE_ASSET,
  SELECT_CHANGE, SELECT_ROW,
} from '../../variables/constants';
import { PrepaidAsset, Subledger } from '../../variables/types';
import { getChildren, getStartEndDate } from './common';
import CreateSubledgerSelectedRow from './CreateSubledgerSelectedRow';
import AddSplitButtonRow from './AddSplitButtonRow';
import CreateSubledgerReadOnlyRow from './CreateSubledgerReadOnlyRow';
import CreateSubledgerTableHeader from './CreateSubledgerTableHeader';
import AddAssetRow from './AddAssetRow';
import { useAccountProvider } from '../../core/accountContext';

interface Props {
  dispatch: any;
  subledger: Subledger;
  selectedRow: string;
  hoverRow: string;
  formSubmit?: boolean;
  historicalEditing?: boolean
}

const useStyles = makeStyles(() => createStyles({
  table: {
    minWidth: 1560,
    marginBottom: 0,
    marginTop: 115,
  },
}));

const CreateSubledgerTable = ({
  dispatch,
  subledger,
  selectedRow,
  hoverRow,
  formSubmit,
  historicalEditing = false,
}: Props) => {
  const { account } = useAccountProvider();
  const { vendors, accountClasses, accountIncomes } = account;
  const classes = useStyles();

  const addAsset = () => {
    dispatch({ type: ADD_ASSET });
  };

  const addSplit = (internalId: string) => () => {
    dispatch({ type: ADD_SPLIT, payload: { internalId } });
  };

  const addSplitAsset = (internalId: string) => () => {
    dispatch({ type: ADD_SPLIT_ASSET, payload: { internalId } });
  };

  const removeAsset = (internalId: string) => () => {
    dispatch({ type: REMOVE_ASSET, payload: { internalId } });
  };

  const onInputBoxChange = (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: INPUT_CHANGE, payload: { internalId, propertyName, value: event.target.value } });
  };

  const onSelectChange = (propertyName: string, internalId: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({ type: SELECT_CHANGE, payload: { internalId, propertyName, value: event.target.value } });
  };

  const onAutoCompleteChange = (propertyName: string, internalId: string) => (value: string) => {
    dispatch({ type: SELECT_CHANGE, payload: { internalId, propertyName, value } });
  };

  const onDateChange = (propertyName: string, internalId: string) => (value: Date|null) => {
    dispatch({ type: DATE_CHANGE, payload: { internalId, propertyName, value } });
  };

  const { startDate, endDate } = getStartEndDate(subledger);

  const renderSelectedRow = (asset: PrepaidAsset, index: number) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const parent = (
      <CreateSubledgerSelectedRow
        key={asset.internalId}
        selectedRow={selectedRow}
        asset={asset}
        hoverRow={hoverRow}
        onRowSelected={() => dispatch({
          type: SELECT_ROW,
          payload: { selectedRow: asset.parentId ?? asset.internalId },
        })}
        hasChildren={!!children?.length}
        index={index}
        formSubmit={formSubmit}
        onDescriptionChange={onInputBoxChange('description', asset.internalId)}
        onVendorChange={onAutoCompleteChange('vendorId', asset.internalId)}
        onExpenseAccountChange={onAutoCompleteChange('expenseAccountId', asset.internalId)}
        accountIncomes={accountIncomes}
        account={account}
        onClassChange={onAutoCompleteChange('classId', asset.internalId)}
        accountClasses={accountClasses}
        onStartingBalanceChange={onInputBoxChange('startingBalance', asset.internalId)}
        subledger={subledger}
        onAmortizationStartDateChange={onDateChange('amortizationStartDate', asset.internalId)}
        onAmortizationEndDateChange={onDateChange('amortizationEndDate', asset.internalId)}
        onAmortizationScheduleChange={onSelectChange('amortizationScheduleType', asset.internalId)}
        onRemoveAsset={removeAsset(asset.internalId)}
        onAddAsset={addSplit(asset.internalId)}
        startDate={startDate}
        endDate={endDate}
        prepaidAssets={subledger?.prepaidAssets}
        historicalEditing={historicalEditing}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children?.map((child: PrepaidAsset, childIndex: number) => (
              <CreateSubledgerSelectedRow
                key={child.internalId}
                selectedRow={selectedRow}
                asset={child}
                hoverRow={hoverRow}
                onRowSelected={() => dispatch({
                  type: SELECT_ROW,
                  payload: { selectedRow: child.parentId ?? child.internalId },
                })}
                hasChildren={false}
                index={childIndex}
                formSubmit={formSubmit}
                onDescriptionChange={onInputBoxChange('description', child.internalId)}
                onVendorChange={onAutoCompleteChange('expenseAccountId', asset.internalId)}
                onExpenseAccountChange={onAutoCompleteChange('expenseAccountId', child.internalId)}
                accountIncomes={accountIncomes}
                account={account}
                onClassChange={onAutoCompleteChange('classId', child.internalId)}
                accountClasses={accountClasses}
                onStartingBalanceChange={onInputBoxChange('startingBalance', child.internalId)}
                subledger={subledger}
                onAmortizationStartDateChange={onDateChange('amortizationStartDate', child.internalId)}
                onAmortizationEndDateChange={onDateChange('amortizationEndDate', child.internalId)}
                onAmortizationScheduleChange={onSelectChange('amortizationScheduleType', child.internalId)}
                onRemoveAsset={removeAsset(child.internalId)}
                onAddAsset={addSplit(asset.internalId)}
                startDate={startDate}
                endDate={endDate}
                prepaidAssets={subledger?.prepaidAssets}
                lastChild={children?.length - 1 === childIndex}
                historicalEditing={historicalEditing}
              />
            ))
        }
        <AddSplitButtonRow
          addSplitAsset={addSplitAsset(asset?.internalId)}
        />
      </>
    );
  };

  const renderRow = (asset: PrepaidAsset, index: number) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const parent = (
      <CreateSubledgerReadOnlyRow
        selectedRow={selectedRow}
        asset={asset}
        hoverRow={hoverRow}
        onClick={() => dispatch({
          type: SELECT_ROW,
          payload: { selectedRow: asset.parentId ?? asset.internalId },
        })}
        hasChildren={!!children?.length}
        lastChild={false}
        index={index}
        vendors={vendors}
        accounts={accountIncomes}
        account={account}
        accountClasses={accountClasses}
        addSplit={addSplit(asset.internalId)}
        startDate={startDate}
        endDate={endDate}
        prepaidAssets={subledger?.prepaidAssets}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {
                    children
                        ?.map((child: PrepaidAsset, childIndex: number) => (
                          <CreateSubledgerReadOnlyRow
                            selectedRow={selectedRow}
                            asset={child}
                            hoverRow={hoverRow}
                            onClick={() => dispatch({
                              type: SELECT_ROW,
                              payload: { selectedRow: asset.parentId ?? asset.internalId },
                            })}
                            hasChildren={false}
                            lastChild={children?.length - 1 === childIndex}
                            index={index}
                            vendors={vendors}
                            accounts={accountIncomes}
                            account={account}
                            accountClasses={accountClasses}
                            addSplit={addSplit(asset.internalId)}
                            startDate={startDate}
                            endDate={endDate}
                            prepaidAssets={subledger?.prepaidAssets}
                          />
                        ))
        }
      </>
    );
  };

  const renderAsset = () => subledger?.prepaidAssets
        ?.filter((asset: PrepaidAsset) => !asset.parentId).map((asset: PrepaidAsset, index: number) => {
          if (asset.internalId === selectedRow) {
            return renderSelectedRow(asset, index);
          }
          return renderRow(asset, index);
        });

  return (
    <Box
      padding={2}
      width="100%"
    >
      <AddAssetRow onClick={addAsset} />
      <Table
        size="small"
        className={classes.table}
      >
        <CreateSubledgerTableHeader
          subledger={subledger}
        />
        <TableBody>
          {
            renderAsset()
          }
        </TableBody>
      </Table>
    </Box>
  );
};

export default CreateSubledgerTable;
