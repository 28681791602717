import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import { Box } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { endOfMonth, isWithinInterval, parse, startOfMonth } from 'date-fns';

import { PrepaidAsset, PrepaidAssetsAmortizationScheduleDetail } from '../../../variables/types';
import { DAY_SHORT_FORMAT, HISTORICAL_MANUAL_ASSET, INITIALED_WITH_LOCAL_STORAGE } from '../../../variables/constants';
import { getChildren, getPrepareJEStartEndDate } from '../../../components/Subledger/common';
import ScheduleHeader from './ScheduleHeader';
import reducer from '../../../components/Subledger/reducer';
import { deleteSubledger, getSubledger, setSubledger } from '../../../util/subledger';
import PrepareJETableHeader from '../../../components/Subledger/PrepareJETableHeader';
import PrepareJERow from '../../../components/Subledger/PrepareJERow';
import { isPostJEEnabled, prepareJEInputChange } from '../../../components/Subledger/prepreJECommon';
import isEmpty from '../../../util/isEmpty';
import Loader from '../../../components/Loader/loader';
import DialogBox from '../../../components/DialogBox';

interface Props {
  scheduleDate: string;
}

const PrepareJE = ({ scheduleDate }: Props) => {
  const history = useHistory();
  const [collapses, setCollapses] = useState<Array<string>>([]);
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {}, selectedRow: '' });

  useEffect(() => {
    const { subledger: localSubledger, historicalUpdatedAssets: localHistoricalPrepaidAssets } = getSubledger();
    dispatch({
      type: INITIALED_WITH_LOCAL_STORAGE,
      payload: {
        subledger: localSubledger,
        historicalUpdatedAssets: localHistoricalPrepaidAssets,
        scheduleDate,
      },
    });
  }, [scheduleDate]);

  if (isEmpty(state.subledger)) {
    return <Loader open />;
  }

  const { subledger, historicalUpdatedAssets } = state;

  const { startDate, endDate } = getPrepareJEStartEndDate(subledger, subledger?.prepaidAssets, scheduleDate);

  const toggleSplit = (internalId: string) => () => {
    const exists = collapses.includes(internalId);
    let updateCollapse: Array<string> = [];
    if (exists) {
      updateCollapse = collapses?.filter((item: string) => item !== internalId);
    } else {
      updateCollapse.push(internalId);
    }
    setCollapses(updateCollapse);
  };

  const activePrepaidAssets = subledger?.prepaidAssets
      ?.filter((asset: PrepaidAsset) => isWithinInterval(asset.assetCreationDate!, {
        start: startOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
        end: endOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
      }));
  const postJEEnabled = isPostJEEnabled(activePrepaidAssets, scheduleDate);

  const onInputBoxChange = (lastDay: string, asset: PrepaidAsset, schedule?: PrepaidAssetsAmortizationScheduleDetail) => (event: React.ChangeEvent<HTMLInputElement>) => {
    prepareJEInputChange({ number: event.target.value, asset, schedule, lastDay, prepaidAssets: subledger?.prepaidAssets, dispatch });
    dispatch({ type: HISTORICAL_MANUAL_ASSET, payload: { internalId: asset.internalId } });
  };

  const renderRow = (asset: PrepaidAsset) => {
    const isActive = isWithinInterval(asset.assetCreationDate!, {
      start: startOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
      end: endOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
    });
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const parent = (
      <PrepareJERow
        asset={asset}
        prepaidAssets={subledger?.prepaidAssets}
        scheduleDate={scheduleDate}
        endDate={endDate!}
        startDate={startDate}
        hasChildren={!!children?.length}
        lastChild={false}
        collapses={collapses}
        onInputBoxChange={onInputBoxChange}
        toggleSplit={toggleSplit}
        disabled={!isActive}
      />
    );
    if (!children?.length || collapses.includes(asset.internalId)) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children
                ?.map((child: PrepaidAsset, childIndex: number) => (
                  <PrepareJERow
                    key={child.internalId}
                    asset={child}
                    prepaidAssets={subledger?.prepaidAssets}
                    scheduleDate={scheduleDate}
                    endDate={endDate!}
                    startDate={startDate}
                    hasChildren={false}
                    lastChild={children?.length - 1 === childIndex}
                    collapses={collapses}
                    onInputBoxChange={onInputBoxChange}
                    toggleSplit={toggleSplit}
                    disabled={!isActive}
                  />
                ))
        }
      </>
    );
  };

  const navigateToSchedulePrepaid = () => {
    setSubledger({ subledger, historicalUpdatedAssets });
    history.push(`/historical/subledgers/schedule/${subledger.id}/?scheduleDate=${scheduleDate}&fromPrepareJE=true`);
  };

  const onPostJE = async () => {
    setSubledger({ subledger, historicalUpdatedAssets });
    history.push(`/historical/subledgers/schedule/${subledger.id}/post-je?scheduleDate=${scheduleDate}`);
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
  };

  const onCancel = () => {
    if (historicalUpdatedAssets?.length > 0) {
      setOpenSubledgerDialog(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    deleteSubledger();
    setOpenSubledgerDialog(false);
    history.push(`/journal-entries/?account_id=${subledger?.account?.id}`);
  };

  return (
    <>
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page? \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{ title: 'YES', event: navigateBack }]}
      />
      <Box
        width="100%"
      >
        <ScheduleHeader
          subledger={subledger}
          scheduleDate={scheduleDate}
          postJEEnabled={postJEEnabled}
          onPostJE={onPostJE}
          onCancel={onCancel}
          navigateToSchedulePrepaid={navigateToSchedulePrepaid}
        />
        <Box padding={1}>
          <Table
            size="small"
          >
            <PrepareJETableHeader
              startDate={startDate}
              endDate={endDate!}
              scheduleDate={scheduleDate}
              prepaidAssets={subledger?.prepaidAssets}
            />
            <TableBody>
              {
                subledger?.prepaidAssets
                    ?.filter((asset: PrepaidAsset) => !asset.parentId).map(renderRow)
              }
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default PrepareJE;
