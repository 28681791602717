/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HideVisibilityIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="icon / eye - closed">
      <path
        id="Vector"
        d="M0.727273 0.923636L1.65818 0L13.8182 12.16L12.8945 13.0909L10.6545 10.8509C9.81818 11.1273 8.93091 11.2727 8 11.2727C4.36364 11.2727 1.25818 9.01091 0 5.81818C0.501818 4.53818 1.30182 3.41091 2.32 2.51636L0.727273 0.923636ZM8 3.63636C8.57865 3.63636 9.13361 3.86623 9.54278 4.2754C9.95195 4.68457 10.1818 5.23953 10.1818 5.81818C10.1822 6.06587 10.1404 6.31181 10.0582 6.54545L7.27273 3.76C7.50638 3.67781 7.75232 3.636 8 3.63636ZM8 0.363636C11.6364 0.363636 14.7418 2.62545 16 5.81818C15.4064 7.32597 14.3978 8.6347 13.0909 9.59273L12.0582 8.55273C13.0639 7.857 13.8751 6.91568 14.4145 5.81818C13.8266 4.61814 12.9138 3.60711 11.7798 2.9C10.6459 2.1929 9.33634 1.81809 8 1.81818C7.20727 1.81818 6.42909 1.94909 5.70182 2.18182L4.58182 1.06909C5.62909 0.618182 6.78545 0.363636 8 0.363636ZM1.58545 5.81818C2.17341 7.01823 3.08623 8.02926 4.22017 8.73636C5.3541 9.44346 6.66366 9.81827 8 9.81818C8.50182 9.81818 8.99636 9.76727 9.45455 9.66545L7.79636 8C7.2903 7.94576 6.81805 7.71991 6.45816 7.36002C6.09827 7.00013 5.87243 6.52788 5.81818 6.02182L3.34545 3.54182C2.62545 4.16 2.02182 4.93091 1.58545 5.81818Z"
        fill="#CFD3DA"
      />
    </g>

  </SvgIcon>
);

export default HideVisibilityIcon;
