import React from 'react';
import { Box, createStyles, IconButton, makeStyles, MenuItem, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { isBefore } from 'date-fns';
import { Account, AccountClass, AccountInfo, PrepaidAsset, ScheduleType, Subledger } from '../../variables/types';
import InputBox from '../InputBox';
import VendorMenu from './VendorMenu';
import ExpenseAccountMenu from './ExpenseAccountMenu';
import AccountClassMenu from './AccountClassMenu';
import FactaDatePicker from '../DatePicker';
import SelectBox from '../Select';
import DeleteIcon from '../Icons/DeleteIcon';
import SplitIcon from '../Icons/SplitIcon';
import { useTableStyles } from './Subledger.styled';
import CreateSubledgerAmortizationSchedules from './CreateSubledgerAmortizationSchedules';

const useStyles = makeStyles(() => createStyles({
  table: {
    minWidth: 1560,
  },
  fontSize13: {
    fontSize: 13,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  arrowIcon: {
    fontSize: 12,
    textTransform: 'none',
    height: 14,
    marginTop: 8,
    marginBottom: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

interface Props {
  selectedRow: string,
  asset: PrepaidAsset,
  hoverRow: string,
  onRowSelected: () => void,
  hasChildren?: boolean,
  index: number,
  formSubmit?: boolean,
  onDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onVendorChange:(value: string) => void,
  account: AccountInfo,
  accountClasses: Array<AccountClass>,
  onExpenseAccountChange:(value: string) => void,
  accountIncomes: Array<Account>,
  onClassChange: (value: string) => void,
  onStartingBalanceChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  subledger: Subledger,
  onAmortizationStartDateChange: (newDate: Date|null) => void,
  onAmortizationEndDateChange: (newDate: Date|null) => void,
  onAmortizationScheduleChange: (event: React.ChangeEvent<{ value: unknown }>) => void,
  onRemoveAsset: () => void,
  onAddAsset: () => void,
  startDate: Date;
  endDate: Date | null;
  prepaidAssets: Array<PrepaidAsset>;
  lastChild?: boolean;
  historicalEditing?: boolean;
}

const CreateSubledgerSelectedRow = ({
  onRemoveAsset,
  onRowSelected,
  index,
  hoverRow,
  account,
  accountClasses,
  hasChildren,
  asset,
  selectedRow,
  subledger,
  onAmortizationStartDateChange,
  onDescriptionChange,
  formSubmit,
  onVendorChange,
  onExpenseAccountChange,
  onClassChange,
  onStartingBalanceChange,
  onAmortizationScheduleChange,
  onAddAsset,
  onAmortizationEndDateChange,
  startDate,
  endDate,
  prepaidAssets,
  lastChild,
  historicalEditing = false,
}: Props) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  return (
    <TableRow
      hover={false}
      selected={selectedRow === asset.internalId || (hoverRow === asset.internalId || asset.parentId === selectedRow)}
      onClick={onRowSelected}
      classes={{
        selected: tableClasses.selected,
      }}
    >
      <TableCell className={clsx(tableClasses.assetCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
      })}
      >
        {asset.parentId ? '' : `Open ${index + 1}`}
      </TableCell>
      <TableCell className={clsx(tableClasses.descriptionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
      })}
      >
        <InputBox
          placeholder=""
          error={!asset.description && formSubmit && !asset.parentId}
          value={asset.description}
          className={tableClasses.inputBox}
          onChange={onDescriptionChange}
        />
      </TableCell>
      <TableCell className={clsx(tableClasses.customerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
      })}
      >
        {
          !asset.parentId && (
            <VendorMenu
              error={!asset.vendorId && formSubmit}
              className={clsx(tableClasses.customerSelectBox)}
              defaultValue={asset.vendorId}
              value={asset.vendorId}
              onChange={onVendorChange}
            />
          )
        }
      </TableCell>
      <TableCell
        className={clsx(tableClasses.expenseAccountCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
        })}
      >
        {
          !hasChildren && (
            <ExpenseAccountMenu
              error={!asset.prepaidSchedule?.expenseAccountId && formSubmit}
              className={clsx(tableClasses.expenseAccountSelectBox)}
              defaultValue={asset?.prepaidSchedule?.expenseAccountId!}
              value={asset?.prepaidSchedule?.expenseAccountId!}
              onChange={onExpenseAccountChange}
            />
          )
        }
        {
          hasChildren
                    && (
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography className={classes.fontSize13}>Multiple</Typography>
                        <ArrowDropDownIcon />
                      </Box>
                    )
        }
      </TableCell>
      {
        (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
          <TableCell className={clsx(tableClasses.classesCell, {
            [tableClasses.child]: asset.parentId,
            [tableClasses.parent]: hasChildren,
          })}
          >
            {
              !hasChildren && (
                <AccountClassMenu
                  className={clsx(tableClasses.classSelectBox)}
                  defaultValue={asset?.prepaidSchedule?.classId!}
                  value={asset?.prepaidSchedule?.classId!}
                  onChange={onClassChange}
                  accountClasses={accountClasses}
                  disabled={historicalEditing}
                />
              )
            }

          </TableCell>
        )
      }

      <TableCell className={clsx(tableClasses.currencyCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
      })}
      >
        <InputBox
          fullWidth
          type="number"
          readOnly={hasChildren}
          inputProps={{ style: { textAlign: 'right' } }}
          value={asset.startingBalance}
          className={tableClasses.inputBox}
          onChange={onStartingBalanceChange}
        />
      </TableCell>
      <TableCell
        className={clsx(tableClasses.datePickerCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
        })}
      >
        {
          !hasChildren && (
            <FactaDatePicker
              disabled={asset.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual}
              error={
                asset.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual
                && subledger?.factaStartDate
                && asset.prepaidSchedule?.amortizationStartDate
                && isBefore(asset.prepaidSchedule?.amortizationStartDate!, subledger?.factaStartDate)
              }
              minDate={subledger?.factaStartDate!}
              selectedDate={asset.prepaidSchedule?.amortizationStartDate}
              onDateChange={onAmortizationStartDateChange}
            />
          )
        }
      </TableCell>
      <TableCell className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
      })}
      >
        {
          !hasChildren && (
            <FactaDatePicker
              disabled={asset.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual}
              error={
                asset.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual
                && asset.prepaidSchedule?.amortizationStartDate
                && asset.prepaidSchedule?.amortizationEndDate
                && isBefore(asset.prepaidSchedule?.amortizationEndDate, asset.prepaidSchedule?.amortizationStartDate)
              }
              minDate={asset.prepaidSchedule?.amortizationStartDate}
              selectedDate={asset.prepaidSchedule?.amortizationEndDate}
              onDateChange={onAmortizationEndDateChange}
            />
          )
        }

      </TableCell>
      <TableCell className={clsx(tableClasses.scheduleTypeCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
      })}
      >
        {
          !hasChildren && (
            <SelectBox
              disableUnderline
              className={clsx(tableClasses.scheduleTypeSelectBox, tableClasses.inputBox)}
              value={asset.prepaidSchedule?.amortizationScheduleType}
              onChange={onAmortizationScheduleChange}
            >
              <MenuItem value="DAILY">Daily</MenuItem>
              <MenuItem value="MONTHLY">Monthly</MenuItem>
              <MenuItem value="MANUAL">Manual</MenuItem>
            </SelectBox>
          )
        }
      </TableCell>
      <TableCell className={clsx(tableClasses.actionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
      })}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <IconButton
            className={tableClasses.actionCellButtonPadding}
            onClick={onRemoveAsset}
          >
            <DeleteIcon fontSize="small" className={tableClasses.fontSize13} />
          </IconButton>
          {!asset.parentId
                    && (
                      <IconButton
                        disabled={hasChildren}
                        className={clsx(tableClasses.actionCellButtonPadding, {
                          [tableClasses.iconDisabled]: hasChildren,
                        })}
                        onClick={onAddAsset}
                      >
                        <SplitIcon className={tableClasses.fontSize13} />
                      </IconButton>
                    )}
        </Box>
      </TableCell>
      <CreateSubledgerAmortizationSchedules
        startDate={startDate}
        endDate={endDate}
        asset={asset}
        prepaidAssets={prepaidAssets}
        lastChild={lastChild}
      />
    </TableRow>
  );
};

export default React.memo(CreateSubledgerSelectedRow);
