/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DownloadIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9993 8.3335V10.7778C11.9993 11.1019 11.8705 11.4128 11.6413 11.642C11.4121 11.8712 11.1013 11.9999 10.7771 11.9999H2.22214C1.89801 11.9999 1.58715 11.8712 1.35796 11.642C1.12876 11.4128 1 11.1019 1 10.7778V8.3335" stroke={props.stroke ?? '#252729'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.4436 5.27759L6.49896 8.33294L9.55431 5.27759" stroke={props.stroke ?? '#252729'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.49951 8.33284V1" stroke={props.stroke ?? '#252729'} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </SvgIcon>
);

export default DownloadIcon;
