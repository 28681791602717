import { addMonths, format, lastDayOfMonth, startOfMonth } from 'date-fns';
import { Subledger, SubledgerAmortizationLog } from '../variables/types';
import { DAY_SHORT_FORMAT } from '../variables/constants';

const getScheduleDate = (subledger: Subledger) => {
  let startDate = startOfMonth(subledger?.factaStartDate!);
  if (!subledger?.subledgerAmortizationLogs) {
    return format(lastDayOfMonth(startDate!), DAY_SHORT_FORMAT);
  }

  while (startDate) {
    const scheduleDate = format(lastDayOfMonth(startDate!), DAY_SHORT_FORMAT);
    const schedule = getSchedule(scheduleDate, subledger?.subledgerAmortizationLogs);
    if (!schedule) {
      return scheduleDate;
    }
    startDate = addMonths(startOfMonth(startDate), 1);
  }
  return null;
};

const getSchedule = (scheduleDate: string, logs: Array<SubledgerAmortizationLog>) => logs
    ?.find((schedule: SubledgerAmortizationLog) => format(schedule.scheduleDate!, DAY_SHORT_FORMAT) === scheduleDate);

export default getScheduleDate;
