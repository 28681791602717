import { startOfDay } from 'date-fns';

const pad = (n: number) => (n < 10 ? '0' : '') + n;
const padd = (n: number) => (n < 100 ? '0' : '') + pad(n);

export const toUTC = (date: Date | null) : string => {
  if (!date) {
    return '';
  }
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())
  }T${pad(0)}:${pad(0)}:${
    pad(0)}.${padd(0)}Z`;
};

const parseISOLocal = (stringDate: string) => {
  if (stringDate) {
    const b = stringDate.split(/\D/);
    return startOfDay(new Date(Number(b[0]), Number(b[1]) - 1, Number(b[2]), Number(b[3]), Number(b[4]), Number(b[5])));
  }
  return startOfDay(new Date());
};

export const fromUTC = (stringDate: string): Date => parseISOLocal(stringDate);
