import { Box, Button, createStyles, makeStyles, TableCell, TableRow } from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import React from 'react';
import clsx from 'clsx';
import { useTableStyles } from './Subledger.styled';
import COLORS from '../../variables/colors';
import { FREEZE_PANEL_Z_INDEX } from '../../variables/constants';

interface Props {
  onClick: () => void
}

const useStyles = makeStyles(() => createStyles({
  arrowIcon: {
    fontSize: 12,
    textTransform: 'none',
    marginTop: 8,
    marginBottom: 8,
    position: 'fixed',
    marginLeft: 18,
    '&:hover': {
      backgroundColor: COLORS.white,
    },
  },
  noPadding: {
    padding: 0,
  },
  addAsset: {
    position: 'fixed',
    top: 200,
    background: COLORS.white,
    width: '100%',
    zIndex: FREEZE_PANEL_Z_INDEX,
    height: 60,
    justifyContent: 'end',
    marginLeft: '-17px',
  },
}));

const AddAssetRow = ({ onClick }: Props) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  return (
    <TableRow hover={false} selected classes={{ selected: tableClasses.selected }}>
      <TableCell colSpan={100} className={clsx(tableClasses.noBorder, classes.noPadding)}>
        <Box className={classes.addAsset}>
          <Button
            color="primary"
            className={classes.arrowIcon}
            startIcon={(
              <AddCircleRoundedIcon
                color="primary"
                fontSize="small"
              />
            )}
            onClick={onClick}
          >
            Add Asset
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(AddAssetRow);
