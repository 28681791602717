import { Button, TableCell, TableRow, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import COLORS from '../../variables/colors';
import { useTableStyles } from './Subledger.styled';

interface Props {
  addSplitAsset: () => void;
}

const AddSplitButtonRow = ({ addSplitAsset }: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableRow
      selected
      classes={{
        selected: tableClasses.selected,
      }}
    >
      <TableCell />
      <TableCell colSpan={100}>
        <Button
          color="primary"
          className={tableClasses.arrowIcon}
          onClick={addSplitAsset}
          startIcon={<AddIcon className={tableClasses.fontSize12} color="primary" />}
        >
          <Typography style={{ color: COLORS.skyBlue }}>Add</Typography>
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(AddSplitButtonRow);
