import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import SyncIcon from '@material-ui/icons/Sync';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import makeStyles from '@material-ui/styles/makeStyles';
import { createStyles, Snackbar, Theme, MenuItem } from '@material-ui/core';
import { format, formatRelative, parseISO } from 'date-fns';
import useFetch from 'use-http';
import useInterval from '@use-it/interval';
import { useHistory } from 'react-router';

import clsx from 'clsx';
import COLORS from '../../variables/colors';
import {
  PageTitle,
  TopBarContainer,
  TopBarLabel,
  TopBarBoldLabel,
  StyledResyncButton,
  StyledAppBar,
} from './TopBar.styled';
import { useAccountProvider } from '../../core/accountContext';
import Loader from '../Loader/loader';
import { useNavBarContext } from '../../core/navbarContext';
import { FULL_DAY_FORMAT } from '../../variables/constants';
import Alert from '../Alert';
import { Company } from '../../variables/types';
import SelectBox from '../Select';
import { setToken } from '../../util/auth';

interface Props {
  title: string;
  showCompanyInfo?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  selectBox: {
    width: 230,
    boxShadow: COLORS.selectBoxShadow,
    borderRadius: 2,
    border: 'none',
    height: 32,
    marginRight: 10,
  },
  marginLeft26: {
    marginLeft: 26,
  },
  marginLeft8: {
    marginLeft: 8,
  },
  medGray: {
    color: COLORS.medGray,
  },
  syncIcon: {
    fontSize: '11px !important',
  },

  contentPanelSideBarOpen: {
    paddingLeft: 162,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  contentPanelSideBarClosed: {
    paddingLeft: 52,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

}));

const TopBar = ({ title, showCompanyInfo = true }: Props) => {
  const { get, loading, response, error, post } = useFetch();
  const classes = useStyles();
  const { account, setAccount } = useAccountProvider();
  const [lastSyncString, setLastSyncString] = useState<string>('');
  const { navBarOpen } = useNavBarContext();
  const history = useHistory();

  const onSyncData = async () => {
    const data = await get('/account/sync');
    if (response.ok) {
      setAccount(data);
    }
  };

  const changeClient = () => async (event: React.ChangeEvent<{ value: unknown }>) => {
    const companyId = event.target.value;

    await post(`/account/company/${companyId}/switch`);
    if (response.ok) {
      const { token } = response.data;
      setToken(token);

      // Sync account info for new client
      await onSyncData();
      history.push('/');
    }
  };

  useInterval(() => {
    setLastSyncString(account?.lastSync ? formatRelative(new Date(account?.lastSync), new Date()).replace(' at', ',') : '');
  }, 60000); // delay for a minute

  useEffect(() => {
    setLastSyncString(account?.lastSync ? formatRelative(new Date(account?.lastSync), new Date()).replace(' at', ',') : '');
  }, [account]);

  return (
    <>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={5000}
      >
        <Alert severity="error">
          {error && (response?.data ?? error?.message)}
        </Alert>
      </Snackbar>
      <Loader open={loading} />
      <StyledAppBar
        className={clsx({
          [classes.contentPanelSideBarOpen]: navBarOpen,
          [classes.contentPanelSideBarClosed]: !navBarOpen,
        })}
      >
        <TopBarContainer
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={COLORS.lightGray3}
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
          >
            <PageTitle>
              {title}
            </PageTitle>
            {
              showCompanyInfo && (
                (
                  <>
                    <TopBarLabel>
                      Client
                    </TopBarLabel>
                    <SelectBox
                      value={account?.companyId}
                      disableUnderline
                      onChange={changeClient()}
                    >
                      {account?.companies.map((cmp: Company) => (
                        <MenuItem value={cmp.id} key={cmp.id}>{cmp.companyName}</MenuItem>
                      ))}
                    </SelectBox>
                    <TopBarBoldLabel
                      className={classes.marginLeft26}
                    >
                      Last Sync
                    </TopBarBoldLabel>
                    <TopBarLabel>
                      {lastSyncString}
                    </TopBarLabel>
                    <StyledResyncButton
                      startIcon={<SyncIcon fontSize="small" className={classes.syncIcon} />}
                      onClick={onSyncData}
                    >
                      Sync Now
                    </StyledResyncButton>
                  </>
                )
              )
            }
          </Box>
          {
            account?.bookCloseDate && showCompanyInfo && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                marginRight={8}
              >
                <HttpsOutlinedIcon
                  className={classes.medGray}
                  fontSize="small"
                />
                <TopBarLabel className={classes.marginLeft8}>
                  Last Close Date:
                </TopBarLabel>
                <TopBarBoldLabel>
                  {account?.bookCloseDate ? format(parseISO(account?.bookCloseDate), FULL_DAY_FORMAT) : account?.bookCloseDate}
                </TopBarBoldLabel>
              </Box>
            )
          }
        </TopBarContainer>
      </StyledAppBar>
    </>
  );
};

export default TopBar;
