/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useHistory, useLocation } from 'react-router';
import useFetch from 'use-http';

import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Intercom from 'react-intercom';
import COLORS from '../../variables/colors';
import {
  FactaConnectMessage,
  FactaLoginBackgroundLogo,
  FactaLoginMessage,
  LoginContainer,
  LoginDivider,
} from './Login.styled';
import Loader from '../../components/Loader/loader';
import { getErrorFromCookie, updateTokenFromCookie } from '../../util/auth';
import { useAccountProvider } from '../../core/accountContext';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';

const Login = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const history = useHistory();
  const { setAccount } = useAccountProvider();
  const success = useQuery()?.get('success');
  const [loading, setLoading] = useState<boolean>(Boolean(success));
  const { get, response, loading: accountInfoLoading, error } = useFetch();
  const userInfo = {
    Environment: process.env.REACT_APP_ENV,
  };

  useEffect(() => {
    const checkAccountInfo = async () => {
      if (success) {
        if (success === 'true') {
          updateTokenFromCookie();
          const accountInfo = await get('/account/sync');
          if (response.ok) {
            setAccount(accountInfo);
            history.push('/');
          }
        } else {
          const requestError = getErrorFromCookie();
          if (requestError) {
            openSnackbar({ message: requestError }, NotifierType.Error);
          }
        }
        setLoading(false);
      }
    };
    checkAccountInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogin = () => {
    setLoading(true);
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/connect`;
  };
  return (
    <>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">
          {error?.message}
        </Alert>
      </Snackbar>
      <Loader open={(loading || accountInfoLoading) && !error} />
      <LoginContainer
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        m={0}
        p={0}
        pl="10%"
        mt="-10%"
        width="100%"
      >
        <FactaLoginBackgroundLogo src="/facta_login_background_logo.svg" />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            flexDirection="column"
            alignSelf="flex-start"
          >
            <img
              src="/full_logo.png"
              height={85}
              width={250}
            />
            <LoginDivider />
            <FactaLoginMessage>
              The automated solution for business expenses
            </FactaLoginMessage>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            ml={16}
          >
            <FactaConnectMessage>
              Connect to Facta
            </FactaConnectMessage>
            <ButtonBase
              onClick={onLogin}
            >
              <img src="/quickbook_connect.png" color={COLORS.white} height={48} />
            </ButtonBase>
          </Box>
        </Box>
        {
          !success && (
            <Intercom appID="wzlagc2y" {...userInfo} />
          )
        }
      </LoginContainer>
    </>
  );
};

export default React.memo(Login);
