import {
  addMonths,
  differenceInCalendarDays,
  differenceInCalendarMonths, endOfMonth,
  format,
  getDaysInMonth,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  lastDayOfMonth,
  max,
  parse,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import roundTo from 'round-to';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import {
  Account,
  AccountClass, AccountInfo,
  PrepaidAsset,
  PrepaidAssetsAmortizationScheduleDetail,
  PrepaidSchedule,
  ScheduleType,
  Subledger,
  SubledgerAmortizationLog,
  Vendor,
} from '../../variables/types';
import {
  ASSET_SCHEDULED_STATUS,
  ASSET_TEMP_SCHEDULED_STATUS,
  CALCULATE_ASSET,
  FULL_DAY_FORMAT,
  DAY_SHORT_FORMAT, SELECT_ROW, FACTA_SOURCE, MONTH_SHOW_FORMAT,
} from '../../variables/constants';
import { fromUTC, toUTC } from '../../util/timezone';
import currencyFormatter from '../../util/currencyFormatter';
import getQuickbookLink from '../../util/getQuickbookLink';
import getSubledgerStartEndDate from '../../util/getSubledgerStartEndDate';
import { setSubledger } from '../../util/subledger';

export const getChildSumForMonth = (childAssets: Array<PrepaidAsset>, date: string) => {
  let sum = 0;
    childAssets?.forEach((asset: PrepaidAsset) => {
        asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails
            ?.forEach((assetSchedule: PrepaidAssetsAmortizationScheduleDetail) => {
              if (format(assetSchedule.scheduleDate, DAY_SHORT_FORMAT) === date) {
                if (!Number.isNaN(Number(assetSchedule.amount))) {
                  sum += Number(assetSchedule.amount);
                }
              }
            });
    });
    return sum;
};

export const getPrepaidScheduleAmountForMonth = (asset: PrepaidAsset, date: string) => {
  let amount = 0;
    asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails?.forEach((assetSchedule: PrepaidAssetsAmortizationScheduleDetail) => {
      if (format(assetSchedule.scheduleDate, DAY_SHORT_FORMAT) === date && !Number.isNaN(Number(assetSchedule.amount))) {
        amount = Number(assetSchedule.amount);
      }
    });
    return amount;
};

export const getTotalBalanceAndAmortizationBalance = (childAssets: Array<PrepaidAsset>) => {
  let totalBalance = 0;
  let amortizationToDateBalance = 0;
    childAssets?.forEach((asset: PrepaidAsset) => {
      totalBalance += Number(asset.startingBalance);
      amortizationToDateBalance += Number(asset.amortizationToDateBalance ?? 0);
    });
    return { totalBalance, amortizationToDateBalance };
};

export const getAccountNameFromList = (expenseAccountId: string, accounts: Array<Account>) => {
  const expenseAccount = accounts?.find(({ id }: Account) => id === expenseAccountId);
  if (expenseAccount) {
    if (expenseAccount?.accNum) {
      return `${expenseAccount?.accNum} ${expenseAccount?.name}`;
    }
    return expenseAccount?.name;
  }
  return '';
};

export const getAccountName = (account: Account) => {
  if (account?.accNum) {
    return `${account?.accNum} ${account?.name}`;
  }
  return account?.name;
};

export const getAssetCreationDate = (prepaidAsset: PrepaidAsset, assets: Array<PrepaidAsset>) => {
  if (prepaidAsset?.parentId) {
    const parent = getParent(prepaidAsset.parentId, assets);
    if (parent) {
      return parent?.assetCreationDate;
    }
  }
  return prepaidAsset?.assetCreationDate;
};

export const getVendorName = (vendorId: string, vendors: Array<Vendor>) => vendors?.find(({ id }: Vendor) => id === vendorId)?.displayName;

export const getAccountByName = (accountName: string, accounts: Array<Account>) => (accounts?.find((
  { accNum, name }: Account,
) => accountName === `${accNum} ${name}` || accountName === name)
);

export const getAccountById = (accountId: string, accounts: Array<Account>) => accounts
    ?.find(({ id }: Account) => id === accountId);

export const getVendorById = (vendorId: string, vendors: Array<Vendor>) => vendors
    ?.find(({ id }: Vendor) => id === vendorId);

export const getClassName = (classId: string, accountClasses: Array<AccountClass>) => accountClasses?.find(({ id }: AccountClass) => id === classId)?.className;
export const getAccountClassById = (classId: string, accountClasses: Array<AccountClass>) => accountClasses
    ?.find(({ id }: AccountClass) => id === classId);

export const getChildren = (id: string, assets: Array<PrepaidAsset>) => assets?.filter(({ parentId }: PrepaidAsset) => parentId === id);

export const getAssetSumForMonth = (date: string, assets: Array<PrepaidAsset>) => {
  let sum = 0;
    assets?.forEach((asset: PrepaidAsset) => {
        asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails
            ?.forEach((assetSchedule: PrepaidAssetsAmortizationScheduleDetail) => {
              if (format(assetSchedule.scheduleDate, DAY_SHORT_FORMAT) === date) {
                if (!Number.isNaN(Number(assetSchedule.amount))) {
                  sum += Number(assetSchedule.amount);
                }
              }
            });
    });
    return sum;
};

export const getAssetSumForMonthWithStatus = (date: string, assets: Array<PrepaidAsset>, status?: Array<string>) => {
  let sum = 0;
    assets?.forEach((asset: PrepaidAsset) => {
      if ((!status && !asset.status) || (status && status.includes(asset?.status))) {
        asset?.prepaidSchedule
            ?.prepaidAssetsAmortizationScheduleDetails
            ?.forEach((assetSchedule: PrepaidAssetsAmortizationScheduleDetail) => {
              if (format(assetSchedule.scheduleDate, DAY_SHORT_FORMAT) === date) {
                if (!Number.isNaN(Number(assetSchedule.amount))) {
                  sum += Number(assetSchedule.amount);
                }
              }
            });
      }
    });
    return sum;
};

export const getFactaBalance = (assets: Array<PrepaidAsset>) => {
  let total = 0;
  assets?.forEach((asset: PrepaidAsset) => {
    if (!asset?.parentId) {
      total += roundTo(Number(asset.startingBalance), 2);
    }
  });
  return roundTo(total, 2);
};

export const getFactaSourceBalance = (assets: Array<PrepaidAsset>) => {
  let total = 0;
  assets?.forEach((asset: PrepaidAsset) => {
    if (!asset?.parentId && asset?.source === FACTA_SOURCE) {
      total += roundTo(Number(asset.startingBalance), 2);
    }
  });
  return roundTo(total, 2);
};

export const getPositiveNegativeAssetForMonth = (date: string, assets: Array<PrepaidAsset>) => {
  let positiveTotal = 0;
  let negativeTotal = 0;
    assets?.forEach((asset: PrepaidAsset) => {
        asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails?.forEach((assetSchedule: PrepaidAssetsAmortizationScheduleDetail) => {
          if (![ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS].includes(asset?.status)) {
            return;
          }
          if (format(assetSchedule.scheduleDate, DAY_SHORT_FORMAT) === date && !Number.isNaN(Number(assetSchedule.amount))) {
            if (Number(assetSchedule.amount) > 0) {
              positiveTotal += Number(assetSchedule.amount);
            } else if (Number(assetSchedule.amount) < 0) {
              negativeTotal += Number(assetSchedule.amount);
            }
          }
        });
    });
    return { positiveTotal: Math.abs(positiveTotal), negativeTotal: Math.abs(negativeTotal) };
};

export const getParent = (id: string, assets: Array<PrepaidAsset>) => assets
    ?.find((asset: PrepaidAsset) => asset.id === id);

export const getMonthlyCalculationFactor = (asset: PrepaidAsset) => {
  const startMonthFraction = ((differenceInCalendarDays(lastDayOfMonth(asset?.prepaidSchedule?.amortizationStartDate!), asset?.prepaidSchedule?.amortizationStartDate!)) + 1) / getDaysInMonth(asset?.prepaidSchedule?.amortizationStartDate!);
  const endDateMonthFraction = ((differenceInCalendarDays(asset.prepaidSchedule?.amortizationEndDate!, startOfMonth(asset?.prepaidSchedule?.amortizationEndDate!)) + 1) / getDaysInMonth(asset?.prepaidSchedule?.amortizationEndDate!));
  const monthsDifference = differenceInCalendarMonths(lastDayOfMonth(asset?.prepaidSchedule?.amortizationEndDate!), startOfMonth(asset?.prepaidSchedule?.amortizationStartDate!)) - 1;
  return startMonthFraction + endDateMonthFraction + monthsDifference;
};

export const getStartEndDate = (subledger?: Subledger, prepaidAssets?: Array<PrepaidAsset>) => {
  let endDate: Date | null = null;
  const calAssets = prepaidAssets ?? subledger?.prepaidAssets;
    calAssets?.forEach((asset: PrepaidAsset) => {
      if (asset?.prepaidSchedule.amortizationEndDate) {
        if (!endDate) {
          endDate = asset?.prepaidSchedule.amortizationEndDate;
        } else if (isBefore(endDate, asset?.prepaidSchedule?.amortizationEndDate)) {
          endDate = asset?.prepaidSchedule?.amortizationEndDate;
        }
      }
    });
    return { startDate: lastDayOfMonth(subledger?.factaStartDate!), endDate };
};

export const getPrepareJEStartEndDate = (subledger: Subledger, prepaidAssets: Array<PrepaidAsset>, scheduleDate: string) => {
  const currentMonth = parse(scheduleDate!, DAY_SHORT_FORMAT, new Date());
  let endDate: Date | null = null;
  const calAssets = prepaidAssets ?? subledger?.prepaidAssets;
  calAssets?.forEach((asset: PrepaidAsset) => {
    if (asset?.prepaidSchedule.amortizationEndDate) {
      if (!endDate) {
        endDate = asset?.prepaidSchedule.amortizationEndDate;
      } else if (isBefore(endDate, asset?.prepaidSchedule?.amortizationEndDate)) {
        endDate = asset?.prepaidSchedule?.amortizationEndDate;
      }
    }
  });
  const advanceMonth = addMonths(lastDayOfMonth(currentMonth), 11);
  endDate = max([endDate!, advanceMonth]);
  return { startDate: lastDayOfMonth(subledger?.factaStartDate!), endDate };
};

export const geSubledgerStartEndDateForCalculations = (subledger?: Subledger, prepaidAssets?: Array<PrepaidAsset>) => {
  let endDate: Date | null = null;
  let startDate: Date | null = null;
  const calAssets = prepaidAssets ?? subledger?.prepaidAssets;
  calAssets?.forEach((asset: PrepaidAsset) => {
    if (asset?.prepaidSchedule.amortizationEndDate && asset?.prepaidSchedule?.amortizationStartDate) {
      if (!endDate) {
        endDate = asset?.prepaidSchedule.amortizationEndDate;
      } else if (isBefore(endDate, asset?.prepaidSchedule?.amortizationEndDate)) {
        endDate = asset?.prepaidSchedule?.amortizationEndDate;
      }
      if (!startDate) {
        startDate = asset?.prepaidSchedule.amortizationStartDate;
      } else if (isAfter(startDate, asset?.prepaidSchedule?.amortizationStartDate)) {
        startDate = asset?.prepaidSchedule?.amortizationStartDate;
      }
    }
  });
  return { startDate, endDate };
};

export const calculatePrepaidAssetAmortizationSchedules = (startDate: Date, asset: PrepaidAsset,
  calculationsMonths: number, diffInMonths: number, totalAmount: number,
  calculationsDays: number) => (index: number) => {
  const currentMonth = startOfMonth(addMonths(startDate!, index));
  if (isBefore(startOfMonth(asset?.prepaidSchedule?.amortizationStartDate!), asset?.prepaidSchedule?.amortizationEndDate!)
        && isWithinInterval(currentMonth, {
          start: startOfMonth(asset?.prepaidSchedule?.amortizationStartDate!),
          end: asset?.prepaidSchedule?.amortizationEndDate!,
        }) || isEqual(currentMonth, startOfMonth(asset?.prepaidSchedule?.amortizationStartDate!))) {
    const lastDay = lastDayOfMonth(currentMonth);
    if (isSameMonth(asset?.prepaidSchedule?.amortizationStartDate!, asset?.prepaidSchedule?.amortizationEndDate!)) {
      return {
        amount: Number(asset?.startingBalance),
        scheduleDate: lastDay,
        prepaidAssetId: asset.id,
      };
    }
    let amount: number | null = 0;
    // calculations
    if (asset?.prepaidSchedule?.amortizationScheduleType === ScheduleType.Monthly) {
      const perMonthAmount = asset.startingBalance / calculationsMonths;
      if (isSameMonth(asset?.prepaidSchedule?.amortizationStartDate!, currentMonth)) {
        const startMonthFraction = ((differenceInCalendarDays(lastDayOfMonth(asset?.prepaidSchedule?.amortizationStartDate!), asset?.prepaidSchedule?.amortizationStartDate!)) + 1) / getDaysInMonth(asset?.prepaidSchedule?.amortizationStartDate!);
        amount = perMonthAmount * startMonthFraction;
      } else if (isSameMonth(asset?.prepaidSchedule?.amortizationEndDate!, currentMonth)) {
        amount = asset.startingBalance - totalAmount;
      } else {
        amount = perMonthAmount;
      }
    } else if (asset?.prepaidSchedule?.amortizationScheduleType === ScheduleType.Daily) {
      const perDayAmount = asset.startingBalance / calculationsDays;
      if (isSameMonth(asset?.prepaidSchedule?.amortizationStartDate!, currentMonth)) {
        amount = (differenceInCalendarDays(lastDayOfMonth(currentMonth!), asset?.prepaidSchedule?.amortizationStartDate!) + 1) * perDayAmount;
      } else if (isSameMonth(asset?.prepaidSchedule?.amortizationEndDate!, currentMonth)) {
        amount = asset.startingBalance - totalAmount;
      } else {
        amount = getDaysInMonth(currentMonth) * perDayAmount;
      }
    }
    // eslint-disable-next-line no-param-reassign
    totalAmount += roundTo(amount, 2);
    return {
      amount: roundTo(amount, 2),
      scheduleDate: lastDay,
      prepaidAssetId: asset.id,
    };
  }
  return null;
};

export const calculateScheduleAsset = (subledger: Subledger, asset: PrepaidAsset, prepaidAssets?: Array<PrepaidAsset>): PrepaidAsset => {
  const { startDate, endDate } = geSubledgerStartEndDateForCalculations(subledger, prepaidAssets);
  if (!(asset?.prepaidSchedule?.amortizationStartDate && asset?.prepaidSchedule?.amortizationEndDate && startDate && endDate)) {
    return asset;
  }
  const diffInMonths = differenceInCalendarMonths(lastDayOfMonth(endDate!), startOfMonth(startDate!)) + 1;
  const calculationsMonths = getMonthlyCalculationFactor(asset);
  const calculationsDays = differenceInCalendarDays(asset?.prepaidSchedule?.amortizationEndDate!, asset?.prepaidSchedule?.amortizationStartDate!) + 1;
  if (diffInMonths > 0) {
    const totalAmount = 0;
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    asset.prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = Array.from(Array(diffInMonths).keys())
      .map(calculatePrepaidAssetAmortizationSchedules(startDate!, asset, calculationsMonths, diffInMonths, totalAmount, calculationsDays))
      .filter((item) => !!item);
  }
  return asset;
};

export const getAssetSum = (subledger: Subledger, prepaidAssets?: Array<PrepaidAsset>) => {
  const assets = prepaidAssets ?? subledger?.prepaidAssets;
  let sum = 0;
  assets?.forEach((asset: PrepaidAsset) => {
    if (!asset?.parentId) {
      sum += Number(asset?.startingBalance);
    }
  });
  return Number(sum).toFixed(2);
};

export const getAssetSumWithStatus = (subledger: Subledger, prepaidAssets?: Array<PrepaidAsset>) => {
  let sum = 0;
    (prepaidAssets ?? subledger?.prepaidAssets)?.forEach((asset: PrepaidAsset) => {
      if (asset?.parentId || ![ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS].includes(asset?.status)) {
        return;
      }
      sum += Number(asset?.startingBalance) - Number(asset?.amortizationToDateBalance);
    });
    return Number(sum).toFixed(2);
};

export const getStartingBalanceOfParent = (subledger: Subledger, parentAssetId: string, skipChildId?: string) => {
  const children = getChildren(parentAssetId, subledger?.prepaidAssets);
  return children.reduce((total: number, currentAsset: PrepaidAsset) => {
    if (currentAsset.startingBalance && skipChildId !== currentAsset.internalId) {
      // eslint-disable-next-line no-param-reassign
      total += Number(currentAsset.startingBalance);
    }
    return total;
  }, 0);
};

export const catchUpAmountForPrepaidAsset = (date: Date, newAssets: Array<PrepaidAsset>, index: number) => {
  const scheduledDate = format(date, DAY_SHORT_FORMAT);
  if (isBefore(newAssets[index]?.prepaidSchedule?.amortizationStartDate!, date)) {
    // get the amortization assets
    const total = newAssets[index]?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails
            ?.reduce((acc: number, schedule: PrepaidAssetsAmortizationScheduleDetail) => {
              if (isBefore(schedule.scheduleDate, date)) {
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                acc += schedule.amount;
              }
              return acc;
            }, 0);
    const prepaidAssetsAmortizationScheduleDetails = newAssets[index]?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails
            ?.filter((schedule: PrepaidAssetsAmortizationScheduleDetail) => !isBefore(schedule.scheduleDate, date)) ?? [];
        prepaidAssetsAmortizationScheduleDetails?.forEach((schedule: PrepaidAssetsAmortizationScheduleDetail) => {
          if (format(schedule.scheduleDate, DAY_SHORT_FORMAT) === scheduledDate) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            schedule.amount += roundTo(total, 2);
          }
        });
        // eslint-disable-next-line no-param-reassign
        newAssets[index].prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = prepaidAssetsAmortizationScheduleDetails;
  }
  return newAssets[index];
};

export const defaultAsset = (prepaidAsset: PrepaidAsset, scheduleType: string, factaStartDate: Date | null, scheduleDate?: Date | null): PrepaidAsset => {
  const today = startOfDay(new Date());
  let amortizationEndDate = scheduleDate ?? today;
  if (prepaidAsset?.prepaidSchedule?.amortizationStartDate
        // @ts-ignore
        && isBefore(amortizationEndDate, fromUTC(prepaidAsset?.prepaidSchedule?.amortizationStartDate))) {
    // @ts-ignore
    amortizationEndDate = fromUTC(prepaidAsset?.prepaidSchedule?.amortizationStartDate);
  }
  if (prepaidAsset?.prepaidSchedule?.amortizationEndDate) {
    // @ts-ignore
    amortizationEndDate = fromUTC(prepaidAsset?.prepaidSchedule?.amortizationEndDate);
  }
  let prepaidAssetsAmortizationScheduleDetails: Array<PrepaidAssetsAmortizationScheduleDetail> = [];
  if (prepaidAsset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails?.length) {
    prepaidAssetsAmortizationScheduleDetails = prepaidAsset.prepaidSchedule.prepaidAssetsAmortizationScheduleDetails
            ?.map((schedule: PrepaidAssetsAmortizationScheduleDetail) => ({
              ...schedule,
              // @ts-ignore
              scheduleDate: fromUTC(schedule.scheduleDate),
            }));
  }
  // @ts-ignore
  return ({
    ...prepaidAsset,
    internalId: prepaidAsset.id,
    prepaidSchedule: {
      ...prepaidAsset.prepaidSchedule,
      amortizationStartDate: prepaidAsset?.prepaidSchedule?.amortizationStartDate
      // @ts-ignore
        ? fromUTC(prepaidAsset?.prepaidSchedule?.amortizationStartDate)
        : factaStartDate ?? today,
      amortizationEndDate,
      amortizationScheduleType: prepaidAsset?.prepaidSchedule?.amortizationScheduleType
        ? prepaidAsset?.prepaidSchedule?.amortizationScheduleType : scheduleType,
      prepaidAssetsAmortizationScheduleDetails,
    },
    // @ts-ignore
    assetCreationDate: prepaidAsset?.assetCreationDate ? fromUTC(prepaidAsset?.assetCreationDate) : today,
  });
};

export const defaultAssetFromLocalStorage = (prepaidAsset: PrepaidAsset): PrepaidAsset => {
  let prepaidAssetsAmortizationScheduleDetails: Array<PrepaidAssetsAmortizationScheduleDetail> = [];
  if (prepaidAsset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails?.length) {
    prepaidAssetsAmortizationScheduleDetails = prepaidAsset.prepaidSchedule.prepaidAssetsAmortizationScheduleDetails
        ?.map((schedule: PrepaidAssetsAmortizationScheduleDetail) => ({
          ...schedule,
          scheduleDate: new Date(schedule.scheduleDate!),
        }));
  }
  return ({
    ...prepaidAsset,
    internalId: prepaidAsset.id ?? prepaidAsset.internalId ?? uuidv4(),
    prepaidSchedule: {
      ...prepaidAsset.prepaidSchedule,
      amortizationStartDate: new Date(prepaidAsset?.prepaidSchedule?.amortizationStartDate!),
      amortizationEndDate: new Date(prepaidAsset?.prepaidSchedule?.amortizationEndDate!),
      prepaidAssetsAmortizationScheduleDetails,
    },
    assetCreationDate: new Date(prepaidAsset?.assetCreationDate!),
  });
};

interface InitSubledgerProps {
  subledger: Subledger;
  scheduleDate?: string;
}

export const initializedSubledger = ({ subledger, scheduleDate }: InitSubledgerProps): Subledger => {
  const date = scheduleDate ? parse(scheduleDate, DAY_SHORT_FORMAT, new Date()) : null;
  // @ts-ignore
  const factaStartDate = startOfMonth(fromUTC(subledger?.factaStartDate!));
  const serviceAssets = subledger
        ?.prepaidAssets
        ?.map((prepaidAsset: any) => defaultAsset(
          prepaidAsset,
            subledger?.account?.scheduleType,
            date,
        )) ?? [];
    // @ts-ignore
  let subledgerAmortizationLogs: Array<SubledgerAmortizationLog> = [];
  if (subledger?.subledgerAmortizationLogs?.length) {
    subledgerAmortizationLogs = subledger?.subledgerAmortizationLogs
            ?.map((log: SubledgerAmortizationLog) => ({
              ...log,
              // @ts-ignore
              scheduleDate: fromUTC(log.scheduleDate),
            }));
  }
  return {
    ...subledger,
    prepaidAssets: serviceAssets,
    factaStartDate,
    subledgerAmortizationLogs,
  };
};

export const initializedSubledgerFromLocalStorage = ({ subledger }: InitSubledgerProps): Subledger => {
  // @ts-ignore
  const factaStartDate = startOfMonth(new Date(subledger?.factaStartDate!));
  const serviceAssets = subledger
      ?.prepaidAssets
      ?.map((prepaidAsset: any) => defaultAssetFromLocalStorage(
        prepaidAsset,
      )) ?? [];
  let subledgerAmortizationLogs: Array<SubledgerAmortizationLog> = [];
  if (subledger?.subledgerAmortizationLogs?.length) {
    subledgerAmortizationLogs = subledger?.subledgerAmortizationLogs
        ?.map((log: SubledgerAmortizationLog) => ({
          ...log,
          // @ts-ignore
          scheduleDate: new Date(log.scheduleDate),
        }));
  }
  return {
    ...subledger,
    prepaidAssets: serviceAssets,
    factaStartDate,
    subledgerAmortizationLogs,
  };
};

export const calculateAssetForUnScheduledAssets = (newAssets: Array<PrepaidAsset>, index: number, subledger: Subledger) => {
  if (newAssets[index]?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual) {
    const assetCreationDate = getAssetCreationDate(newAssets[index], newAssets);
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    newAssets[index] = calculateScheduleAsset(subledger, newAssets[index]);
    // eslint-disable-next-line no-param-reassign
    newAssets[index] = catchUpAmountForPrepaidAsset(lastDayOfMonth(assetCreationDate!), newAssets, index);
  } else {
    // eslint-disable-next-line no-param-reassign
    newAssets[index].prepaidSchedule.amortizationEndDate = null;
    // eslint-disable-next-line no-param-reassign
    newAssets[index].prepaidSchedule.amortizationStartDate = null;
    // eslint-disable-next-line no-param-reassign
    newAssets[index].prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = null;
  }
};

export interface ExportToCSVProps {
  subledger: Subledger;
  vendors: Array<Vendor>;
  accountClasses: Array<AccountClass>;
  accountIncomes: Array<Account>;
  account: AccountInfo;
  scheduleDate?: string;
}

export const schedulerExportToCSV = ({ subledger, vendors, accountClasses, accountIncomes, account, scheduleDate }: ExportToCSVProps) => {
  // filter the prepaid assets for which the assests status is scheduled.
  const filteredPrepaidAssets = subledger?.prepaidAssets
      ?.filter((asset: PrepaidAsset) => !asset.parentId && [ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS]?.includes(asset.status));
  // chunk to create a flat array representing the parent child relationships in a nested array.
  // @ts-ignore
  const prepaidAssetSet: Array = [];
  filteredPrepaidAssets?.forEach((asset: PrepaidAsset) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const hasChildren = children?.length > 0;
    const { amortizationToDateBalance } = hasChildren ? getTotalBalanceAndAmortizationBalance(children) : {
      amortizationToDateBalance: 0,
    };
    const vendor = getVendorName(asset.vendorId, vendors);
    const parentAccountClass = getClassName(asset?.prepaidSchedule?.classId!, accountClasses);
    const parentRemainingBalance = currencyFormatter.format(asset?.startingBalance - (asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0));
    const expenseToDate = currencyFormatter.format((asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0));
    const parentAmortizationStartDate = asset?.prepaidSchedule?.amortizationStartDate && asset?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual ? format(asset?.prepaidSchedule?.amortizationStartDate, FULL_DAY_FORMAT) : 'M';
    const parentAmortizationEndDate = asset?.prepaidSchedule?.amortizationEndDate && asset?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual ? format(asset?.prepaidSchedule?.amortizationEndDate, FULL_DAY_FORMAT) : 'M';
    const classAvailable = account?.classTrackingPerTxnLine || account?.classTrackingPerTxn;
    const reportingMonth = format(parse(scheduleDate!, DAY_SHORT_FORMAT, new Date()), MONTH_SHOW_FORMAT);
    const parentDescription = asset?.description;

    if (!children?.length) {
      // @ts-ignore
      prepaidAssetSet.push({
        ...asset,
        reportingMonth,
        parentDescription,
        expenseAccount: getAccountNameFromList(asset?.prepaidSchedule?.expenseAccountId!, accountIncomes),
        accountClass: classAvailable ? parentAccountClass : '',
        vendor,
        remainingBalance: parentRemainingBalance,
        expenseToDate,
        amortizationToDateBalance: currencyFormatter.format((asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0)),
        amount: currencyFormatter.format(asset?.startingBalance!),
        amortizationStartDate: parentAmortizationStartDate,
        amortizationEndDate: parentAmortizationEndDate,
        url: getQuickbookLink(asset.assetId, asset.assetType),
        assetCreationDate: asset?.assetCreationDate && format(asset?.assetCreationDate, FULL_DAY_FORMAT),
        amortizationScheduleType: asset?.prepaidSchedule?.amortizationScheduleType,
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      children?.forEach((child) => {
        prepaidAssetSet.push({
          ...asset,
          reportingMonth,
          parentDescription,
          description: child?.description,
          expenseAccount: getAccountNameFromList(child?.prepaidSchedule?.expenseAccountId!, accountIncomes),
          accountClass: classAvailable ? getClassName(child?.prepaidSchedule?.classId!, accountClasses) : '',
          vendor,
          remainingBalance: currencyFormatter.format(child.startingBalance - (child?.amortizationToDateBalance ? child?.amortizationToDateBalance : 0)),
          expenseToDate: currencyFormatter.format((child?.amortizationToDateBalance ? child?.amortizationToDateBalance : 0)),
          amortizationToDateBalance,
          amount: currencyFormatter.format(child.startingBalance),
          amortizationStartDate: child?.prepaidSchedule?.amortizationStartDate && child?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual ? format(child?.prepaidSchedule?.amortizationStartDate, FULL_DAY_FORMAT) : 'M',
          amortizationEndDate: child?.prepaidSchedule?.amortizationEndDate && child?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual ? format(child?.prepaidSchedule?.amortizationEndDate, FULL_DAY_FORMAT) : 'M',
          amortizationScheduleType: child?.prepaidSchedule?.amortizationScheduleType,
          url: getQuickbookLink(asset.assetId, asset.assetType),
          assetCreationDate: asset?.assetCreationDate && format(asset?.assetCreationDate, FULL_DAY_FORMAT),
        });
      });
    }
  });
  // preparing filtered data to be unparsed and fed to csv
  const csvData = Papa.unparse({
    data: prepaidAssetSet,
    fields: ['reportingMonth',
      'assetCreationDate',
      'assetId',
      'url',
      'parentDescription',
      'description',
      'vendor',
      'expenseAccount',
      (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) ? 'accountClass' : '',
      'amortizationStartDate',
      'amortizationEndDate',
      'amortizationScheduleType',
      'amount',
      'expenseToDate',
      'remainingBalance'],
  }, {
    header: false,
    skipEmptyLines: true,
  });

  // chunk to unify the column and render the fed to csv data
  const downloadData = `${['Report Period',
    'JE Date',
    'GL JE ID',
    'Url',
    'Parent Description',
    'Description',
    'Vendor',
    'Expense Account',
    (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) ? 'Class' : null,
    'Amortization Start Date',
    'Amortization End Date',
    'Amortization Schedule',
    'Amount',
    'Expense To Date',
    'Remaining Balance'].join(',')}\n${csvData}`;

  fileDownload(downloadData, 'Schedule Prepaid.csv');
};

export const calculateAsset = (subledger: Subledger, internalId: string) => {
  const newAssets = [...subledger.prepaidAssets];
  const index = subledger.prepaidAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === internalId);
  const children = getChildren(newAssets[index].internalId, newAssets);
  if (children?.length) {
    // eslint-disable-next-line no-param-reassign
    newAssets[index].prepaidSchedule.amortizationEndDate = null;
    // eslint-disable-next-line no-param-reassign
    newAssets[index].prepaidSchedule.amortizationStartDate = null;
    // eslint-disable-next-line no-param-reassign
    newAssets[index].prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = null;
    children?.forEach((child) => {
      const childIndex = subledger.prepaidAssets
            ?.findIndex((asset: PrepaidAsset) => asset.internalId === child.internalId);
      calculateAssetForUnScheduledAssets(newAssets, childIndex, subledger);
    });
  } else {
    calculateAssetForUnScheduledAssets(newAssets, index, subledger);
  }
  return newAssets;
};

export const isPrepaidScheduleSame = (oldPrepaidSchedule: PrepaidSchedule, newPrepaidSchedule: PrepaidSchedule) => {
  if (oldPrepaidSchedule?.amortizationScheduleType === ScheduleType.Manual
        && newPrepaidSchedule?.amortizationScheduleType === ScheduleType.Manual) {
    return oldPrepaidSchedule.classId === newPrepaidSchedule.classId
            && oldPrepaidSchedule.expenseAccountId === newPrepaidSchedule.expenseAccountId;
  }
  return (
    oldPrepaidSchedule.classId === newPrepaidSchedule.classId
    && oldPrepaidSchedule.expenseAccountId === newPrepaidSchedule.expenseAccountId
    && isSameDay((oldPrepaidSchedule.amortizationStartDate ?? new Date()), (newPrepaidSchedule.amortizationStartDate ?? new Date()))
    && isSameDay((oldPrepaidSchedule.amortizationEndDate ?? new Date()), (newPrepaidSchedule.amortizationEndDate ?? new Date()))
    && oldPrepaidSchedule.amortizationScheduleType === newPrepaidSchedule.amortizationScheduleType
  );
};

export const initialPrepaidAssetForPrepareJE = (subledger: Subledger, scheduleDate: string) => subledger?.prepaidAssets
    ?.map((prepaidAsset: any) => {
      let amortizationSchedules = prepaidAsset
        ?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails
        ?.map((item: PrepaidAssetsAmortizationScheduleDetail) => ({
          ...item,
          amount: item?.amount ?? 'M',
        }));

      if (prepaidAsset.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual) {
        const date = parse(scheduleDate, DAY_SHORT_FORMAT, new Date());
        const amortizationScheduleDate = startOfDay(date);
        const amortizationScheduleAmount = prepaidAsset.startingBalance - (prepaidAsset?.amortizationToDateBalance ?? 0);
        if (!amortizationSchedules) {
          amortizationSchedules = [{
            scheduleDate: amortizationScheduleDate,
            amount: amortizationScheduleAmount,
          }];
        } else if (!amortizationSchedules
            ?.some((scheduled: PrepaidAssetsAmortizationScheduleDetail) => format(scheduled.scheduleDate, DAY_SHORT_FORMAT) === scheduleDate)
            && isAfter(endOfMonth(amortizationScheduleDate), prepaidAsset.assetCreationDate)) {
          amortizationSchedules = [...amortizationSchedules, {
            scheduleDate: amortizationScheduleDate,
            amount: amortizationScheduleAmount,
          }];
        }
      }
      return ({
        ...prepaidAsset,
        prepaidSchedule: {
          ...prepaidAsset.prepaidSchedule,
          prepaidAssetsAmortizationScheduleDetails: amortizationSchedules,
        },
      });
    });

export const getTotalAndRowsCount = (prepaidAssets: Array<PrepaidAsset>, scheduleDate: string): any => {
  const filterAssets = prepaidAssets?.filter((asset: PrepaidAsset) => {
    const children = getChildren(asset?.id, prepaidAssets!);
    return children?.length === 0;
  });
  const { positiveTotal, negativeTotal } = getPositiveNegativeAssetForMonth(scheduleDate, filterAssets!);
  const total = Math.max(positiveTotal, negativeTotal);
  const totalPrepaidAccount = positiveTotal - negativeTotal;
  return {
    totalPrepaidAccount,
    rowsCount: filterAssets?.length,
    total,
  };
};

export const updateAssetsForSaving = (prepaidAssets: Array<PrepaidAsset>) => {
  prepaidAssets?.forEach((prepaidAsset: PrepaidAsset) => {
    // eslint-disable-next-line no-param-reassign
    prepaidAsset.startingBalance = Number(prepaidAsset.startingBalance);
    if (prepaidAsset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails?.length) {
      const prepaidAssetsAmortizationScheduleDetails = prepaidAsset?.prepaidSchedule
          ?.prepaidAssetsAmortizationScheduleDetails
          ?.filter((item: PrepaidAssetsAmortizationScheduleDetail) => !Number.isNaN(Number(item.amount)));
      for (const amortizationSchedule of prepaidAssetsAmortizationScheduleDetails) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        amortizationSchedule.prepaidAsset = null;
        // @ts-ignore
        amortizationSchedule.scheduleDate = toUTC(amortizationSchedule.scheduleDate);
        amortizationSchedule.amount = Number(amortizationSchedule.amount);
      }
      // eslint-disable-next-line no-param-reassign
      prepaidAsset.prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = prepaidAssetsAmortizationScheduleDetails;
    }
    if (prepaidAsset?.prepaidSchedule?.amortizationStartDate) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      prepaidAsset.prepaidSchedule.amortizationStartDate = toUTC(prepaidAsset?.prepaidSchedule?.amortizationStartDate);
    }
    if (prepaidAsset?.prepaidSchedule?.amortizationEndDate) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      prepaidAsset.prepaidSchedule.amortizationEndDate = toUTC(prepaidAsset?.prepaidSchedule?.amortizationEndDate);
    }
    if (prepaidAsset?.assetCreationDate) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      prepaidAsset.assetCreationDate = toUTC(prepaidAsset?.assetCreationDate);
    }
  });
};

export const monthChange = (previousDate: Date, subledger: Subledger) => {
  const { endDate } = getSubledgerStartEndDate(subledger);
  const datesEquality = isEqual(
    endDate,
    previousDate,
  );
  return {
    equalDates: datesEquality,
    scheduleDateToNavigate: lastDayOfMonth(previousDate),
  };
};

export const getPrepaidAssetsForSave = async (existingAsset: PrepaidAsset, dispatch: any,
  scheduleDate: string, subledger: Subledger) => {
  const asset = cloneDeep(existingAsset) as PrepaidAsset;
  await dispatch({ type: CALCULATE_ASSET, payload: { internalId: asset.internalId, scheduleDate } });
  const subledgerAssets = cloneDeep(subledger?.prepaidAssets);
  const prepaidAsset = subledgerAssets?.find((a: PrepaidAsset) => a.internalId === asset.internalId);
  if (!prepaidAsset) {
    return { prepaidAsset: null };
  }
  const children = getChildren(prepaidAsset?.internalId!, subledgerAssets);
  const prepaidAssets = [prepaidAsset, ...children];
  updateAssetsForSaving(prepaidAssets);
  return { prepaidAsset, prepaidAssets };
};

export const isCreateSubledgerFormValid = (subledger: Subledger, dispatch: any) => {
  let formInvalid = true;
  subledger?.prepaidAssets?.filter((prepaidAsst: PrepaidAsset) => !prepaidAsst.parentId).forEach((prepaidAsset: PrepaidAsset) => {
    const children = getChildren(prepaidAsset.internalId, subledger?.prepaidAssets);
    if (children?.length > 0) {
      if (!prepaidAsset.description || !prepaidAsset.vendorId) {
        if (formInvalid) {
          formInvalid = false;
          dispatch({ type: SELECT_ROW, payload: { selectedRow: prepaidAsset.internalId } });
        }
      }
      children?.forEach((asset: PrepaidAsset) => {
        if (!asset.prepaidSchedule?.expenseAccountId
            || (asset.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual && (
              !asset.prepaidSchedule?.amortizationEndDate
                || !asset?.prepaidSchedule?.amortizationStartDate
                || isBefore(asset?.prepaidSchedule?.amortizationEndDate, asset?.prepaidSchedule?.amortizationStartDate)
                || isBefore(asset.prepaidSchedule?.amortizationStartDate, subledger?.factaStartDate!)
            ))
        ) {
          if (formInvalid) {
            formInvalid = false;
            dispatch({ type: SELECT_ROW, payload: { selectedRow: prepaidAsset.internalId } });
          }
        }
      });
    } else if (!prepaidAsset.description
        || !prepaidAsset.vendorId
        || !prepaidAsset.prepaidSchedule?.expenseAccountId
        || (prepaidAsset.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual && (
          !prepaidAsset.prepaidSchedule?.amortizationEndDate
            || !prepaidAsset.prepaidSchedule?.amortizationStartDate
            || isBefore(prepaidAsset.prepaidSchedule?.amortizationEndDate, prepaidAsset?.prepaidSchedule?.amortizationStartDate)
            || isBefore(prepaidAsset.prepaidSchedule?.amortizationStartDate, subledger?.factaStartDate!)
        ))
    ) {
      if (formInvalid) {
        formInvalid = false;
        dispatch({ type: SELECT_ROW, payload: { selectedRow: prepaidAsset.internalId } });
      }
    }
  });
  return formInvalid;
};

export const updateHistoricalSubledgerData = (
  historicalUpdatedAssets: Array<PrepaidAsset>,
  subledger: Subledger,
  serviceData: Subledger,
) => {
  if (historicalUpdatedAssets?.length) {
    const prepaidAssets = subledger?.prepaidAssets.map((asset: PrepaidAsset) => {
      const historicalAsset = historicalUpdatedAssets?.find((ha: PrepaidAsset) => ha.internalId === asset.internalId);
      if (historicalAsset) {
        return cloneDeep(historicalAsset);
      }
      return asset;
    });
    const newSubledger = { ...subledger, prepaidAssets };
    setSubledger({ subledger: newSubledger, historicalUpdatedAssets });
  } else {
    const existingSubledger = serviceData ? initializedSubledger({ subledger: serviceData }) : subledger;
    setSubledger({ subledger: existingSubledger, historicalUpdatedAssets });
  }
};
