/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React, { createContext, useState, useContext } from 'react';

// @ts-ignore
const NavBarContext = createContext();

export default (props: any) => {
  const navBarStatus = Boolean(props.open);
  const [navBarOpen, setNavBarOpen] = useState<boolean>(navBarStatus);

  const setNavBar = (open: boolean) => {
    localStorage.setItem('navBarOpen', open.toString());
    setNavBarOpen(open);
  };

  const value = React.useMemo(() => ({
    navBarOpen,
    setNavBar,
  }), [navBarOpen]);

  return <NavBarContext.Provider value={value} {...props} />;
};

export const useNavBarContext = () => {
  const context = useContext(NavBarContext);

  if (!context) {
    throw new Error('useNavBarContext() has to be used within a child of the NavBar Provider');
  }

  const {
    navBarOpen,
    setNavBar: setNavBarOpen,
  }:any = context;

  return {
    navBarOpen,
    setNavBarOpen,
  };
};
