/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Controller, useForm } from 'react-hook-form';
import { createStyles, Divider, Link } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import { useFetch } from 'use-http';
import { FormInputBox, StyledFormControl, StyledFormHelperText, StyledInputLabel } from '../../components/FormControl';
import Loader from '../../components/Loader/loader';
import { PrimaryButton } from '../../components/Buttons';
import { StyledInputLabelRequired, StyledFormLabel } from '../../components/FormControl/FormControl.styled';
import { NotifierType, User } from '../../variables/types';
import 'react-phone-input-2/lib/style.css';
import COLORS from '../../variables/colors';
import { openSnackbar } from '../../components/Notifier';
import { useAccountProvider } from '../../core/accountContext';

interface Props {
  user?: User,
  update: Function,
  response?: any,
}

const useStyles = makeStyles(() => createStyles({
  inputClass: {
    width: '100% !important',
    background: COLORS.white,
    border: '1px solid rgba(207, 211, 218, 0.6) !important',
    borderRadius: 2,
    color: COLORS.deepGray,
  },
  inputClassError: {
    border: `1px solid ${COLORS.violet} !important`,
  },
  buttonClass: {
    border: '1px solid rgba(207, 211, 218, 0.6) !important',
  },
  buttonClassError: {
    border: `1px solid ${COLORS.violet} !important`,
  },
  linkClass: {
    color: COLORS.skyBlue,
    paddingLeft: '4px',
    paddingTop: '10px',
  },
  helperLinks: {
    paddingLeft: 4,
    marginTop: 15,
  },
}));

const Profile = ({ user, update, response }: Props) => {
  const classes = useStyles();
  const { delete: deleteAll, response: deleteResponse } = useFetch('/account');
  const { get } = useFetch();
  const { setAccount } = useAccountProvider();

  const { register, control, handleSubmit, errors } = useForm({
    defaultValues: {
      ...user,
    },
  });
  const [loading, setLoading] = useState(false);

  const onSignUp = async (data:Record<string, string>) => {
    setLoading(true);
    await update({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      officePhone: data.officePhone,
      mobilePhone: data.mobilePhone,
    });
    if (!response.ok) {
      openSnackbar({ message: response.data }, NotifierType.Error);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    await deleteAll();
    if (!deleteResponse.ok) {
      openSnackbar({ message: deleteResponse.data }, NotifierType.Error);
    }
    const data = await get('/account/sync');
    if (response.ok) {
      setAccount(data);
    }
    setLoading(false);
  };

  return (
    <Box ml={5} display="flex" flexDirection="row">
      <Box m={2}>
        <Loader open={loading} />
        <StyledFormLabel>Profile</StyledFormLabel>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSignUp)}
          noValidate
        >
          <Box display="flex" flexDirection="column" width="100%">
            <FormInputBox
              title="First Name"
              name="firstName"
              type="input"
              require
              defaultValue={user?.firstName}
              hasError={Boolean(errors?.firstName)}
              helperText={errors?.firstName?.message!}
              inputRef={register({
                required: { value: true, message: 'First Name is required' },
              })}
            />
            <FormInputBox
              title="Last Name"
              name="lastName"
              type="input"
              require
              defaultValue={user?.lastName}
              hasError={Boolean(errors?.lastName)}
              helperText={errors?.lastName?.message!}
              inputRef={register({
                required: { value: true, message: 'Last Name is required' },
              })}
            />
            <FormInputBox
              title="Email"
              name="email"
              type="email"
              require
              hasError={Boolean(errors?.email)}
              defaultValue={user?.email}
              helperText={errors?.email?.message!}
              inputRef={register({
                required: { value: true, message: 'Email is required' },
                pattern: {
                  value: /[^\\.\\s@:](?:[^\\s@:]*[^\\s@:\\.])?@[^\\.\\s@]+(?:\\.[^\\.\\s@]+)*/,
                  message: 'Please enter correct email address.',
                },
              })}
            />
            <StyledFormControl>
              <StyledInputLabel>
                {
                  require && (<StyledInputLabelRequired>*</StyledInputLabelRequired>)
                }
                Office Phone
              </StyledInputLabel>
              <Controller
                control={control}
                name="officePhone"
                mask="+99 999 - 999 - 9999"
                type="tel"
                maskChar={null}
                defaultValue={user?.officePhone}
                error={errors?.officePhone}
                country="us"
                required
                rules={
                  {
                    required: { value: true, message: 'Office Phone is required' },
                  }
                }
                as={(
                  <PhoneInput
                    inputClass={clsx(classes.inputClass, {
                      [classes.inputClassError]: errors?.officePhone,
                    })}
                    buttonClass={clsx(classes.buttonClass, {
                      [classes.buttonClassError]: errors?.officePhone,
                    })}
                  />
                )}
              />
              {
                errors?.officePhone && <StyledFormHelperText>{errors?.officePhone?.message}</StyledFormHelperText>
              }
            </StyledFormControl>
            <StyledFormControl>
              <StyledInputLabel>Mobile Phone</StyledInputLabel>
              <Controller
                control={control}
                name="mobilePhone"
                mask="+99 999 - 999 - 9999"
                type="tel"
                maskChar={null}
                defaultValue={user?.mobilePhone}
                country="us"
                as={(
                  <PhoneInput
                    inputClass={classes.inputClass}
                    buttonClass={classes.buttonClass}
                  />
                )}
              />
              {
                errors.mobilePhone && <StyledFormHelperText>{errors?.mobilePhone?.message}</StyledFormHelperText>
              }
            </StyledFormControl>
            <Divider style={{ marginTop: 16 }} />
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <PrimaryButton
                variant="contained"
                color="primary"
                type="submit"
              >
                UPDATE
              </PrimaryButton>
            </Box>
          </Box>
        </form>
      </Box>
      <Box m={2}>
        <StyledFormLabel>Product Info</StyledFormLabel>
        <Box display="flex" flexDirection="column" width="100%">
          <Link className={classes.helperLinks} href="https://help.facta.io" target="_blank">FAQ</Link>
          <Link className={classes.helperLinks} href="https://facta.io/privacy-policy" target="_blank">Policies & Procedures</Link>
          <Link className={classes.helperLinks} href="https://facta.io/terms-and-conditions" target="_blank">Terms of Use</Link>
        </Box>
      </Box>

      {
        process.env.REACT_APP_ENV !== 'prod' && (
          <Box m={2}>
            <StyledFormLabel>Settings</StyledFormLabel>
            <PrimaryButton
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLORS.violet }}
              onClick={onDelete}
            >
              Delete All
            </PrimaryButton>
          </Box>
        )
      }
    </Box>
  );
};

export default Profile;
