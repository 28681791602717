import React, { useCallback, useEffect, useState } from 'react';
import useFetch from 'use-http';
import Table from '@material-ui/core/Table';
import {
  Box,
  ButtonBase,
  createStyles,
  IconButton,
  MenuItem,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/styles/makeStyles';
import startCase from 'lodash.startcase';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Loader from '../../components/Loader/loader';
import { Account, ErrorPageType, Subledger } from '../../variables/types';
import SortNoneIcon from '../../components/Icons/SortNonecon';
import SortUpIcon from '../../components/Icons/SortUpIcon';
import SortDownIcon from '../../components/Icons/SortDownIcon';
import { Direction, Sort, sortAccountData } from '../../util/sort';
import currencyFormatter from '../../util/currencyFormatter';
import SelectBox from '../../components/Select';
import { PREPAID_EXPENSES, FINALIZED_SUBLEDGER_STATUS } from '../../variables/constants';
import ExportIcon from '../../components/Icons/ExportIcon';
import COLORS from '../../variables/colors';
import { useAccountProvider } from '../../core/accountContext';
import { RecordIcon } from '../../components/Icons';
import HideVisibilityIcon from '../../components/Icons/HideVisibilityIcon';
import VisibilityIcon from '../../components/Icons/VisibilityIcon';
import DialogBox from '../../components/DialogBox';
import getScheduleDate from '../../util/getScheduleDate';
import ErrorPage from '../../components/ErrorPage';
import { initializedSubledger } from '../../components/Subledger/common';

const useStyles = makeStyles(() => createStyles({
  opacity4: {
    opacity: 0.4,
  },
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
  fontSize13: {
    fontSize: 13,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const BalanceSheet = () => {
  const { get, loading, error, response: fetchResponse } = useFetch();
  const { put, error: requestError, response, post } = useFetch();
  const { account } = useAccountProvider();
  const [balances, setBalances] = useState<Array<Account>>([]);
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState(false);
  const [accountId, setaccountId] = useState();
  const classes = useStyles();
  const [sortBy, setSortBy] = useState<Sort>({ name: 'visited', direction: Direction.Down });
  const history = useHistory();
  const getSortIcon = (name: string) => {
    if (name === sortBy?.name) {
      if (sortBy.direction === Direction.Down) {
        return SortUpIcon;
      }
      return SortDownIcon;
    }
    return SortNoneIcon;
  };

  const getData = useCallback(async () => {
    if (!loading && !error) {
      const result = await get('/account/balances');
      if (fetchResponse.ok) {
        setBalances(result ?? []);
        await put('/account/actions/balances/visited');
      }
    }
  }, [error, fetchResponse.ok, get, loading, put]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const onSortClick = (name: string) => () => {
    if (sortBy?.name === name) {
      setSortBy({
        name,
        direction: sortBy?.direction === Direction.Up ? Direction.Down : Direction.Up,
      });
    } else {
      setSortBy({
        name,
        direction: Direction.Up,
      });
    }
  };

  const showDialog = (id: string) => {
    setOpenSubledgerDialog(true);
    setaccountId(id);
  };
  const closeDialog = () => {
    setOpenSubledgerDialog(false);
    setaccountId('');
  };

  const changeScheduleType = (id: string) => async (event: React.ChangeEvent<{ value: unknown }>) => {
    const accountBalance = balances?.find((balance) => balance.id === id);
    if (accountBalance) {
      await put(`/account/balances/${id}`, { enable: accountBalance.enable, scheduleType: event.target.value as string });
      if (response.ok) {
        const acctBalances = balances?.map((balance) => {
          if (balance?.id === id) {
            return {
              ...balance,
              scheduleType: event.target.value as string,
            };
          }
          return { ...balance };
        });
        setBalances(acctBalances);
      }
    }
  };

  // Subledger creation POST trigger
  const createSubledger = async () => {
    const subledgerData = await post('/subledgers', { accountId });
    if (response.ok) {
      closeDialog();
      history.push(`/subledgers/scheduler/${subledgerData.id}`);
    }
  };
  const subledgerRedirect = async (subledger: Subledger) => {
    if (subledger?.status !== FINALIZED_SUBLEDGER_STATUS) {
      history.push(`/subledgers/scheduler/${subledger.id}`);
    } else {
      const scheduleDate = getScheduleDate(initializedSubledger({ subledger }));
      history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${scheduleDate}`);
    }
  };
  const changeVisibility = (id: string, enable: boolean) => async () => {
    const accountBalance = balances?.find((balance) => balance.id === id);
    if (accountBalance) {
      await put(`/account/balances/${id}`, { enable: !enable, scheduleType: accountBalance.scheduleType });
      if (response.ok) {
        const acctBalances = balances?.map((balance) => {
          if (balance?.id === id) {
            return {
              ...balance,
              enable: !enable,
            };
          }
          return { ...balance };
        });
        setBalances(acctBalances);
      }
    }
  };

  const subledgerEditRedirect = async (subledger: Subledger) => {
    if (subledger?.subledgerAmortizationLogs?.length
        || subledger?.status === FINALIZED_SUBLEDGER_STATUS) {
      history.push(`/historical/subledgers/scheduler/${subledger.id}`);
    } else {
      history.push(`/subledgers/scheduler/${subledger.id}`);
    }
  };

  if (error) {
    return <ErrorPage variant={ErrorPageType.Error} />;
  }
  if (loading && !balances?.length) {
    return <Loader open />;
  }

  const notVisitedAccounts = balances?.filter((balance: Account) => !balance.visited)?.sort(sortAccountData(sortBy)) ?? [];
  const currentBalances = balances?.filter((balance: Account) => !!balance.visited)?.sort(sortAccountData(sortBy)) ?? [];

  const onDownload = () => {
    const allAccounts = [...notVisitedAccounts, ...currentBalances];
    if (allAccounts?.length) {
      const acctBalances = allAccounts?.map((balance: any) => ({
        ...balance,
        glBalance: currencyFormatter.format(balance.glBalance),
        accountDetail: startCase(balance.accountDetail),
        scheduleType: balance.accountDetail === PREPAID_EXPENSES ? balance.scheduleType : '',
        active: balance.active ? 'Active' : 'Inactive',
      }));
      const csvData = Papa.unparse({
        data: acctBalances,
        fields: ['accNum', 'name', 'accountType', 'accountDetail', 'active', 'scheduleType', 'glBalance'],
      }, {
        header: false,
      });
      const downloadData = `${['Number', 'Account Name', 'Type', 'Detail', 'Active/Inactive', 'AMort. Schedule', 'GL Balance'].join(',')}\n${csvData}`;
      fileDownload(downloadData, 'Balance Sheet.csv');
    }
  };

  const renderAccountBalances = (balance: Account) => (
    <TableRow
      key={balance.id}
      className={!balance.active ? classes.opacity4 : ''}
      hover
    >
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {balance.accNum}
      </TableCell>
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {balance.name}
      </TableCell>
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {balance.accountType}
      </TableCell>
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {startCase(balance.accountDetail)}
      </TableCell>
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {balance.active ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell>
        {
          balance.accountDetail === PREPAID_EXPENSES && (
            <SelectBox
              value={balance.scheduleType}
              disableUnderline
              onChange={changeScheduleType(balance.id)}
            >
              <MenuItem value="DAILY">
                Daily
              </MenuItem>
              <MenuItem value="MONTHLY">
                Monthly
              </MenuItem>
            </SelectBox>
          )
        }
      </TableCell>
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {currencyFormatter.format(balance.glBalance)}
      </TableCell>
      <TableCell align="center">
        {balance.accountDetail === PREPAID_EXPENSES && (
            balance?.subledger?.id ? (
              <IconButton className={classes.noOffset} onClick={() => subledgerRedirect(balance.subledger)}>
                <RecordIcon color="primary" fontSize="small" />
              </IconButton>
            ) : (
              <IconButton className={classes.noOffset} onClick={() => showDialog(balance.id)}>
                <AddCircleRoundedIcon color="primary" fontSize="small" />
              </IconButton>
            )
        )}
      </TableCell>
      <TableCell align="center">
        {balance.accountDetail === PREPAID_EXPENSES && balance?.subledger?.id && (
          <IconButton className={classes.noOffset} onClick={() => subledgerEditRedirect(balance.subledger)}>
            <EditOutlinedIcon color="primary" fontSize="small" />
          </IconButton>
        )}
      </TableCell>
      {balance.accountDetail === PREPAID_EXPENSES && balance?.subledger?.id && (
        <TableCell align="left">
          <IconButton
            className={classes.noOffset}
            disabled={!balance.active}
            onClick={changeVisibility(balance.id, balance.enable)}
          >
            {
              !balance.enable && (
                <HideVisibilityIcon />
              )
            }
            {
              balance.enable && (
                <VisibilityIcon />
              )
            }
          </IconButton>
        </TableCell>
      )}
      <TableCell />
    </TableRow>
  );

  return (
    <Box
      padding={2}
      width="100%"
    >
      <Loader open={loading} />
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        actions={[{ title: 'Create Subledger', event: createSubledger }]}
        dialogContext={'You are about to create a new subledger. \n Do you wish to proceed?'}
        dialogTitle="Alert"
        dismissContext="Cancel"
      />
      <Snackbar
        open={Boolean(requestError)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">
          {requestError?.message}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <ButtonBase onClick={onDownload}>
          <Typography color="primary" className={classes.fontSize13}>Export</Typography>
          <ExportIcon fill={COLORS.skyBlue} className={classes.exportIcon} fontSize="small" />
        </ButtonBase>
      </Box>
      <Table
        size="small"

      >
        <TableHead>
          <TableRow
            hover={false}
          >
            <TableCell>
              <TableSortLabel IconComponent={getSortIcon('accNum')} onClick={onSortClick('accNum')}>
                Number
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel IconComponent={getSortIcon('name')} onClick={onSortClick('name')}>
                Account Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              Type
            </TableCell>
            <TableCell>
              Detail
            </TableCell>
            <TableCell>
              Active/Inactive
            </TableCell>
            <TableCell>
              AMort. Schedule
            </TableCell>
            <TableCell>
              GL Balance
            </TableCell>
            <TableCell width={100}>
              <TableSortLabel IconComponent={getSortIcon('subledger')} onClick={onSortClick('subledger.id')}>
                Facta Subledger
              </TableSortLabel>
            </TableCell>
            <TableCell>
              Edit Subledger
            </TableCell>
            <TableCell>
              Dashboard
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notVisitedAccounts.map((balance: Account) => renderAccountBalances(balance))}
          {currentBalances.map((balance: Account) => renderAccountBalances(balance))}
        </TableBody>
      </Table>
    </Box>

  );
};

export default BalanceSheet;
