import React from 'react';
import { useFetch } from 'use-http';

import Layout from '../../components/Layout';
import Profile from './Profile';
import Loader from '../../components/Loader/loader';

const ProfileContainer = () => {
  const { data, loading, put, response } = useFetch('/user/me', []);

  if (loading && !data) {
    return (<Loader open={loading} />);
  }

  return (
    <Layout title="Profile" showCompanyInfo={false}>
      <Profile
        user={data}
        update={put}
        response={response}
      />
    </Layout>
  );
};

export default ProfileContainer;
