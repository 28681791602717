import React, { useCallback, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import {
  Box,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import useFetch from 'use-http';
import Loader from '../../../components/Loader/loader';
import {
  PrepaidAsset,
} from '../../../variables/types';
import {
  getChildren,
  getTotalAndRowsCount,
} from '../../../components/Subledger/common';
import PostJEHeader from './PostJEHeader';
import reducer from '../../../components/Subledger/reducer';
import isEmpty from '../../../util/isEmpty';
import PostJETableHeader from '../../../components/Subledger/PostJETableHeader';
import PostJERow from '../../../components/Subledger/PostJERow';
import PostJETableFooter from '../../../components/Subledger/PostJETableFooter';
import PostJEPrepaidAccountRow from '../../../components/Subledger/PostJEPrepaidAccountRow';
import { INITIALED } from '../../../variables/constants';

interface Props {
  scheduleDate: string;
}

const PostJE = ({ scheduleDate }: Props) => {
  const { id: subledgerId } = useParams();
  const { get, loading: subledgerLoading, error: subLedgerError, response } = useFetch(`/subledgers/${subledgerId}/details`);
  const currentMonth = new Date(scheduleDate).toLocaleString('en-us', { month: 'long' });

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {} });

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subLedgerError) {
      const result = await get(`?scheduleDate=${scheduleDate}`);
      if (response.ok) {
        dispatch({
          type: INITIALED,
          payload: {
            subledger: result,
          },
        });
      }
    }
  }, [get, response.ok, scheduleDate, subLedgerError, subledgerLoading]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { subledger } = state;
  if (subledgerLoading && !isEmpty(subledger)) {
    return <Loader open />;
  }

  const renderAsset = () => (
    <>
      {
          subledger?.prepaidAssets?.filter((asset: PrepaidAsset) => {
            const children = getChildren(asset?.id, subledger?.prepaidAssets!);
            return children?.length === 0;
          }).map((asset: PrepaidAsset, index: number) => (
            <PostJERow
              key={asset?.internalId}
              asset={asset}
              index={index}
              prepaidAssets={subledger?.prepaidAssets}
              scheduleDate={scheduleDate}
            />
          ))
      }
    </>
  );

  const { totalPrepaidAccount, rowsCount, total } = (
    getTotalAndRowsCount(subledger?.prepaidAssets, scheduleDate) ?? { totalPrepaidAccount: 0, rowsCount: 0, total: 0 }
  );

  return (
    <>
      <Loader open={subledgerLoading} />
      <Box
        width="100%"
      >
        <PostJEHeader
          subledger={subledger}
          scheduleDate={scheduleDate}
          currentMonth={currentMonth}
        />
        <Box padding={1}>
          <Table
            size="small"
            id="journalEntryTable"
          >
            <PostJETableHeader />
            <TableBody>
              {
                renderAsset()
              }
              <PostJEPrepaidAccountRow
                rowsCount={rowsCount}
                account={subledger?.account}
                currentMonth={currentMonth}
                totalPrepaidAccount={totalPrepaidAccount}
              />
            </TableBody>
            <PostJETableFooter total={total} />
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default PostJE;
