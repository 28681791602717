/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SplitIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    idth="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 8V12M1 12H5M1 12L6 7M7 0V6L12.5 12M12.5 12V8M12.5 12H8.5" stroke={props?.fill ?? '#252729'} strokeWidth="0.75" />
  </SvgIcon>
);

export default SplitIcon;
