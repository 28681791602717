import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import cardStyles from './cardStyles';
import AccountName from './AccountName';
import GLBalance from './GLBalance';
import FactaBalance from './FactaBalance';
import TaskReadOnlyDetail from './TaskReadOnlyDetail';

interface Props {
  accountName: string;
}

const SubledgerDisableCard = ({
  accountName,
}: Props) => {
  const classes = cardStyles();
  return (
    <Card
      className={clsx(classes.card, classes.disabled)}
    >
      <CardContent>
        <section className={classes.cardType}>
          <h4>Account</h4>
        </section>
        <section className={classes.closedTag} />
        <AccountName accountName={accountName} />
        <GLBalance glBalance={0} />
        <FactaBalance balance={0} />
      </CardContent>
      <CardActions className={classes.padding15}>
        <TaskReadOnlyDetail
          name="Prepaids Scheduled"
          amount={0}
        />
        <TaskReadOnlyDetail
          name="Amortization Unposted"
          amount={0}
        />
        <TaskReadOnlyDetail
          name="Amortization Posted"
          amount={0}
        />
      </CardActions>
    </Card>
  );
};

export default React.memo(SubledgerDisableCard);
