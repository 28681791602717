import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  createStyles,
  InputAdornment, Typography,
} from '@material-ui/core';

import { format } from 'date-fns';
import roundTo from 'round-to';
import { CurrencyLabel, SchedulerLabel, SchedulerTop } from './SchedulerHeader.styled';
import COLORS from '../../../variables/colors';
import { Account, Subledger } from '../../../variables/types';
import currencyFormatter from '../../../util/currencyFormatter';
import { FULL_DAY_FORMAT } from '../../../variables/constants';
import {
  AccountName,
  HeaderCurrencyDetails,
  HeaderCurrencyDetailsDifference,
} from '../../../components/Subledger/Subledger.styled';
import isEmpty from '../../../util/isEmpty';
import InputBox from '../../../components/InputBox';
import StyledCalendarIcon from '../../../components/DatePicker/DatePicker.styled';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';

const useStyles = makeStyles(() => createStyles({
  subledgerBalance: {
    color: COLORS.violet,
  },
  glBalance: {
    maxWidth: 100,
    width: 100,
  },
  factaStartingBalance: {
    maxWidth: 120,
    width: 150,
  },
  inputBox: {
    boxShadow: COLORS.selectBoxShadow,
    borderRadius: 2,
    border: 'none',
    height: 32,
    '&> .MuiSelect-select:focus': {
      backgroundColor: COLORS.lightGray3,
    },
    marginRight: 20,
    width: 150,
  },
  pageMode: {
    position: 'absolute',
    top: 75,
    left: '50%',
    backgroundColor: COLORS.violet,
    padding: 2,
    paddingLeft: 20,
    paddingRight: 20,
    color: COLORS.white,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  font13: {
    fontSize: 13,
  },
}));

interface Props {
  subledger?: Subledger | null;
  onAmortizationScheduleTypeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  accountBalance: Account
}

const Header = ({
  subledger,
  accountBalance,
}: Props) => {
  const classes = useStyles();

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const difference = roundTo(Number(accountBalance?.glBalance), 2) - roundTo(Number(subledger?.openingBalance), 2);

  return (
    <>
      <SchedulerTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="calc(100vw - 200px)"
        paddingTop="35px !important"
      >
        <Box className={classes.pageMode}>
          <Typography className={classes.font13}>Edit Mode</Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          borderRight="1px solid #CFD3DA"
          paddingLeft="38px"
          paddingRight="38px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
            marginTop="10px"
          >
            <SchedulerLabel>
              Facta Start Date
            </SchedulerLabel>
            <InputBox
              value={format(subledger.factaStartDate!, FULL_DAY_FORMAT)}
              className={classes.inputBox}
              readOnly
              endAdornment={(
                <InputAdornment position="end">
                  <StyledCalendarIcon />
                </InputAdornment>
              )}
            />

            <SchedulerLabel>
              Amortization Schedule
            </SchedulerLabel>
            <AccountName>
              {subledger?.account?.scheduleType}
            </AccountName>
          </Box>
        </Box>
        <HeaderCurrencyDetails>
          <SchedulerLabel className={classes.glBalance}>
            GL Balance on start date:
          </SchedulerLabel>
          <CurrencyLabel>
            {currencyFormatter.format(accountBalance?.glBalance)}
          </CurrencyLabel>
        </HeaderCurrencyDetails>
        <HeaderCurrencyDetails>
          <SchedulerLabel className={classes.factaStartingBalance}>
            Facta Opening Subledger balance:
          </SchedulerLabel>
          <CurrencyLabel className={classes.subledgerBalance}>
            {currencyFormatter.format(subledger?.openingBalance)}
          </CurrencyLabel>
        </HeaderCurrencyDetails>
        <HeaderCurrencyDetailsDifference>
          <SchedulerLabel className={classes.glBalance}>
            Difference
          </SchedulerLabel>
          <CurrencyLabel>
            {currencyFormatter.format(difference)}
          </CurrencyLabel>
        </HeaderCurrencyDetailsDifference>
      </SchedulerTop>
    </>
  );
};

export default Header;
