import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { AppBar, Typography } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import COLORS from '../../variables/colors';

export const StyledAppBar = styled(AppBar)({
  border: 'none',
  boxShadow: 'none',
  backgroundColor: COLORS.lightGray3,
});

export const TopBarContainer = styled(Box)({
  paddingTop: 20, paddingBottom: 20,
});

export const PageTitle = styled(Typography)({
  fontSize: 24,
  textTransform: 'uppercase',
  marginRight: 38,
  marginLeft: 38,
  color: COLORS.lightGray,
  fontFamily: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
});

export const TopBarLabel = styled(Typography)({
  fontSize: 12,
  marginRight: 9,
  color: COLORS.medGray,
  textTransform: 'capitalize',
  fontFamily: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',

});

export const TopBarBoldLabel = styled(TopBarLabel)({
  fontWeight: 600,
  fontFamily: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
});

export const StyledResyncButton = styled(Button)({
  border: `1px solid ${COLORS.deepGray}`,
  marginLeft: 8,
  fontSize: 11,
  paddingLeft: 8,
  paddingRight: 8,
  height: 18,
  fontFamily: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
});
