import React, { useReducer, useState, useEffect } from 'react';
import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { endOfMonth, format, isAfter, isBefore, isWithinInterval, lastDayOfMonth, parse, subMonths } from 'date-fns';
import { useHistory, useLocation } from 'react-router';
import useFetch from 'use-http';
import cloneDeep from 'lodash.clonedeep';
import Alert from '@material-ui/lab/Alert/Alert';
import SubledgerCard from './SubledgerCard';
import { Account, Subledger } from '../../variables/types';
import FactaDatePicker from '../../components/DatePicker';
import getSubledgersStartEndDate from '../../util/getSubledgersStartEndDate';
import COLORS from '../../variables/colors';
import AssetsSettled from './AssetsSettled';
import {
  ASSET_TEMP_SCHEDULED_STATUS,
  DAY_SHORT_FORMAT,
  INITIALED_WITHOUT_MODIFICATION,
  MONTH_SHORT_FORMAT,
} from '../../variables/constants';
import SubledgerDetails from './SubledgerDetails';
import ErrorPage from '../../components/ErrorPage';
import reducer from '../../components/Subledger/reducer';
import isEmpty from '../../util/isEmpty';
import getSubledgerStartEndDate from '../../util/getSubledgerStartEndDate';
import { getFactaSourceBalance } from '../../components/Subledger/common';

const useStyles = makeStyles({
  root: {
    marginLeft: '2.5%',
    marginTop: '1%',
    lineHeight: '32px',
    '& span': {
      marginRight: 8,
      color: COLORS.deepGray,
    },
    '& input': {
      paddingLeft: 12,
      background: COLORS.white,
      color: COLORS.medGray,
    },
  },
  backgroundWhite: {
    background: COLORS.white,
  },
  cardRecordsStyles: {
    marginLeft: '1.5%',
    marginTop: 50,
  },
  alert: {
    width: '90%',
    backgroundColor: COLORS.violet15,
    color: COLORS.medGray,
  },
  diagnosisButton: {
    paddingLeft: '4px',
    marginTop: '15px',
    color: COLORS.violet,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

interface Props {
  subledgers: Array<Subledger>;
  accountBalances: Array<Account>;
}

const Accounts = ({ subledgers, accountBalances }: Props) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const history = useHistory();
  const scheduleDate = useQuery().get('scheduleDate');
  const refDate = scheduleDate ? parse(scheduleDate, DAY_SHORT_FORMAT, new Date()) : null;
  const { startDate, endDate } = getSubledgersStartEndDate(subledgers);
  const [selectedDate, setSelectedDate] = useState<Date | null>(refDate ?? endDate);
  const classes = useStyles();
  const ids = subledgers?.map((subledger) => subledger?.account?.id).join(',');
  const selectedDateFormatted = format(lastDayOfMonth(selectedDate!), DAY_SHORT_FORMAT);
  const { loading, error, data: accounts } = useFetch(`/account/balance?selectedDate=${selectedDateFormatted}&ids=${ids}`, [selectedDateFormatted]);
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {}, scheduleDate: selectedDate });

  const settle = refDate && endDate && isBefore(refDate, endDate!);
  const [dashboardSubledgers, setDashboardSubledgers] = useState<Array<Subledger>>(cloneDeep(subledgers));

  useEffect(() => {
    if (!isEmpty(state?.subledger)) {
      const allSubledgers = cloneDeep(cloneDeep(subledgers));
      setDashboardSubledgers(allSubledgers);
      const subledger = allSubledgers?.find((item) => item.id === state?.subledger?.id);
      dispatch({ type: INITIALED_WITHOUT_MODIFICATION, payload: { scheduleDate, subledger } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subledgers]);

  const onDateChange = (date: Date | null) => {
    if (date && startDate && endDate
            && isAfter(endDate!, startDate!)
            && isWithinInterval(date, {
              start: startDate!,
              end: endDate!,
            })) {
      setSelectedDate(date);
      if (scheduleDate) {
        history.replace('/');
      }
    }
  };

  const onSubledgerChange = (id: string) => {
    if (!isEmpty(state?.subledger)) {
      if (id === state?.subledger?.id) {
        return;
      }
      const allSubledgers = cloneDeep(dashboardSubledgers);
      const dashboardSubledgerIndex = dashboardSubledgers
                ?.findIndex((item) => item.id === state?.subledger?.id);
      allSubledgers[dashboardSubledgerIndex] = state?.subledger;
      setDashboardSubledgers(allSubledgers);
    }
    const subledger = dashboardSubledgers?.find((item) => item.id === id);
    dispatch({ type: INITIALED_WITHOUT_MODIFICATION, payload: { scheduleDate, subledger } });
  };

  const getOutOfPeriodInfo = () => {
    if (subledgers?.length) {
      for (const subledger of subledgers) {
        const accountStartingBalance = accountBalances?.find((account) => account.id === subledger?.accountId);
        if (getFactaSourceBalance(subledger?.prepaidAssets) !== accountStartingBalance?.glBalance) {
          return {
            outOfPeriod: true,
            message: 'Out-of-period Transaction prior to Facta start date',
            outOfPeriodTransactions: 0,
          };
        }
      }
      const outOfPeriodTransactions = subledgers?.reduce((transaction: number, subledger: Subledger): number => {
        const { endDate: subledgerEndDate } = getSubledgerStartEndDate(subledger);
        const transactionsPerSubledger = subledger
                    ?.prepaidAssets
                    ?.filter((asset) => (!asset.status || asset?.status === ASSET_TEMP_SCHEDULED_STATUS)
                        && isBefore(asset.assetCreationDate!, subMonths(subledgerEndDate, 1))
                        && !asset?.parentId)
                    ?.length || 0;
        return transaction + transactionsPerSubledger;
      }, 0);
      if (outOfPeriodTransactions > 0) {
        return {
          outOfPeriod: true,
          outOfPeriodTransactions,
          message: `Out-of-period Transactions as of ${format(endDate!, MONTH_SHORT_FORMAT)}`,
        };
      }
    }
    return { outOfPeriod: false, outOfPeriodTransactions: 0 };
  };

  const navigateToDiagnosisPage = () => {
    for (const subledger of subledgers) {
      const accountStartingBalance = accountBalances?.find((account) => account.id === subledger?.accountId);
      if (getFactaSourceBalance(subledger?.prepaidAssets) !== accountStartingBalance?.glBalance) {
        history.push(`/historical/subledgers/scheduler/${subledger.id}`);
        break;
      }
      const { endDate: subledgerEndDate } = getSubledgerStartEndDate(subledger);
      const outOfScopeAsset = subledger
          ?.prepaidAssets
          ?.find((asset) => ((!asset.status || asset?.status === ASSET_TEMP_SCHEDULED_STATUS)
              && isBefore(asset.assetCreationDate!, subMonths(subledgerEndDate, 1))
              && !asset?.parentId));
      if (outOfScopeAsset) {
        if (!outOfScopeAsset.status) {
          history.push(`/historical/subledgers/schedule/${subledger.id}/?scheduleDate=${format(endOfMonth(outOfScopeAsset?.assetCreationDate!), DAY_SHORT_FORMAT)}`);
        } else {
          history.push(`/historical/subledgers/schedule/${subledger.id}/post-je?scheduleDate=${format(endOfMonth(outOfScopeAsset?.assetCreationDate!), DAY_SHORT_FORMAT)}&fromDashboard=true`);
        }

        break;
      }
    }
  };

  if (error) {
    return (<ErrorPage />);
  }

  const { outOfPeriod, message, outOfPeriodTransactions } = getOutOfPeriodInfo();

  return (
    <>
      {outOfPeriod && (
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          flexWrap="wrap"
          className={classes.root}
        >
          <Alert
            severity="error"
            icon={false}
            className={classes.alert}
          >
            <strong>
              Attention:
              {' '}
              {outOfPeriodTransactions > 0 ? outOfPeriodTransactions : ''}
              {' '}
            </strong>
            {message}
            <Link
              className={classes.diagnosisButton}
              onClick={navigateToDiagnosisPage}
            >
              Diagnose
            </Link>
          </Alert>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        flexWrap="wrap"
        className={classes.root}
      >
        <span>Choose Period</span>
        <FactaDatePicker
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          minDate={startDate}
          maxDate={endDate}
          className={classes.backgroundWhite}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        flexWrap="wrap"
      >
        {
          dashboardSubledgers?.map((subledger: Subledger) => (
            <SubledgerCard
              subledgerAccountStartingBalance={accountBalances
                  ?.find((account) => account.id === subledger?.accountId)?.glBalance!}
              loading={loading}
              active={state?.subledger?.id === subledger?.id}
              onChange={onSubledgerChange}
              subledger={state?.subledger?.id === subledger?.id ? state?.subledger : subledger}
              selectedDate={selectedDate}
              account={accounts?.find((account: Account) => account.id === subledger?.account?.id)}
            />
          ))
        }
      </Box>

      {
        !isEmpty(state?.subledger) && (
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            flexWrap="wrap"
          >
            <SubledgerDetails
              dispatch={dispatch}
              subledger={state?.subledger}
              selectedDate={selectedDate}
              selectedRow={state?.selectedRow}
            />
          </Box>
        )
      }
      <Box position="relative">
        {
          settle && <AssetsSettled />
        }
      </Box>
    </>
  );
};
export default React.memo(Accounts);
