import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import {
  getAccountNameFromList,
  getClassName,
  getParent,
  getPrepaidScheduleAmountForMonth,
  getVendorName,
} from './common';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';
import { useAccountProvider } from '../../core/accountContext';
import { PrepaidAsset } from '../../variables/types';

interface Props {
  index: number;
  asset: PrepaidAsset;
  prepaidAssets: Array<PrepaidAsset>;
  scheduleDate: string;
}

const PostJERow = ({ index, asset, prepaidAssets, scheduleDate }: Props) => {
  const tableClasses = useTableStyles();
  const { account } = useAccountProvider();
  const { vendors, accountIncomes, accountClasses, classTrackingPerTxnLine, classTrackingPerTxn } = account;
  const prepaidAssetAmount = getPrepaidScheduleAmountForMonth(asset, scheduleDate);
  const parent = getParent(asset?.parentId, prepaidAssets);
  return (
    <TableRow
      hover
    >
      <TableCell className={tableClasses.assetCell}>
        {index + 1}
      </TableCell>
      <TableCell className={tableClasses.assetCell}>
        {asset.parentId ? parent?.assetId : asset.assetId}
      </TableCell>
      <TableCell className={tableClasses.descriptionCell}>
        {asset.description ? asset?.description : parent?.description}
      </TableCell>
      <TableCell className={tableClasses.customerCell}>
        {getVendorName(asset.parentId ? parent?.vendorId! : asset.vendorId, vendors)}
      </TableCell>
      <TableCell className={tableClasses.expenseAccountCell}>
        {
          getAccountNameFromList(asset?.prepaidSchedule?.expenseAccountId!, accountIncomes)
        }
      </TableCell>
      {
        (classTrackingPerTxnLine || classTrackingPerTxn) && (
          <TableCell className={tableClasses.classesCell}>
            {
              getClassName(asset?.prepaidSchedule?.classId!, accountClasses)
            }
          </TableCell>
        )
      }
      <TableCell className={tableClasses.prepaidAssetAmount}>
        {
            asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails?.length
            && prepaidAssetAmount && prepaidAssetAmount > 0 ? currencyFormatter.format(Math.abs(Number(prepaidAssetAmount!))) : '-'
        }
      </TableCell>
      <TableCell className={tableClasses.prepaidAssetAmount}>
        {
            asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails?.length
            && prepaidAssetAmount && prepaidAssetAmount < 0 ? currencyFormatter.format(Math.abs(Number(prepaidAssetAmount!))) : '-'
        }
      </TableCell>
    </TableRow>
  );
};

export default React.memo(PostJERow);
