import React from 'react';
import { Box, createStyles, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import COLORS from '../../variables/colors';
import { useTableStyles } from './Subledger.styled';

interface Props {
  isExpanded: boolean;
  onRowClick: () => void;
  title: string;
  color?: string;
}

const useStyles = makeStyles(() => createStyles({
  label: {
    fontSize: 12,
  },
  collapseExpandCell: {
    border: 'none',
    cursor: 'pointer',
  },
  arrowIcon: {
    fontSize: 12,
    textTransform: 'none',
    color: COLORS.lightGray,
    fontWeight: 500,
  },
}));

const ExpandCollapseRow = ({ isExpanded, onRowClick, title, color }: Props) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  return (
    <TableRow
      selected
      classes={{
        selected: tableClasses.selected,
      }}
    >
      <TableCell
        colSpan={100}
        onClick={onRowClick}
        className={classes.collapseExpandCell}
      >
        <Box
          display="flex"
          flexDirection="row"
          width="100px"
          className={classes.arrowIcon}
          alignItems="center"
        >
          <Typography className={classes.label} style={{ color }}>{title}</Typography>
          {
            isExpanded && (<ArrowDropDownIcon style={{ color }} color="primary" />)
          }
          {
            !isExpanded && (<ArrowRightIcon style={{ color }} color="primary" />)
          }
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ExpandCollapseRow;
