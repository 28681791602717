/* eslint-disable react/jsx-props-no-spreading */
import MaskedInput from 'react-text-mask';
import React from 'react';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const DatePickerMask = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      keepCharPositions
      guide={false}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar="_"
      showMask
    />
  );
};

export default React.memo(DatePickerMask);
