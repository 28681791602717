import React from 'react';
import { Box, createStyles, IconButton, Link, makeStyles, TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import { FULL_DAY_FORMAT } from '../../variables/constants';
import {
  getChildren,
  getTotalBalanceAndAmortizationBalance,
  getVendorName,
} from './common';
import { PrepaidAsset, ScheduleType } from '../../variables/types';
import currencyFormatter from '../../util/currencyFormatter';
import SplitIcon from '../Icons/SplitIcon';
import { useTableStyles } from './Subledger.styled';
import getQuickbookLink from '../../util/getQuickbookLink';
import { useAccountProvider } from '../../core/accountContext';
import ExpenseAccountEditableCell from './ExpenseAccountEditableCell';
import ClassEditableCell from './ClassEditableCell';
import { PrimaryButton } from '../Buttons';

interface Props {
  asset: PrepaidAsset;
  hasChildren?: boolean;
  lastChild?: boolean;
  prepaidAssets: Array<PrepaidAsset>;
  disabled?: boolean;
  selectedRow: string;
  onRowSelect: (internalId: string) => () => void,
  onSave: (asset: PrepaidAsset) => () => Promise<void>;
  onSelectAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void,
}

const useStyles = makeStyles(() => createStyles({
  saveButton: {
    width: 43,
    height: 24,
    margin: 0,
    marginLeft: 8,
  },
}));

const PrepaidSchdulerCurrentMonthAssetRow = ({
  asset,
  prepaidAssets,
  hasChildren,
  lastChild,
  disabled,
  selectedRow,
  onRowSelect,
  onSave,
  onSelectAutoCompleteChange,
}: Props) => {
  const classes = useStyles();
  const { account } = useAccountProvider();
  const { vendors } = account;
  const tableClasses = useTableStyles();
  const children = getChildren(asset.internalId, prepaidAssets);
  const { totalBalance, amortizationToDateBalance } = hasChildren ? getTotalBalanceAndAmortizationBalance(children) : {
    totalBalance: 0,
    amortizationToDateBalance: 0,
  };
  const assetId = asset.parentId ? '' : asset.assetId;
  const quickbookLink = getQuickbookLink(assetId, asset.assetType);
  const isRowSelected = selectedRow === asset.parentId || selectedRow === asset.internalId;
  return (
    <TableRow
      hover
      classes={{
        selected: tableClasses.selected,
      }}
      className={clsx({
        [tableClasses.disabledRow]: disabled,
      })}
      onClick={onRowSelect(asset.parentId ?? asset.internalId)}
    >
      <TableCell className={clsx(tableClasses.assetCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {asset.parentId ? '' : format(asset?.assetCreationDate!, FULL_DAY_FORMAT)}
      </TableCell>
      <TableCell className={clsx(tableClasses.assetCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {(!asset?.assetType || !quickbookLink) && assetId}
        {quickbookLink && (<Link href={quickbookLink} target="_blank">{assetId}</Link>)}
      </TableCell>
      <TableCell className={clsx(tableClasses.descriptionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {asset.description}
      </TableCell>
      <TableCell className={clsx(tableClasses.customerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {!asset.parentId ? getVendorName(asset.vendorId, vendors) : ''}
      </TableCell>
      <ExpenseAccountEditableCell
        asset={asset}
        hasChildren={hasChildren}
        lastChild={lastChild!}
        isRowSelected={isRowSelected}
        onSelectChange={onSelectAutoCompleteChange}
      />
      {

        (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
          <ClassEditableCell
            asset={asset}
            hasChildren={hasChildren}
            lastChild={lastChild!}
            isRowSelected={isRowSelected}
            onSelectChange={onSelectAutoCompleteChange}
          />
        )
      }
      <TableCell className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          hasChildren && 'Multiple'
        }
        {
                    asset?.prepaidSchedule?.amortizationStartDate
                    && asset?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual
                    && !hasChildren ? format(asset?.prepaidSchedule?.amortizationStartDate, FULL_DAY_FORMAT) : ''
        }
        {
          !hasChildren
                    && asset?.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual
                    && 'M'
        }
      </TableCell>
      <TableCell className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          hasChildren && 'Multiple'
        }
        {
                    asset?.prepaidSchedule?.amortizationEndDate
                    && asset?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual
                    && !hasChildren ? format(asset?.prepaidSchedule?.amortizationEndDate, FULL_DAY_FORMAT) : ''
        }
        {
          !hasChildren
                    && asset?.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual
                    && 'M'
        }
      </TableCell>
      <TableCell className={clsx(tableClasses.scheduleTypeCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {!hasChildren && asset?.prepaidSchedule?.amortizationScheduleType?.toLocaleLowerCase()}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {hasChildren && currencyFormatter.format(totalBalance)}
        {!hasChildren && currencyFormatter.format(asset?.startingBalance!)}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {hasChildren && currencyFormatter.format(amortizationToDateBalance)}
        {!hasChildren && currencyFormatter.format(asset?.amortizationToDateBalance)}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {hasChildren && currencyFormatter.format(totalBalance - amortizationToDateBalance)}
        {!hasChildren && currencyFormatter.format(asset?.startingBalance - asset?.amortizationToDateBalance)}
      </TableCell>
      <TableCell className={clsx(tableClasses.actionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {!asset.parentId && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {
              hasChildren && (
                <IconButton
                  disabled
                  className={clsx(tableClasses.actionCellButtonPadding, {
                    [tableClasses.iconDisabled]: hasChildren,
                  })}
                >
                  <SplitIcon className={tableClasses.fontSize13} />
                </IconButton>
              )
            }
            {
              isRowSelected && (
                <PrimaryButton
                  className={classes.saveButton}
                  variant="contained"
                  color="primary"
                  onClick={onSave(asset)}
                >
                  Save
                </PrimaryButton>
              )
            }
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(PrepaidSchdulerCurrentMonthAssetRow);
