/* eslint-disable react/jsx-props-no-spreading,@typescript-eslint/no-throw-literal,no-param-reassign */
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import useFetch from 'use-http';
import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import {
  subDays,
  format, isAfter,
  isBefore,
  isValid,
  startOfMonth,
} from 'date-fns';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useHistory, useParams } from 'react-router-dom';

import roundTo from 'round-to';
import Loader from '../../../components/Loader/loader';
import COLORS from '../../../variables/colors';

import {
  Account,
  AccountClass,
  NotifierType,
  PrepaidAsset,
  ScheduleType,
  Subledger,
  Vendor,
} from '../../../variables/types';
import {
  CREATE_SUBLEDGER_COLUMNS,
  DAY_SHORT_FORMAT,
  DIGIT_LOWER_LIMIT,
  DIGIT_UPPER_LIMIT,
  FINALIZED_SUBLEDGER_STATUS,
  IMPORT_CSV,
  INITIALED,
  SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE,
  SUBLEDGER_START_DATE_CHANGE,
} from '../../../variables/constants';
import Header from './Header';
import { PrimaryButton } from '../../../components/Buttons';
import { openSnackbar } from '../../../components/Notifier';
import { useAccountProvider } from '../../../core/accountContext';
import {
  getAccountName,
  isCreateSubledgerFormValid,
  updateAssetsForSaving,
} from '../../../components/Subledger/common';
import DialogBox from '../../../components/DialogBox';
import reducer from '../../../components/Subledger/reducer';
import { fromUTC, toUTC } from '../../../util/timezone';
import ErrorPage from '../../../components/ErrorPage';
import CreateSubledgerTable from '../../../components/Subledger/CreateSubledgerTable';
import isEmpty from '../../../util/isEmpty';
import getCSVImportDate from '../../../util/csv';

const CreateSubledger = () => {
  const { id: subledgerId } = useParams();
  const history = useHistory();
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { get, loading: subledgerLoading, error: subLedgerError, response } = useFetch(`/subledgers/${subledgerId}`);
  const { put, loading: subledgerUpdateLoading, response: updateResponse } = useFetch(`/subledgers/${subledgerId}`);
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { selectedRow: '', subledger: {}, hoverRow: '' });
  const { subledger, selectedRow, hoverRow } = state;
  // Will get subleger balance by last day of the month of previous month
  const lastDayOfSelectedDate = format(subDays(startOfMonth(state?.subledger?.factaStartDate ?? new Date()), 1), DAY_SHORT_FORMAT);
  const { get: getBalanceData, loading, error: accountBalanceError, abort, response: accountBalanceResponse } = useFetch(`/subledgers/${subledgerId}/account/balance`);
  const [formSubmit, setFormSubmit] = useState<boolean>();
  const { account } = useAccountProvider();
  const { vendors, accountClasses, accountIncomes } = account;
  const [accountBalance, setAccountBalance] = useState<Account | null>();

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subLedgerError) {
      const result = await get('/details?ignorePrepaidAssetsSorting=true');
      if (response.ok) {
        if (result.status === FINALIZED_SUBLEDGER_STATUS) {
          history.push('/');
        }
        dispatch({
          type: INITIALED,
          payload: {
            subledger: result,
          },
        });
      }
    }
  }, [get, history, response.ok, subLedgerError, subledgerLoading]);

  const getAccountBalance = useCallback(async () => {
    abort();
    const result = await getBalanceData(`?selectedDate=${lastDayOfSelectedDate}`);
    if (accountBalanceResponse.ok) {
      setAccountBalance(result);
    }
  }, [abort, getBalanceData, lastDayOfSelectedDate, accountBalanceResponse.ok]);

  useEffect(() => {
    getAccountBalance();
  }, [getAccountBalance, getBalanceData, lastDayOfSelectedDate, account]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!accountBalance || isEmpty(subledger) || !subledger) {
    return <Loader open />;
  }

  if (subLedgerError || accountBalanceError) {
    return <ErrorPage />;
  }
  const onImportCSV = (data: any, _: any) => {
    try {
      const prepaidAssets = data?.map((item: PrepaidAsset) => {
        const prepaidAsset: any = {
          internalId: uuidv4(),
          // @ts-ignore
          description: item[CREATE_SUBLEDGER_COLUMNS.DESCRIPTION] ?? '',
          // @ts-ignore
          vendor: item[CREATE_SUBLEDGER_COLUMNS.VENDOR] ?? '',
          // @ts-ignore
          startingBalance: item[CREATE_SUBLEDGER_COLUMNS.FACTA_STARTING_BALANCE] ? Number(item[CREATE_SUBLEDGER_COLUMNS.FACTA_STARTING_BALANCE]) : 0,
          prepaidSchedule: {},
        };
        if (Number.isNaN(Number(prepaidAsset?.startingBalance))
                    || Number(prepaidAsset?.startingBalance) > DIGIT_UPPER_LIMIT
                    || Number(prepaidAsset?.startingBalance) < DIGIT_LOWER_LIMIT) {
          throw 'Facta Starting Balance is invalid';
        }
        // @ts-ignore
        if (item[CREATE_SUBLEDGER_COLUMNS.AMORTIZATION_START_DATE]) {
          // @ts-ignore
          prepaidAsset.prepaidSchedule.amortizationStartDate = getCSVImportDate(item[CREATE_SUBLEDGER_COLUMNS.AMORTIZATION_START_DATE]);
          // @ts-ignore
          if (!isValid(prepaidAsset.prepaidSchedule?.amortizationStartDate)) {
            throw 'Amortization Start Date is invalid';
          }
          if (isBefore(prepaidAsset?.prepaidSchedule?.amortizationStartDate, startOfMonth(subledger?.factaStartDate!))) {
            throw 'Amortization Start Date should be after Facta Start Date';
          }
        }
        // @ts-ignore
        if (!item[CREATE_SUBLEDGER_COLUMNS.AMORT_SCHEDULE] || ![
          ScheduleType.Daily.toLowerCase(),
          ScheduleType.Monthly.toLowerCase(),
          ScheduleType.Manual.toLowerCase(),
          // @ts-ignore
        ].includes(item[CREATE_SUBLEDGER_COLUMNS.AMORT_SCHEDULE].toLowerCase())) {
          prepaidAsset.prepaidSchedule.amortizationScheduleType = subledger?.account?.scheduleType;
        } else {
          // @ts-ignore
          prepaidAsset.prepaidSchedule.amortizationScheduleType = item[CREATE_SUBLEDGER_COLUMNS.AMORT_SCHEDULE].toUpperCase();
        }
        // @ts-ignore
        if (item[CREATE_SUBLEDGER_COLUMNS.AMORTIZATION_END_DATE]) {
          // @ts-ignore
          prepaidAsset.prepaidSchedule.amortizationEndDate = getCSVImportDate(item[CREATE_SUBLEDGER_COLUMNS.AMORTIZATION_END_DATE]);
          // @ts-ignore
          if (!isValid(prepaidAsset.prepaidSchedule?.amortizationEndDate)) {
            throw 'Amortization End Date is invalid';
          }
          if (isBefore(prepaidAsset.prepaidSchedule?.amortizationEndDate, prepaidAsset.prepaidSchedule?.amortizationStartDate)) {
            throw 'Amortization End Date should be after Amortization Start Date';
          }
        }
        // @ts-ignore
        if (item[CREATE_SUBLEDGER_COLUMNS.EXPENSES_ACCOUNT]) {
          // @ts-ignore
          const expenseAccount = accountIncomes?.find((income: Account) => getAccountName(income)?.toLowerCase() === item[CREATE_SUBLEDGER_COLUMNS.EXPENSES_ACCOUNT].toLowerCase());
          if (expenseAccount) {
            // @ts-ignore
            prepaidAsset.prepaidSchedule.expenseAccountId = expenseAccount.id;
          } else {
            throw 'Expense Account is invalid';
          }
        }
        // @ts-ignore
        // @ts-ignore
        if (item[CREATE_SUBLEDGER_COLUMNS.CLASS] && (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn)) {
          // @ts-ignore
          const accountClass = accountClasses?.find((accClass: AccountClass) => accClass.className?.toLowerCase() === item[CREATE_SUBLEDGER_COLUMNS.CLASS]?.toLowerCase());
          if (accountClass) {
            // @ts-ignore
            prepaidAsset.prepaidSchedule.classId = accountClass.id;
          } else {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw 'Class is not valid';
          }
        }
        // @ts-ignore
        if (item[CREATE_SUBLEDGER_COLUMNS.VENDOR]) {
          // @ts-ignore
          const vendor = vendors?.find((ven: Vendor) => ven.displayName === item[CREATE_SUBLEDGER_COLUMNS.VENDOR]);
          if (vendor) {
            prepaidAsset.vendorId = vendor.id;
          } else {
            throw 'Vendor is not valid';
          }
        }
        return prepaidAsset;
      });
      if (!prepaidAssets?.length) {
        throw 'CSV file does not have any data';
      }
      dispatch({
        type: IMPORT_CSV,
        payload: {
          assets: prepaidAssets,
        },
      });
    } catch (err) {
      openSnackbar({ message: `CSV Import Failed: ${err}` }, NotifierType.Error);
    }
    // cler file uploaded file
    const inputElement = (document.querySelector('#csv-importer') as HTMLInputElement);
    inputElement.value = '';
  };

  const callSaveAPI = async (existingSubledger: Subledger, redirect?: boolean) => {
    const updatedSubledger = cloneDeep(existingSubledger) as Subledger;
        updatedSubledger.prepaidAssets?.filter((prepaidAsset: PrepaidAsset) => !prepaidAsset.parentId).forEach((prepaidAsset: PrepaidAsset, index: number) => {
          // eslint-disable-next-line no-param-reassign
          prepaidAsset.assetId = `Open ${index + 1}`;
        });
        updateAssetsForSaving(updatedSubledger?.prepaidAssets);
        // eslint-disable-next-line no-param-reassign
        updatedSubledger.openingBalance = Number(updatedSubledger?.openingBalance);
        // eslint-disable-next-line no-param-reassign
        // @ts-ignore
        updatedSubledger.factaStartDate = toUTC(updatedSubledger.factaStartDate);
        try {
          const result = await put(updatedSubledger);
          if (updateResponse.ok) {
            if (redirect) {
              history.push('/');
            }
            if (result.status === FINALIZED_SUBLEDGER_STATUS) {
              history.push('/');
            }
            dispatch({
              type: INITIALED,
              payload: {
                subledger: result,
              },
            });
          } else {
            const errorMessage = updateResponse.data;
            openSnackbar({ message: errorMessage }, NotifierType.Error);
          }
        } catch (error) {
          const errorMessage = updateResponse.data;
          openSnackbar({ message: errorMessage }, NotifierType.Error);
        }
  };

  const onSave = async () => {
    await callSaveAPI({
      ...subledger,
      factaStartDate: startOfMonth(subledger?.factaStartDate),
    });
  };

  const onSaveAndExit = async () => {
    await callSaveAPI({
      ...subledger,
      factaStartDate: startOfMonth(subledger?.factaStartDate),
    }, true);
  };

  const onFinalizedSubledger = async () => {
    setFormSubmit(true);
    if (isCreateSubledgerFormValid(subledger, dispatch)) {
      if (accountBalance && roundTo(Number(accountBalance?.glBalance!), 2) !== roundTo(Number(subledger?.openingBalance), 2)) {
        setOpenSubledgerDialog(true);
        return;
      }
      await callSaveAPI({
        ...subledger,
        factaStartDate: startOfMonth(subledger?.factaStartDate),
        status: FINALIZED_SUBLEDGER_STATUS,
      }, true);
    }
  };

  const navigateBack = () => {
    history.push('/');
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
  };

  const onSubledgerAmortizationScheduleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({ type: SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE, payload: { value: event.target.value } });
  };

  const onFactaStartDateChange = (date: Date | null) => {
    // @ts-ignore
    dispatch({ type: SUBLEDGER_START_DATE_CHANGE, payload: { value: date } });
  };

  const openSaveAndExitMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const factaEndDate = subledger?.factaDate?.endDate ? fromUTC(subledger?.factaDate?.endDate) : null;

  return (
    <>
      <Loader open={subledgerLoading || loading || subledgerUpdateLoading} />
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext="The difference between your Facta subledger and your General Ledger balance must be $0.00 to
            finalize subledger."
        dialogTitle="Hint"
        dismissContext="Dismiss"
      />
      <Header
        onImportCSV={onImportCSV}
        subledger={subledger}
        onFactaStartDateChange={onFactaStartDateChange}
        onAmortizationScheduleTypeChange={onSubledgerAmortizationScheduleChange}
        accountBalance={accountBalance}
      />
      <CreateSubledgerTable
        subledger={subledger}
        selectedRow={selectedRow}
        hoverRow={hoverRow}
        dispatch={dispatch}
        formSubmit={formSubmit}
      />
      <Box
        width="calc(100vw - 100px)"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        pr="100px"
      >
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          variant="contained"
        >
          <Button
            onClick={onSave}
            variant="contained"
            color="primary"
            style={{ color: COLORS.white, paddingRight: 30 }}
          >
            Save
          </Button>
          <Button
            style={{ color: COLORS.white, width: 10, padding: 0, margin: 0 }}
            onClick={openSaveAndExitMenu}
          >
            <ArrowDropDownIcon fontSize="small" />
          </Button>
        </ButtonGroup>

        <PrimaryButton
          onClick={onFinalizedSubledger}
          variant="contained"
          color="primary"
          disabled={Boolean(factaEndDate && isAfter(subledger.factaStartDate!, factaEndDate!))}
        >
          Finalize Subledger
        </PrimaryButton>
        <PrimaryButton
          onClick={navigateBack}
          color="primary"
          style={{ color: COLORS.skyBlue }}
        >
          Cancel
        </PrimaryButton>
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: -45,
            horizontal: 120,
          }}
          id="save-exit-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={onSaveAndExit}>Save and Exit</MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default React.memo(CreateSubledger);
