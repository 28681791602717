import { createStyles, makeStyles, TableCell, TableFooter } from '@material-ui/core';
import clsx from 'clsx';
import React, { memo } from 'react';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';
import { useAccountProvider } from '../../core/accountContext';
import COLORS from '../../variables/colors';

interface Props {
  total: number;
}

const useStyles = makeStyles(() => createStyles({
  totalAmount: {
    color: COLORS.deepGray,
    fontWeight: 'bold',
    fontSize: 13,
  },
  tableFooter: {
    background: COLORS.lightGray3,
  },
}));

const PostJETableFooter = ({ total }: Props) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const { account } = useAccountProvider();
  return (
    <TableFooter className={classes.tableFooter}>
      <TableCell
        colSpan={(account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) ? 6 : 5}
        align="right"
        className={classes.totalAmount}
      >
        Total
      </TableCell>
      <TableCell className={clsx(tableClasses.prepaidAssetAmount, classes.totalAmount)}>
        {currencyFormatter.format(Math.abs(total))}
      </TableCell>
      <TableCell className={clsx(tableClasses.prepaidAssetAmount, classes.totalAmount)}>
        {currencyFormatter.format(Math.abs(total))}
      </TableCell>
    </TableFooter>
  );
};

export default memo(PostJETableFooter);
