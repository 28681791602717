import Box from '@material-ui/core/Box';
import React from 'react';
import { AccountLabel, AccountName } from './Subledger.styled';
import { getAccountNameFromList } from './common';
import { Account } from '../../variables/types';
import { useAccountProvider } from '../../core/accountContext';

interface Props {
  account: Account;
}

const SubledgerAccountName = ({ account }: Props) => {
  const { account: { accountBalances } } = useAccountProvider();
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      fontSize="14px"
    >
      <AccountLabel>
        ACCOUNT
      </AccountLabel>
      <AccountName>
        {getAccountNameFromList(account?.id, accountBalances)}
      </AccountName>
    </Box>
  );
};

export default React.memo(SubledgerAccountName);
