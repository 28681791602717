import React from 'react';
import useFetch from 'use-http';
import Loader from '../../components/Loader/loader';
import Subledgers from './Subledgers';
import Layout from '../../components/Layout';
import ErrorPage from '../../components/ErrorPage';

const Dashboard = () => {
  const { loading, error } = useFetch('/account/sync/subledgers', []);
  if (error) {
    return (<ErrorPage />);
  }

  if (loading) {
    return <Loader open />;
  }

  return (<Subledgers />);
};

const DashboardContainer = () => (
  <Layout title="Dashboard">
    <Dashboard />
  </Layout>
);

export default DashboardContainer;
