import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import cloneDeep from 'lodash.clonedeep';
import {
  Box,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import useFetch from 'use-http';
import Loader from '../../../components/Loader/loader';
import {
  Account,
  NotifierType,
  PrepaidAsset,
  PrepaidAssetsAmortizationScheduleDetail,
  ScheduleType,
} from '../../../variables/types';
import {
  FINALIZED_SUBLEDGER_STATUS, INITIALED_FOR_PREPARE_JE,
} from '../../../variables/constants';
import {
  getChildren, getPrepareJEStartEndDate,
} from '../../../components/Subledger/common';
import ScheduleHeader from './ScheduleHeader';
import { openSnackbar } from '../../../components/Notifier';
import reducer from '../../../components/Subledger/reducer';
import isEmpty from '../../../util/isEmpty';
import { toUTC } from '../../../util/timezone';
import PrepareJERow from '../../../components/Subledger/PrepareJERow';
import PrepareJETableHeader from '../../../components/Subledger/PrepareJETableHeader';
import { isPostJEEnabled, prepareJEInputChange } from '../../../components/Subledger/prepreJECommon';

interface Props {
  scheduleDate: string;
  accountBalance: Account;
}

const PrepareJE = ({ scheduleDate, accountBalance }: Props) => {
  const { id: subledgerId } = useParams();
  const history = useHistory();
  const { get, loading: subledgerLoading, error: subLedgerError, response } = useFetch(`/subledgers/${subledgerId}/details`);
  const { patch, loading: onUpdating, response: updateResponse } = useFetch(`/subledgers/${subledgerId}/manual/assets`);
  const [collapses, setCollapses] = useState<Array<string>>([]);
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {} });
  const { subledger } = state;

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subLedgerError) {
      const result = await get(`?scheduleDate=${scheduleDate}&requiredAmortizationToDate=true`);
      if (response.ok) {
        if (result.status !== FINALIZED_SUBLEDGER_STATUS) {
          history.push(`/subledgers/scheduler/${subledgerId}`);
        }
        dispatch({
          type: INITIALED_FOR_PREPARE_JE,
          payload: {
            subledger: result,
            scheduleDate,
          },
        });
      }
    }
  }, [get, history, response.ok, scheduleDate, subLedgerError, subledgerId, subledgerLoading]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (subledgerLoading || isEmpty(subledger)) {
    return <Loader open />;
  }

  const { startDate, endDate } = getPrepareJEStartEndDate(subledger, subledger?.prepaidAssets, scheduleDate);

  const toggleSplit = (internalId: string) => () => {
    const exists = collapses.includes(internalId);
    let updateCollapse: Array<string> = [];
    if (exists) {
      updateCollapse = collapses?.filter((item: string) => item !== internalId);
    } else {
      updateCollapse.push(internalId);
    }
    setCollapses(updateCollapse);
  };

  const postJEEnabled = isPostJEEnabled(subledger?.prepaidAssets, scheduleDate);

  const onPostJE = async () => {
    try {
      const manualPrepaidAssets = cloneDeep(subledger?.prepaidAssets)
          ?.filter((asset: PrepaidAsset) => asset.prepaidSchedule.amortizationScheduleType === ScheduleType.Manual);
      if (manualPrepaidAssets?.length > 0) {
        for (const prepaidAsset of manualPrepaidAssets) {
          if (prepaidAsset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails?.length) {
            const prepaidAssetsAmortizationScheduleDetails = prepaidAsset?.prepaidSchedule
                ?.prepaidAssetsAmortizationScheduleDetails
                ?.filter((item: PrepaidAssetsAmortizationScheduleDetail) => !Number.isNaN(Number(item.amount)));
            for (const amortizationSchedule of prepaidAssetsAmortizationScheduleDetails) {
              amortizationSchedule.scheduleDate = toUTC(amortizationSchedule.scheduleDate);
              amortizationSchedule.amount = Number(amortizationSchedule.amount);
            }
            prepaidAsset.prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = prepaidAssetsAmortizationScheduleDetails;
          }
        }
        await patch(manualPrepaidAssets);
        if (updateResponse.ok) {
          history.push(`/subledgers/schedule/${subledgerId}/journal-entry/?scheduleDate=${scheduleDate}`);
        } else {
          openSnackbar({ message: updateResponse.data }, NotifierType.Error);
        }
      } else {
        history.push(`/subledgers/schedule/${subledgerId}/journal-entry/?scheduleDate=${scheduleDate}`);
      }
    } catch (error) {
      openSnackbar({ message: updateResponse.data }, NotifierType.Error);
    }
  };

  const onInputBoxChange = (lastDay: string, asset: PrepaidAsset, schedule?: PrepaidAssetsAmortizationScheduleDetail) => (event: React.ChangeEvent<HTMLInputElement>) => {
    prepareJEInputChange({ number: event.target.value, asset, schedule, lastDay, prepaidAssets: subledger?.prepaidAssets, dispatch });
  };

  const renderRow = (asset: PrepaidAsset) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const parent = (
      <PrepareJERow
        asset={asset}
        prepaidAssets={subledger?.prepaidAssets}
        scheduleDate={scheduleDate}
        endDate={endDate!}
        startDate={startDate}
        hasChildren={!!children?.length}
        lastChild={false}
        collapses={collapses}
        onInputBoxChange={onInputBoxChange}
        toggleSplit={toggleSplit}
      />
    );
    if (!children?.length || collapses.includes(asset.internalId)) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children
                ?.map((child: PrepaidAsset, childIndex: number) => (
                  <PrepareJERow
                    asset={child}
                    prepaidAssets={subledger?.prepaidAssets}
                    scheduleDate={scheduleDate}
                    endDate={endDate!}
                    startDate={startDate}
                    hasChildren={false}
                    lastChild={children?.length - 1 === childIndex}
                    collapses={collapses}
                    onInputBoxChange={onInputBoxChange}
                    toggleSplit={toggleSplit}
                  />
                ))
        }
      </>
    );
  };

  return (
    <>
      <Loader open={subledgerLoading || onUpdating} />
      <Box
        width="100%"
      >
        <ScheduleHeader
          subledger={subledger}
          scheduleDate={scheduleDate}
          postJEEnabled={postJEEnabled}
          onPostJE={onPostJE}
          accountBalance={accountBalance}
        />
        <Box padding={1}>
          <Table
            size="small"
          >
            <PrepareJETableHeader
              startDate={startDate}
              endDate={endDate!}
              scheduleDate={scheduleDate}
              prepaidAssets={subledger?.prepaidAssets}
            />
            <TableBody>
              {
                subledger?.prepaidAssets
                    ?.filter((asset: PrepaidAsset) => !asset.parentId).map(renderRow)
              }
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default PrepareJE;
