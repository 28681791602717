import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { useRouteMatch, useHistory } from 'react-router-dom';

import clsx from 'clsx';
import {
  StyledDivider,
  StyledNavigationButton,
  StyledNavigationButtonBox,
} from './Stylebar.styled';
import { AccountIcon, BalanceIcon, HomeIcon, NavCloseIcon, NavOpenIcon } from '../Icons';
import COLORS from '../../variables/colors';
import { deleteToken } from '../../util/auth';
import { useAccountProvider } from '../../core/accountContext';
import SettingIcon from '../Icons/SettingIcon';
import { useNavBarContext } from '../../core/navbarContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
  active: {
    backgroundColor: COLORS.lightActiveColor,
  },
  iconWidth: {
    width: 38,
  },
  marginRight8: {
    marginRight: 8,
  },
  settingIcon: {
    marginRight: 8,
    fontSize: 13,
  },
  settingIconDrawerClose: {
    fontSize: 18,
  },
  root: {
    display: 'flex',
  },
  drawer: {
    zIndex: theme.zIndex.drawer + 1,
  },
  navBarIcon: {
    fontSize: 15,
    opacity: 0.3,
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: COLORS.black,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 52,
  },
}));

const Sidebar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { navBarOpen, setNavBarOpen } = useNavBarContext();
  const { setAccount } = useAccountProvider();
  const classes = useStyles();
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (path: string) => () => {
    history.push(path);
  };

  const onLogout = () => {
    deleteToken();
    setAccount(null);
    history.push('/');
    setAnchorEl(null);
  };

  return (
    <Box
      position="fixed"
      height="100%"
      width={162}
      bgcolor={COLORS.black}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: navBarOpen,
        [classes.drawerClose]: !navBarOpen,
      })}
    >
      <Box
        flexGrow={1}
        flexDirection="column"
        display="flex"
        alignItems="space-between"
        justifyContent="center"
        height="100%"
        m={0}
        p={0}
      >
        <Box
          width="100%"
          m={0}
          p={0}
          flexGrow={1}
          flexDirection="column"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Box display="flex" height={68}>
            <ButtonBase>
              {navBarOpen && <img src="/full_logo.png" alt="logo" height={40} />}
              {!navBarOpen && <img src="/mini_logo.svg" alt="logo" height={40} />}
            </ButtonBase>
          </Box>
          <StyledDivider />
          <Box>
            <StyledNavigationButton
              style={{ margin: 0, padding: 0 }}
              fullWidth
              disableRipple
              disableTouchRipple
              onClick={() => setNavBarOpen(!navBarOpen)}
            >
              <StyledNavigationButtonBox style={{ justifyContent: navBarOpen ? 'flex-end' : 'center' }}>
                {
                  navBarOpen && <NavCloseIcon className={classes.navBarIcon} />
                }
                {
                  !navBarOpen && <NavOpenIcon className={classes.navBarIcon} />
                }
              </StyledNavigationButtonBox>
            </StyledNavigationButton>
            <StyledNavigationButton
              fullWidth
              onClick={navigateTo('/')}
            >
              <StyledNavigationButtonBox
                className={clsx({
                  [classes.active]: useRouteMatch({ path: '/', exact: true }),
                  [classes.iconWidth]: !navBarOpen,
                })}
              >
                <HomeIcon
                  className={clsx({
                    [classes.marginRight8]: navBarOpen,
                  })}
                />
                {navBarOpen && 'Home'}
              </StyledNavigationButtonBox>
            </StyledNavigationButton>
            <StyledNavigationButton
              fullWidth
              onClick={navigateTo('/account')}
            >
              <StyledNavigationButtonBox
                className={clsx({
                  [classes.active]: useRouteMatch({ path: '/account', exact: false }),
                  [classes.iconWidth]: !navBarOpen,
                })}
              >
                <AccountIcon
                  className={clsx({
                    [classes.marginRight8]: navBarOpen,
                  })}
                />
                {navBarOpen && 'Chart of Accounts'}
              </StyledNavigationButtonBox>
            </StyledNavigationButton>
            <StyledNavigationButton
              fullWidth
              onClick={navigateTo('/journal-entries')}
            >
              <StyledNavigationButtonBox
                className={clsx({
                  [classes.active]: useRouteMatch({ path: '/journal-entries', exact: false }),
                  [classes.iconWidth]: !navBarOpen,
                })}
              >
                <BalanceIcon
                  className={clsx({
                    [classes.marginRight8]: navBarOpen,
                  })}
                />
                {navBarOpen && 'Journal Entries'}
              </StyledNavigationButtonBox>
            </StyledNavigationButton>
          </Box>
        </Box>
        <Box
          flexDirection="column"
          display="flex"
          alignItems="center"
          justifyContent="center"
          pb={2}
        >
          <StyledDivider />
          <StyledNavigationButton fullWidth onClick={handleClick}>
            <StyledNavigationButtonBox
              className={clsx({
                [classes.active]: useRouteMatch({ path: '/profile', exact: false }),
                [classes.iconWidth]: !navBarOpen,
              })}
            >
              <SettingIcon
                className={clsx({
                  [classes.settingIcon]: navBarOpen,
                  [classes.settingIconDrawerClose]: !navBarOpen,
                })}
              />
              {navBarOpen && 'Settings'}
            </StyledNavigationButtonBox>
          </StyledNavigationButton>
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={navigateTo('/profile')}>Profile</MenuItem>
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default Sidebar;
