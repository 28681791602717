import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, createStyles, Tooltip } from '@material-ui/core';
import useFetch from 'use-http';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Loader from '../../../components/Loader/loader';
import COLORS from '../../../variables/colors';
import { Subledger } from '../../../variables/types';
import { PrimaryButton } from '../../../components/Buttons';
import isEmpty from '../../../util/isEmpty';
import SubledgerMonthIndicator from '../../../components/Subledger/SubledgerMonthIndicator';
import { ScheduleTop } from '../../../components/Subledger/Subledger.styled';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';

const useStyles = makeStyles(() => createStyles({
  navigation: {
    paddingLeft: 38,
    paddingTop: 8,
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  postJEEnabled: boolean;
  onPostJE: () => void;
  navigateToSchedulePrepaid: () => void;
  onCancel: () => void;
}

const ScheduleHeader = ({
  subledger,
  scheduleDate,
  postJEEnabled,
  onPostJE,
  onCancel,
  navigateToSchedulePrepaid,
}: Props) => {
  const { loading } = useFetch();
  const classes = useStyles();

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  return (
    <>
      <Loader open={loading} />
      <SubledgerMonthIndicator
        title="Previous Period:"
        scheduleDate={scheduleDate}
        color={COLORS.violet}
      />
      <img
        src="/schedulePrepaidReviewJE.svg"
        className={classes.navigation}
      />
      <ScheduleTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          paddingLeft="38px"
          paddingRight="38px"
          height="70px"
          marginRight="200px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            marginTop="10px"
            height="70px"
          />
        </Box>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ChevronLeftIcon />}
          onClick={navigateToSchedulePrepaid}
        >
          SCHEDULE PREPAID
        </Button>
        {
          !postJEEnabled && (
            <Tooltip
              title="Please ensure all Manual Prepaids have a $0.00 or greater value."
              arrow
              placement="top"
            >
              <Box>
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  onClick={onPostJE}
                  disabled
                >
                  PREPARE JE
                </PrimaryButton>
              </Box>
            </Tooltip>

          )
        }
        {
          postJEEnabled && (
            <Box>
              <PrimaryButton
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                onClick={onPostJE}
                disabled={!postJEEnabled}
              >
                PREPARE JE
              </PrimaryButton>
            </Box>
          )
        }
        <Button
          style={{ color: COLORS.skyBlue }}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </ScheduleTop>
    </>
  );
};

export default ScheduleHeader;
