import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { ButtonBase, createStyles, Tooltip, Typography, Button } from '@material-ui/core';
import useFetch from 'use-http';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { eachMonthOfInterval } from 'date-fns';
import { ScheduleTop } from '../../../components/Subledger/Subledger.styled';
import Loader from '../../../components/Loader/loader';
import COLORS from '../../../variables/colors';
import {
  Account,
  AccountClass,
  Subledger,
  Vendor,
} from '../../../variables/types';
import { PrimaryButton } from '../../../components/Buttons';

import isEmpty from '../../../util/isEmpty';
import ExportIcon from '../../../components/Icons/ExportIcon';
import CalendarNavigation from '../../../components/CalendarNavigation';
import getSubledgerStartEndDate from '../../../util/getSubledgerStartEndDate';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  fontSize13: {
    fontSize: 13,
  },
  relative: {
    position: 'relative',
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 8,
  },
  displayBlock: {
    display: 'block',
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string
  vendors: Array<Vendor>
  accountIncomes: Array<Account>
  accountClasses: Array<AccountClass>
  downloadCSV: any;
  navigateToPrepareJE: () => void;
  onCancel: () => void;
  onMonthChange: (dateItem: Date) => void;
}
const ScheduleHeader = ({ subledger, scheduleDate, downloadCSV, onCancel, navigateToPrepareJE, onMonthChange }: Props) => {
  const { loading } = useFetch();
  const classes = useStyles();
  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const { startDate, endDate } = getSubledgerStartEndDate(subledger);

  const subledgerInterval = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  });

  const isPrepareJEEnable = true; // !subledger?.prepaidAssets?.some((asset:PrepaidAsset) => !asset?.status); // TODO -- this will be part of Out of Period

  const onDateChange = (dateItem: Date) => {
    onMonthChange(dateItem);
  };

  // @ts-ignore
  return (
    <div className={classes.relative}>
      <Loader open={loading} />
      <CalendarNavigation
        onDateChange={onDateChange}
        scheduleDate={scheduleDate}
        subledgerInterval={subledgerInterval}
        periodContext="Previous Period :"
        color={COLORS.violet}
      />
      <img
        src="/scheduleReversePrepaid.svg"
        className={classes.navigation}
      />
      <ScheduleTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          paddingLeft="38px"
          paddingRight="38px"
          marginRight="200px"
        >
          <SubledgerAccountName account={subledger?.account} />
        </Box>
        {
          isPrepareJEEnable && (
            <Box>
              <PrimaryButton
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                onClick={navigateToPrepareJE}
              >
                REVIEW JE
              </PrimaryButton>
            </Box>
          )
        }
        {
          !isPrepareJEEnable && (
            <Tooltip
              title="Please ensure that all prepaids are sheduled."
              arrow
              placement="top"
            >
              <Box>
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  onClick={navigateToPrepareJE}
                  disabled={!isPrepareJEEnable}
                >
                  PREPARE JE
                </PrimaryButton>
              </Box>
            </Tooltip>
          )
        }
        <Button
          style={{ color: COLORS.skyBlue }}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </ScheduleTop>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        position="absolute"
        top="5%"
        right="4%"
      >
        <ButtonBase onClick={downloadCSV}>
          <Typography color="primary" className={classes.fontSize13}>Export</Typography>
          <ExportIcon fill={COLORS.skyBlue} className={classes.exportIcon} fontSize="small" />
        </ButtonBase>
      </Box>
    </div>
  );
};

export default ScheduleHeader;
