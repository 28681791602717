import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, ButtonBase, createStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import useFetch from 'use-http';

import clsx from 'clsx';
import SelectBox from '../../components/Select';
import { JournalEntryTop, JournalEntryLabel } from './JournalEntryHeader.styled';
import Loader from '../../components/Loader/loader';
import COLORS from '../../variables/colors';
import ExportIcon from '../../components/Icons/ExportIcon';
import { getAccountName } from '../../components/Subledger/common';
import { Account, ReportType, Subledger } from '../../variables/types';
import { FINALIZED_SUBLEDGER_STATUS } from '../../variables/constants';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 300,
  },
  fullWidth: {
    width: '100%',
  },
  whiteFont: {
    color: COLORS.white,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  fontSize13: {
    fontSize: 13,
  },
  relative: {
    position: 'relative',
  },
  filterButton: {
    background: COLORS.white,
    height: 30,
    width: 80,
    boxShadow: 'none',
    border: `1px solid ${COLORS.deepGray}`,
    marginLeft: 25,
    color: COLORS.deepGray,
    '&:hover': {
      boxShadow: 'none',
      background: COLORS.hoverButtonGrey,
    },
  },
  filterAppliedButton: {
    background: COLORS.deepGray,
    height: 30,
    width: 110,
    boxShadow: 'none',
    border: `1px solid ${COLORS.white}`,
    marginLeft: 25,
    color: COLORS.white,
    '&:hover': {
      boxShadow: 'none',
      background: COLORS.deepGray,
    },
    whiteSpace: 'nowrap',
  },
  displayBlock: {
    display: 'block',
  },
  filterMenu: {
    border: `1px solid ${COLORS.lightGray2}`,
    borderRadius: 4,
    boxShadow: COLORS.selectBoxShadow,
    '& li': {
      fontSize: 13,
      color: COLORS.medGray,
    },
  },
}));
interface Props {
  downloadCSV: (type: ReportType) => void;
  toggleFilter: any;
  subledger: Subledger;
  accounts: Array<Account>
  onAccountChange: (event: React.ChangeEvent<{ value: unknown }>) => void
  filterApplied: Boolean
}

const JournalEntryHeader = ({
  downloadCSV,
  toggleFilter,
  accounts,
  subledger,
  onAccountChange,
  filterApplied,
}: Props) => {
  const { loading } = useFetch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (type: ReportType) => () => {
    downloadCSV(type);
    handleClose();
  };

  const searchResultsLength = subledger.journalEntries?.length;

  return (
    <div className={classes.relative}>
      <Loader open={loading} />
      <JournalEntryTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          paddingLeft="38px"
          paddingRight="38px"
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            <JournalEntryLabel>
              ACCOUNT
            </JournalEntryLabel>
            <SelectBox
              className={clsx(classes.selectBox)}
              disableUnderline
              fullWidth
              value={subledger?.account?.id}
              onChange={onAccountChange}
            >
              {accounts?.filter((acc:Account) => acc?.subledger?.status === FINALIZED_SUBLEDGER_STATUS)?.map((acc: Account) => (
                <MenuItem value={acc.id} key={acc.id}>{getAccountName(acc)}</MenuItem>
              ))}
            </SelectBox>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="default"
              className={filterApplied ? classes.filterAppliedButton : classes.filterButton}
              onClick={() => toggleFilter(true)}
              startIcon={filterApplied ? <img src="/filterApplied.svg" /> : <img src="/filter.svg" />}
            >
              {filterApplied && searchResultsLength ? (searchResultsLength > 1 ? `${searchResultsLength} Results` : `${searchResultsLength} Result`) : 'Filter'}
            </Button>
          </Box>
        </Box>
      </JournalEntryTop>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        position="absolute"
        top="5%"
        right="4%"
      >
        <ButtonBase className={classes.displayBlock}>
          <Typography
            color="primary"
            className={classes.fontSize13}
            onClick={handleOpen}
          >
            Export
            <ExportIcon fill={COLORS.skyBlue} className={classes.exportIcon} fontSize="small" />
          </Typography>
          <Menu
            className={classes.filterMenu}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: -35,
              horizontal: 'center',
            }}
          >
            <MenuItem value="posted" key="posted" onClick={handleClick(ReportType.Detail)}>Detail</MenuItem>
            <MenuItem value="reversed" key="reversed" onClick={handleClick(ReportType.Summary)}>Summary</MenuItem>
          </Menu>
        </ButtonBase>
      </Box>
    </div>
  );
};

export default JournalEntryHeader;
