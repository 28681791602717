import React, { useCallback, useEffect, useState } from 'react';
import useFetch from 'use-http';
import Table from '@material-ui/core/Table';
import {
  Box,
  ButtonBase,
  createStyles,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Snackbar,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/styles/makeStyles';
import startCase from 'lodash.startcase';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import Alert from '@material-ui/lab/Alert';

import Loader from '../../components/Loader/loader';
import { Account } from '../../variables/types';
import SortNoneIcon from '../../components/Icons/SortNonecon';
import SortUpIcon from '../../components/Icons/SortUpIcon';
import SortDownIcon from '../../components/Icons/SortDownIcon';
import { Direction, Sort, sortAccountData } from '../../util/sort';
import ExportIcon from '../../components/Icons/ExportIcon';
import COLORS from '../../variables/colors';
import VisibilityIcon from '../../components/Icons/VisibilityIcon';
import HideVisibilityIcon from '../../components/Icons/HideVisibilityIcon';
import { useAccountProvider } from '../../core/accountContext';
import ErrorPage from '../../components/ErrorPage';

const useStyles = makeStyles(() => createStyles({
  opacity4: {
    opacity: 0.4,
  },
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
  fontSize13: {
    fontSize: 13,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const IncomeStatement = () => {
  const { get, loading, error, response: fetchResponse } = useFetch();
  const { account } = useAccountProvider();
  const [balances, setBalances] = useState<Array<Account>>([]);
  const { put, error: requestError, response } = useFetch();
  const classes = useStyles();
  const [sortBy, setSortBy] = useState<Sort>({ name: 'name', direction: Direction.Down });
  const getSortIcon = (name: string) => {
    if (name === sortBy?.name) {
      if (sortBy.direction === Direction.Down) {
        return SortUpIcon;
      }
      return SortDownIcon;
    }
    return SortNoneIcon;
  };

  const changeVisibility = (id: string, enable: boolean) => async () => {
    await put(`/account/incomes/${id}`, { enable: !enable });
    if (response.ok) {
      const acctBalances = balances?.map((accountBalance) => {
        if (accountBalance?.id === id) {
          return {
            ...accountBalance,
            enable: !enable,
          };
        }
        return { ...accountBalance };
      });
      setBalances(acctBalances);
    }
  };

  const getData = useCallback(async () => {
    if (!loading && !error) {
      const result = await get('/account/incomes');
      if (fetchResponse.ok) {
        setBalances(result ?? []);
        await put('/account/actions/incomes/visited');
      }
    }
  }, [error, fetchResponse.ok, get, loading, put]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const onSortClick = (name: string) => () => {
    if (sortBy?.name === name) {
      setSortBy({
        name,
        direction: sortBy?.direction === Direction.Up ? Direction.Down : Direction.Up,
      });
    } else {
      setSortBy({
        name,
        direction: Direction.Up,
      });
    }
  };

  if (error) {
    return <ErrorPage />;
  }
  if (loading && !balances?.length) {
    return <Loader open />;
  }

  const notVisitedAccounts = balances?.filter((balance: Account) => !balance.visited)?.sort(sortAccountData(sortBy)) ?? [];
  const currentBalances = balances?.filter((balance: Account) => !!balance.visited)?.sort(sortAccountData(sortBy)) ?? [];

  const onDownload = () => {
    const allAccounts = [...notVisitedAccounts, ...currentBalances];
    if (allAccounts?.length) {
      const acctBalances = allAccounts.map((balance: any) => ({
        ...balance,
        accountDetail: startCase(balance.accountDetail),
        active: balance.active ? 'Active' : 'Inactive',
      }));
      const csvData = Papa.unparse({
        data: acctBalances,
        fields: ['accNum', 'name', 'accountType', 'accountDetail', 'active'],
      }, {
        header: false,
      });
      const downloadData = `${['Number', 'Account Name', 'Type', 'Detail', 'Active/Inactive'].join(',')}\n${csvData}`;
      fileDownload(downloadData, 'Income Statement.csv');
    }
  };

  const renderIncomeStatement = (balance: Account) => (
    <TableRow
      key={balance.id}
      className={!balance.active ? classes.opacity4 : ''}
      hover
    >
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {balance.accNum}
      </TableCell>
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {balance.name}
      </TableCell>
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {balance.accountType}
      </TableCell>
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {startCase(balance.accountDetail)}
      </TableCell>
      <TableCell className={!balance.visited ? classes.bold : ''}>
        {balance.active ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell align="left">
        <IconButton
          className={classes.noOffset}
          disabled={!balance.active}
          onClick={changeVisibility(balance.id, balance.enable)}
        >
          {
            !balance.enable && (
              <HideVisibilityIcon />
            )
          }
          {
            balance.enable && (
              <VisibilityIcon />
            )
          }
        </IconButton>
      </TableCell>
    </TableRow>
  );

  return (
    <Box
      padding={2}
      width="100%"
    >
      <Loader open={loading} />
      <Snackbar
        open={Boolean(requestError)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">
          {requestError?.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(requestError)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">
          {requestError?.message}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <ButtonBase onClick={onDownload}>
          <Typography color="primary" className={classes.fontSize13}>Export</Typography>
          <ExportIcon fill={COLORS.skyBlue} className={classes.exportIcon} fontSize="small" />
        </ButtonBase>
      </Box>
      <Table
        size="small"
      >
        <TableHead>
          <TableRow
            hover={false}
          >
            <TableCell>
              <TableSortLabel IconComponent={getSortIcon('accNum')} onClick={onSortClick('accNum')}>
                Number
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel IconComponent={getSortIcon('name')} onClick={onSortClick('name')}>
                Account Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              Type
            </TableCell>
            <TableCell>
              Detail
            </TableCell>
            <TableCell>
              Active/Inactive
            </TableCell>
            <TableCell>
              <TableSortLabel IconComponent={getSortIcon('enable')} onClick={onSortClick('enable')}>
                Visible
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notVisitedAccounts.map((balance: Account) => renderIncomeStatement(balance))}
          {currentBalances.map((balance: Account) => renderIncomeStatement(balance))}
        </TableBody>
      </Table>
    </Box>

  );
};

export default React.memo(IncomeStatement);
