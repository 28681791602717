import { CachePolicies } from 'use-http';
import { getToken } from '../util/auth';

const GlobalOptions = {
  cachePolicy: CachePolicies.NO_CACHE,
  interceptors: {
    request: ({ options }: any) => {
      const token = getToken();
      return ({
        ...options,
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });
    },
  },
};

export default GlobalOptions;
