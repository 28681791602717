import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { ButtonBase, createStyles, Tooltip, Typography } from '@material-ui/core';
import useFetch from 'use-http';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import roundTo from 'round-to';
import { eachMonthOfInterval } from 'date-fns';
import { ScheduleTop, ScheduleLabel, CurrencyLabel } from './ScheduleHeader.styled';
import Loader from '../../../components/Loader/loader';
import COLORS from '../../../variables/colors';
import currencyFormatter from '../../../util/currencyFormatter';
import { Account, AccountClass, PrepaidAsset, Subledger, Vendor } from '../../../variables/types';
import { PrimaryButton } from '../../../components/Buttons';
import isEmpty from '../../../util/isEmpty';
import ExportIcon from '../../../components/Icons/ExportIcon';
import CalendarNavigation from '../../../components/CalendarNavigation';
import getSubledgerStartEndDate from '../../../util/getSubledgerStartEndDate';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  periodBox: {
    width: 100,
    marginRight: 20,
  },
  subledgerBalance: {
    color: COLORS.violet,
  },
  datepicker: {
    background: COLORS.white,
    borderRadius: 2,
    marginRight: 20,
    width: 120,
    maxWidth: 120,
    '& input': {
      background: COLORS.white,
    },
  },
  fullWidth: {
    width: '100%',
  },
  whiteFont: {
    color: COLORS.white,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  fontSize13: {
    fontSize: 13,
  },
  relative: {
    position: 'relative',
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 8,
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string
  vendors: Array<Vendor>
  accountIncomes: Array<Account>
  accountClasses: Array<AccountClass>
  accountBalance: Account;
  downloadCSV: any,
  navigateToPrepareJE: () => void;
  onMonthChange: (dateItem: Date) => void;
}
const ScheduleHeader = ({ subledger, scheduleDate, accountBalance, downloadCSV, navigateToPrepareJE, onMonthChange }: Props) => {
  const { loading } = useFetch();
  const classes = useStyles();

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const difference = roundTo(Number(accountBalance?.glBalance), 2) - roundTo(Number(subledger?.openingBalance), 2);

  const isPrepareJEEnable = !subledger?.prepaidAssets?.some((asset:PrepaidAsset) => !asset?.status)
      && difference === 0;

  const { startDate, endDate } = getSubledgerStartEndDate(subledger);

  const subledgerInterval = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  });

  const onDateChange = (dateItem: Date) => {
    onMonthChange(dateItem);
  };

  return (
    <div className={classes.relative}>
      <Loader open={loading} />
      <CalendarNavigation
        onDateChange={onDateChange}
        scheduleDate={scheduleDate}
        subledgerInterval={subledgerInterval}
        periodContext="Current Period :"
        color={COLORS.deepGray}
      />
      <img
        src="/schedulePrepaidNav.svg"
        className={classes.navigation}
      />
      <ScheduleTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          borderRight="1px solid #CFD3DA"
          paddingLeft="38px"
          paddingRight="38px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
            marginTop="10px"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
        >
          <ScheduleLabel>
            Current GL Balance:
          </ScheduleLabel>
          <CurrencyLabel>
            {currencyFormatter.format(accountBalance.glBalance)}
          </CurrencyLabel>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
        >

          <ScheduleLabel>
            Current Facta Balance:
          </ScheduleLabel>
          <CurrencyLabel className={classes.subledgerBalance}>
            {currencyFormatter.format(subledger.openingBalance)}
          </CurrencyLabel>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          marginTop="10px"
          paddingLeft="19px"
          minWidth="200px"
        >
          <ScheduleLabel>
            Difference
          </ScheduleLabel>
          <CurrencyLabel>
            {currencyFormatter.format(difference)}
          </CurrencyLabel>
        </Box>
        {
          isPrepareJEEnable && (
            <Box>
              <PrimaryButton
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                onClick={navigateToPrepareJE}
              >
                PREPARE JE
              </PrimaryButton>
            </Box>
          )
        }
        {
          !isPrepareJEEnable && (
            <Tooltip
              title="Please ensure that all prepaids are sheduled."
              arrow
              placement="top"
            >
              <Box>
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  onClick={navigateToPrepareJE}
                  disabled={!isPrepareJEEnable}
                >
                  PREPARE JE
                </PrimaryButton>
              </Box>
            </Tooltip>
          )
        }
      </ScheduleTop>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        position="absolute"
        top="5%"
        right="4%"
      >
        <ButtonBase onClick={downloadCSV}>
          <Typography color="primary" className={classes.fontSize13}>Export</Typography>
          <ExportIcon fill={COLORS.skyBlue} className={classes.exportIcon} fontSize="small" />
        </ButtonBase>
      </Box>
    </div>
  );
};

export default ScheduleHeader;
