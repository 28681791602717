import { TableCell } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { getClassName } from './common';
import { useTableStyles } from './Subledger.styled';
import { PrepaidAsset } from '../../variables/types';
import AccountClassMenu from './AccountClassMenu';
import { useAccountProvider } from '../../core/accountContext';

interface Props {
  hasChildren?: boolean;
  lastChild: boolean;
  asset: PrepaidAsset;
  isRowSelected: boolean;
  onSelectChange: (propertyName: string, internalId: string) => (value: string) => void;
  isBoldEnable?: boolean;
}

const ClassEditableCell = ({
  hasChildren, lastChild, asset, isRowSelected, onSelectChange, isBoldEnable = false,
}: Props) => {
  const tableClasses = useTableStyles();
  const { account: { accountClasses } } = useAccountProvider();
  return (
    <TableCell className={clsx(tableClasses.cursorPointer, tableClasses.classesCell, {
      [tableClasses.child]: asset.parentId,
      [tableClasses.parent]: hasChildren,
      [tableClasses.bold]: isBoldEnable && !asset.status,
      [tableClasses.lastChild]: lastChild,
    })}
    >
      {
        hasChildren && 'Multiple'
      }
      {
        isRowSelected && !hasChildren && (
          <AccountClassMenu
            className={clsx(tableClasses.classSelectBox)}
            defaultValue={asset?.prepaidSchedule?.classId!}
            value={asset?.prepaidSchedule?.classId!}
            onChange={onSelectChange('classId', asset.internalId)}
            accountClasses={accountClasses}
          />
        )
      }
      {
        !isRowSelected && !hasChildren && getClassName(asset?.prepaidSchedule?.classId!, accountClasses)
      }
    </TableCell>
  );
};

export default React.memo(ClassEditableCell);
