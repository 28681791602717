import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, createStyles, TextField } from '@material-ui/core';

import { lastDayOfMonth, startOfMonth } from 'date-fns';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { AccountLabel, AccountTop } from '../../../components/Subledger/Subledger.styled';
import COLORS from '../../../variables/colors';
import { JournalEntry, Subledger } from '../../../variables/types';
import FactaDatePicker from '../../../components/DatePicker';
import InputBox from '../../../components/InputBox';
import isEmpty from '../../../util/isEmpty';
import { PrimaryButton } from '../../../components/Buttons';
import SubledgerMonthIndicator from '../../../components/Subledger/SubledgerMonthIndicator';
import { fromUTC } from '../../../util/timezone';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';

const useStyles = makeStyles(() => createStyles({
  datepicker: {
    background: COLORS.white,
    borderRadius: 2,
    width: 120,
    maxWidth: 120,
    '& input': {
      background: COLORS.white,
    },
  },
  inputBox: {
    backgroundColor: COLORS.white,
    boxShadow: COLORS.selectBoxShadow,
    borderRadius: 2,
    border: 'none',
    height: 32,
    '&> .MuiSelect-select:focus': {
      backgroundColor: COLORS.white,
    },
    '&.Mui-focused': {
      boxShadow: COLORS.selectBoxShadow,
    },
  },
  jeNumber: {
    '& input': {
      paddingTop: 7.5,
      paddingBottom: 7.5,
    },
  },
  jeDescription: {
    background: COLORS.lightGray3,
    margin: 0,
    width: 400,
    '& p': {
      position: 'absolute',
      top: -25,
      right: 0,
    },
    '& input': {
      paddingTop: 14.5,
      paddingBottom: 14.5,
    },
    '& fieldset': {
      border: 0,
    },
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 8,
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  currentMonth: string;
  onCancel: () => void;
  journalEntry: JournalEntry;
  onPostJE: () => void;
  navigateToPrepareJE: () => void;
}
const PostJEHeader = ({
  subledger,
  scheduleDate,
  onCancel,
  journalEntry,
  onPostJE,
  navigateToPrepareJE,
}: Props) => {
  const classes = useStyles();
  const CHARACTER_LIMIT = 140;

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  return (
    <>
      <SubledgerMonthIndicator
        title="Previous Period:"
        scheduleDate={scheduleDate}
        color={COLORS.violet}
      />
      <img
        src="/reversePrepareJEScheduled.svg"
        className={classes.navigation}
      />
      <AccountTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderRight="1px solid #CFD3DA"
          paddingLeft="38px"
          paddingRight="38px"
          height="70px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            marginTop="10px"
            height="70px"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
          height="70px"
        >
          <AccountLabel>
            JE Number:
          </AccountLabel>
          <InputBox
            readOnly
            fullWidth
            className={classes.inputBox}
            value={journalEntry.jeNumber}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
          height="70px"
        >

          <AccountLabel>
            Posting Date
          </AccountLabel>
          <FactaDatePicker
            readOnly
            selectedDate={fromUTC(journalEntry.txnDate as string)}
            onDateChange={() => {}}
            className={classes.datepicker}
            minDate={startOfMonth(new Date(scheduleDate))}
            maxDate={lastDayOfMonth(new Date(scheduleDate))}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          paddingLeft="19px"
          width="400px"
          height="70px"
        >
          <AccountLabel>
            JE Description
          </AccountLabel>
          <TextField
            disabled
            inputProps={{
              maxlength: CHARACTER_LIMIT,
            }}
            value={journalEntry.description}
            helperText={`${journalEntry?.description?.length}/${CHARACTER_LIMIT}`}
            margin="normal"
            variant="outlined"
            className={classes.jeDescription}
          />
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            paddingLeft="19px"
            marginLeft="20px"
            marginTop="18px"
          >
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ChevronLeftIcon />}
              onClick={navigateToPrepareJE}
            >
              REVIEW JE
            </Button>
            <PrimaryButton
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              onClick={onPostJE}
            >
              POST JE
            </PrimaryButton>
            <Button
              style={{ color: COLORS.skyBlue }}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </AccountTop>
    </>
  );
};

export default React.memo(PostJEHeader);
