import { addMonths, differenceInCalendarMonths, format, lastDayOfMonth, startOfMonth } from 'date-fns';
import React from 'react';
import { PrepaidAsset, PrepaidAssetsAmortizationScheduleDetail } from '../../variables/types';
import { getChildren, getChildSumForMonth } from './common';
import { DAY_SHORT_FORMAT } from '../../variables/constants';
import CreateSubledgerAmoirtizationCell from './CreateSubledgerAmoirtizationCell';

interface Props {
  startDate: Date;
  endDate: Date | null;
  asset: PrepaidAsset;
  lastChild?: boolean;
  prepaidAssets: Array<PrepaidAsset>;
}

const CreateSubledgerAmortizationSchedules = ({
  startDate, endDate, asset, lastChild, prepaidAssets,
}: Props) => {
  if (startDate && endDate) {
    const diffInMonths = differenceInCalendarMonths(lastDayOfMonth(endDate!), startOfMonth(startDate!)) + 1;
    if (diffInMonths > 0) {
      const children = getChildren(asset.internalId, prepaidAssets);
      const hasChildren = children?.length > 0;
      return (
        <>
          {
            Array.from(Array(diffInMonths).keys()).map((index: number) => {
              const dayOfMonth = lastDayOfMonth(addMonths(startDate!, index));
              const lastDay = format(dayOfMonth, DAY_SHORT_FORMAT);
              const sum = hasChildren ? getChildSumForMonth(children, lastDay) : 0;
              const schedule = asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails
                              ?.find((item: PrepaidAssetsAmortizationScheduleDetail) => format(item.scheduleDate, DAY_SHORT_FORMAT) === lastDay);
              return (
                <CreateSubledgerAmoirtizationCell
                  key={schedule?.id}
                  lastChild={lastChild}
                  hasChildren={hasChildren}
                  asset={asset}
                  sum={sum}
                  index={index}
                  schedule={schedule}
                />
              );
            })
          }
        </>
      );
    }
  }
  return null;
};

export default CreateSubledgerAmortizationSchedules;
