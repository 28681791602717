import React from 'react';
import { Box, createStyles, IconButton, Link, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FULL_DAY_FORMAT } from '../../variables/constants';
import {
  getAccountNameFromList,
  getChildren,
  getClassName,
  getTotalBalanceAndAmortizationBalance,
  getVendorName,
} from './common';
import { PrepaidAsset, ScheduleType } from '../../variables/types';
import currencyFormatter from '../../util/currencyFormatter';
import SplitIcon from '../Icons/SplitIcon';
import { useTableStyles } from './Subledger.styled';
import getQuickbookLink from '../../util/getQuickbookLink';
import { useAccountProvider } from '../../core/accountContext';

interface Props {
  asset: PrepaidAsset;
  hasChildren?: boolean;
  lastChild?: boolean;
  prepaidAssets: Array<PrepaidAsset>;
  onChildExpand: (internalId: string) => () => void;
  collapses: Array<string>;
  disabled?: boolean;
}

const useStyles = makeStyles(() => createStyles({
  cursorPointer: {
    cursor: 'pointer',
  },
}));

const PrepaidAssetRow = ({
  asset,
  prepaidAssets,
  hasChildren,
  lastChild,
  onChildExpand,
  collapses,
  disabled,
}: Props) => {
  const { account } = useAccountProvider();
  const { accountIncomes, accountClasses, vendors } = account;
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const children = getChildren(asset.internalId, prepaidAssets);
  const { totalBalance, amortizationToDateBalance } = hasChildren ? getTotalBalanceAndAmortizationBalance(children) : {
    totalBalance: 0,
    amortizationToDateBalance: 0,
  };
  const assetId = asset.parentId ? '' : asset.assetId;
  const quickbookLink = getQuickbookLink(assetId, asset.assetType);
  return (
    <TableRow
      hover
      classes={{
        selected: tableClasses.selected,
      }}
      className={clsx({
        [tableClasses.disabledRow]: disabled,
      })}
    >
      <TableCell className={clsx(tableClasses.assetCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {asset.parentId ? '' : format(asset?.assetCreationDate!, FULL_DAY_FORMAT)}
      </TableCell>
      <TableCell className={clsx(tableClasses.assetCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {(!asset?.assetType || !quickbookLink) && assetId}
        {quickbookLink && (<Link href={quickbookLink} target="_blank">{assetId}</Link>)}
      </TableCell>
      <TableCell className={clsx(tableClasses.descriptionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {asset.description}
      </TableCell>
      <TableCell className={clsx(tableClasses.customerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {!asset.parentId ? getVendorName(asset.vendorId, vendors) : ''}
      </TableCell>
      <TableCell
        className={clsx(classes.cursorPointer, tableClasses.expenseAccountCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
        onClick={onChildExpand(asset.parentId ?? asset.internalId)}
      >
        {
          hasChildren
                    && (
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography className={tableClasses.fontSize13}>Multiple</Typography>
                        {
                          collapses.includes(asset.internalId) && (
                            <ArrowRightIcon />
                          )
                        }
                        {
                          !collapses.includes(asset.internalId) && (
                            <ArrowDropDownIcon />
                          )
                        }
                      </Box>
                    )
        }
        {
          !hasChildren
                    && getAccountNameFromList(asset?.prepaidSchedule?.expenseAccountId!, accountIncomes)
        }
      </TableCell>
      {

        (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
          <TableCell className={clsx(tableClasses.classesCell, {
            [tableClasses.child]: asset.parentId,
            [tableClasses.parent]: hasChildren,
            [tableClasses.lastChild]: lastChild,
          })}
          >
            {
              hasChildren && 'Multiple'
            }
            {
              !hasChildren && getClassName(asset?.prepaidSchedule?.classId!, accountClasses)
            }
          </TableCell>
        )
      }
      <TableCell className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          hasChildren && 'Multiple'
        }
        {
                    asset?.prepaidSchedule?.amortizationStartDate
                    && asset?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual
                    && !hasChildren ? format(asset?.prepaidSchedule?.amortizationStartDate, FULL_DAY_FORMAT) : ''
        }
        {
          !hasChildren
                    && asset?.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual
                    && 'M'
        }
      </TableCell>
      <TableCell className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          hasChildren && 'Multiple'
        }
        {
                    asset?.prepaidSchedule?.amortizationEndDate
                    && asset?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual
                    && !hasChildren ? format(asset?.prepaidSchedule?.amortizationEndDate, FULL_DAY_FORMAT) : ''
        }
        {
          !hasChildren
                    && asset?.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual
                    && 'M'
        }
      </TableCell>
      <TableCell className={clsx(tableClasses.scheduleTypeCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {!hasChildren && asset?.prepaidSchedule?.amortizationScheduleType?.toLocaleLowerCase()}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {hasChildren && currencyFormatter.format(totalBalance)}
        {!hasChildren && currencyFormatter.format(asset?.startingBalance!)}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {hasChildren && currencyFormatter.format(amortizationToDateBalance)}
        {!hasChildren && currencyFormatter.format(asset?.amortizationToDateBalance)}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {hasChildren && currencyFormatter.format(totalBalance - amortizationToDateBalance)}
        {!hasChildren && currencyFormatter.format(asset?.startingBalance - asset?.amortizationToDateBalance)}
      </TableCell>
      <TableCell className={clsx(tableClasses.actionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          hasChildren && !asset.parentId && (
            <Box textAlign="right">
              <IconButton
                disabled
                className={clsx(tableClasses.actionCellButtonPadding, {
                  [tableClasses.iconDisabled]: hasChildren,
                })}
              >
                <SplitIcon className={tableClasses.fontSize13} />
              </IconButton>
            </Box>
          )
        }
      </TableCell>
    </TableRow>
  );
};

export default React.memo(PrepaidAssetRow);
