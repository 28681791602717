import Cookies from 'universal-cookie';

import { AUTH_TOKEN_KEY } from '../variables/constants';

export const setToken = (token: string) => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const getToken = (): string | null => localStorage.getItem(AUTH_TOKEN_KEY);

export const deleteToken = () => localStorage.removeItem(AUTH_TOKEN_KEY);

export const updateTokenFromCookie = () => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (token) {
    setToken(token);
    cookies.remove('token');
  }
};

export const getErrorFromCookie = () => {
  const cookies = new Cookies();
  const requestError = cookies.get('error');
  cookies.remove('error');
  return requestError;
};
