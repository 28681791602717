import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import COLORS from '../../variables/colors';
import { useNavBarContext } from '../../core/navbarContext';

const useStyles = makeStyles({
  meditatingLlama: {
    width: 'calc(100vw/2)',
    height: 'calc(100vh/2)',
    position: 'absolute',
    left: 'calc(100vw-50%)',
    top: 0,
    animation: '$slideDown 3s',
    animationDirection: 'normal',
    animationFillMode: 'forwards',
  },
  mountain: {
    height: 'calc(100vh - 10%)',
    width: 'calc(100vw - 10%)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    animation: '$slideUp 3s',
    animationDirection: 'normal',
    animationFillMode: 'forwards',
  },
  '@keyframes slideDown': {
    '0%': {
      top: -500,
    },
    '100%': {
      top: 0,
    },
  },
  '@keyframes slideUp': {
    '0%': {
      bottom: -500,
    },
    '100%': {
      bottom: 0,
    },
  },
  paper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.white,
    opacity: '0.9',
    zIndex: 0,
    border: 'none',
  },
});

const AssetsSettled = () => {
  const [animateImages, setAnimation] = useState<boolean>(true);
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const { navBarOpen } = useNavBarContext();
  const history = useHistory();

  const handleClose = useCallback(() => {
    setOpen(false);
    setAnimation(false);
    history.replace('/');
  }, [history]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClose);
    return () => {
      document.removeEventListener('mousedown', handleClose);
    };
  }, [handleClose, history]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ border: 'none', zIndex: 99999999999 }}
    >
      <div className={classes.paper} style={{ left: navBarOpen ? 152 : 52 }}>
        {animateImages && (
          <>
            <Box
              justifyContent="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              height="100%"
              position="absolute"
              style={{ zIndex: 99999 }}
            >
              <img
                src="/meditatingLlama.svg"
                className={classes.meditatingLlama}
              />
            </Box>
            <Box
              justifyContent="flex-end"
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              width="100%"
              height="100%"
              position="absolute"
              style={{ bottom: 0 }}
            >
              <img
                src="/mountain.svg"
                className={classes.mountain}
              />
            </Box>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AssetsSettled;
