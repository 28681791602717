import Box from '@material-ui/core/Box';
import React from 'react';
import { CardLabel, CurrencyLabel } from '../Dashboard.styled';
import currencyFormatter from '../../../util/currencyFormatter';
import cardStyles from './cardStyles';

interface Props {
  balance: number;
}

const FactaBalance = ({ balance }: Props) => {
  const classes = cardStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      fontSize="14px"
      paddingTop="8px"
      className={classes.factaBalance}
    >
      <CardLabel className={classes.factaBalance}>
        Facta
      </CardLabel>
      <CurrencyLabel className={classes.factaBalance}>
        {currencyFormatter.format(balance)}
      </CurrencyLabel>
    </Box>
  );
};

export default React.memo(FactaBalance);
