import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import cardStyles from './cardStyles';
import AccountName from './AccountName';
import GLBalance from './GLBalance';
import FactaBalance from './FactaBalance';
import TaskReadOnlyDetail from './TaskReadOnlyDetail';
import TaskActionDetail from './TaskActionDetail';
import AccountUnScheduledCountInfo from './AccountUnScheduledCountInfo';

interface Props {
  active: boolean;
  accountName: string;
  loading: boolean;
  glBalance: number;
  closingBalance: number;
  action: () => void;
  onCurrencyNavigation: () => void;
  amount: number;
  amortizationPostedAmount: number;
  amortizationUnPostedAmount: number;
  unScheduleAssetsCount: number;
}

const OutOfPeriodMonthUnScheduledCard = ({
  active,
  accountName,
  loading,
  glBalance,
  closingBalance,
  action,
  onCurrencyNavigation,
  amount,
  amortizationPostedAmount,
  amortizationUnPostedAmount,
  unScheduleAssetsCount,
}: Props) => {
  const classes = cardStyles();
  return (
    <Card
      className={clsx(classes.card, { [classes.active]: active })}
    >
      <CardContent>
        <AccountUnScheduledCountInfo
          unScheduleAssetsCount={unScheduleAssetsCount}
        />
        <AccountName accountName={accountName} />
        <GLBalance glBalance={glBalance} loading={loading} />
        <FactaBalance balance={closingBalance} />
      </CardContent>
      <CardActions className={classes.padding15}>
        <TaskActionDetail
          name="Schedule Prepaids"
          amount={amount}
          action={action}
          onCurrencyNavigation={onCurrencyNavigation}
          enableCurrencyNavigation
        />
        <TaskReadOnlyDetail
          name="Amortization Unposted"
          amount={amortizationUnPostedAmount}
        />
        <TaskReadOnlyDetail
          name="Amortization Posted"
          amount={amortizationPostedAmount}
        />
      </CardActions>
    </Card>
  );
};

export default React.memo(OutOfPeriodMonthUnScheduledCard);
