import { Route } from 'react-router-dom';
import React from 'react';
import { useLocation } from 'react-router';
import { format, lastDayOfMonth } from 'date-fns';
import Scheduler from './Scheduler';
import { DAY_SHORT_FORMAT } from '../../variables/constants';
import PrepareJE from './PrepareJE';
import PostJE from './PostJE';
import CreateSubledger from './CreateSubledger';

const HistoricalEditing = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const scheduleDate = useQuery().get('scheduleDate') ?? format(lastDayOfMonth(new Date()), DAY_SHORT_FORMAT);
  return (
    <>
      <Route path="/historical/subledgers/scheduler/:id" exact>
        <CreateSubledger />
      </Route>
      <Route path="/historical/subledgers/schedule/:id" exact>
        <Scheduler
          scheduleDate={scheduleDate}
        />
      </Route>
      <Route path="/historical/subledgers/schedule/:id/prepare-je" exact>
        <PrepareJE
          scheduleDate={scheduleDate}
        />
      </Route>
      <Route path="/historical/subledgers/schedule/:id/post-je" exact>
        <PostJE
          scheduleDate={scheduleDate}
        />
      </Route>
    </>
  );
};

export default HistoricalEditing;
