import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import useFetch from 'use-http';
import { endOfMonth, format, isBefore, isWithinInterval, parse, startOfMonth } from 'date-fns';
import debounce from 'lodash.debounce';

import roundTo from 'round-to';
import { Button, TableCell, TableRow, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Loader from '../../../components/Loader/loader';
import { useAccountProvider } from '../../../core/accountContext';
import { useRefreshDataProvider } from '../../../core/dataRefreshContext';

import {
  PrepaidAsset, ScheduleType,
} from '../../../variables/types';
import {
  ADD_SPLIT,
  ADD_SPLIT_ASSET,
  ASSET_SCHEDULED_STATUS,
  ASSET_TEMP_SCHEDULED_STATUS,
  DAY_SHORT_FORMAT,
  FINALIZED_SUBLEDGER_STATUS,
  HISTORICAL_UPDATE_ASSET,
  INITIALED,
  INITIALED_WITH_LOCAL_STORAGE,
  SELECT_ROW, UNSCHEDULED_DATE_CHANGE, UNSCHEDULED_INPUT_CHANGE,
  UNSCHEDULED_REMOVE_ASSET, UNSCHEDULED_SELECT_CHANGE,
} from '../../../variables/constants';
import COLORS from '../../../variables/colors';
import {
  getChildren, getTotalBalanceAndAmortizationBalance,
  initializedSubledger,
  isPrepaidScheduleSame, monthChange,
  schedulerExportToCSV, updateHistoricalSubledgerData,
} from '../../../components/Subledger/common';
import ScheduleHeader from './ScheduleHeader';
import reducer from '../../../components/Subledger/reducer';
import ErrorPage from '../../../components/ErrorPage';
import SchedulerTableHeader from '../../../components/Subledger/SchedulerTableHeader';
import PrepaidAssetRow from '../../../components/Subledger/PrepaidAssetRow';
import ExpandCollapseRow from '../../../components/Subledger/ExpandCollapseRow';
import HistoricalEditableRow from '../../../components/Subledger/HistoricalEditableRow';
import DialogBox from '../../../components/DialogBox';
import { deleteSubledger, getSubledger } from '../../../util/subledger';
import isEmpty from '../../../util/isEmpty';
import AddSplitButtonRow from '../../../components/Subledger/AddSplitButtonRow';
import UnScheduledRow from '../../../components/Subledger/UnScheduledRow';
import { useTableStyles } from '../../../components/Subledger/Subledger.styled';

interface Props {
  scheduleDate: string;
}

const Scheduler = ({
  scheduleDate,
}: Props) => {
  const { id: subledgerId } = useParams();
  const location = useLocation();
  const tableClasses = useTableStyles();
  const useQuery = () => new URLSearchParams(location.search);
  const history = useHistory();
  const { account } = useAccountProvider();
  const { refreshDate } = useRefreshDataProvider();
  const { accountClasses, vendors, accountIncomes } = account;
  const { get, loading: subledgerLoading, error: subLedgerError, response, data } = useFetch(`/subledgers/${subledgerId}/details`);
  const [collapses, setCollapses] = useState<Array<string>>([]);
  const [showSchedule, setShowSchedule] = useState<boolean>(true);
  const [showUnScheduled, setShowUnScheduled] = useState<boolean>(true);
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);
  const [openConfirmationDialog, setConfirmationDialog] = useState<boolean>(false);
  const [refreshLocal, setRefreshLocal] = useState<Date>(refreshDate);
  const fromPrepareJE = !!useQuery().get('fromPrepareJE');
  const [navigationDate, setNavigationDate] = useState(new Date());

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {}, selectedRow: '' });

  const getData = useCallback(async () => {
    if (!fromPrepareJE || refreshLocal !== refreshDate) {
      setRefreshLocal(refreshDate);
      deleteSubledger();
      if (!subledgerLoading && !subLedgerError) {
        const result = await get(`?scheduleDate=${scheduleDate}&requiredAmortizationToDate=true`);
        if (response.ok) {
          if (result.status !== FINALIZED_SUBLEDGER_STATUS) {
            history.push(`/subledgers/scheduler/${subledgerId}`);
          }
          dispatch({
            type: INITIALED,
            payload: {
              subledger: result,
              scheduleDate,
              historicalUpdatedAssets: [],
            },
          });
        }
      }
    } else {
      const { subledger: localSubledger, historicalUpdatedAssets: localHistoricalPrepaidAssets } = getSubledger();
      dispatch({
        type: INITIALED_WITH_LOCAL_STORAGE,
        payload: {
          subledger: localSubledger,
          historicalUpdatedAssets: localHistoricalPrepaidAssets,
          scheduleDate,
        },
      });
    }
  }, [fromPrepareJE, get, history, refreshDate, refreshLocal, response.ok, scheduleDate, subLedgerError, subledgerId, subledgerLoading]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, scheduleDate, refreshDate]);

  const { subledger, selectedRow, historicalUpdatedAssets } = state;

  if (subledgerLoading && isEmpty(subledger)) {
    return <Loader open />;
  }

  if (subLedgerError) {
    return <ErrorPage />;
  }

  const onMonthChange = (previousDateType: Date) => {
    setNavigationDate(previousDateType);
    if (historicalUpdatedAssets?.length > 0) {
      setOpenSubledgerDialog(true);
    } else navigateToDates(previousDateType);
  };

  const switchDates = () => {
    setOpenSubledgerDialog(false);
    deleteSubledger();
    navigateToDates(navigationDate);
  };

  const navigateToDates = (previousDateType: Date) => {
    const { equalDates, scheduleDateToNavigate } = monthChange(previousDateType, subledger);
    if (equalDates) {
      history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${format(scheduleDateToNavigate, DAY_SHORT_FORMAT)}`);
    } else {
      deleteSubledger();
      history.push(`/historical/subledgers/schedule/${subledger.id}/?scheduleDate=${format(scheduleDateToNavigate, DAY_SHORT_FORMAT)}`);
    }
  };

  const onRowSelect = (internalId: string) => () => {
    dispatch({
      type: SELECT_ROW,
      payload: { selectedRow: internalId },
    });
  };

  const resetSelected = debounce(onRowSelect(''), 200);

  const onSave = (existingAsset: PrepaidAsset) => async () => {
    dispatch({ type: HISTORICAL_UPDATE_ASSET, payload: { internalId: existingAsset.internalId } });
    resetSelected();
  };

  const addSplit = (internalId: string) => () => {
    dispatch({ type: ADD_SPLIT, payload: { internalId } });
  };

  const addSplitAsset = (internalId: string) => () => {
    dispatch({ type: ADD_SPLIT_ASSET, payload: { internalId } });
  };

  const removeAsset = (internalId: string) => () => {
    dispatch({ type: UNSCHEDULED_REMOVE_ASSET, payload: { internalId } });
  };

  const onDateChange = (propertyName: string, internalId: string, value: Date | null) => {
    dispatch({ type: UNSCHEDULED_DATE_CHANGE, payload: { internalId, propertyName, value, scheduleDate } });
  };

  const onSelectChange = (propertyName: string, internalId: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: UNSCHEDULED_SELECT_CHANGE,
      payload: { internalId, propertyName, value: event.target.value, scheduleDate },
    });
  };

  const onAutoCompleteChange = (propertyName: string, internalId: string) => (value: string) => {
    dispatch({
      type: UNSCHEDULED_SELECT_CHANGE,
      payload: { internalId, propertyName, value, scheduleDate },
    });
  };

  const onInputBoxChange = (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UNSCHEDULED_INPUT_CHANGE,
      payload: { internalId, propertyName, value: event.target.value, scheduleDate },
    });
  };

  const isSaveEnabled = (asset: PrepaidAsset) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    if (children?.length > 0) {
      for (const child of children) {
        if (!(child?.prepaidSchedule.expenseAccountId)) {
          return false;
        }
        if (child?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual) {
          if (isBefore(child?.prepaidSchedule?.amortizationEndDate!, startOfMonth(asset?.assetCreationDate!))) {
            return false;
          }
          if (isBefore(child?.prepaidSchedule?.amortizationEndDate!, startOfMonth(child?.prepaidSchedule.amortizationStartDate!))) {
            return false;
          }
        }
      }
      const { totalBalance } = getTotalBalanceAndAmortizationBalance(children);
      return roundTo(Number(totalBalance), 2) === roundTo(Number(asset?.startingBalance), 2);
    }
    if (historicalUpdatedAssets
        ?.find((prepaidAsset: PrepaidAsset) => prepaidAsset.internalId === asset.internalId)) {
      return true;
    }
    const existingSubledger = initializedSubledger({ subledger: data });
    const existingAsset = existingSubledger?.prepaidAssets
        ?.find((prepaidAsset: PrepaidAsset) => prepaidAsset.id === asset.internalId);
    if (!existingAsset) {
      return true;
    }
    if (!isPrepaidScheduleSame(existingAsset?.prepaidSchedule, asset?.prepaidSchedule)) {
      return true;
    }
    const existingChildren = getChildren(asset.internalId, existingSubledger?.prepaidAssets);
    if (children?.length !== existingChildren?.length) {
      return true;
    }
    for (const existingChild of existingChildren) {
      const child = subledger?.prepaidAssets
          ?.find((prepaidAsset: PrepaidAsset) => prepaidAsset.internalId === existingChild.internalId);
      if (!isPrepaidScheduleSame(existingChild?.prepaidSchedule, child?.prepaidSchedule)) {
        return true;
      }
    }
    return false;
  };

  const toggleSplit = (internalId: string) => () => {
    const exists = collapses.includes(internalId);
    let updateCollapse: Array<string> = [];
    if (exists) {
      updateCollapse = collapses?.filter((item: string) => item !== internalId);
    } else {
      updateCollapse.push(internalId);
    }
    setCollapses(updateCollapse);
  };

  const toggleSchedule = () => {
    setShowSchedule(!showSchedule);
  };

  const toggleUnScheduled = () => {
    setShowUnScheduled(!showUnScheduled);
  };

  const renderReadOnlyRow = (asset: PrepaidAsset) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const parent = (
      <PrepaidAssetRow
        asset={asset}
        prepaidAssets={subledger?.prepaidAssets}
        onChildExpand={toggleSplit}
        collapses={collapses}
        hasChildren={!!children?.length}
        disabled
      />
    );
    if (!children?.length || collapses.includes(asset.internalId)) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children
                ?.map((child: PrepaidAsset, childIndex: number) => (
                  <PrepaidAssetRow
                    disabled
                    key={child.internalId}
                    asset={child}
                    prepaidAssets={subledger?.prepaidAssets}
                    onChildExpand={toggleSplit}
                    collapses={collapses}
                    hasChildren={false}
                    lastChild={children?.length - 1 === childIndex}
                  />
                )
                )
        }
      </>
    );
  };

  const renderEditableRow = (asset: PrepaidAsset) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const parent = (
      <HistoricalEditableRow
        selectedRow={selectedRow}
        childrenPrepaidAssets={children}
        scheduleDate={scheduleDate}
        addSplit={addSplit}
        addSplitAsset={addSplitAsset}
        asset={asset}
        collapses={collapses}
        hasChildren={!!children?.length}
        isSaveEnabled={isSaveEnabled}
        onDateChange={onDateChange}
        onInputBoxChange={onInputBoxChange}
        onSave={onSave}
        onSelectChange={onSelectChange}
        onAutoCompleteChange={onAutoCompleteChange}
        parentAsset={asset}
        removeAsset={removeAsset}
        onRowSelect={onRowSelect}
        key={asset.internalId}
        subledgerId={subledger?.id}
      />
    );
    if (!children?.length || collapses.includes(asset.internalId)) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children
                ?.map((child: PrepaidAsset, index: number) => (
                  <HistoricalEditableRow
                    onRowSelect={onRowSelect}
                    selectedRow={selectedRow}
                    childrenPrepaidAssets={children}
                    scheduleDate={scheduleDate}
                    addSplit={addSplit}
                    addSplitAsset={addSplitAsset}
                    asset={child}
                    collapses={collapses}
                    hasChildren={false}
                    isSaveEnabled={isSaveEnabled}
                    onDateChange={onDateChange}
                    onInputBoxChange={onInputBoxChange}
                    onSave={onSave}
                    onSelectChange={onSelectChange}
                    onAutoCompleteChange={onAutoCompleteChange}
                    parentAsset={asset}
                    removeAsset={removeAsset}
                    key={child.internalId}
                    lastChild={index === children?.length - 1 && selectedRow !== asset?.internalId}
                    subledgerId={subledger?.id}
                  />
                )
              )
        }
        {
          selectedRow === asset?.internalId && (
            <AddSplitButtonRow
              addSplitAsset={addSplitAsset(asset.internalId)}
            />
          )
        }
      </>
    );
  };

  const renderUnScheduledRow = (asset: PrepaidAsset) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const parent = (
      <UnScheduledRow
        selectedRow={selectedRow}
        showRemainingBalance
        childrenPrepaidAssets={children}
        factaStartDate={subledger?.factaStartDate}
        scheduleDate={scheduleDate}
        addSplit={addSplit}
        addSplitAsset={addSplitAsset}
        asset={asset}
        hasChildren={!!children?.length}
        isSaveEnabled={isSaveEnabled}
        onDateChange={onDateChange}
        onInputBoxChange={onInputBoxChange}
        onSave={onSave}
        onSelectChange={onSelectChange}
        onAutoCompleteChange={onAutoCompleteChange}
        parentAsset={asset}
        removeAsset={removeAsset}
        onRowSelect={onRowSelect}
        key={asset.internalId}
        saveTitle="Schedule"
        subledgerId={subledger?.id}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children
                ?.map((child: PrepaidAsset, index: number) => (
                  <UnScheduledRow
                    onRowSelect={onRowSelect}
                    selectedRow={selectedRow}
                    showRemainingBalance
                    childrenPrepaidAssets={children}
                    factaStartDate={subledger?.factaStartDate}
                    scheduleDate={scheduleDate}
                    addSplit={addSplit}
                    addSplitAsset={addSplitAsset}
                    asset={child}
                    hasChildren={false}
                    isSaveEnabled={isSaveEnabled}
                    onDateChange={onDateChange}
                    onInputBoxChange={onInputBoxChange}
                    onSave={onSave}
                    onSelectChange={onSelectChange}
                    onAutoCompleteChange={onAutoCompleteChange}
                    parentAsset={asset}
                    removeAsset={removeAsset}
                    key={child.internalId}
                    lastChild={index === children?.length - 1 && selectedRow !== asset?.internalId}
                    saveTitle="schedule"
                    subledgerId={subledger?.id}
                  />
                ))
        }
        {
          selectedRow === asset?.internalId && (
            <TableRow
              selected
              classes={{
                selected: tableClasses.selected,
              }}
            >
              <TableCell />
              <TableCell colSpan={100}>
                <Button
                  color="primary"
                  className={tableClasses.arrowIcon}
                  onClick={addSplitAsset(asset.internalId)}
                  startIcon={<AddIcon fontSize="small" className={tableClasses.fontSize12} color="primary" />}
                >
                  <Typography style={{ color: COLORS.skyBlue }}>Add</Typography>
                </Button>
              </TableCell>
            </TableRow>
          )
        }
      </>
    );
  };

  const renderAsset = () => (
    <>
      <ExpandCollapseRow
        onRowClick={toggleUnScheduled}
        isExpanded={showUnScheduled}
        title="UNSCHEDULED"
        color={COLORS.violet}
      />
      {
        showUnScheduled && subledger?.prepaidAssets
            ?.filter((asset:PrepaidAsset) => !asset.status && !asset.parentId)
            ?.map(renderUnScheduledRow)
      }
      <ExpandCollapseRow
        onRowClick={toggleSchedule}
        isExpanded={showSchedule}
        title="SCHEDULED"
        color={COLORS.lightGray}
      />
      {
        showSchedule && subledger?.prepaidAssets
            ?.filter((asset: PrepaidAsset) => !asset.parentId && [ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS].includes(asset.status))
            ?.map((asset: PrepaidAsset) => {
              if (isWithinInterval(asset.assetCreationDate!, {
                start: startOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
                end: endOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
              })) {
                return renderEditableRow(asset);
              }
              return renderReadOnlyRow(asset);
            })
      }
    </>
  );

  const navigateToPrepareJE = () => {
    updateHistoricalSubledgerData(historicalUpdatedAssets, subledger, data);
    history.push(`/historical/subledgers/schedule/${subledger.id}/prepare-je/?scheduleDate=${scheduleDate}`);
  };

  // @ts-ignore
  const onCSVExport = () => {
    schedulerExportToCSV({ subledger, vendors, accountClasses, accountIncomes, account, scheduleDate });
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
    setConfirmationDialog(false);
  };

  const onCancel = () => {
    if (historicalUpdatedAssets?.length > 0) {
      setConfirmationDialog(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    deleteSubledger();
    setConfirmationDialog(false);
    history.push(`/journal-entries/?account_id=${subledger?.account?.id}`);
  };

  // @ts-ignore
  return (
    <>
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page? \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{ title: 'YES', event: switchDates }]}
      />
      <DialogBox
        openDialog={openConfirmationDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page? \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{ title: 'YES', event: navigateBack }]}
      />
      <Loader open={subledgerLoading} />
      <Box
        width="100%"
      >
        <ScheduleHeader
          subledger={subledger}
          scheduleDate={scheduleDate}
          vendors={vendors}
          accountIncomes={accountIncomes}
          accountClasses={accountClasses}
          downloadCSV={onCSVExport}
          navigateToPrepareJE={navigateToPrepareJE}
          onCancel={onCancel}
          onMonthChange={onMonthChange}
        />
        <Box padding={1}>
          <Table
            size="small"
          >
            <SchedulerTableHeader />
            <TableBody>
              {
                renderAsset()
              }
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default Scheduler;
