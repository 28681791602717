import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import React from 'react';
import COLORS from '../../variables/colors';
import { PrepaidAsset, PrepaidAssetsAmortizationScheduleDetail, ScheduleType } from '../../variables/types';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';

interface Props {
  hasChildren: boolean;
  index: number;
  asset: PrepaidAsset;
  lastChild?: boolean;
  schedule?: PrepaidAssetsAmortizationScheduleDetail;
  sum: number;
}

const CreateSubledgerAmoirtizationCell = ({
  hasChildren,
  index,
  asset,
  lastChild,
  schedule,
  sum,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableCell
      className={clsx(tableClasses.amortizationRowCell, {
        [tableClasses.cellBackground]: index % 2 === 0,
        [tableClasses.amortizationSplitCell]: hasChildren || asset.parentId,
        [tableClasses.lastChild]: lastChild,
      })}
      style={{
        padding: hasChildren ? 0 : 4,
        borderLeft: index === 0 ? `1px solid ${COLORS.lightGray2}` : 'none',
      }}
      align="center"
    >
      {
        !hasChildren && !Number.isNaN(Number(schedule?.amount))
          ? currencyFormatter.format(Number(schedule?.amount))
          : asset.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual ? 'M' : '-'
      }
      {
        schedule && hasChildren && (
          sum
            ? currencyFormatter.format(sum) : '-'
        )
      }
    </TableCell>
  );
};

export default CreateSubledgerAmoirtizationCell;
