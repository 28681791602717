import React from 'react';
import Scheduler from './Scheduler';
import Layout from '../../../components/Layout';
import { Account } from '../../../variables/types';

interface Props {
  scheduleDate: string;
  accountBalance: Account;
}

const ScheduleContainer = ({
  scheduleDate,
  accountBalance,
}: Props) => (

  <Layout title="Schedule">
    <Scheduler
      scheduleDate={scheduleDate}
      accountBalance={accountBalance}
    />
  </Layout>
);

export default ScheduleContainer;
