import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import COLORS from '../../variables/colors';
import { StyledButton, WelcomeMessage } from './Dashboard.styled';

const NoData = () => {
  const history = useHistory();
  const navigateToCharts = () => history.push('/account');
  return (
    <Box
      bgcolor={COLORS.lighterBlue}
      height={172}
      display="flex"
      flexDirection="row"
      width="95%"
      alignSelf="center"
      borderRadius="4px"
      marginTop={6}
      p={0}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <img src="/half-llama.svg" />
      </Box>
      <Box
        display="flex"
        maxWidth="650px"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        marginLeft="-50px"
      >
        <WelcomeMessage>Welcome to Facta!</WelcomeMessage>
        <Typography>
          Your dashboard is currently empty because no subledgers have been created. Create your first
          subledger by clicking this magical button.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginLeft="-175px"
        marginTop="75px"
      >
        <img src="/get-started-arrow.svg" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        ml="4px"
      >
        <StyledButton
          variant="contained"
          color="primary"
          onClick={navigateToCharts}
        >
          GET STARTED
        </StyledButton>
      </Box>
    </Box>
  );
};

export default NoData;
