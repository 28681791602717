import React from 'react';
import { format, isBefore, max } from 'date-fns';
import {
  Box,
  createStyles,
  IconButton,
  Link,
  makeStyles,
  MenuItem,
  TableCell,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import getQuickbookLink from '../../util/getQuickbookLink';
import { FULL_DAY_FORMAT, QUICKBOOK_SOURCE } from '../../variables/constants';
import { getTotalBalanceAndAmortizationBalance, getVendorName } from './common';
import { PrepaidAsset, ScheduleType } from '../../variables/types';
import FactaDatePicker from '../DatePicker';
import SelectBox from '../Select';
import currencyFormatter from '../../util/currencyFormatter';
import InputBox from '../InputBox';
import DeleteIcon from '../Icons/DeleteIcon';
import SplitIcon from '../Icons/SplitIcon';
import { PrimaryButton } from '../Buttons';
import { useTableStyles } from './Subledger.styled';
import { useAccountProvider } from '../../core/accountContext';
import ExpenseAccountEditableCell from './ExpenseAccountEditableCell';
import ClassEditableCell from './ClassEditableCell';
import RefreshData from '../RefreshData';

const useStyles = makeStyles(() => createStyles({
  bold: {
    fontWeight: 'bold',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  saveButton: {
    minWidth: 43,
    padding: 2,
    height: 24,
    margin: 0,
    marginLeft: 8,
    fontSize: 11,
  },
}));

interface Props {
  scheduleDate: string,
  onSave: (asset: PrepaidAsset) => () => Promise<void>
  parentAsset: PrepaidAsset,
  asset: PrepaidAsset,
  hasChildren?: boolean,
  collapses: Array<string>
  onDateChange: (propertyName: string, internalId: string, value: Date | null) => void,
  onSelectChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<{ value: unknown }>) => void,
  onAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void,
  onInputBoxChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  addSplit: (internalId: string) => () => void,
  onRowSelect: (internalId: string) => () => void,
  addSplitAsset: (internalId: string) => () => void,
  removeAsset: (internalId: string) => () => void,
  isSaveEnabled: (asset: PrepaidAsset) => any,
  childrenPrepaidAssets: Array<PrepaidAsset>,
  selectedRow: string,
  lastChild?: boolean,
  subledgerId: string,
}

const HistoricalEditableRow = ({
  parentAsset,
  asset,
  hasChildren,
  collapses,
  onSave,
  onDateChange,
  onSelectChange,
  onInputBoxChange,
  addSplit,
  isSaveEnabled,
  removeAsset,
  childrenPrepaidAssets,
  selectedRow,
  onRowSelect,
  onAutoCompleteChange,
  subledgerId,
  lastChild = false,
}: Props) => {
  const { account } = useAccountProvider();
  const { vendors } = account;
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const { totalBalance, amortizationToDateBalance } = hasChildren ? getTotalBalanceAndAmortizationBalance(childrenPrepaidAssets) : {
    totalBalance: 0,
    amortizationToDateBalance: 0,
  };
  const assetId = asset.parentId ? '' : asset.assetId;
  const quickbookLink = getQuickbookLink(assetId, asset.assetType);
  const amortizationMinEndDate = asset?.prepaidSchedule?.amortizationStartDate ? max([asset.assetCreationDate!, asset.prepaidSchedule.amortizationStartDate]) : asset?.assetCreationDate!;
  const isRowSelected = selectedRow === asset.parentId || selectedRow === asset.internalId;

  return (
    <TableRow
      selected={isRowSelected}
      classes={{
        selected: tableClasses.selected,
      }}
      onClick={onRowSelect(asset.parentId ?? asset.internalId)}
    >
      <TableCell className={clsx(classes.cursorPointer, tableClasses.assetCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {asset.parentId ? '' : format(asset?.assetCreationDate!, FULL_DAY_FORMAT)}
      </TableCell>
      <TableCell className={clsx(classes.cursorPointer, tableClasses.assetCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {(!asset?.assetType || !quickbookLink) && assetId}
        {quickbookLink && (<Link href={quickbookLink} target="_blank">{assetId}</Link>)}
      </TableCell>
      <TableCell className={clsx(classes.cursorPointer, tableClasses.descriptionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          isRowSelected && asset.parentId && (
            <InputBox
              placeholder=""
              value={asset.description}
              className={tableClasses.inputBox}
              onChange={onInputBoxChange('description', asset.internalId)}
            />
          )
        }
        {(!asset.parentId || !isRowSelected) && asset.description}
      </TableCell>
      <TableCell className={clsx(classes.cursorPointer, tableClasses.customerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          !asset.parentId && getVendorName(asset.vendorId, vendors)
        }
      </TableCell>
      <ExpenseAccountEditableCell
        asset={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
        collapses={collapses}
        isRowSelected={isRowSelected}
        onSelectChange={onAutoCompleteChange}
      />
      {

        (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
          <ClassEditableCell
            asset={asset}
            hasChildren={hasChildren}
            lastChild={lastChild}
            isRowSelected={isRowSelected}
            onSelectChange={onAutoCompleteChange}
          />
        )
      }

      <TableCell className={clsx(classes.cursorPointer, tableClasses.datePickerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          hasChildren && 'Multiple'
        }
        {
          isRowSelected && !hasChildren && (
            <FactaDatePicker
              disabled={asset.prepaidSchedule.amortizationScheduleType === ScheduleType.Manual}
              selectedDate={asset.prepaidSchedule.amortizationStartDate}
              onDateChange={(newDate) => onDateChange('amortizationStartDate', asset.internalId, newDate)}
            />
          )
        }
        {
          asset.prepaidSchedule.amortizationScheduleType === ScheduleType.Manual && !isRowSelected && !hasChildren && 'M'
        }
        {
          asset.prepaidSchedule.amortizationScheduleType !== ScheduleType.Manual && !isRowSelected && !hasChildren && asset.prepaidSchedule.amortizationStartDate && format(asset.prepaidSchedule.amortizationStartDate, FULL_DAY_FORMAT)
        }
      </TableCell>
      <TableCell className={clsx(classes.cursorPointer, tableClasses.datePickerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          hasChildren && 'Multiple'
        }
        {
          isRowSelected && !hasChildren && (
            <FactaDatePicker
              disabled={asset.prepaidSchedule.amortizationScheduleType === ScheduleType.Manual}
              error={asset.prepaidSchedule.amortizationScheduleType !== ScheduleType.Manual && amortizationMinEndDate && asset.prepaidSchedule.amortizationEndDate && isBefore(asset.prepaidSchedule.amortizationEndDate, amortizationMinEndDate)}
              minDate={amortizationMinEndDate}
              selectedDate={asset.prepaidSchedule.amortizationEndDate}
              onDateChange={(newDate) => onDateChange('amortizationEndDate', asset.internalId, newDate)}
            />
          )
        }
        {
          asset.prepaidSchedule.amortizationScheduleType === ScheduleType.Manual && !isRowSelected && !hasChildren && 'M'
        }
        {
          asset.prepaidSchedule.amortizationScheduleType !== ScheduleType.Manual && !isRowSelected && !hasChildren && asset.prepaidSchedule.amortizationEndDate && format(asset.prepaidSchedule.amortizationEndDate, FULL_DAY_FORMAT)
        }
      </TableCell>
      <TableCell className={clsx(classes.cursorPointer, tableClasses.scheduleTypeCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          isRowSelected && !hasChildren && (
            <SelectBox
              disableUnderline
              className={clsx(tableClasses.scheduleTypeSelectBox, tableClasses.inputBox)}
              value={asset.prepaidSchedule.amortizationScheduleType}
              onChange={onSelectChange('amortizationScheduleType', asset.internalId)}
            >
              <MenuItem value="DAILY">Daily</MenuItem>
              <MenuItem value="MONTHLY">Monthly</MenuItem>
              <MenuItem value="MANUAL">Manual</MenuItem>
            </SelectBox>
          )
        }
        {
          !isRowSelected && !hasChildren && asset?.prepaidSchedule.amortizationScheduleType?.toLocaleLowerCase()
        }
      </TableCell>
      <TableCell
        className={clsx(classes.cursorPointer, tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {!isRowSelected && currencyFormatter.format(asset?.startingBalance!)}
        {isRowSelected && !asset.parentId && currencyFormatter.format(asset?.startingBalance!)}
        {isRowSelected && asset.parentId
                && (
                  <InputBox
                    fullWidth
                    type="number"
                    error={Boolean(totalBalance > parentAsset?.startingBalance)}
                    readOnly={hasChildren}
                    inputProps={{ style: { textAlign: 'right' } }}
                    value={asset.startingBalance}
                    className={tableClasses.inputBox}
                    onChange={onInputBoxChange('startingBalance', asset.internalId)}
                  />
                )}
      </TableCell>
      <TableCell
        className={clsx(classes.cursorPointer, tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {hasChildren && currencyFormatter.format(amortizationToDateBalance ?? 0)}
        {!hasChildren && currencyFormatter.format(asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0)}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {hasChildren && currencyFormatter.format(totalBalance - amortizationToDateBalance)}
        {!hasChildren && currencyFormatter.format(asset?.startingBalance - (asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0))}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.actionCellWithSave, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {
          asset.parentId && (
            <IconButton
              className={tableClasses.actionCellButtonPadding}
              onClick={removeAsset(asset.internalId)}
            >
              <DeleteIcon fontSize="small" className={tableClasses.fontSize13} />
            </IconButton>
          )
        }
        {!asset.parentId && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >

            <IconButton
              disabled={hasChildren}
              className={clsx(tableClasses.actionCellButtonPadding, {
                [tableClasses.iconDisabled]: hasChildren,
              })}
              onClick={addSplit(asset.internalId)}
            >
              <SplitIcon className={tableClasses.fontSize13} />
            </IconButton>
            <PrimaryButton
              className={classes.saveButton}
              variant="contained"
              color="primary"
              disabled={!isSaveEnabled(asset)}
              onClick={onSave(asset)}
            >
              Save
            </PrimaryButton>
            {asset?.source === QUICKBOOK_SOURCE && <RefreshData subledgerId={subledgerId} assetId={assetId} alertDialog dialogContent={'Are you sure you want to refresh the asset? \n If you press "Yes" now, ALL your changes will be lost!'} />}
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(HistoricalEditableRow);
