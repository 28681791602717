import { Route, useParams } from 'react-router-dom';
import React from 'react';
import { useLocation } from 'react-router';
import { format, lastDayOfMonth } from 'date-fns';
import useFetch from 'use-http';
import Schedule from './Scheduler';
import PrepareJE from './PrepareJE';
import JournalEntry from './PostJE';
import { DAY_SHORT_FORMAT } from '../../variables/constants';
import Loader from '../../components/Loader/loader';
import Layout from '../../components/Layout';
import { useAccountProvider } from '../../core/accountContext';
import ErrorPage from '../../components/ErrorPage';
import { useRefreshDataProvider } from '../../core/dataRefreshContext';

const SchedulerWizard = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const scheduleDate = useQuery().get('scheduleDate') ?? format(lastDayOfMonth(new Date()), DAY_SHORT_FORMAT);
  const { id: subledgerId } = useParams();
  const { account } = useAccountProvider();
  const { refreshDate } = useRefreshDataProvider();
  const { data: accountBalance, loading, error } = useFetch(`/subledgers/${subledgerId}/account/balance?selectedDate=${scheduleDate}`, [scheduleDate, account?.lastSync, refreshDate]);
  if (loading && !accountBalance) {
    return (
      <Layout title="loading...   ">
        <Loader open />
      </Layout>
    );
  }
  if (error) {
    return (
      <Layout title="Error">
        <ErrorPage />
      </Layout>
    );
  }
  return (
    <>
      <Loader open={loading} />
      <Route path="/subledgers/schedule/:id" exact>
        <Schedule
          accountBalance={accountBalance}
          scheduleDate={scheduleDate}
        />
      </Route>
      <Route path="/subledgers/schedule/:id/prepare-je" exact>
        <PrepareJE
          accountBalance={accountBalance}
          scheduleDate={scheduleDate}
        />
      </Route>
      <Route path="/subledgers/schedule/:id/journal-entry" exact>
        <JournalEntry
          scheduleDate={scheduleDate}
        />
      </Route>
    </>
  );
};

export default SchedulerWizard;
