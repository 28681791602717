import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, createStyles, TextField, Tooltip } from '@material-ui/core';
import useFetch from 'use-http';

import { format, isAfter, isWithinInterval, lastDayOfMonth, parse, parseISO, startOfMonth } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { AccountLabel, AccountTop } from '../../../components/Subledger/Subledger.styled';
import Loader from '../../../components/Loader/loader';
import COLORS from '../../../variables/colors';
import { NotifierType, Subledger } from '../../../variables/types';
import { DAY_SHORT_FORMAT, QUICKBOOK_ERRORS } from '../../../variables/constants';
import FactaDatePicker from '../../../components/DatePicker';
import InputBox from '../../../components/InputBox';
import { openSnackbar } from '../../../components/Notifier';
import isEmpty from '../../../util/isEmpty';
import { PrimaryButton } from '../../../components/Buttons';
import { fromUTC, toUTC } from '../../../util/timezone';
import DialogBox from '../../../components/DialogBox';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';
import { useAccountProvider } from '../../../core/accountContext';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  periodBox: {
    width: 100,
    marginRight: 20,
  },
  subledgerBalance: {
    color: COLORS.violet,
  },
  datepicker: {
    background: COLORS.white,
    borderRadius: 2,
    width: 120,
    maxWidth: 120,
    '& input': {
      background: COLORS.white,
    },
  },
  fullWidth: {
    width: '100%',
  },
  whiteFont: {
    color: COLORS.white,
  },
  inputBox: {
    backgroundColor: COLORS.white,
    boxShadow: COLORS.selectBoxShadow,
    borderRadius: 2,
    border: 'none',
    height: 32,
    '&> .MuiSelect-select:focus': {
      backgroundColor: COLORS.white,
    },
  },
  currentPeriod: {
    background: COLORS.deepGray,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 0,
    color: COLORS.white,
    paddingRight: 10,
    paddingLeft: 10,
    position: 'absolute',
    left: '50%',
    marginTop: -8,
    '& p': {
      fontSize: 13,
      marginTop: 5,
      marginBottom: 5,
      '& span': {
        fontWeight: 'bold',
        marginLeft: 5,
      },
    },
  },
  jeNumber: {
    '& input': {
      paddingTop: 7.5,
      paddingBottom: 7.5,
    },
  },
  jeDescription: {
    background: COLORS.lightGray3,
    margin: 0,
    width: 400,
    '& p': {
      position: 'absolute',
      top: -25,
      right: 0,
    },
    '& input': {
      paddingTop: 14.5,
      paddingBottom: 14.5,
      '&:focus': {
        boxShadow: COLORS.focusBoxShadow,
      },
    },
    '& fieldset': {
      border: 0,
    },
  },
  margin0: {
    margin: 0,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 8,
  },
}));

interface postJEResponseError {
  message: string;
  detail: string;
  code: string;
}

interface postJEResponse {
  errors: postJEResponseError[];
}

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  currentMonth: string;
}
const PostJEHeader = ({
  subledger,
  currentMonth,
  scheduleDate,
}: Props) => {
  const { loading, post, response } = useFetch();
  const { account } = useAccountProvider();
  const history = useHistory();
  const { id: subledgerId } = useParams();
  const classes = useStyles();
  const CHARACTER_LIMIT = 140;
  const [values, setValues] = useState(`${currentMonth} prepaid expense amortization`);
  const [jeNumber, setJeNumber] = useState('');
  const [selectedDate, handleDateChange] = useState(new Date(scheduleDate));
  const [openDialog, setOpenDialog] = useState(false);

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const postJE = async () => {
    try {
      // @ts-ignore
      await post('/subledgers/journal-entry', { subledgerID: subledgerId, scheduleDate, jeNumber, description: values, postingDate: toUTC(selectedDate) });
      if (response.ok) {
        history.push(`/?scheduleDate=${scheduleDate}`);
      } else {
        let snackbarMessages: string[] = [];
        response.data.errors.forEach((err: postJEResponseError) => {
          if (err.code === QUICKBOOK_ERRORS.BOOK_CLOSE_DATE && account?.bookCloseDate) {
            setOpenDialog(true);
          } else {
            snackbarMessages = [err.detail, ...snackbarMessages];
          }
        });
        if (snackbarMessages.length > 0) {
          openSnackbar({ message: snackbarMessages.join(', ') }, NotifierType.Error);
        }
      }
    } catch (err) {
      openSnackbar({ message: response.data ?? 'Please try later' }, NotifierType.Error);
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const navigateToPrepareJE = async () => {
    history.push(`/subledgers/schedule/${subledger.id}/prepare-je/?scheduleDate=${scheduleDate}`);
  };

  const changeDate = (date: Date | ((prevState: Date) => Date) | null) => {
    // @ts-ignore
    handleDateChange(date);
  };

  // @ts-ignore
  const factaEndDate = subledger?.factaDate?.endDate ? fromUTC(subledger?.factaDate?.endDate) : null;
  // @ts-ignore
  const factaStartDate = subledger?.factaDate?.startDate ? fromUTC(subledger?.factaDate?.startDate) : null;
  const currentAccountClosingDate = parse(scheduleDate, DAY_SHORT_FORMAT, new Date());
  const postJEEnabled = jeNumber
      && (!factaEndDate || (
        factaEndDate
          && factaStartDate
          && isAfter(factaEndDate, factaStartDate)
          && isWithinInterval(currentAccountClosingDate, { start: factaStartDate, end: factaEndDate })));
  // @ts-ignore

  let tooltip = '';
  if (!jeNumber) {
    tooltip = 'Please add the JE Number before you post your journal entry';
  } else {
    tooltip = 'Previous periods are not closed. Please close previous periods before posting JE';
  }

  return (
    <>
      <Loader open={loading} />
      {
        account?.bookCloseDate && (
          <DialogBox
            openDialog={openDialog}
            closeDialog={closeDialog}
            dialogContext={`QuickBooks is locked as of ${format(parseISO(account?.bookCloseDate), 'LLLL dd, yyyy')}. \n You cannot edit a locked period. \n Please unlock QuickBooks in order to post changes.`}
            dialogTitle="Alert"
            dismissContext="Dismiss"
          />
        )
      }
      <div className={classes.currentPeriod}>
        <p>
          Current Period:
          <span>{format(currentAccountClosingDate, 'MMM 20yy')}</span>
        </p>
      </div>
      <img
        src="/postJENav.svg"
        className={classes.navigation}
      />
      <AccountTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderRight="1px solid #CFD3DA"
          paddingLeft="38px"
          paddingRight="38px"
          height="70px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            marginTop="10px"
            height="70px"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
          height="70px"
        >
          <AccountLabel>
            JE Number:
          </AccountLabel>
          <InputBox
            fullWidth
            className={classes.inputBox}
            onChange={(e) => setJeNumber(e.target.value)}
            value={jeNumber}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          borderRight="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
          height="70px"
        >

          <AccountLabel>
            Posting Date
          </AccountLabel>
          <FactaDatePicker
            selectedDate={selectedDate}
            onDateChange={(date) => changeDate(date)}
            className={classes.datepicker}
            minDate={startOfMonth(new Date(scheduleDate))}
            maxDate={lastDayOfMonth(new Date(scheduleDate))}

          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          paddingLeft="19px"
          width="400px"
          height="70px"
        >
          <AccountLabel>
            JE Description
          </AccountLabel>
          <TextField
            inputProps={{
              maxlength: CHARACTER_LIMIT,
            }}
            value={values}
            helperText={`${values.length}/${CHARACTER_LIMIT}`}
            margin="normal"
            variant="outlined"
            className={classes.jeDescription}
            onChange={(e) => setValues(e.target.value)}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          height="100%"
          paddingLeft="19px"
          marginLeft="20px"
          marginTop="18px"
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ChevronLeftIcon />}
            onClick={navigateToPrepareJE}
          >
            PREPARE JE
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          paddingLeft="19px"
          width="15%"
          marginTop="18px"
        >
          {
            !postJEEnabled && (
              <Tooltip
                title={tooltip}
                arrow
                placement="top"
              >
                <Box>
                  <PrimaryButton
                    variant="contained"
                    color="primary"
                    endIcon={<ChevronRightIcon />}
                    onClick={postJE}
                    disabled
                  >
                    POST JE
                  </PrimaryButton>
                </Box>
              </Tooltip>

            )
          }
          {
            postJEEnabled && (
              <Box>
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  onClick={postJE}
                  disabled={!jeNumber}
                >
                  POST JE
                </PrimaryButton>
              </Box>
            )
          }
        </Box>
      </AccountTop>
    </>
  );
};

export default PostJEHeader;
