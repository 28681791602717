import Box from '@material-ui/core/Box';
import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import currencyFormatter from '../../../util/currencyFormatter';
import { CurrencyLabel, ActionButton } from '../Dashboard.styled';
import cardStyles from './cardStyles';

interface Props {
  name: string;
  amount: number;
  action: () => void;
  onCurrencyNavigation?: () => void;
  enableCurrencyNavigation?: boolean
}

const TaskActionDetail = ({
  name,
  amount,
  action,
  onCurrencyNavigation,
  enableCurrencyNavigation = false,
}: Props) => {
  const classes = cardStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      padding="5px"
      margin="0px !important"
    >
      <ActionButton
        variant="contained"
        color="primary"
        className={classes.pendingTasks}
        onClick={action}
      >
        { name }
      </ActionButton>
      <ButtonBase
        disabled={!enableCurrencyNavigation}
        onClick={onCurrencyNavigation}
        className={clsx({
          [classes.navigatePrepaid]: enableCurrencyNavigation,
        })}
      >
        <CurrencyLabel className={classes.schedule}>
          {currencyFormatter.format(amount)}
        </CurrencyLabel>
      </ButtonBase>
    </Box>
  );
};

export default React.memo(TaskActionDetail);
