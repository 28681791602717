import { TableCell, TableRow } from '@material-ui/core';
import React, { memo } from 'react';
import { getAccountName } from './common';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';
import { useAccountProvider } from '../../core/accountContext';
import { Account } from '../../variables/types';

interface Props {
  rowsCount: number;
  currentMonth: string;
  totalPrepaidAccount: number;
  account: Account;
}

const PostJEPrepaidAccountRow = ({ rowsCount, currentMonth, totalPrepaidAccount, account }: Props) => {
  const tableClasses = useTableStyles();
  const { account: { classTrackingPerTxnLine, classTrackingPerTxn } } = useAccountProvider();
  return (
    <TableRow>
      <TableCell className={tableClasses.assetCell}>
        {rowsCount + 1}
      </TableCell>
      <TableCell />
      <TableCell className={tableClasses.descriptionCell}>
        {`${currentMonth} Prepaid Amortization`}
      </TableCell>
      <TableCell />
      <TableCell className={tableClasses.expenseAccountCell}>
        {`${getAccountName(account)}`}
      </TableCell>
      {
        (classTrackingPerTxnLine || classTrackingPerTxn) && (<TableCell />)
      }
      <TableCell
        className={tableClasses.assetAmountCell}
      >
        {totalPrepaidAccount < 0 ? currencyFormatter.format(Math.abs(totalPrepaidAccount)) : '-'}
      </TableCell>
      <TableCell className={tableClasses.assetAmountCell}>
        {totalPrepaidAccount > 0 ? currencyFormatter.format(Math.abs(totalPrepaidAccount)) : '-'}
      </TableCell>
    </TableRow>
  );
};

export default memo(PostJEPrepaidAccountRow);
