import React from 'react';
import Table from '@material-ui/core/Table';
import {
  Box, createStyles, Link,
  TableCell, TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { useParams } from 'react-router';

import makeStyles from '@material-ui/styles/makeStyles';
import useFetch from 'use-http';
import clsx from 'clsx';
import JournalEntryViewHeader from './JournalEntryViewHeader';
import COLORS from '../../variables/colors';
import ErrorPage from '../../components/ErrorPage';
import { ErrorPageType } from '../../variables/types';
import Loader from '../../components/Loader/loader';
import {
  getAccountNameFromList,
  getClassName, getVendorName,
} from '../../components/Subledger/common';
import { useAccountProvider } from '../../core/accountContext';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from '../../components/Subledger/Subledger.styled';
import getQuickbookLink from '../../util/getQuickbookLink';

const useStyles = makeStyles(() => createStyles({
  root: {
    '& td': {
      color: COLORS.deepGray,
    },
  },
  fontSize13: {
    fontSize: 13,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  editIcon: {
    height: 12,
    width: 12,
    marginLeft: 5,
  },
  colorBlue: {
    color: `${COLORS.skyBlue} !important`,
  },
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
  tableFooter: {
    background: COLORS.lightGray3,
  },
  totalAmount: {
    color: COLORS.deepGray,
    fontWeight: 'bold',
    fontSize: 13,
  },
}));

const JournalEntryView = () => {
  const classes = useStyles();
  const { id: jeID } = useParams();
  const { data, loading, error } = useFetch(`/journal-entries/${jeID}`, []);
  const { account } = useAccountProvider();
  const { vendors, accountIncomes, accountClasses } = account;
  const tableClasses = useTableStyles();

  if (error) {
    return (
      <ErrorPage variant={ErrorPageType.Error} />
    );
  }

  if (loading) {
    return <Loader open />;
  }

  const renderRow = (journalEntryLineItem: any, index:number) => {
    const quickbookLink = getQuickbookLink(journalEntryLineItem?.assetId, journalEntryLineItem?.assetType);
    return (
      <TableRow hover>
        <TableCell className={tableClasses.assetCell}>
          {index + 1}
        </TableCell>
        <TableCell className={tableClasses.assetCell}>
          {(!journalEntryLineItem?.assetType || !quickbookLink) && journalEntryLineItem?.assetId}
          {quickbookLink && (<Link href={quickbookLink} target="_blank">{journalEntryLineItem?.assetId}</Link>)}
        </TableCell>
        <TableCell className={tableClasses.descriptionCell}>
          {journalEntryLineItem?.description}
        </TableCell>
        <TableCell className={tableClasses.customerCell}>
          {getVendorName(journalEntryLineItem?.vendorId, vendors)}
        </TableCell>
        <TableCell className={tableClasses.expenseAccountCell}>
          {
            getAccountNameFromList(journalEntryLineItem?.accountId, accountIncomes)
          }
        </TableCell>
        <TableCell className={tableClasses.classesCell}>
          {getClassName(journalEntryLineItem?.classId, accountClasses)}
        </TableCell>
        <TableCell className={tableClasses.prepaidAssetAmount}>
          {journalEntryLineItem?.postingType === 'Debit' ? currencyFormatter.format(Math.abs(Number(journalEntryLineItem?.amount))) : '-'}
        </TableCell>
        <TableCell className={tableClasses.prepaidAssetAmount}>
          {journalEntryLineItem?.postingType !== 'Debit' ? currencyFormatter.format(Math.abs(Number(journalEntryLineItem?.amount))) : '-'}
        </TableCell>
      </TableRow>
    );
  };
  // @ts-ignore
  const renderAsset = () => (
    <>
      {
          data?.journalEntryLineItems.map((JournalEntryLineItem: any, index: number) => renderRow(JournalEntryLineItem, index))
      }
    </>
  );

  return (
    <>
      <Box
        width="100%"
      >
        <JournalEntryViewHeader journalEntry={data} />
        <Box padding={1}>
          <Table
            size="small"
            id="journalEntryTable"
          >
            <TableHead>
              <TableRow
                hover={false}
              >
                <TableCell className={tableClasses.assetCell}>
                  JE LINE
                </TableCell>
                <TableCell className={tableClasses.assetCell}>
                  PREPAID ID
                </TableCell>
                <TableCell className={tableClasses.descriptionCell}>
                  DESCRIPTION
                </TableCell>
                <TableCell className={tableClasses.customerCell}>
                  VENDOR
                </TableCell>
                <TableCell className={tableClasses.expenseAccountCell}>
                  EXPENSE ACCOUNT
                </TableCell>
                <TableCell className={tableClasses.classesCell}>
                  CLASS
                </TableCell>
                <TableCell className={tableClasses.prepaidAssetAmount}>
                  DR
                </TableCell>
                <TableCell className={tableClasses.prepaidAssetAmount}>
                  CR
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                renderAsset()
              }
            </TableBody>
            <TableFooter className={classes.tableFooter}>
              <TableCell
                colSpan={6}
                align="right"
                className={classes.totalAmount}
              >
                Total
              </TableCell>
              <TableCell className={clsx(tableClasses.prepaidAssetAmount, classes.totalAmount)}>
                {currencyFormatter.format(Math.abs(data?.amount))}
              </TableCell>
              <TableCell className={clsx(tableClasses.prepaidAssetAmount, classes.totalAmount)}>
                {currencyFormatter.format(Math.abs(data?.amount))}
              </TableCell>
            </TableFooter>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default JournalEntryView;
