/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ExportIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.71436 8.14284H10.3586L9.25292 9.25284L9.85721 9.85712L12.0001 7.71427L9.85721 5.57141L9.25292 6.1757L10.3586 7.2857H4.71436V8.14284Z"
      fill={props?.fill ?? '#252729'}
    />
    <path
      d="M8.57142 5.14286V3.42858C8.57175 3.37217 8.56094 3.31626 8.53961 3.26405C8.51828 3.21183 8.48686 3.16434 8.44714 3.12429L5.44714 0.124293C5.40709 0.0845722 5.3596 0.0531471 5.30739 0.0318195C5.25517 0.0104918 5.19926 -0.000318934 5.14285 7.16326e-06H0.857142C0.629814 7.16326e-06 0.411797 0.0903129 0.251051 0.251058C0.0903058 0.411804 0 0.629821 0 0.857149V11.1429C0 11.3702 0.0903058 11.5882 0.251051 11.7489C0.411797 11.9097 0.629814 12 0.857142 12H7.71428C7.94161 12 8.15963 11.9097 8.32037 11.7489C8.48112 11.5882 8.57142 11.3702 8.57142 11.1429V10.2857H7.71428V11.1429H0.857142V0.857149H4.28571V3.42858C4.28571 3.6559 4.37602 3.87392 4.53676 4.03467C4.69751 4.19541 4.91553 4.28572 5.14285 4.28572H7.71428V5.14286H8.57142ZM5.14285 3.42858V1.03286L7.53857 3.42858H5.14285Z"
      fill={props?.fill ?? '#252729'}
    />
  </SvgIcon>
);

export default ExportIcon;
