/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AccountIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    height={20}
    width={16}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.57252 11.4294H9.2868V12.858H3.57252V11.4294Z" />
    <path d="M3.57252 7.85693H12.1439V9.2855H3.57252V7.85693Z" />
    <path d="M3.57252 15H7.14395V16.4286H3.57252V15Z" />
    <path
      d="M14.2857 2.14286H12.1429V1.42857C12.1429 1.04969 11.9923 0.686328 11.7244 0.418419C11.4565 0.15051 11.0932 0 10.7143 0H5C4.62112 0 4.25776 0.15051 3.98985 0.418419C3.72194 0.686328 3.57143 1.04969 3.57143 1.42857V2.14286H1.42857C1.04969 2.14286 0.686328 2.29337 0.418419 2.56128C0.15051 2.82919 0 3.19255 0 3.57143V18.5714C0 18.9503 0.15051 19.3137 0.418419 19.5816C0.686328 19.8495 1.04969 20 1.42857 20H14.2857C14.6646 20 15.028 19.8495 15.2959 19.5816C15.5638 19.3137 15.7143 18.9503 15.7143 18.5714V3.57143C15.7143 3.19255 15.5638 2.82919 15.2959 2.56128C15.028 2.29337 14.6646 2.14286 14.2857 2.14286ZM5 1.42857H10.7143V4.28571H5V1.42857ZM14.2857 18.5714H1.42857V3.57143H3.57143V5.71429H12.1429V3.57143H14.2857V18.5714Z"
    />
  </SvgIcon>
);

export default AccountIcon;
