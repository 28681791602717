import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'use-http';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './core/sentry';
import theme from './variables/theme';
import Notifier from './components/Notifier';
import AppRouter from './pages/AppRouter';
import GlobalOptions from './core/apiClient';
import AccountContext from './core/accountContext';
import NavBarContext from './core/navbarContext';
import RefreshDataContext from './core/dataRefreshContext';

const App = () => (
  <Provider url={process.env.REACT_APP_API_URL} options={GlobalOptions}>
    <AccountContext>
      <RefreshDataContext>
        <NavBarContext open={localStorage.getItem('navBarOpen') !== 'false'}>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Notifier />
              <AppRouter />
            </ThemeProvider>
          </LocalizationProvider>
        </NavBarContext>
      </RefreshDataContext>
    </AccountContext>
  </Provider>
);

export default App;
