import React from 'react';
import {
  StyledFormControl,
  StyledFormHelperText,
  StyledInputLabel,
  StyledInputLabelRequired,
} from './FormControl.styled';
import InputBox from '../InputBox';

interface Props {
  title: string;
  helperText: string;
  hasError?: boolean;
  type?: string;
  name?: string;
  inputRef?: React.Ref<any>;
  require?: boolean;
  defaultValue?: string;
}

const FormInputBox = ({
  title,
  helperText,
  hasError,
  type,
  name,
  inputRef,
  require,
  defaultValue,
}: Props) => (
  <StyledFormControl>
    <StyledInputLabel>
      {
        require && (<StyledInputLabelRequired>*</StyledInputLabelRequired>)
      }
      {title}
    </StyledInputLabel>
    <InputBox
      fullWidth
      type={type}
      name={name}
      inputRef={inputRef}
      error={hasError}
      defaultValue={defaultValue}
    />
    {
      hasError && <StyledFormHelperText>{helperText}</StyledFormHelperText>
    }
  </StyledFormControl>
);

export default FormInputBox;
