export const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';
export const SUBLEDGER_STORAGE_KEY = 'SUBLEDGER_STORAGE_KEY';
export const PREPAID_EXPENSES = 'PrepaidExpenses';

export const ASSET_CELL = 80;
export const DESCRIPTION_CELL = 230;
export const CUSTOMER_CELL = 180;
export const EXPENSE_ACCOUNT_CELL = 235;
export const CLASSES_CELL = 127;
export const REMAINING_BALANCE = 100;
export const DATEPICKER_CELL = 140;
export const SCHEDULE_TYPE_CELL = 95;
export const AMORTIZAION_ROW_CELL = 100;
export const CURRENCY_CELL = 100;
export const AMORTIZATION_ROW_CELL = 120;
export const ACTION_CELL = 70;
export const ACTION_CELL_WITH_SAVE = 150;
export const PREPAID_AMOUNT = 200;

export const FINALIZED_SUBLEDGER_STATUS = 'FINALIZED';
export const ASSET_SCHEDULED_STATUS = 'SCHEDULED';
export const ASSET_TEMP_SCHEDULED_STATUS = 'TEMP_SCHEDULED';
export const AMORTIZATION_POSTED = 'POSTED';

export const CREATE_SUBLEDGER_COLUMNS = {
  DESCRIPTION: 'DESCRIPTION',
  VENDOR: 'VENDOR',
  EXPENSES_ACCOUNT: 'EXPENSE ACCOUNT',
  CLASS: 'CLASS',
  FACTA_STARTING_BALANCE: 'FACTA STARTING BALANCE',
  AMORTIZATION_START_DATE: 'AMORTIZATION START DATE',
  AMORTIZATION_END_DATE: 'AMORTIZATION END DATE',
  AMORT_SCHEDULE: 'AMORTIZATION SCHEDULE',
  JE_DATE: 'JE DATE',
  GL_JE_ID: 'GL JE ID',
  AMOUNT: 'AMOUNT',
  EXPENSE_TO_DATE: 'EXPENSE TO DATE',
  REMAINING_BALANCE: 'REMAINING BALANCE',
};

export const FACTA_SOURCE = 'FACTA';
export const QUICKBOOK_SOURCE = 'QUICKBOOK';

export const DAY_SHORT_FORMAT = 'L/d/yy';
export const FULL_DAY_FORMAT = 'LL/dd/yyyy';
export const CSV_IMPORT_DAY_FORMAT = 'L/d/yyyy';
export const POSTING_DATE_FORMAT = 'yyyy-LL-dd';
export const MONTH_SHORT_FORMAT = 'MMM yyyy';
export const FULL_DATE_FORMAT = 'MM/dd/yyyy';
export const MONTH_SHOW_FORMAT = 'MMM';

export const VISITED_TIME = 100;

export const DIGIT_UPPER_LIMIT = 100000000000;
export const DIGIT_LOWER_LIMIT = -100000000000;

export const DEBIT = 'Debit';
export const CREDIT = 'Credit';
export const INPUT_CHANGE = 'INPUT_CHANGE';
export const SELECT_CHANGE = 'SELECT_CHANGE';
export const DATE_CHANGE = 'DATE_CHANGE';
export const UNSCHEDULED_DATE_CHANGE = 'UNSCHEDULED_DATE_CHANGE';
export const UNSCHEDULED_SELECT_CHANGE = 'UNSCHEDULED_SELECT_CHANGE';
export const UNSCHEDULED_INPUT_CHANGE = 'UNSCHEDULED_INPUT_CHANGE';
export const PREPARE_JE_UPDATE_ASSETS = 'PREPARE_JE_UPDATE_ASSETS';
export const ADD_ASSET = 'ADD_ASSET';
export const REMOVE_ASSET = 'REMOVE_ASSET';
export const UNSCHEDULED_REMOVE_ASSET = 'UNSCHEDULED_REMOVE_ASSET';
export const ADD_SPLIT = 'ADD_SPLIT';
export const ADD_SPLIT_ASSET = 'ADD_SPLIT_ASSET';
export const IMPORT_CSV = 'IMPORT_CSV';
export const SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE = 'SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE';
export const SUBLEDGER_START_DATE_CHANGE = 'SUBLEDGER_START_DATE_CHANGE';
export const INITIALED = 'INITIALED';
export const INITIALED_WITHOUT_MODIFICATION = 'INITIALED_WITHOUT_MODIFICATION';
export const INITIALED_WITH_LOCAL_STORAGE = 'INITIALED_WITH_LOCAL_STORAGE';
export const INITIALED_FOR_PREPARE_JE = 'INITIALED_FOR_PREPARE_JE';
export const SELECT_ROW = 'SELECT_ROW';
export const HOVER_ROW = 'HOVER_ROW';
export const CALCULATE_ASSET = 'CALCULATE_ASSET';
export const HISTORICAL_UPDATE_ASSET = 'HISTORICAL_UPDATE_ASSET';
export const HISTORICAL_MANUAL_ASSET = 'HISTORICAL_MANUAL_ASSET';
export const UPDATE_ASSET = 'UPDATE_ASSET';

export const QUICKBOOK_ERRORS = {
  BOOK_CLOSE_DATE: '6200',
};

export const MIN_SYSTEM_DATE = '1970-01-01';
export const FREEZE_PANEL_Z_INDEX = 100;
