import React from 'react';
import Box from '@material-ui/core/Box';
import { Tabs, createStyles } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/styles/makeStyles';
import { Redirect, Switch, useHistory, useParams } from 'react-router';
import { Route } from 'react-router-dom';
import Layout from '../../components/Layout';
import COLORS from '../../variables/colors';
import BalanceSheet from './BalanceSheet';
import IncomeStatement from './IncomeStatement';
import Classes from './Classes';
import { useAccountProvider } from '../../core/accountContext';

const useStyles = makeStyles(() => createStyles({
  tab: {
    border: 'none !important',
    transition: 'none !important',
    fontSize: 13,
    textTransform: 'none',
    height: 28,
    padding: 0,
    margin: 0,
    minHeight: 'auto !important',
  },
  selected: {
    color: `${COLORS.white} !important`,
    background: COLORS.skyBlue,
  },
  marginRight8: {
    marginRight: 8,
  },
  tabs: {
    minHeight: 'auto !important',
  },
  boxBorder: {
    borderBottom: `2px solid ${COLORS.skyBlue}`,
    paddingLeft: 36,
    transition: 'none !important',
  },
}));

interface Params {
  section?: string
}

const getIndexBySection = (section?: string) => {
  if (!section || section === 'balance') {
    return 0;
  }
  if (section === 'statement') {
    return 1;
  }
  return 2;
};

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const { section } = useParams<Params>();
  const [value, setValue] = React.useState(getIndexBySection(section));
  const { account } = useAccountProvider();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      return history.push('/account');
    }
    if (newValue === 1) {
      return history.push('/account/statement');
    }
    if (newValue === 2) {
      return history.push('/account/classes');
    }
    return null;
  };
  return (
    <Layout title="Chart of Accounts">
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        width="100%"
        className={classes.boxBorder}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="off"
          classes={{
            root: classes.tabs,
          }}
        >
          <Tab
            label="Balance Sheet"
            disableRipple
            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
          />
          <Tab
            disableRipple
            label="Income Statement"
            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
          />
          {
            (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
              <Tab
                disableRipple
                label="Classes"
                classes={{
                  root: classes.tab,
                  selected: classes.selected,
                }}
              />
            )
          }
        </Tabs>
      </Box>
      <Switch>
        <Route path="/account" component={BalanceSheet} exact />
        <Route path="/account/statement" component={IncomeStatement} exact />
        {
          (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
            <Route path="/account/classes" component={Classes} exact />
          )
        }
        {
          !(account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
            <Route path="/account/classes" exact>
              <Redirect to="/account" />
            </Route>
          )
        }
      </Switch>
    </Layout>
  );
};

export default Account;
