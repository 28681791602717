/* eslint-disable react/jsx-props-no-spreading,@typescript-eslint/no-throw-literal,no-param-reassign */
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import useFetch from 'use-http';
import {
  Box,
} from '@material-ui/core';
import {
  subDays,
  format, isAfter,
  startOfMonth,
} from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { diff } from 'deep-object-diff';

import cloneDeep from 'lodash.clonedeep';
import roundTo from 'round-to';
import Loader from '../../../components/Loader/loader';
import COLORS from '../../../variables/colors';
import {
  DAY_SHORT_FORMAT, FACTA_SOURCE,
  INITIALED,
  SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE,
} from '../../../variables/constants';
import Header from './Header';
import { PrimaryButton } from '../../../components/Buttons';
import DialogBox from '../../../components/DialogBox';
import reducer from '../../../components/Subledger/reducer';
import { fromUTC } from '../../../util/timezone';
import ErrorPage from '../../../components/ErrorPage';
import CreateSubledgerTable from '../../../components/Subledger/CreateSubledgerTable';
import {
  getFactaBalance,
  initializedSubledger,
  isCreateSubledgerFormValid,
  updateAssetsForSaving,
} from '../../../components/Subledger/common';

import { Account, NotifierType, PrepaidAsset, Subledger } from '../../../variables/types';
import isEmpty from '../../../util/isEmpty';
import { useAccountProvider } from '../../../core/accountContext';
import { openSnackbar } from '../../../components/Notifier';

const CreateSubledger = () => {
  const { id: subledgerId } = useParams();
  const history = useHistory();
  const { account } = useAccountProvider();
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);
  const [openDirtyCheckDialog, setOpenDirtyCheckDialog] = useState<boolean>(false);
  const [openFinalizeSubledgerAlert, setFinalizeSubledgerAlert] = useState<boolean>(false);
  const { get, loading: subledgerLoading, error: subLedgerError, response } = useFetch(`/subledgers/${subledgerId}`);
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { selectedRow: '', subledger: {}, hoverRow: '' });
  const { subledger, selectedRow, hoverRow } = state;
  // Will get subleger balance by last day of the month of previous month
  const lastDayOfSelectedDate = format(subDays(startOfMonth(subledger?.factaStartDate ?? new Date()), 1), DAY_SHORT_FORMAT);
  const { get: getBalanceData, loading, error: accountBalanceError, abort } = useFetch(`/subledgers/${subledgerId}/account/balance`);
  const [formSubmit, setFormSubmit] = useState<boolean>();
  const [existingSubledger, setExistingSubledger] = useState<Subledger>();
  const [accountBalance, setAccountBalance] = useState<Account | null>();
  const { patch, loading: onUpdating, response: updateResponse } = useFetch(`/subledgers/${subledgerId}/historical/subledger/assets`);

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subLedgerError) {
      const result = await get(`/details?source=${FACTA_SOURCE}&ignorePrepaidAssetsSorting=true`);
      if (response.ok) {
        const openingBalance = getFactaBalance(result?.prepaidAssets);
        dispatch({
          type: INITIALED,
          payload: {
            subledger: {
              ...result,
              openingBalance,
            },
          },
        });
        setExistingSubledger(initializedSubledger({ subledger: result }));
      }
    }
  }, [get, response.ok, subLedgerError, subledgerLoading]);

  const getAccountBalance = useCallback(async () => {
    abort();
    const result = await getBalanceData(`?selectedDate=${lastDayOfSelectedDate}`);
    if (response.ok) {
      setAccountBalance(result);
    }
  }, [abort, getBalanceData, lastDayOfSelectedDate, response.ok]);

  useEffect(() => {
    getAccountBalance();
  }, [getAccountBalance, getBalanceData, lastDayOfSelectedDate, account]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  if (!subledger || !accountBalance || isEmpty(subledger)) {
    return <Loader open />;
  }

  if (subLedgerError || accountBalanceError) {
    return <ErrorPage />;
  }

  const onSubledgerAmortizationScheduleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({ type: SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE, payload: { value: event.target.value } });
  };

  const callFinalizedSubledger = async () => {
    setFinalizeSubledgerAlert(false);
    if (!isEmpty(diff(existingSubledger?.prepaidAssets!, subledger?.prepaidAssets))) {
      try {
        const prepaidAssets = cloneDeep(subledger?.prepaidAssets)
            ?.map((prepaidAsset: PrepaidAsset) => ({
              ...prepaidAsset,
              assetCreationDate: startOfMonth(subledger?.factaStartDate!),
              source: FACTA_SOURCE,
            }));
        prepaidAssets?.filter((prepaidAsset: PrepaidAsset) => !prepaidAsset.parentId)
            .forEach((prepaidAsset: PrepaidAsset, index: number) => {
              // eslint-disable-next-line no-param-reassign
              prepaidAsset.assetId = `Open ${index + 1}`;
            });
        updateAssetsForSaving(prepaidAssets);
        await patch(prepaidAssets);
        if (updateResponse.ok) {
          history.push('/');
        } else {
          openSnackbar({ message: updateResponse.data }, NotifierType.Error);
        }
      } catch (err) {
        openSnackbar({ message: updateResponse.data }, NotifierType.Error);
      }
    } else {
      history.push('/');
    }
  };

  const showDirtyCheckDialog = () => {
    if (!isEmpty(diff(existingSubledger?.prepaidAssets!, subledger?.prepaidAssets))) {
      setOpenDirtyCheckDialog(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    history.push('/');
  };

  const onFinalizedSubledger = () => {
    const difference = roundTo(Number(accountBalance?.glBalance), 2) - roundTo(Number(subledger?.openingBalance), 2);
    if (difference === 0) {
      setFormSubmit(true);
      const valid = isCreateSubledgerFormValid(subledger, dispatch);
      if (valid) {
        setFinalizeSubledgerAlert(true);
      }
    } else {
      setOpenSubledgerDialog(true);
    }
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
    setOpenDirtyCheckDialog(false);
    setFinalizeSubledgerAlert(false);
  };

  const factaEndDate = subledger?.factaDate?.endDate ? fromUTC(subledger?.factaDate?.endDate) : null;

  return (
    <>
      <Loader open={subledgerLoading || loading || onUpdating} />
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext="The difference between your Facta subledger and your General Ledger balance must be $0.00 to
            finalize subledger."
        dialogTitle="Hint"
        dismissContext="Dismiss"
      />
      <DialogBox
        openDialog={openDirtyCheckDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page? \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{ title: 'YES', event: navigateBack }]}
      />
      <DialogBox
        openDialog={openFinalizeSubledgerAlert}
        closeDialog={closeDialog}
        dialogContext={'You are about to edit a historical period. \n Do you want to proceed?'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{ title: 'YES', event: callFinalizedSubledger }]}
      />
      <Header
        subledger={subledger}
        onAmortizationScheduleTypeChange={onSubledgerAmortizationScheduleChange}
        accountBalance={accountBalance}
      />
      <CreateSubledgerTable
        subledger={subledger}
        selectedRow={selectedRow}
        hoverRow={hoverRow}
        dispatch={dispatch}
        formSubmit={formSubmit}
      />
      <Box
        width="calc(100vw - 100px)"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        pr="100px"
      >
        <PrimaryButton
          onClick={onFinalizedSubledger}
          variant="contained"
          color="primary"
          disabled={Boolean(factaEndDate && isAfter(subledger.factaStartDate!, factaEndDate!))}
        >
          Finalize Subledger
        </PrimaryButton>
        <PrimaryButton
          onClick={showDirtyCheckDialog}
          color="primary"
          style={{ color: COLORS.skyBlue }}
        >
          Cancel
        </PrimaryButton>
      </Box>
    </>
  );
};

export default React.memo(CreateSubledger);
