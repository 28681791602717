/* eslint-disable react/jsx-props-no-spreading */
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Intercom from 'react-intercom';
import Account from './Account';
import Profile from './Profile';
import Scheduler from './Subledger';
import HistoricalEditing from './HistoricalEditing';
import JournalEntryListing from './JournalEntryListing';
import JournalEntryView from './JournalEntryView';
import Dashboard from './Dashboard';
import Layout from '../components/Layout';
import ErrorPage from '../components/ErrorPage';
import { ErrorPageType } from '../variables/types';
import { useAccountProvider } from '../core/accountContext';

const ProtectedRoutes = () => {
  const { account: { user, companyName } } = useAccountProvider();
  const userInfo = {
    user_id: user.id,
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
    phone: user.officePhone ?? user?.mobilePhone,
    Environment: process.env.REACT_APP_ENV,
    company: companyName,
  };
  return (
    <>
      <Switch>
        <Route path="/account/:section?" component={Account} exact />
        <Route path="/profile" component={Profile} exact />
        <Route path="/subledgers" component={Scheduler} />
        <Route path="/historical" component={HistoricalEditing} />
        <Route path="/journal-entries" component={JournalEntryListing} exact />
        <Route path="/journal-entries/:id" component={JournalEntryView} exact />
        <Route path="/" component={Dashboard} exact />
        <Route path="*">
          <Layout title="404">
            <ErrorPage variant={ErrorPageType.NotFound} />
          </Layout>
        </Route>
      </Switch>
      <Intercom appID="wzlagc2y" {...userInfo} />
    </>
  );
};

export default ProtectedRoutes;
