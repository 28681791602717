/* eslint-disable indent */
import { addHours, endOfMonth, isValid, startOfDay, startOfMonth } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import roundTo from 'round-to';
import cloneDeep from 'lodash.clonedeep';
import { PrepaidAsset, ScheduleType, Subledger } from '../../variables/types';
import {
  calculateAsset,
  calculateAssetForUnScheduledAssets,
  calculateScheduleAsset,
  defaultAsset,
  defaultAssetFromLocalStorage,
  getAssetSum,
  getChildren,
  getStartingBalanceOfParent,
  initializedSubledger,
  initializedSubledgerFromLocalStorage,
  initialPrepaidAssetForPrepareJE,
} from './common';
import {
  ADD_ASSET,
  ADD_SPLIT,
  ADD_SPLIT_ASSET,
  ASSET_TEMP_SCHEDULED_STATUS,
  CALCULATE_ASSET,
  DATE_CHANGE,
  DIGIT_LOWER_LIMIT,
  DIGIT_UPPER_LIMIT,
  HISTORICAL_MANUAL_ASSET,
  HISTORICAL_UPDATE_ASSET,
  HOVER_ROW,
  IMPORT_CSV,
  INITIALED,
  INITIALED_FOR_PREPARE_JE,
  INITIALED_WITH_LOCAL_STORAGE,
  INITIALED_WITHOUT_MODIFICATION,
  INPUT_CHANGE,
  PREPARE_JE_UPDATE_ASSETS,
  REMOVE_ASSET,
  SELECT_CHANGE,
  SELECT_ROW,
  SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE,
  SUBLEDGER_START_DATE_CHANGE,
  UNSCHEDULED_DATE_CHANGE,
  UNSCHEDULED_INPUT_CHANGE,
  UNSCHEDULED_REMOVE_ASSET,
  UNSCHEDULED_SELECT_CHANGE,
  UPDATE_ASSET,
} from '../../variables/constants';

interface Payload {
  internalId: string;
  propertyName: string;
  value: any;
  subledger: Subledger;
  selectedRow: string;
  hoverRow: string;
  scheduleDate: string;
  assets: Array<PrepaidAsset>;
  balance: number,
  historicalUpdatedAssets: Array<PrepaidAsset>;
}

interface Action {
  type: string;
  payload: Payload
}

interface State {
  subledger: Subledger;
  selectedRow: string;
  hoverRow: string;
  scheduleDate: string;
  historicalUpdatedAssets: Array<PrepaidAsset>;
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case INITIALED_WITHOUT_MODIFICATION:
    {
      const { subledger } = action.payload;
      return {
        ...state,
        subledger,
      };
    }
    case INITIALED:
    {
      const subledger = initializedSubledger(action.payload);
      return {
        subledger,
        historicalUpdatedAssets: action.payload.historicalUpdatedAssets,
      };
    }
    case INITIALED_FOR_PREPARE_JE:
    {
      const { scheduleDate } = action.payload;
      const subledger = initializedSubledger(action.payload);
      const serviceAssets = initialPrepaidAssetForPrepareJE(subledger, scheduleDate);

      // @ts-ignore
      return {
        subledger: {
          ...subledger,
          prepaidAssets: serviceAssets,
        },
      };
    }
    case INITIALED_WITH_LOCAL_STORAGE:
    {
      const { subledger: localSubledger, historicalUpdatedAssets: localHistoricalPrepaidAssets, scheduleDate } = action.payload;
      const historicalUpdatedAssets = localHistoricalPrepaidAssets?.map(defaultAssetFromLocalStorage) ?? [];
      // @ts-ignore
      const subledger = initializedSubledgerFromLocalStorage({ subledger: localSubledger });
      const serviceAssets = initialPrepaidAssetForPrepareJE(subledger, scheduleDate);

      // @ts-ignore
      return {
        subledger: {
          ...subledger,
          prepaidAssets: serviceAssets,
        },
        historicalUpdatedAssets,
      };
    }
    case PREPARE_JE_UPDATE_ASSETS:
    {
      const { assets } = action.payload;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: assets,
        },
      };
    }
    case SELECT_ROW:
    {
      if (state.selectedRow === action.payload.selectedRow) {
        return state;
      }
      return {
        ...state,
        selectedRow: action.payload.selectedRow,
      };
    }
    case HOVER_ROW:
    {
      if (state.hoverRow === action.payload.hoverRow) {
        return state;
      }
      return {
        ...state,
        hoverRow: action.payload.hoverRow,
      };
    }
    case SUBLEDGER_START_DATE_CHANGE:
    {
      const { value } : Payload = action.payload;
      return {
        ...state,
        subledger: {
          ...state?.subledger,
          factaStartDate: startOfMonth(value!),
        },
      };
    }
    case SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE:
    {
      const { value } : Payload = action.payload;
      return {
        ...state,
        subledger: {
          ...state?.subledger,
          account: {
            ...state.subledger?.account,
            scheduleType: value,
          },
        },
      };
    }
    case INPUT_CHANGE:
    {
      const { internalId, propertyName, value } : Payload = action.payload;
      const newAssets = [...state?.subledger?.prepaidAssets];
      const index = newAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === internalId);
      if (propertyName === 'startingBalance') {
        if (Number.isNaN(Number(value)) || Number(value) >= DIGIT_UPPER_LIMIT || Number(value) <= DIGIT_LOWER_LIMIT) {
          return state;
        }
        // @ts-ignore
        newAssets[index][propertyName] = value;
        newAssets[index] = calculateScheduleAsset(state?.subledger, newAssets[index]);

        if (newAssets[index].parentId) {
          const parentAssetIndex = newAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === newAssets[index].parentId);
          // eslint-disable-next-line no-underscore-dangle
          // @ts-ignore
          newAssets[parentAssetIndex].startingBalance = getStartingBalanceOfParent(state?.subledger, newAssets[parentAssetIndex].internalId).toString();
        }
        return {
          ...state,
          subledger: {
            ...state.subledger,
            openingBalance: getAssetSum(state?.subledger, newAssets),
            prepaidAssets: newAssets,
          },
        };
      }

      // @ts-ignore
      newAssets[index][propertyName] = value;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case UNSCHEDULED_INPUT_CHANGE:
    {
      const { internalId, propertyName, value } : Payload = action.payload;
      const newAssets = [...state?.subledger?.prepaidAssets];
      const index = newAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === internalId);
      if (propertyName === 'startingBalance') {
        if (Number.isNaN(Number(value)) || Number(value) >= DIGIT_UPPER_LIMIT || Number(value) <= DIGIT_LOWER_LIMIT) {
          return state;
        }
        newAssets[index][propertyName] = value;
        calculateAssetForUnScheduledAssets(newAssets, index, state?.subledger);
        return {
          ...state,
          subledger: {
            ...state.subledger,
            prepaidAssets: newAssets,
          },
        };
      }

      // @ts-ignore
      newAssets[index][propertyName] = value;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case SELECT_CHANGE:
    {
      const { internalId, propertyName, value } : Payload = action.payload;
      const newAssets = [...state?.subledger.prepaidAssets] as Array<PrepaidAsset>;
      const index = newAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === internalId);
      if (propertyName === 'amortizationScheduleType') {
        newAssets[index].prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = null;
        if (newAssets[index]?.prepaidSchedule?.[propertyName] === ScheduleType.Manual && value !== ScheduleType.Manual) {
          newAssets[index].prepaidSchedule.amortizationEndDate = new Date();
          newAssets[index].prepaidSchedule.amortizationStartDate = state?.subledger?.factaStartDate;
        }
        // @ts-ignore
        newAssets[index].prepaidSchedule[propertyName] = value;
        if (newAssets[index]?.prepaidSchedule?.[propertyName] !== ScheduleType.Manual) {
          newAssets[index] = calculateScheduleAsset(state?.subledger, newAssets[index]);
        } else {
          newAssets[index].prepaidSchedule.amortizationEndDate = null;
          newAssets[index].prepaidSchedule.amortizationStartDate = null;
          newAssets[index].prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = null;
        }
      } else if (['classId', 'expenseAccountId'].includes(propertyName)) {
        // @ts-ignore
        newAssets[index].prepaidSchedule[propertyName] = value;
      } else {
        // @ts-ignore
        newAssets[index][propertyName] = value;
      }
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case DATE_CHANGE:
    {
      const { internalId, propertyName, value } : Payload = action.payload;
      if (!isValid(value)) {
        return state;
      }
      const newAssets = [...state?.subledger.prepaidAssets] as Array<PrepaidAsset>;
      const index = state?.subledger.prepaidAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === internalId);
      // @ts-ignore
      newAssets[index].prepaidSchedule[propertyName] = value ?? new Date();
      newAssets[index] = calculateScheduleAsset(state?.subledger, newAssets[index]);
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case UNSCHEDULED_DATE_CHANGE:
    {
      const { internalId, propertyName, value } : Payload = action.payload;
      if (!isValid(value)) {
        return state;
      }
      const newAssets = [...state?.subledger.prepaidAssets];
      const index = state?.subledger.prepaidAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === internalId);
      // @ts-ignore
      newAssets[index].prepaidSchedule[propertyName] = value ?? new Date();
      calculateAssetForUnScheduledAssets(newAssets, index, state?.subledger);
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case UNSCHEDULED_SELECT_CHANGE:
    {
      const { internalId, propertyName, value } : Payload = action.payload;
      const newAssets = [...state?.subledger.prepaidAssets];
      const index = newAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === internalId);
      if (propertyName === 'amortizationScheduleType') {
        newAssets[index].prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = null;
        if (newAssets[index].prepaidSchedule[propertyName] === ScheduleType.Manual && value !== ScheduleType.Manual) {
          newAssets[index].prepaidSchedule.amortizationEndDate = new Date();
          newAssets[index].prepaidSchedule.amortizationStartDate = state?.subledger?.factaStartDate;
        } else if (newAssets[index]?.prepaidSchedule?.[propertyName] !== ScheduleType.Manual && value === ScheduleType.Manual) {
          newAssets[index].prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = null;
        }
        // @ts-ignore
        newAssets[index].prepaidSchedule[propertyName] = value;
        calculateAssetForUnScheduledAssets(newAssets, index, state?.subledger);
      } else if (['classId', 'expenseAccountId'].includes(propertyName)) {
        // @ts-ignore
        newAssets[index].prepaidSchedule[propertyName] = value;
      } else {
        // TODO need to add a list here
        // @ts-ignore
        newAssets[index][propertyName] = value;
      }
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case HISTORICAL_UPDATE_ASSET:
    {
      const { internalId } : Payload = action.payload;
      const newAssets = calculateAsset(state?.subledger, internalId);
      const parentAsset = newAssets
          ?.find((asset: PrepaidAsset) => asset.internalId === internalId);
      if (!parentAsset) {
        return state;
      }
      if (!parentAsset.status) {
        parentAsset.status = ASSET_TEMP_SCHEDULED_STATUS;
      }
      const historicalUpdatedAssets = state?.historicalUpdatedAssets
          ?.filter((asset: PrepaidAsset) => !(asset.internalId === internalId || asset.parentId === internalId));

      // update historical assets
      historicalUpdatedAssets.push(cloneDeep(parentAsset));
      const children = getChildren(internalId, newAssets);
      children?.forEach((child) => {
        if (!child.status) {
          // eslint-disable-next-line no-param-reassign
          child.status = ASSET_TEMP_SCHEDULED_STATUS;
        }
        historicalUpdatedAssets.push(cloneDeep(child));
      });

      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
        historicalUpdatedAssets,
      };
    }
    case HISTORICAL_MANUAL_ASSET:
    {
      const { internalId } : Payload = action.payload;
      const manualAsset = state?.subledger?.prepaidAssets
          ?.find((asset: PrepaidAsset) => asset.internalId === internalId);
      if (!manualAsset) {
        return state;
      }
      let historicalUpdatedAssets = [];
      if (manualAsset?.parentId) {
        const parentAsset = state?.subledger?.prepaidAssets
            ?.find((asset: PrepaidAsset) => asset.internalId === manualAsset?.parentId);
        if (!parentAsset) {
          return state;
        }
        historicalUpdatedAssets = state?.historicalUpdatedAssets
            ?.filter((asset: PrepaidAsset) => !(asset.internalId === parentAsset.internalId || asset.parentId === parentAsset.internalId));

        // update historical assets
        historicalUpdatedAssets.push(cloneDeep(parentAsset));
        const children = getChildren(parentAsset.internalId, state?.subledger?.prepaidAssets);
        children?.forEach((child) => historicalUpdatedAssets.push(cloneDeep(child)));
      } else {
        historicalUpdatedAssets = state?.historicalUpdatedAssets
            ?.filter((asset: PrepaidAsset) => !(asset.internalId === internalId));

        // update historical assets
        historicalUpdatedAssets.push(cloneDeep(manualAsset));
      }
      return {
        ...state,
        historicalUpdatedAssets,
      };
    }
    case CALCULATE_ASSET:
    {
      const { internalId } : Payload = action.payload;
      const newAssets = calculateAsset(state?.subledger, internalId);
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case UPDATE_ASSET:
    {
      const { assets, balance, internalId } : Payload = action.payload;

      // old child assets
      const oldAssets = state?.subledger?.prepaidAssets?.filter((a) => a.parentId !== internalId);

      // get parent asset index
      const index = oldAssets?.findIndex((a: PrepaidAsset) => a.internalId === internalId);

      // get parent asset from service
      let prepaidAsset = assets?.find((a) => !a.parentId);
      if (!prepaidAsset) {
        return state;
      }
      // @ts-ignore
      prepaidAsset = defaultAsset(
        prepaidAsset,
        action?.payload?.subledger?.account?.scheduleType,
        state?.subledger?.factaStartDate,
      );

      const newChildAssets = assets
          ?.filter((a) => a.parentId === prepaidAsset?.id)
          ?.map((a) => defaultAsset(
            a,
            action?.payload?.subledger?.account?.scheduleType,
            state?.subledger?.factaStartDate,
          )) ?? [];

      // @ts-ignore
      oldAssets[index] = prepaidAsset;
      const updatedAssets = [...oldAssets, ...newChildAssets];
      const openingBalance = balance;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          openingBalance,
          prepaidAssets: updatedAssets,
        },
      };
    }
    case ADD_ASSET:
    {
      const internalId = uuidv4();
      const newAssets = [...state?.subledger?.prepaidAssets, calculateScheduleAsset(state?.subledger, {
        internalId,
        assetCreationDate: startOfDay(state?.subledger?.factaStartDate ?? new Date()),
        prepaidSchedule: {
          amortizationEndDate: endOfMonth(state?.subledger?.factaStartDate ?? new Date()),
          amortizationStartDate: state?.subledger?.factaStartDate,
          amortizationScheduleType: state?.subledger?.account?.scheduleType,
        },
        startingBalance: 0,
      } as PrepaidAsset)];
      return {
        ...state,
        selectedRow: internalId,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case REMOVE_ASSET:
    {
      const { internalId } : Payload = action.payload;
      const oldAssets = [...state?.subledger?.prepaidAssets];
      const index = oldAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === internalId);
      const internalIds = [internalId];
      if (oldAssets[index].parentId) {
        const children = getChildren(oldAssets[index].parentId, oldAssets);
        const parentAssetIndex = oldAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === oldAssets[index].parentId);
        if (children?.length === 2) {
          internalIds.push(children?.[0]?.internalId);
          internalIds.push(children?.[1]?.internalId);
          oldAssets[parentAssetIndex].startingBalance = 0;
          oldAssets[parentAssetIndex].prepaidSchedule.amortizationStartDate = state?.subledger?.factaStartDate;
          oldAssets[parentAssetIndex].prepaidSchedule.amortizationEndDate = new Date();
        } else {
          // eslint-disable-next-line no-underscore-dangle
          oldAssets[parentAssetIndex].startingBalance = getStartingBalanceOfParent(state?.subledger, oldAssets[parentAssetIndex].internalId, internalId);
        }
      }
      const updatedAssets = oldAssets?.filter((asset: PrepaidAsset) => !(internalIds.includes(asset.internalId) || internalIds.includes(asset.parentId)));
      return {
        ...state,
        subledger: {
          ...state.subledger,
          openingBalance: getAssetSum(state?.subledger, updatedAssets),
          prepaidAssets: updatedAssets,
        },
      };
    }
    case UNSCHEDULED_REMOVE_ASSET:
    {
      const { internalId } : Payload = action.payload;
      const oldAssets = [...state?.subledger?.prepaidAssets];
      const index = oldAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === internalId);
      const internalIds = [internalId];
      if (oldAssets[index].parentId) {
        const children = getChildren(oldAssets[index].parentId, oldAssets);
        const parentAssetIndex = oldAssets?.findIndex((asset: PrepaidAsset) => asset.internalId === oldAssets[index].parentId);
        if (children?.length === 2) {
          internalIds.push(children?.[0]?.internalId);
          internalIds.push(children?.[1]?.internalId);
          oldAssets[parentAssetIndex].prepaidSchedule.amortizationStartDate = state?.subledger?.factaStartDate;
          oldAssets[parentAssetIndex].prepaidSchedule.amortizationEndDate = new Date();
        }
      }

      const updatedAssets = oldAssets?.filter((asset: PrepaidAsset) => !(internalIds.includes(asset.internalId)));
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: [...updatedAssets],
        },
      };
    }
    case ADD_SPLIT:
    {
      const { internalId } : Payload = action.payload;
      const parentAsset = state?.subledger?.prepaidAssets
          ?.find((asset: PrepaidAsset) => asset.internalId === internalId);
      if (!parentAsset) {
        return state;
      }

      const assetCreationDate = parentAsset.assetCreationDate ?? startOfDay(state?.subledger?.factaStartDate ?? new Date());
      const splitAmount = roundTo(parentAsset?.startingBalance ? parentAsset?.startingBalance / 2 : 0, 2);
      const amortizationStartDate = addHours(startOfDay(parentAsset.prepaidSchedule.amortizationStartDate ?? assetCreationDate!), 12);
      const amortizationEndDate = addHours(startOfDay(parentAsset.prepaidSchedule.amortizationEndDate! ?? assetCreationDate!), 12);
      const amortizationScheduleType = parentAsset.prepaidSchedule.amortizationScheduleType!;
      parentAsset.prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = null;
      const newAssets = [...state?.subledger?.prepaidAssets, calculateScheduleAsset(state?.subledger, {
        internalId: uuidv4(),
        assetCreationDate,
        prepaidSchedule: {
          amortizationEndDate,
          amortizationStartDate,
          amortizationScheduleType,
        },
        parentId: internalId,
        startingBalance: splitAmount,
      } as PrepaidAsset), calculateScheduleAsset(state?.subledger, {
        internalId: uuidv4(),
        assetCreationDate,
        prepaidSchedule: {
          amortizationEndDate,
          amortizationStartDate,
          amortizationScheduleType,
        },
        parentId: internalId,
        startingBalance: roundTo(parentAsset?.startingBalance ? parentAsset?.startingBalance - splitAmount : 0, 2),
      } as PrepaidAsset)];
      parentAsset.prepaidSchedule.amortizationStartDate = null;
      parentAsset.prepaidSchedule.amortizationEndDate = null;
      parentAsset.prepaidSchedule.amortizationScheduleType = state?.subledger?.account?.scheduleType!;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case ADD_SPLIT_ASSET:
    {
      const { internalId } : Payload = action.payload;
      const parentAsset = state?.subledger?.prepaidAssets
          ?.find((asset: PrepaidAsset) => asset.internalId === internalId);
      if (!parentAsset) {
        return state;
      }
      parentAsset.prepaidSchedule.prepaidAssetsAmortizationScheduleDetails = null;
      parentAsset.prepaidSchedule.amortizationStartDate = null;
      parentAsset.prepaidSchedule.amortizationEndDate = null;
      parentAsset.prepaidSchedule.amortizationScheduleType = state?.subledger?.account?.scheduleType!;
      const assetCreationDate = parentAsset.assetCreationDate ?? startOfDay(state?.subledger?.factaStartDate ?? new Date());
      const newAssets = [...state?.subledger?.prepaidAssets, {
        internalId: uuidv4(),
        assetCreationDate,
        prepaidSchedule: {
          amortizationEndDate: endOfMonth(assetCreationDate),
          amortizationStartDate: startOfMonth(assetCreationDate),
          amortizationScheduleType: state?.subledger?.account?.scheduleType,
        },
        parentId: internalId,
        startingBalance: 0,
      }] as Array<PrepaidAsset>;

      return {
        ...state,
        subledger: {
          ...state.subledger,
          prepaidAssets: newAssets,
        },
      };
    }
    case IMPORT_CSV:
    {
      const { assets } : Payload = action.payload;
      const allAssets = [...state?.subledger?.prepaidAssets, ...assets];
      const updatedAssets = assets?.map((item: PrepaidAsset) => calculateScheduleAsset(state?.subledger, item, allAssets));
      const modifiedAllAssets = [...state?.subledger?.prepaidAssets, ...updatedAssets];
      return {
        ...state,
        subledger: {
          ...state?.subledger,
          prepaidAssets: [...modifiedAllAssets],
          openingBalance: getAssetSum(state?.subledger, modifiedAllAssets),
        },
      };
    }
    default:
      throw new Error();
  }
};

export default reducer;
