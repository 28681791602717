import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import {
  addMonths,
  differenceInCalendarMonths,
  format,
  isAfter,
  isBefore, isSameMonth,
  lastDayOfMonth,
  parse,
  startOfMonth,
} from 'date-fns';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { FULL_DAY_FORMAT, DAY_SHORT_FORMAT } from '../../variables/constants';
import {
  getAccountNameFromList,
  getChildren, getChildSumForMonth,
  getClassName,
  getTotalBalanceAndAmortizationBalance,
  getVendorName,
} from './common';
import currencyFormatter from '../../util/currencyFormatter';
import { PrepaidAsset, PrepaidAssetsAmortizationScheduleDetail, ScheduleType } from '../../variables/types';
import { useTableStyles } from './Subledger.styled';
import { useAccountProvider } from '../../core/accountContext';
import COLORS from '../../variables/colors';
import InputBox from '../InputBox';

interface Props {
  asset: PrepaidAsset;
  prepaidAssets: Array<PrepaidAsset>;
  hasChildren: boolean;
  collapses: Array<string>;
  lastChild?: boolean;
  toggleSplit: (id: string) => () => void;
  startDate: Date;
  endDate: Date;
  scheduleDate: string;
  disabled?: boolean;
  onInputBoxChange: (lastDay: string, asset: PrepaidAsset, schedule?: PrepaidAssetsAmortizationScheduleDetail) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PrepareJERow = ({
  asset,
  prepaidAssets,
  hasChildren,
  lastChild,
  toggleSplit,
  collapses,
  scheduleDate,
  startDate,
  endDate,
  onInputBoxChange,
  disabled = false,
}: Props) => {
  const tableClasses = useTableStyles();
  const { account } = useAccountProvider();
  const { vendors, accountIncomes, accountClasses } = account;

  const children = getChildren(asset.internalId, prepaidAssets);
  const { totalBalance, amortizationToDateBalance } = hasChildren ? getTotalBalanceAndAmortizationBalance(children) : {
    totalBalance: 0,
    amortizationToDateBalance: 0,
  };

  const renderAmortizationSchedules = (prepaidAsset: PrepaidAsset) => {
    const scheduledOnDate = parse(scheduleDate, DAY_SHORT_FORMAT, new Date());

    if (startDate && endDate) {
      const diffInMonths = differenceInCalendarMonths(lastDayOfMonth(endDate!), startOfMonth(startDate!)) + 1;
      if (diffInMonths > 0) {
        const childrenFromParent = getChildren(prepaidAsset.parentId, prepaidAssets);
        return (
          <>
            {
              Array.from(Array(diffInMonths).keys()).map((index: number) => {
                const currentDate = lastDayOfMonth(addMonths(startDate!, index));
                const lastDay = format(currentDate, 'L/d/yy');
                const sum = hasChildren ? getChildSumForMonth(children, lastDay) : 0;
                const schedule = prepaidAsset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails
                                    ?.find((item: PrepaidAssetsAmortizationScheduleDetail) => format(item.scheduleDate, DAY_SHORT_FORMAT) === lastDay);
                return (
                  <TableCell
                    align="right"
                    className={clsx(tableClasses.amortizationRowCell, tableClasses.amortizationCell, {
                      [tableClasses.amortizationManualCell]: !disabled && !hasChildren && (prepaidAsset.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual && !isBefore(currentDate, scheduledOnDate)),
                      [tableClasses.cellBackground]: index % 2 === 0,
                      [tableClasses.selectedCellBackground]: lastDay === scheduleDate,
                      [tableClasses.amortizationSplitCell]: hasChildren || (prepaidAsset.parentId && childrenFromParent?.[childrenFromParent?.length - 1]?.internalId !== prepaidAsset.internalId),
                    })}
                    style={{
                      borderLeft: index === 0 ? `1px solid ${COLORS.lightGray2}` : 'none',
                    }}
                  >
                    {
                      !disabled && !hasChildren && prepaidAsset.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual && (isAfter(currentDate, scheduledOnDate) || isSameMonth(currentDate, scheduledOnDate)) && (
                        <InputBox
                          error={!disabled && lastDay === scheduleDate && (Number.isNaN(Number(schedule?.amount)) || schedule?.amount === '' || schedule?.error)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          onChange={onInputBoxChange(lastDay, prepaidAsset, schedule)}
                          value={!Number.isNaN(Number(schedule?.amount)) || schedule?.amount === '-' ? schedule?.amount : 'M'}
                          disabled={disabled}
                        />
                      )
                    }
                    {
                      !hasChildren && (prepaidAsset.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual || isBefore(currentDate, scheduledOnDate) || disabled) && (
                                                schedule?.amount
                                                  ? currencyFormatter.format(Number(schedule?.amount)) : '-'
                      )
                    }
                    {
                      hasChildren && (
                        sum
                          ? currencyFormatter.format(sum) : '-'
                      )
                    }
                  </TableCell>
                );
              })
            }
          </>
        );
      }
    }
    return null;
  };

  return (
    <TableRow
      hover={false}
      className={clsx({
        [tableClasses.disabledRow]: disabled,
      })}
      classes={{
        selected: tableClasses.selected,
      }}
    >
      <TableCell className={clsx(tableClasses.assetCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {asset.parentId ? '' : format(asset?.assetCreationDate!, FULL_DAY_FORMAT)}
      </TableCell>
      <TableCell className={clsx(tableClasses.descriptionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {asset.description}
      </TableCell>
      <TableCell className={clsx(tableClasses.customerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {!asset.parentId ? getVendorName(asset.vendorId, vendors) : ''}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.expenseAccountCell, tableClasses.cursorPointer, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
        onClick={toggleSplit(asset.parentId ?? asset.internalId)}
      >
        {
          hasChildren
            && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography className={tableClasses.fontSize13}>Multiple</Typography>
                {
                  collapses.includes(asset.internalId) && (
                    <ArrowRightIcon />
                  )
                }
                {
                  !collapses.includes(asset.internalId) && (
                    <ArrowDropDownIcon />
                  )
                }
              </Box>
            )
        }
        {
          !hasChildren
                    && getAccountNameFromList(asset?.prepaidSchedule?.expenseAccountId!, accountIncomes)
        }
      </TableCell>
      {

        (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
          <TableCell className={clsx(tableClasses.classesCell, {
            [tableClasses.child]: asset.parentId,
            [tableClasses.parent]: hasChildren,
            [tableClasses.lastChild]: lastChild,
          })}
          >
            {
              hasChildren && 'Multiple'
            }
            {
              !hasChildren && getClassName(asset?.prepaidSchedule?.classId!, accountClasses)
            }
          </TableCell>
        )
      }
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {hasChildren && currencyFormatter.format(totalBalance - amortizationToDateBalance)}
        {!hasChildren && currencyFormatter.format(asset?.startingBalance - (asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0))}
      </TableCell>
      {renderAmortizationSchedules(asset)}
    </TableRow>
  );
};

export default React.memo(PrepareJERow);
