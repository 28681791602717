/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DeleteIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.16261 12.671H1.92431C1.77194 12.6674 1.62177 12.6338 1.48238 12.5722C1.343 12.5105 1.21712 12.422 1.11196 12.3117C1.00679 12.2014 0.924386 12.0714 0.86946 11.9292C0.814534 11.7871 0.788159 11.6354 0.791843 11.4831V3.65479H1.58378V11.4831C1.58 11.5315 1.58582 11.5801 1.60091 11.6263C1.616 11.6724 1.64006 11.7151 1.67171 11.7519C1.70336 11.7887 1.74196 11.8189 1.78532 11.8407C1.82867 11.8625 1.8759 11.8755 1.92431 11.879H9.16261C9.21102 11.8755 9.25826 11.8625 9.30161 11.8407C9.34496 11.8189 9.38357 11.7887 9.41522 11.7519C9.44686 11.7151 9.47092 11.6724 9.48601 11.6263C9.5011 11.5801 9.50693 11.5315 9.50315 11.4831V3.65479H10.2951V11.4831C10.2988 11.6354 10.2724 11.7871 10.2175 11.9292C10.1625 12.0714 10.0801 12.2014 9.97497 12.3117C9.8698 12.422 9.74393 12.5105 9.60454 12.5722C9.46515 12.6338 9.31498 12.6674 9.16261 12.671V12.671Z"
      fill={props?.fill ?? '#252729'}
    />
    <path
      d="M10.604 2.7718H0.395968C0.290951 2.7718 0.190235 2.73008 0.115977 2.65582C0.041718 2.58156 0 2.48084 0 2.37583C0 2.27081 0.041718 2.17009 0.115977 2.09583C0.190235 2.02158 0.290951 1.97986 0.395968 1.97986H10.604C10.709 1.97986 10.8098 2.02158 10.884 2.09583C10.9583 2.17009 11 2.27081 11 2.37583C11 2.48084 10.9583 2.58156 10.884 2.65582C10.8098 2.73008 10.709 2.7718 10.604 2.7718Z"
      fill={props?.fill ?? '#252729'}
    />
    <path d="M6.73145 4.35559H7.52338V10.2951H6.73145V4.35559Z" fill={props?.fill ?? '#252729'} />
    <path d="M3.56372 4.35559H4.35566V10.2951H3.56372V4.35559Z" fill={props?.fill ?? '#252729'} />
    <path
      d="M7.5234 1.52844H6.77106V0.791937H4.31606V1.52844H3.56372V0.791937C3.56347 0.588586 3.64145 0.39293 3.7815 0.2455C3.92156 0.0980709 4.11296 0.0101674 4.31606 0H6.77106C6.97416 0.0101674 7.16556 0.0980709 7.30562 0.2455C7.44568 0.39293 7.52366 0.588586 7.5234 0.791937V1.52844Z"
      fill={props?.fill ?? '#252729'}
    />
  </SvgIcon>
);

export default DeleteIcon;
