import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { endOfMonth, format, isAfter, isBefore, isWithinInterval, lastDayOfMonth, startOfMonth } from 'date-fns';
import { useHistory } from 'react-router';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import roundTo from 'round-to';
import COLORS from '../../variables/colors';
import { Account, PrepaidAsset, Subledger } from '../../variables/types';
import { getAccountName, getAssetSumForMonthWithStatus, getFactaSourceBalance } from '../../components/Subledger/common';
import getSubledgerStartEndDate from '../../util/getSubledgerStartEndDate';
import { ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS, DAY_SHORT_FORMAT } from '../../variables/constants';
import ClosedMonthCard from './Card/ClosedMonthCard';
import OutOfPeriodMonthUnScheduledCard from './Card/OutOfPeriodMonthUnScheduledCard';
import OutOfPeriodMonthScheduledCard from './Card/OutOfPeriodMonthScheduledCard';
import CurrentMonthScheduledCard from './Card/CurrentMonthScheduledCard';
import CurrentMonthUnScheduledCard from './Card/CurrentMonthUnScheduledCard';
import SubledgerDisableCard from './Card/SubledgerDisableCard';
import OutOfPeriodCurrentMonthCard from './Card/OutOfPeriodCurrentMonthCard';

const useStyles = makeStyles({
  root: {
    marginLeft: '2.5%',
    marginTop: '2.5%',
    textAlign: 'left',
  },
  card: {
    minWidth: 360,
    boxShadow: '0px 2px 12px rgba(24, 43, 88, 0.13)',
  },
  title: {
    fontSize: 14,
    color: COLORS.deepGray,
    fontWeight: 600,
    paddingTop: 20,
  },
  accountStatus: {
    height: 22,
    width: 22,
    background: COLORS.successGreen,
    borderRadius: 12,
    margin: 0,
    marginTop: '-5px',
    marginRight: '-12px',
    color: COLORS.white,
    position: 'relative',
    fontSize: 11,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pendingAssets: {
    background: COLORS.violet,
  },
  cardType: {
    display: 'flex',
    justifyContent: 'space-between',
    '& h4': {
      margin: 0,
      fontSize: 10,
      fontWeight: 'normal',
      color: COLORS.lightGray,
    },
  },
  factaBalance: {
    color: COLORS.violet,
  },
  schedule: {
    color: COLORS.deepGray,
  },
  amortization: {
    fontSize: 13,
    color: COLORS.lightGray,
  },
  pendingTasks: {
    background: COLORS.violet,
    border: `1px solid ${COLORS.violet}`,
    '&:hover': {
      border: `1px solid ${COLORS.hoverBlue}`,
    },
  },
  completedTasks: {
    background: COLORS.successGreen,
    border: `1px solid ${COLORS.successGreen}`,
  },
  checkComplete: {
    fontSize: 20,
    color: COLORS.white,
  },
  checkCompleted: {
    fontSize: 14,
    marginLeft: 9,
    color: COLORS.successGreen,
  },
  closedTag: {
    display: 'flex',
    fontSize: 10,
    fontWeight: 500,
    color: COLORS.successGreen,
    '& span': {
      marginRight: 14,
    },
  },
  active: {
    borderLeft: `4px solid ${COLORS.violet}`,
  },
  disabled: {
    opacity: 0.5,
  },
  navigatePrepaid: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  postDisabled: {
    background: `${COLORS.lightGray2} !important`,
    color: `${COLORS.white} !important`,
  },
  padding15: {
    paddingLeft: 15,
    paddingRight: 15,
  },
});

interface Props {
  subledger: Subledger;
  account: Account;
  selectedDate: Date | null;
  active: boolean;
  onChange: (id: string) => void;
  loading: boolean,
  subledgerAccountStartingBalance: number;
}

const getSubledgerInfo = (subledger: Subledger, account: Account, selectedDate: Date | null) => {
  const { startDate, endDate } = getSubledgerStartEndDate(subledger);

  let subledgerEnable = true;
  let glBalance = account?.glBalance ?? subledger?.account?.glBalance;
  let postAmortizationAmount: number;
  let unPostAmortizationAmount: number;
  let isSubledgerAmortized = false;
  let closingBalance = 0;
  if (selectedDate && startDate && endDate && isAfter(endDate, startDate)
        && isWithinInterval(
          selectedDate, { start: startDate, end: endDate },
        )
  ) {
    const scheduleDate = format(lastDayOfMonth(selectedDate!), DAY_SHORT_FORMAT);
    postAmortizationAmount = getAssetSumForMonthWithStatus(scheduleDate, subledger.prepaidAssets, [ASSET_SCHEDULED_STATUS]);
    unPostAmortizationAmount = getAssetSumForMonthWithStatus(scheduleDate, subledger.prepaidAssets, [ASSET_TEMP_SCHEDULED_STATUS]);
    const subledgerLog = subledger?.subledgerAmortizationLogs
        ?.find((log) => format(log.scheduleDate!, DAY_SHORT_FORMAT) === scheduleDate);
    if (subledgerLog) {
      closingBalance = subledgerLog.closingBalance;
      glBalance = account?.glBalance;
      isSubledgerAmortized = true;
    }
  } else {
    subledgerEnable = false;
    glBalance = 0;
    postAmortizationAmount = 0;
    unPostAmortizationAmount = 0;
  }

  return {
    subledgerEnable,
    glBalance,
    postAmortizationAmount,
    isSubledgerAmortized,
    closingBalance,
    unPostAmortizationAmount,
  };
};

const SubledgerCard = ({
  subledger,
  selectedDate,
  active,
  onChange,
  account,
  loading,
  subledgerAccountStartingBalance,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const unScheduleAssetsForScheduledPeriod = subledger
      ?.prepaidAssets
      ?.filter((prepaidAsset) => (!prepaidAsset.status
          || prepaidAsset.status === ASSET_TEMP_SCHEDULED_STATUS)
          && isWithinInterval(prepaidAsset?.assetCreationDate!, {
            start: startOfMonth(selectedDate!),
            end: endOfMonth(selectedDate!),
          })
      && !prepaidAsset.parentId);
  // @ts-ignore
  const onCardClicked = () => {
    onChange(subledger?.id);
  };

  const {
    glBalance,
    subledgerEnable,
    isSubledgerAmortized,
    postAmortizationAmount,
    closingBalance,
    unPostAmortizationAmount,
  } = getSubledgerInfo(subledger, account, selectedDate);

  const navigateToPostJE = () => {
    history.push(`/subledgers/schedule/${subledger.id}/journal-entry/?scheduleDate=${format(lastDayOfMonth(selectedDate!), DAY_SHORT_FORMAT)}`);
  };

  const navigateToScheduler = () => {
    history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${format(lastDayOfMonth(selectedDate!), DAY_SHORT_FORMAT)}`);
  };

  const navigateToHistoricalScheduler = () => {
    history.push(`historical/subledgers/schedule/${subledger.id}/?scheduleDate=${format(lastDayOfMonth(selectedDate!), DAY_SHORT_FORMAT)}`);
  };

  const navigateToHistoricalPostJE = () => {
    history.push(`historical/subledgers/schedule/${subledger.id}/post-je?scheduleDate=${format(lastDayOfMonth(selectedDate!), DAY_SHORT_FORMAT)}&fromDashboard=true`);
  };

  const renderCard = () => {
    const accountName = getAccountName(subledger?.account);
    if (!subledgerEnable) {
      return (
        <SubledgerDisableCard
          accountName={accountName}
        />
      );
    }
    const previousMonthAssets = subledger
        ?.prepaidAssets
        ?.filter((prepaidAsset) => (!prepaidAsset.status
            || prepaidAsset.status === ASSET_TEMP_SCHEDULED_STATUS)
            && isBefore(prepaidAsset?.assetCreationDate!, startOfMonth(selectedDate!)));

    const unScheduledAssetsWithNoStatus = unScheduleAssetsForScheduledPeriod
        ?.filter((asset) => !asset?.status);

    const tempScheduledAssets = unScheduleAssetsForScheduledPeriod
        ?.filter((asset) => asset.status === ASSET_TEMP_SCHEDULED_STATUS)?.length;

    if (isSubledgerAmortized) {
      if (previousMonthAssets?.length > 0
          || subledgerAccountStartingBalance !== getFactaSourceBalance(subledger?.prepaidAssets)) {
        return (
          <OutOfPeriodCurrentMonthCard
            active={active}
            accountName={accountName}
            loading={loading}
            glBalance={glBalance}
            closingBalance={closingBalance}
            amortizationPostedAmount={postAmortizationAmount}
            unScheduleAssetsCount={unScheduledAssetsWithNoStatus?.length > 0 ? unScheduledAssetsWithNoStatus?.length : tempScheduledAssets > 0 ? 1 : 0}
          />
        );
      }
      if (unScheduleAssetsForScheduledPeriod?.length === 0) {
        return (
          <ClosedMonthCard
            active={active}
            accountName={accountName}
            loading={loading}
            glBalance={glBalance}
            closingBalance={closingBalance}
            amortizationPostedAmount={postAmortizationAmount}
          />
        );
      }
      if (unScheduledAssetsWithNoStatus?.length > 0) {
        const amount = unScheduledAssetsWithNoStatus.map((item: PrepaidAsset) => item.startingBalance)
          .reduce((prev, curr) => prev + curr, 0);
        return (
          <OutOfPeriodMonthUnScheduledCard
            active={active}
            accountName={accountName}
            loading={loading}
            amount={amount}
            closingBalance={closingBalance}
            amortizationPostedAmount={postAmortizationAmount}
            amortizationUnPostedAmount={unPostAmortizationAmount}
            action={navigateToHistoricalScheduler}
            onCurrencyNavigation={navigateToHistoricalScheduler}
            unScheduleAssetsCount={unScheduledAssetsWithNoStatus?.length}
            glBalance={glBalance}
          />
        );
      }
      return (
        <OutOfPeriodMonthScheduledCard
          active={active}
          accountName={accountName}
          loading={loading}
          glBalance={glBalance}
          closingBalance={closingBalance}
          amortizationPostedAmount={postAmortizationAmount}
          amortizationUnPostedAmount={unPostAmortizationAmount + postAmortizationAmount}
          action={navigateToHistoricalPostJE}
          onCurrencyNavigation={navigateToHistoricalScheduler}
          unScheduleAssetsCount={tempScheduledAssets > 0 ? 1 : 0}
        />
      );
    }
    if (previousMonthAssets?.length > 0
        || subledgerAccountStartingBalance !== getFactaSourceBalance(subledger?.prepaidAssets)) {
      return (
        <OutOfPeriodCurrentMonthCard
          active={active}
          accountName={accountName}
          loading={loading}
          glBalance={glBalance}
          closingBalance={subledger?.openingBalance}
          amortizationPostedAmount={0}
          unScheduleAssetsCount={unScheduledAssetsWithNoStatus?.length > 0 ? unScheduledAssetsWithNoStatus?.length : tempScheduledAssets > 0 ? 1 : 0}
        />
      );
    }
    if (unScheduleAssetsForScheduledPeriod?.some((asset) => !asset?.status)) {
      return (
        <CurrentMonthUnScheduledCard
          active={active}
          accountName={accountName}
          loading={loading}
          glBalance={glBalance}
          amount={roundTo(glBalance - subledger?.openingBalance, 2)}
          openingBalance={subledger?.openingBalance}
          amortizationPostedAmount={0}
          amortizationUnPostedAmount={unPostAmortizationAmount + postAmortizationAmount}
          action={navigateToScheduler}
          onCurrencyNavigation={navigateToScheduler}
          unScheduleAssetsCount={unScheduledAssetsWithNoStatus?.length}
        />
      );
    }
    return (
      <CurrentMonthScheduledCard
        active={active}
        accountName={accountName}
        loading={loading}
        glBalance={glBalance}
        openingBalance={subledger?.openingBalance}
        amortizationUnPostedAmount={unPostAmortizationAmount + postAmortizationAmount}
        action={navigateToPostJE}
        onCurrencyNavigation={navigateToScheduler}
        unScheduleAssetsCount={tempScheduledAssets > 0 ? 1 : 0}
      />
    );
  };

  return (
    <ButtonBase
      onClick={onCardClicked}
      className={classes.root}
      disableRipple
      disabled={!subledgerEnable}
    >
      {
        loading && (
          <Box
            position="absolute"
            height="100%"
            width="100%"
            bgcolor={COLORS.lightGrayWithOpacity}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )
      }
      { renderCard() }
    </ButtonBase>
  );
};

export default SubledgerCard;
