/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NavCloseIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    {...props}
  >
    <path d="M5.10225 11.817C5.29906 11.8174 5.48979 11.7489 5.64133 11.6233C5.72662 11.5526 5.79712 11.4657 5.8488 11.3677C5.90048 11.2697 5.93232 11.1625 5.94249 11.0522C5.95266 10.9419 5.94097 10.8306 5.90808 10.7248C5.87519 10.619 5.82175 10.5208 5.75083 10.4356L1.97726 5.92083L5.61606 1.3976C5.68603 1.31144 5.73828 1.2123 5.76981 1.10589C5.80134 0.99947 5.81152 0.88787 5.79978 0.777503C5.78804 0.667136 5.7546 0.560177 5.70139 0.462775C5.64818 0.365372 5.57624 0.279446 5.48971 0.209936C5.40256 0.133255 5.3005 0.0754153 5.18994 0.0400473C5.07937 0.00467928 4.96269 -0.00745317 4.84722 0.00441074C4.73174 0.0162747 4.61996 0.0518791 4.5189 0.10899C4.41784 0.1661 4.32967 0.243484 4.25993 0.336284L0.191551 5.39017C0.0676613 5.54089 -6.56799e-05 5.72994 -6.56799e-05 5.92504C-6.56799e-05 6.12014 0.0676613 6.3092 0.191551 6.45991L4.40313 11.5138C4.48762 11.6157 4.59496 11.6963 4.71643 11.749C4.8379 11.8017 4.97008 11.825 5.10225 11.817Z" fill={props.fill} />
    <path d="M10.1562 11.817C10.353 11.8174 10.5437 11.7489 10.6953 11.6233C10.7806 11.5526 10.8511 11.4657 10.9028 11.3677C10.9544 11.2697 10.9863 11.1625 10.9964 11.0522C11.0066 10.9419 10.9949 10.8306 10.962 10.7248C10.9291 10.619 10.8757 10.5208 10.8048 10.4356L7.03121 5.92083L10.67 1.3976C10.74 1.31144 10.7922 1.2123 10.8238 1.10589C10.8553 0.99947 10.8655 0.88787 10.8537 0.777503C10.842 0.667136 10.8086 0.560177 10.7553 0.462775C10.7021 0.365372 10.6302 0.279446 10.5437 0.209936C10.4565 0.133255 10.3545 0.0754153 10.2439 0.0400473C10.1333 0.00467928 10.0166 -0.00745317 9.90117 0.00441074C9.7857 0.0162747 9.67392 0.0518791 9.57286 0.10899C9.47179 0.1661 9.38363 0.243484 9.31389 0.336284L5.24551 5.39017C5.12162 5.54089 5.05389 5.72994 5.05389 5.92504C5.05389 6.12014 5.12162 6.3092 5.24551 6.45991L9.45708 11.5138C9.54158 11.6157 9.64892 11.6963 9.77039 11.749C9.89186 11.8017 10.024 11.825 10.1562 11.817Z" fill={props.fill} />
  </SvgIcon>
);

export default NavCloseIcon;
