/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HomeIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    height={15}
    width={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.805 0.853897C7.71673 0.777592 7.60751 0.736084 7.495 0.736084C7.38249 0.736084 7.27327 0.777592 7.185 0.853897L0 6.98525L0.62 7.84943L1.5 7.09464V13.8659C1.5 14.156 1.60536 14.4343 1.79289 14.6394C1.98043 14.8446 2.23478 14.9598 2.5 14.9598H12.5C12.7652 14.9598 13.0196 14.8446 13.2071 14.6394C13.3946 14.4343 13.5 14.156 13.5 13.8659V7.10011L14.38 7.84943L15 6.99072L7.805 0.853897ZM8.5 13.8659H6.5V9.49029H8.5V13.8659ZM9.5 13.8659V9.49029C9.5 9.20017 9.39464 8.92193 9.20711 8.71679C9.01957 8.51164 8.76522 8.39639 8.5 8.39639H6.5C6.23478 8.39639 5.98043 8.51164 5.79289 8.71679C5.60536 8.92193 5.5 9.20017 5.5 9.49029V13.8659H2.5V6.24139L7.5 1.98062L12.5 6.24686V13.8659H9.5Z"
    />
  </SvgIcon>
);

export default HomeIcon;
