import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import useFetch from 'use-http';

import { format } from 'date-fns';
import Loader from '../../../components/Loader/loader';
import { useAccountProvider } from '../../../core/accountContext';
import { useRefreshDataProvider } from '../../../core/dataRefreshContext';
import {
  Account, NotifierType,
  PrepaidAsset,
} from '../../../variables/types';
import {
  ASSET_SCHEDULED_STATUS,
  INITIALED, SELECT_CHANGE,
  SELECT_ROW, UPDATE_ASSET,
  ASSET_TEMP_SCHEDULED_STATUS,
  DAY_SHORT_FORMAT,
  FINALIZED_SUBLEDGER_STATUS,
} from '../../../variables/constants';
import COLORS from '../../../variables/colors';
import {
  getChildren,
  monthChange,
  schedulerExportToCSV,
  getPrepaidAssetsForSave,
} from '../../../components/Subledger/common';
import ScheduleHeader from './ScheduleHeader';
import reducer from '../../../components/Subledger/reducer';
import UnScheduleAssets from '../../../components/Subledger/UnScheduledAssets';
import ErrorPage from '../../../components/ErrorPage';
import PrepaidSchdulerCurrentMonthAssetRow from '../../../components/Subledger/PrepaidSchdulerCurrentMonthAssetRow';
import SchedulerTableHeader from '../../../components/Subledger/SchedulerTableHeader';
import ExpandCollapseRow from '../../../components/Subledger/ExpandCollapseRow';
import { openSnackbar } from '../../../components/Notifier';
import { deleteSubledger } from '../../../util/subledger';

interface Props {
  scheduleDate: string;
  accountBalance: Account;
}

const Scheduler = ({
  scheduleDate,
  accountBalance,
}: Props) => {
  const { id: subledgerId } = useParams();
  const history = useHistory();
  const { account } = useAccountProvider();
  const { refreshDate } = useRefreshDataProvider();
  const { accountClasses, vendors, accountIncomes } = account;
  const { get, loading: subledgerLoading, error: subLedgerError, response } = useFetch(`/subledgers/${subledgerId}/details`);
  const { patch, loading, response: updateAssetsResponse } = useFetch();
  const [showSchedule, setShowSchedule] = useState<boolean>(true);
  const [showUnScheduled, setShowUnScheduled] = useState<boolean>(true);

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {}, selectedRow: '' });

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subLedgerError) {
      const result = await get(`?scheduleDate=${scheduleDate}&requiredAmortizationToDate=true`);
      if (response.ok) {
        if (result.status !== FINALIZED_SUBLEDGER_STATUS) {
          history.push(`/subledgers/scheduler/${subledgerId}`);
        }
        dispatch({
          type: INITIALED,
          payload: {
            subledger: result,
            scheduleDate,
          },
        });
      }
    }
  }, [get, history, response.ok, scheduleDate, subLedgerError, subledgerId, subledgerLoading, account?.lastSync]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, refreshDate]);

  const navigateToPrepareJE = () => {
    history.push(`/subledgers/schedule/${subledger.id}/prepare-je/?scheduleDate=${scheduleDate}`);
  };

  const { subledger, selectedRow } = state;

  if (subledgerLoading && !subledger) {
    return <Loader open />;
  }

  if (subLedgerError) {
    return <ErrorPage />;
  }

  const toggleSchedule = () => {
    setShowSchedule(!showSchedule);
  };

  const toggleUnScheduled = () => {
    setShowUnScheduled(!showUnScheduled);
  };

  const onRowSelect = (internalId: string) => () => {
    dispatch({
      type: SELECT_ROW,
      payload: { selectedRow: internalId },
    });
  };

  const onSave = (existingAsset: PrepaidAsset) => async () => {
    const { prepaidAsset, prepaidAssets } = await getPrepaidAssetsForSave(existingAsset, dispatch, scheduleDate, subledger);
    if (!prepaidAsset) {
      return;
    }
    try {
      const result = await patch(`/subledgers/${subledger?.id}/scheduler/assets`, prepaidAssets);
      if (updateAssetsResponse.ok) {
        await dispatch({
          type: UPDATE_ASSET,
          payload: {
            assets: result,
            balance: subledger.openingBalance,
            internalId: prepaidAsset.internalId,
          },
        });
      } else {
        const errorMessage = updateAssetsResponse.data;
        openSnackbar({ message: errorMessage }, NotifierType.Error);
      }
    } catch (err) {
      const errorMessage = updateAssetsResponse.data;
      openSnackbar({ message: errorMessage }, NotifierType.Error);
    }
  };

  const onSelectAutoCompleteChange = (propertyName: string, internalId: string) => (value: string) => {
    dispatch({ type: SELECT_CHANGE, payload: { internalId, propertyName, value } });
  };

  const renderRow = (asset: PrepaidAsset) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const parent = (
      <PrepaidSchdulerCurrentMonthAssetRow
        asset={asset}
        prepaidAssets={subledger?.prepaidAssets}
        hasChildren={!!children?.length}
        selectedRow={selectedRow}
        onRowSelect={onRowSelect}
        onSave={onSave}
        onSelectAutoCompleteChange={onSelectAutoCompleteChange}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {
          children
              ?.map((child: PrepaidAsset, childIndex: number) => (
                <PrepaidSchdulerCurrentMonthAssetRow
                  key={child.internalId}
                  asset={child}
                  prepaidAssets={subledger?.prepaidAssets}
                  hasChildren={false}
                  lastChild={children?.length - 1 === childIndex}
                  selectedRow={selectedRow}
                  onRowSelect={onRowSelect}
                  onSave={onSave}
                  onSelectAutoCompleteChange={onSelectAutoCompleteChange}
                />
              )
            )
        }
      </>
    );
  };

  const renderAsset = () => (
    <>
      <ExpandCollapseRow
        onRowClick={toggleUnScheduled}
        isExpanded={showUnScheduled}
        title="UNSCHEDULED"
        color={COLORS.skyBlue}
      />
      {
        showUnScheduled && (
          <UnScheduleAssets
            selectedRow={state.selectedRow}
            scheduleDate={scheduleDate}
            subledger={subledger}
            unScheduledAssets={subledger?.prepaidAssets
                      ?.filter((asset: PrepaidAsset) => !asset.parentId && (!asset.status))}
            dispatch={dispatch}
          />
        )
      }
      <ExpandCollapseRow
        onRowClick={toggleSchedule}
        isExpanded={showSchedule}
        title="SCHEDULED"
        color={COLORS.lightGray}
      />
      {
        showSchedule && (
          <UnScheduleAssets
            selectedRow={state.selectedRow}
            scheduleDate={scheduleDate}
            subledger={subledger}
            unScheduledAssets={subledger?.prepaidAssets
                      ?.filter((asset: PrepaidAsset) => !asset.parentId && (asset.status === ASSET_TEMP_SCHEDULED_STATUS))}
            dispatch={dispatch}
          />
        )
      }
      {
        showSchedule && subledger?.prepaidAssets
              ?.filter((asset: PrepaidAsset) => !asset.parentId && [ASSET_SCHEDULED_STATUS].includes(asset.status))
              ?.map((asset: PrepaidAsset) => renderRow(asset))
      }
    </>
  );

  const onCSVExport = () => {
    schedulerExportToCSV({ subledger, vendors, accountClasses, accountIncomes, account, scheduleDate });
  };

  const onMonthChange = (previousDateType: Date) => {
    const { equalDates, scheduleDateToNavigate } = monthChange(previousDateType, subledger);
    if (!equalDates) {
      deleteSubledger();
      history.push(`/historical/subledgers/schedule/${subledger.id}/?scheduleDate=${format(scheduleDateToNavigate, DAY_SHORT_FORMAT)}`);
    } else {
      history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${format(scheduleDateToNavigate, DAY_SHORT_FORMAT)}`);
    }
  };

  return (
    <>
      <Loader open={subledgerLoading || loading} />
      <Box
        width="100%"
      >
        <ScheduleHeader
          subledger={subledger}
          scheduleDate={scheduleDate}
          accountBalance={accountBalance}
          vendors={vendors}
          accountIncomes={accountIncomes}
          accountClasses={accountClasses}
          downloadCSV={onCSVExport}
          navigateToPrepareJE={navigateToPrepareJE}
          onMonthChange={onMonthChange}
        />
        <Box padding={1}>
          <Table
            size="small"
          >
            <SchedulerTableHeader />
            <TableBody>
              {
                renderAsset()
              }
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default Scheduler;
