import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import COLORS from '../../variables/colors';
import {
  ASSET_CELL,
  CLASSES_CELL,
  CUSTOMER_CELL,
  DATEPICKER_CELL,
  DESCRIPTION_CELL,
  EXPENSE_ACCOUNT_CELL,
  CURRENCY_CELL,
  SCHEDULE_TYPE_CELL,
  ACTION_CELL,
  AMORTIZAION_ROW_CELL,
  ACTION_CELL_WITH_SAVE,
  PREPAID_AMOUNT,
} from '../../variables/constants';

export const HeaderCurrencyDetails = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: '100%',
  borderRight: `1px solid ${COLORS.lightGray2}`,
  paddingLeft: 17,
  paddingRight: 20,
  paddingBottom: 10,
  marginTop: 1,
  minWidth: 150,
});

export const HeaderCurrencyDetailsDifference = styled(HeaderCurrencyDetails)({
  borderRight: 'none',
  flexGrow: 1,
});

export const AccountTop = styled(Box)({
  paddingTop: 20, paddingBottom: 20,
});

export const AccountLabel = styled(Typography)({
  fontSize: 13,
  marginRight: 9,
  color: COLORS.medGray,
});

export const AccountName = styled('p')({
  fontWeight: 'bold',
});

export const ScheduleTop = styled(Box)({
  paddingTop: 20, paddingBottom: 20,
});

export const ScheduleLabel = styled(Typography)({
  fontSize: 13,
  marginRight: 9,
  color: COLORS.medGray,
});
export const CurrencyLabel = styled(Typography)({
  fontSize: 18,
  fontWeight: 500,
  color: COLORS.medGray,
});

export const useTableStyles = makeStyles(() => createStyles({
  assetCell: {
    minWidth: ASSET_CELL,
    maxWidth: ASSET_CELL,
    width: ASSET_CELL,
    textAlign: 'left',
  },
  descriptionCell: {
    minWidth: DESCRIPTION_CELL,
    maxWidth: DESCRIPTION_CELL,
    width: DESCRIPTION_CELL,
    textAlign: 'left',
  },
  customerCell: {
    minWidth: CUSTOMER_CELL,
    maxWidth: CUSTOMER_CELL,
    width: CUSTOMER_CELL,
    textAlign: 'left',
  },
  expenseAccountCell: {
    minWidth: EXPENSE_ACCOUNT_CELL,
    maxWidth: EXPENSE_ACCOUNT_CELL,
    width: EXPENSE_ACCOUNT_CELL,
  },
  classesCell: {
    minWidth: CLASSES_CELL,
    maxWidth: CLASSES_CELL,
    width: CLASSES_CELL,
    textAlign: 'left',
  },
  datePickerCell: {
    minWidth: DATEPICKER_CELL,
    maxWidth: DATEPICKER_CELL,
    width: DATEPICKER_CELL,
    textAlign: 'left',
  },
  scheduleTypeCell: {
    minWidth: SCHEDULE_TYPE_CELL,
    maxWidth: SCHEDULE_TYPE_CELL,
    width: SCHEDULE_TYPE_CELL,
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  currencyCell: {
    minWidth: CURRENCY_CELL,
    width: CURRENCY_CELL,
    textAlign: 'right',
  },
  actionCell: {
    minWidth: ACTION_CELL,
    maxWidth: ACTION_CELL,
    width: ACTION_CELL,
  },
  actionCellWithSave: {
    minWidth: ACTION_CELL_WITH_SAVE,
    maxWidth: ACTION_CELL_WITH_SAVE,
    width: ACTION_CELL_WITH_SAVE,
  },
  assetAmountCell: {
    minWidth: ASSET_CELL,
    maxWidth: ASSET_CELL,
    width: ASSET_CELL,
    textAlign: 'right',
  },
  actionCellButtonPadding: {
    padding: 4,
    margin: 4,
  },
  parent: {
    borderBottom: 'none !important',
    paddingBottom: '0 !important',
    paddingTop: '8px !important',
  },
  child: {
    borderBottom: 'none !important',
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
  },
  lastChild: {
    borderBottom: `1px dashed ${COLORS.lightGray2} !important`,
    paddingBottom: '8px !important',
  },
  inputBox: {
    backgroundColor: COLORS.lightGray3,
    boxShadow: COLORS.selectBoxShadow,
    borderRadius: 2,
    border: 'none',
    height: 32,
    '&> .MuiSelect-select:focus': {
      backgroundColor: COLORS.lightGray3,
    },
    '&.Mui-focused': {
      boxShadow: COLORS.focusBoxShadow,
    },
  },
  fontSize12: {
    fontSize: 12,
  },
  amortizationRowCell: {
    padding: 0,
    margin: 0,
    width: AMORTIZAION_ROW_CELL,
    minWidth: AMORTIZAION_ROW_CELL,
    textAlign: 'right',
    paddingRight: '16px !important',
    paddingBottom: '2px !important',
  },
  amortizationSplitCell: {
    borderBottom: 'none',
  },
  amortizationRowCellHeader: {
    fontSize: 13,
    fontWeight: 'bold',
    color: COLORS.medGray,
    borderBottom: `1px solid ${COLORS.lightGray2}`,
    width: '100%',
    padding: 8,
    textAlign: 'right',
  },
  cellBackground: {
    backgroundColor: COLORS.lightGray3,
  },
  expenseAccountSelectBox: {
    width: 225,
  },
  customerSelectBox: {
    width: 170,
  },
  classSelectBox: {
    width: 117,
  },
  scheduleTypeSelectBox: {
    width: 71,
  },
  selected: {
    '&.Mui-selected': {
      background: `${COLORS.lightGray2RBA} !important`,
    },
  },
  noBorder: {
    borderBottom: 'none !important',
  },
  iconDisabled: {
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
  disabledRow: {
    opacity: 0.5,
  },
  fontSize13: {
    fontSize: 13,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  amortizationRowCellHeaderCurrency: {
    fontSize: 11,
    fontWeight: 500,
    color: COLORS.lightGray,
    width: '100%',
    textAlign: 'right',
    padding: 8,
    paddingBottom: 2,
  },
  prepaidAssetAmount: {
    width: 'auto',
    maxWidth: PREPAID_AMOUNT,
    textAlign: 'right',
  },
  selectedCellBackground: {
    backgroundColor: COLORS.selectedCellBackground,
    borderLeft: `1px solid ${COLORS.skyBlue} !important`,
    borderRight: `1px solid ${COLORS.skyBlue} !important`,
  },
  selectedCellBackgroundHeaderCell: {
    zIndex: 3,
    borderLeft: `1px solid ${COLORS.skyBlue} !important`,
    borderTop: `1px solid ${COLORS.skyBlue} !important`,
  },
  amortizationManualCell: {
    paddingRight: '4px !important',
  },
  amortizationCell: {
    padding: '8px 4px',
  },
  arrowIcon: {
    fontSize: 12,
    textTransform: 'none',
    height: 14,
    marginTop: 8,
    marginBottom: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
}));
