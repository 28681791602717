import React from 'react';
import {
  Button, createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles';
import { PrimaryButton } from '../Buttons';

interface Action {
  title: string;
  event: any
}

interface Props {
  openDialog : any,
  closeDialog:any,
  actions?: Array<Action>,
  dialogTitle: string,
  dialogContext: string,
  dismissContext: string,
}
const useStyles = makeStyles(() => createStyles({
  displayNewLine: {
    whiteSpace: 'pre-line',
  },
}));
const DialogBox = ({ openDialog, closeDialog, actions, dialogTitle, dialogContext, dismissContext }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={closeDialog}
    >
      <DialogTitle disableTypography>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.displayNewLine}>
          {dialogContext}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          {dismissContext}
        </Button>
        {
        actions?.map((action) => (
          <PrimaryButton
            variant="contained"
            color="primary"
            autoFocus
            onClick={action.event}
          >
            {action.title}
          </PrimaryButton>
        ))
        }
      </DialogActions>
    </Dialog>
  );
};
export default DialogBox;
