import useFetch from 'use-http';
import React from 'react';
import Loader from '../../components/Loader/loader';
import { Subledger } from '../../variables/types';
import NoData from './NoData';
import Accounts from './Accounts';
import { useAccountProvider } from '../../core/accountContext';
import { initializedSubledger } from '../../components/Subledger/common';
import ErrorPage from '../../components/ErrorPage';
import { useRefreshDataProvider } from '../../core/dataRefreshContext';

const Subledgers = () => {
  const { account } = useAccountProvider();
  const { refreshDate } = useRefreshDataProvider();
  const { loading, error, data: subledgers } = useFetch('/subledgers', [account?.lastSync, refreshDate]);
  const { loading: accountBalanceLoading, error: accountBalanceError, data: accountBalances } = useFetch('/account/subledgers/balance', [account?.lastSync]);
  if (error || accountBalanceError) {
    return <ErrorPage />;
  }
  if ((loading && !subledgers) || accountBalanceLoading) {
    return <Loader open />;
  }
  if (subledgers === null || subledgers?.length === 0) {
    return <NoData />;
  }

  const filterSubledgers = subledgers
      ?.filter((subleger: Subledger) => subleger.account?.enable)
      ?.map((subledger: Subledger) => initializedSubledger({ subledger }));
  if (filterSubledgers?.length > 0) {
    return (
      <Accounts
        subledgers={filterSubledgers}
        accountBalances={accountBalances}
      />
    );
  }
  return null;
};

export default React.memo(Subledgers);
