import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTableStyles } from './Subledger.styled';
import { useAccountProvider } from '../../core/accountContext';

const SchedulerTableHeader = () => {
  const { account } = useAccountProvider();
  const tableClasses = useTableStyles();
  return (
    <TableHead>
      <TableRow
        hover={false}
      >
        <TableCell className={tableClasses.assetCell}>
          JE Date
        </TableCell>
        <TableCell className={tableClasses.assetCell}>
          GL JE ID
        </TableCell>
        <TableCell className={tableClasses.descriptionCell}>
          Description
        </TableCell>
        <TableCell className={tableClasses.customerCell}>
          Vendor
        </TableCell>
        <TableCell className={tableClasses.expenseAccountCell}>
          Expense Account
        </TableCell>
        {
          (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
            <TableCell className={tableClasses.classesCell}>
              Class
            </TableCell>
          )
        }
        <TableCell className={tableClasses.datePickerCell}>
          Amortization Start Date
        </TableCell>
        <TableCell className={tableClasses.datePickerCell}>
          Amortization End Date
        </TableCell>
        <TableCell className={tableClasses.scheduleTypeCell}>
          Amort. Schedule
        </TableCell>
        <TableCell className={tableClasses.currencyCell}>
          Amount
        </TableCell>
        <TableCell className={tableClasses.currencyCell}>
          Expense to Date
        </TableCell>
        <TableCell className={tableClasses.currencyCell}>
          Remaining Balance
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default React.memo(SchedulerTableHeader);
