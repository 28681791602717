/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { AccountClass } from '../../variables/types';
import { useAccountProvider } from '../../core/accountContext';
import { getAccountClassById } from './common';
import { AutocompleteBox, AutoCompleteTextField } from '../AutoComplete';
import COLORS from '../../variables/colors';

interface Props {
  error?: boolean;
  className: string;
  defaultValue: string;
  value: string;
  onChange: (value: string) => void;
  accountClasses: Array<AccountClass>;
  disabled?: boolean;
}

const useStyles = makeStyles(() => createStyles({
  error: {
    border: `1px solid ${COLORS.violet}`,
  },
}));

const AccountClassMenu = ({
  className,
  value,
  onChange,
  error,
}: Props) => {
  const classes = useStyles();
  const { account: { accountClasses } } = useAccountProvider();
  const selectedAccount = getAccountClassById(value, accountClasses);
  const onSelectChange = (_: any, accountClass: any) => {
    onChange(accountClass?.id);
  };

  return (
    <AutocompleteBox
      className={clsx(className, { [classes.error]: Boolean(error) })}
      options={accountClasses as AccountClass[] ?? []}
      getOptionLabel={(option: any) => option?.className}
      defaultValue={selectedAccount}
      onChange={onSelectChange}
      value={selectedAccount ?? ''}
      openOnFocus
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          hiddenLabel
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default React.memo(AccountClassMenu);
