import { format, parse } from 'date-fns';
import {
  DAY_SHORT_FORMAT,
  DIGIT_LOWER_LIMIT,
  DIGIT_UPPER_LIMIT,
  PREPARE_JE_UPDATE_ASSETS,
} from '../../variables/constants';
import { PrepaidAsset, PrepaidAssetsAmortizationScheduleDetail, ScheduleType } from '../../variables/types';

interface InputBoxProps {
  number: string,
  asset: PrepaidAsset,
  schedule: PrepaidAssetsAmortizationScheduleDetail | undefined,
  lastDay: string,
  prepaidAssets: Array<PrepaidAsset>,
  dispatch: any
}

export const prepareJEInputChange = ({
  number, asset, schedule, lastDay, dispatch, prepaidAssets,
}: InputBoxProps) => {
  if (Number(number) >= DIGIT_UPPER_LIMIT || Number(number) <= DIGIT_LOWER_LIMIT) {
    return;
  }
  const amortizationSchedules = asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails ?? [];
  const amount = !Number.isNaN(Number(number)) || number.trim() === '-' ? number?.trim() : 'M';
  if (schedule) {
    for (const prepaidAssetsAmortizationSchedule of amortizationSchedules) {
      if (format(prepaidAssetsAmortizationSchedule?.scheduleDate, DAY_SHORT_FORMAT) === lastDay) {
        prepaidAssetsAmortizationSchedule.amount = amount;
      }
    }
  } else {
    const amortizationSchedule: PrepaidAssetsAmortizationScheduleDetail = {
      id: '',
      scheduleDate: parse(lastDay, DAY_SHORT_FORMAT, new Date()), // TODO need to check the value
      amount,
      prepaidAssetId: asset.id,
      status: '',
    };
    amortizationSchedules?.push(amortizationSchedule);
  }
  const updatedAssets = prepaidAssets?.map((item: PrepaidAsset) => {
    if (item?.internalId === asset?.internalId) {
      return {
        ...item,
        prepaidSchedule: {
          ...item.prepaidSchedule,
          prepaidAssetsAmortizationScheduleDetails: amortizationSchedules,
        },
      };
    }
    return item;
  });
  dispatch({ type: PREPARE_JE_UPDATE_ASSETS, payload: { assets: updatedAssets } });
};

export const isPostJEEnabled = (prepaidAssets: Array<PrepaidAsset>, scheduleDate: string) => {
  for (const asset of prepaidAssets) {
    if (asset.prepaidSchedule?.amortizationScheduleType === ScheduleType.Manual) {
      if (!asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails) {
        return false;
      }
      for (const prepaidAssetsAmortizationSchedule of asset?.prepaidSchedule?.prepaidAssetsAmortizationScheduleDetails) {
        if (format(prepaidAssetsAmortizationSchedule?.scheduleDate, DAY_SHORT_FORMAT) === scheduleDate) {
          if (Number.isNaN(Number(prepaidAssetsAmortizationSchedule.amount)) || prepaidAssetsAmortizationSchedule.amount === '') {
            return false;
          }
        }
      }
    }
  }
  return true;
};
