import React from 'react';
import { Box, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { format } from 'date-fns';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Account, AccountClass, AccountInfo, PrepaidAsset, ScheduleType, Vendor } from '../../variables/types';
import { useTableStyles } from './Subledger.styled';
import { getAccountNameFromList, getClassName, getVendorName } from './common';
import currencyFormatter from '../../util/currencyFormatter';
import COLORS from '../../variables/colors';
import SplitIcon from '../Icons/SplitIcon';
import CreateSubledgerAmortizationSchedules from './CreateSubledgerAmortizationSchedules';
import { FULL_DAY_FORMAT } from '../../variables/constants';

interface Props {
  selectedRow: string,
  asset: PrepaidAsset,
  hoverRow: string,
  onClick: () => void,
  hasChildren?: boolean,
  index: number,
  vendors: Array<Vendor>,
  accounts: Array<Account>,
  account: AccountInfo,
  accountClasses: Array<AccountClass>,
  addSplit: () => void,
  startDate: Date;
  endDate: Date | null;
  prepaidAssets: Array<PrepaidAsset>;
  lastChild?: boolean;
}

const CreateSubledgerReadOnlyRow = ({
  selectedRow,
  asset,
  lastChild,
  hasChildren,
  accountClasses,
  vendors,
  account,
  accounts,
  hoverRow,
  index,
  onClick,
  addSplit,
  startDate,
  endDate,
  prepaidAssets,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableRow
      hover={false}
      selected={selectedRow === asset.internalId || (hoverRow === asset.internalId || asset.parentId === hoverRow)}
      onClick={onClick}
      classes={{
        selected: tableClasses.selected,
      }}
    >
      <TableCell className={clsx(tableClasses.assetCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {asset.parentId ? '' : `Open ${index + 1}`}
      </TableCell>
      <TableCell className={clsx(tableClasses.descriptionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {asset.description}
      </TableCell>
      <TableCell className={clsx(tableClasses.customerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {!asset.parentId ? getVendorName(asset.vendorId, vendors) : ''}
      </TableCell>
      <TableCell
        className={clsx(tableClasses.expenseAccountCell, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        {
          hasChildren
                    && (
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography className={tableClasses.fontSize13}>Multiple</Typography>
                        <ArrowDropDownIcon />
                      </Box>
                    )
        }
        {
          !hasChildren
                    && getAccountNameFromList(asset?.prepaidSchedule?.expenseAccountId!, accounts)
        }
      </TableCell>
      {

        (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
          <TableCell className={clsx(tableClasses.classesCell, {
            [tableClasses.child]: asset.parentId,
            [tableClasses.parent]: hasChildren,
            [tableClasses.lastChild]: lastChild,
          })}
          >
            {
              !hasChildren && getClassName(asset?.prepaidSchedule?.classId!, accountClasses)
            }
          </TableCell>
        )
      }
      <TableCell className={clsx(tableClasses.currencyCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {currencyFormatter.format(asset?.startingBalance ?? 0)}
      </TableCell>
      <TableCell className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          asset?.prepaidSchedule?.amortizationStartDate && !hasChildren
            ? asset.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual
              ? format(asset.prepaidSchedule?.amortizationStartDate, FULL_DAY_FORMAT)
              : 'M' : ''
        }
      </TableCell>
      <TableCell className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          asset?.prepaidSchedule?.amortizationEndDate && !hasChildren
            ? asset.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual
              ? format(asset.prepaidSchedule?.amortizationEndDate, FULL_DAY_FORMAT)
              : 'M' : ''
        }
      </TableCell>
      <TableCell className={clsx(tableClasses.scheduleTypeCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {!hasChildren && asset?.prepaidSchedule?.amortizationScheduleType?.toLocaleLowerCase()}
      </TableCell>
      <TableCell className={clsx(tableClasses.actionCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      >
        {
          !hasChildren && !asset.parentId && (
            <MoreHorizIcon className={tableClasses.fontSize13} style={{ color: COLORS.lightGray }} />
          )
        }
        {
          hasChildren && !asset.parentId && (
            <Box textAlign="right">
              <IconButton
                disabled
                className={clsx(tableClasses.actionCellButtonPadding, {
                  [tableClasses.iconDisabled]: hasChildren,
                })}
                onClick={addSplit}
              >
                <SplitIcon className={tableClasses.fontSize13} />
              </IconButton>
            </Box>
          )
        }

      </TableCell>
      <CreateSubledgerAmortizationSchedules
        startDate={startDate}
        endDate={endDate}
        asset={asset}
        prepaidAssets={prepaidAssets}
        lastChild={lastChild}
      />
    </TableRow>
  );
};

export default React.memo(CreateSubledgerReadOnlyRow);
