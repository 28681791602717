/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RecordIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    height={23}
    width={20}
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.95833 5H8.875V6.66667H2.95833V5ZM2.95833 10H8.875V8.33333H2.95833V10ZM2.95833 13.3333H8.875V11.6667H2.95833V13.3333ZM19.2292 5H13.3125V6.66667H19.2292V5ZM19.2292 8.33333H13.3125V10H19.2292V8.33333ZM19.2292 11.6667H13.3125V13.3333H19.2292V11.6667ZM22.1875 1.66667V16.6667C22.1875 17.5833 21.5219 18.3333 20.7083 18.3333H12.5729L11.0937 20L9.61458 18.3333H1.47917C0.665625 18.3333 0 17.5833 0 16.6667V1.66667C0 0.75 0.665625 0 1.47917 0H9.61458L11.0937 1.66667L12.5729 0H20.7083C21.5219 0 22.1875 0.75 22.1875 1.66667ZM10.3542 2.5L9.61458 1.66667H1.47917V16.6667H10.3542V2.5ZM20.7083 1.66667H12.5729L11.8333 2.5V16.6667H20.7083V1.66667Z"
    />
  </SvgIcon>
);

export default RecordIcon;
