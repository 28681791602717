import Typography from '@material-ui/core/Typography';
import React from 'react';
import cardStyles from './cardStyles';

interface Props {
  accountName: string;
}

const AccountName = ({ accountName }: Props) => {
  const classes = cardStyles();
  return (
    <Typography className={classes.title} color="textSecondary" gutterBottom>
      {accountName}
    </Typography>
  );
};

export default React.memo(AccountName);
