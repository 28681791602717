import React, { useState } from 'react';
import useFetch from 'use-http';
import RefreshIcon from '../Icons/RefreshIcon';
import { NotifierType } from '../../variables/types';
import { openSnackbar } from '../Notifier';
import Loader from '../Loader/loader';
import { useRefreshDataProvider } from '../../core/dataRefreshContext';
import DialogBox from '../DialogBox';

type Props = {
  subledgerId: string,
  assetId: string,
  dialogContent?:string,
  alertDialog?:boolean
};

const RefreshData = ({ subledgerId, assetId, alertDialog, dialogContent } : Props) => {
  const { put, response, loading } = useFetch();
  const { setRefreshDate } = useRefreshDataProvider();
  const [openRefreshConfirmationDialog, setRefreshConfirmationDialog] = useState<boolean>(false);

  const onRefresh = async () => {
    if (alertDialog) {
      setRefreshConfirmationDialog(true);
    } else {
      await callRefreshService();
    }
  };

  const callRefreshService = async () => {
    try {
      await put(`/subledgers/${subledgerId}/refresh/${assetId}`);// Refresh API-Hit accomodating DELETE and ACCOUNT-SYNC
    } catch (err) {
      const errorMessage = response.data;
      openSnackbar({ message: errorMessage }, NotifierType.Error);
    }
    setRefreshDate(new Date());
  };

  const closeDialog = () => {
    setRefreshConfirmationDialog(false);
  };

  return (
    <>
      {alertDialog
      && (
        <DialogBox
          openDialog={openRefreshConfirmationDialog}
          closeDialog={closeDialog}
          dialogContext={dialogContent!}
          dialogTitle="Alert"
          dismissContext="Cancel"
          actions={[{ title: 'YES', event: callRefreshService }]}
        />
      )}
      <Loader open={loading} />
      <RefreshIcon onClick={onRefresh} />
    </>
  );
};

export default RefreshData;
