import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  ButtonBase,
  createStyles,
  MenuItem, Snackbar,
} from '@material-ui/core';
import useFetch from 'use-http';
import CSVReader from 'react-csv-reader';
import Typography from '@material-ui/core/Typography';
import fileDownload from 'js-file-download';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';

import { isAfter } from 'date-fns';
import roundTo from 'round-to';
import SelectBox from '../../../components/Select';
import { CurrencyLabel, SchedulerLabel, SchedulerTop } from './SchedulerHeader.styled';
import Loader from '../../../components/Loader/loader';
import COLORS from '../../../variables/colors';
import { Account, NotifierType, Subledger } from '../../../variables/types';
import FactaDatePicker from '../../../components/DatePicker';
import currencyFormatter from '../../../util/currencyFormatter';
import ImportCSVIcon from '../../../components/Icons/ImportCSVIcon';
import { openSnackbar } from '../../../components/Notifier';
import { CREATE_SUBLEDGER_COLUMNS } from '../../../variables/constants';
import { useAccountProvider } from '../../../core/accountContext';
import DownloadIcon from '../../../components/Icons/DownloadIcon';
import { HeaderCurrencyDetails, HeaderCurrencyDetailsDifference } from '../../../components/Subledger/Subledger.styled';
import DialogBox from '../../../components/DialogBox';
import isEmpty from '../../../util/isEmpty';
import { fromUTC } from '../../../util/timezone';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  periodBox: {
    width: 100,
    marginRight: 20,
  },
  subledgerBalance: {
    color: COLORS.violet,
  },
  datepicker: {
    background: COLORS.white,
    borderRadius: 2,
    marginRight: 20,
    width: 120,
    maxWidth: 120,
    '& input': {
      background: COLORS.white,
    },
  },
  fullWidth: {
    width: '100%',
  },
  marginLeft8: {
    marginLeft: 8,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  glBalance: {
    maxWidth: 100,
    width: 100,
  },
  factaStartingBalance: {
    maxWidth: 120,
    width: 150,
  },
}));

interface Props {
  subledger?: Subledger | null;
  onFactaStartDateChange: (date: Date | null) => void;
  onAmortizationScheduleTypeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onImportCSV: (data: any, fileInfo: any) => void,
  accountBalance: Account
}

const Header = ({
  subledger,
  onFactaStartDateChange,
  onAmortizationScheduleTypeChange,
  onImportCSV,
  accountBalance,
}: Props) => {
  const { loading, post, response, error } = useFetch();
  const [accountId, setaccountId] = useState();
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState(false);
  const classes = useStyles();
  const { account: companyAccountInfo } = useAccountProvider();
  const history = useHistory();

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const difference = roundTo(Number(accountBalance?.glBalance), 2) - roundTo(Number(subledger?.openingBalance), 2);

  const onCSVError = (err: Error) => {
    openSnackbar({ message: err.message }, NotifierType.Error);
  };

  const onTemplateDownload = () => {
    let columns = [
      CREATE_SUBLEDGER_COLUMNS.DESCRIPTION,
      CREATE_SUBLEDGER_COLUMNS.VENDOR,
      CREATE_SUBLEDGER_COLUMNS.EXPENSES_ACCOUNT,
      CREATE_SUBLEDGER_COLUMNS.FACTA_STARTING_BALANCE,
      CREATE_SUBLEDGER_COLUMNS.AMORTIZATION_START_DATE,
      CREATE_SUBLEDGER_COLUMNS.AMORTIZATION_END_DATE,
      CREATE_SUBLEDGER_COLUMNS.AMORT_SCHEDULE,
    ];
    if (companyAccountInfo?.classTrackingPerTxnLine || companyAccountInfo?.classTrackingPerTxn) {
      columns = [
        CREATE_SUBLEDGER_COLUMNS.DESCRIPTION,
        CREATE_SUBLEDGER_COLUMNS.VENDOR,
        CREATE_SUBLEDGER_COLUMNS.EXPENSES_ACCOUNT,
        CREATE_SUBLEDGER_COLUMNS.CLASS,
        CREATE_SUBLEDGER_COLUMNS.FACTA_STARTING_BALANCE,
        CREATE_SUBLEDGER_COLUMNS.AMORTIZATION_START_DATE,
        CREATE_SUBLEDGER_COLUMNS.AMORTIZATION_END_DATE,
        CREATE_SUBLEDGER_COLUMNS.AMORT_SCHEDULE,
      ];
    }
    fileDownload(columns.join(','), 'Subledger Template.csv');
  };

  // Subledger creation POST trigger
  const createSubledger = async () => {
    try {
      const subledgerData = await post('/subledgers', { accountId });
      if (response.ok) {
        closeDialog();
        history.push(`/subledgers/scheduler/${subledgerData.id}`);
        window.location.reload();
      }
    } catch (err) {
      openSnackbar({ message: response.data }, NotifierType.Error);
    }
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
    setaccountId('');
  };

  // @ts-ignore
  const factaEndDate = subledger?.factaDate?.endDate ? fromUTC(subledger?.factaDate?.endDate) : null;

  // @ts-ignore
  return (
    <>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">
          {error?.message}
        </Alert>
      </Snackbar>
      <Loader open={loading} />

      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        actions={[{ title: 'Create Subledger', event: createSubledger }]}
        dialogContext={'You are about to create a new subledger. \n Do you wish to proceed?'}
        dialogTitle="Alert"
        dismissContext="Cancel"
      />
      <SchedulerTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="fixed"
        height="133px"
        top="75px"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          borderRight="1px solid #CFD3DA"
          paddingLeft="38px"
          paddingRight="38px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
            marginTop="10px"
          >
            <SchedulerLabel>
              Facta Start Date
            </SchedulerLabel>
            <FactaDatePicker
              selectedDate={subledger.factaStartDate}
              onDateChange={onFactaStartDateChange}
              className={classes.datepicker}
              error={factaEndDate && isAfter(subledger.factaStartDate!, factaEndDate!)}
              maxDate={factaEndDate}
            />

            <SchedulerLabel>
              Amortization Schedule
            </SchedulerLabel>
            <SelectBox
              value={subledger?.account?.scheduleType}
              disableUnderline
              className={classes.periodBox}
              onChange={onAmortizationScheduleTypeChange}
            >
              <MenuItem value="DAILY">
                Daily
              </MenuItem>
              <MenuItem value="MONTHLY">
                Monthly
              </MenuItem>
            </SelectBox>
          </Box>
        </Box>
        <HeaderCurrencyDetails>
          <SchedulerLabel className={classes.glBalance}>
            GL Balance on start date:
          </SchedulerLabel>
          <CurrencyLabel>
            {currencyFormatter.format(accountBalance?.glBalance)}
          </CurrencyLabel>
        </HeaderCurrencyDetails>
        <HeaderCurrencyDetails>
          <SchedulerLabel className={classes.factaStartingBalance}>
            Facta Opening Subledger balance:
          </SchedulerLabel>
          <CurrencyLabel className={classes.subledgerBalance}>
            {currencyFormatter.format(subledger?.openingBalance)}
          </CurrencyLabel>
        </HeaderCurrencyDetails>
        <HeaderCurrencyDetailsDifference>
          <SchedulerLabel className={classes.glBalance}>
            Difference
          </SchedulerLabel>
          <CurrencyLabel>
            {currencyFormatter.format(difference)}
          </CurrencyLabel>
        </HeaderCurrencyDetailsDifference>
        <Box
          pr={2}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          right="9%"
        >
          <CSVReader
            onFileLoaded={onImportCSV}
            onError={onCSVError}
            parserOptions={{ header: true, skipEmptyLines: true }}
            inputId="csv-importer"
            label={(
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                className={classes.cursorPointer}
              >
                <ImportCSVIcon
                  color="primary"
                  fill={COLORS.skyBlue}
                  fontSize="small"
                />
                <Typography
                  color="primary"
                  className={classes.marginLeft8}
                >
                  Import CSV
                </Typography>
              </Box>
            )}
            inputStyle={{ display: 'none' }}
          />
          <Box ml={1}>
            <ButtonBase onClick={onTemplateDownload}>
              <DownloadIcon stroke={COLORS.skyBlue} fontSize="small" />
              <Typography
                color="primary"
                className={classes.marginLeft8}
              >
                CSV Template
              </Typography>
            </ButtonBase>
          </Box>
        </Box>
      </SchedulerTop>
    </>
  );
};

export default Header;
