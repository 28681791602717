import React from 'react';
import { Button, createStyles, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import useFetch from 'use-http';
import { isBefore, startOfMonth } from 'date-fns';
import roundTo from 'round-to';
import {
  NotifierType,
  PrepaidAsset,
  ScheduleType,
  Subledger,
} from '../../variables/types';
import { getChildren, getPrepaidAssetsForSave, getTotalBalanceAndAmortizationBalance } from './common';
import UnScheduledRow from './UnScheduledRow';
import COLORS from '../../variables/colors';
import { useTableStyles } from './Subledger.styled';
import { openSnackbar } from '../Notifier';
import Loader from '../Loader/loader';
import {
  ADD_SPLIT,
  ADD_SPLIT_ASSET,
  SELECT_ROW, UNSCHEDULED_DATE_CHANGE, UNSCHEDULED_INPUT_CHANGE,
  UNSCHEDULED_REMOVE_ASSET, UNSCHEDULED_SELECT_CHANGE,
  UPDATE_ASSET,
} from '../../variables/constants';

interface Props {
  subledger: Subledger,
  scheduleDate: string,
  selectedRow: string,
  unScheduledAssets: Array<PrepaidAsset>,
  dispatch: any,
  showRemainingBalance?: boolean,
}

const useStyles = makeStyles(() => createStyles({
  arrowIcon: {
    fontSize: 12,
    textTransform: 'none',
    color: COLORS.lightGray,
    fontWeight: 500,
  },
  fontSize12: {
    fontSize: 12,
  },
}));

const UnScheduleAssets = ({
  subledger,
  scheduleDate,
  dispatch,
  unScheduledAssets,
  showRemainingBalance = true,
  selectedRow,
}: Props) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const { patch, loading, response } = useFetch();

  const onSave = (existingAsset: PrepaidAsset) => async () => {
    const { prepaidAsset, prepaidAssets } = await getPrepaidAssetsForSave(existingAsset, dispatch, scheduleDate, subledger);
    if (!prepaidAsset) {
      return;
    }
    try {
      const result = await patch(`/subledgers/${subledger?.id}/unscheduled/assets`, prepaidAssets);
      if (response.ok) {
        await dispatch({
          type: UPDATE_ASSET,
          payload: {
            assets: result?.prepaidAssets,
            balance: roundTo(result?.balance, 2),
            internalId: prepaidAsset.internalId,
          },
        });
      } else {
        const errorMessage = response.data;
        openSnackbar({ message: errorMessage }, NotifierType.Error);
      }
    } catch (err) {
      const errorMessage = response.data;
      openSnackbar({ message: errorMessage }, NotifierType.Error);
    }
  };

  const addSplit = (internalId: string) => () => {
    dispatch({ type: ADD_SPLIT, payload: { internalId } });
  };

  const addSplitAsset = (internalId: string) => () => {
    dispatch({ type: ADD_SPLIT_ASSET, payload: { internalId } });
  };

  const removeAsset = (internalId: string) => () => {
    dispatch({ type: UNSCHEDULED_REMOVE_ASSET, payload: { internalId } });
  };

  const onDateChange = (propertyName: string, internalId: string, value: Date | null) => {
    dispatch({ type: UNSCHEDULED_DATE_CHANGE, payload: { internalId, propertyName, value, scheduleDate } });
  };

  const onSelectChange = (propertyName: string, internalId: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: UNSCHEDULED_SELECT_CHANGE,
      payload: { internalId, propertyName, value: event.target.value, scheduleDate },
    });
  };

  const onAutoCompleteChange = (propertyName: string, internalId: string) => (value: string) => {
    dispatch({
      type: UNSCHEDULED_SELECT_CHANGE,
      payload: { internalId, propertyName, value, scheduleDate },
    });
  };

  const onInputBoxChange = (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UNSCHEDULED_INPUT_CHANGE,
      payload: { internalId, propertyName, value: event.target.value, scheduleDate },
    });
  };

  const onRowSelect = (internalId: string) => () => {
    dispatch({
      type: SELECT_ROW,
      payload: { selectedRow: internalId },
    });
  };

  const isSaveEnabled = (asset: PrepaidAsset) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    if (children?.length === 0) {
      if (asset?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual) {
        if (isBefore(asset?.prepaidSchedule?.amortizationEndDate!, startOfMonth(asset?.assetCreationDate!))) {
          return false;
        }
        if (isBefore(asset?.prepaidSchedule?.amortizationEndDate!, startOfMonth(asset?.prepaidSchedule.amortizationStartDate!))) {
          return false;
        }
      }
      return !!asset?.prepaidSchedule?.expenseAccountId;
    }
    if (children?.length > 0) {
      for (const child of children) {
        if (!(child?.prepaidSchedule.expenseAccountId)) {
          return false;
        }
        if (child?.prepaidSchedule?.amortizationScheduleType !== ScheduleType.Manual) {
          if (isBefore(child?.prepaidSchedule?.amortizationEndDate!, startOfMonth(asset?.assetCreationDate!))) {
            return false;
          }
          if (isBefore(child?.prepaidSchedule?.amortizationEndDate!, startOfMonth(child?.prepaidSchedule.amortizationStartDate!))) {
            return false;
          }
        }
      }
      const { totalBalance } = getTotalBalanceAndAmortizationBalance(children);
      return roundTo(Number(totalBalance), 2) === roundTo(Number(asset?.startingBalance), 2);
    }
    return true;
  };

  const renderUnScheduledRow = (asset: PrepaidAsset) => {
    const children = getChildren(asset.internalId, subledger?.prepaidAssets);
    const parent = (
      <UnScheduledRow
        selectedRow={selectedRow}
        showRemainingBalance={showRemainingBalance}
        childrenPrepaidAssets={children}
        factaStartDate={subledger?.factaStartDate}
        scheduleDate={scheduleDate}
        addSplit={addSplit}
        addSplitAsset={addSplitAsset}
        asset={asset}
        hasChildren={!!children?.length}
        isSaveEnabled={isSaveEnabled}
        onDateChange={onDateChange}
        onInputBoxChange={onInputBoxChange}
        onSave={onSave}
        onSelectChange={onSelectChange}
        onAutoCompleteChange={onAutoCompleteChange}
        parentAsset={asset}
        removeAsset={removeAsset}
        onRowSelect={onRowSelect}
        key={asset.internalId}
        subledgerId={subledger?.id}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children
              ?.map((child: PrepaidAsset, index: number) => (
                <UnScheduledRow
                  onRowSelect={onRowSelect}
                  selectedRow={selectedRow}
                  showRemainingBalance={showRemainingBalance}
                  childrenPrepaidAssets={children}
                  factaStartDate={subledger?.factaStartDate}
                  scheduleDate={scheduleDate}
                  addSplit={addSplit}
                  addSplitAsset={addSplitAsset}
                  asset={child}
                  hasChildren={false}
                  isSaveEnabled={isSaveEnabled}
                  onDateChange={onDateChange}
                  onInputBoxChange={onInputBoxChange}
                  onSave={onSave}
                  onSelectChange={onSelectChange}
                  onAutoCompleteChange={onAutoCompleteChange}
                  parentAsset={asset}
                  removeAsset={removeAsset}
                  key={child.internalId}
                  lastChild={index === children?.length - 1 && selectedRow !== asset?.internalId}
                  subledgerId={subledger?.id}
                />
              ))
        }
        {
          selectedRow === asset?.internalId && (
            <TableRow
              selected
              classes={{
                selected: tableClasses.selected,
              }}
            >
              <TableCell />
              <TableCell colSpan={100}>
                <Button
                  color="primary"
                  className={classes.arrowIcon}
                  onClick={addSplitAsset(asset.internalId)}
                  startIcon={<AddIcon fontSize="small" className={tableClasses.fontSize12} color="primary" />}
                >
                  <Typography style={{ color: COLORS.skyBlue }}>Add</Typography>
                </Button>
              </TableCell>
            </TableRow>
          )
        }
      </>
    );
  };
  return (
    <>
      <Loader open={loading} />
      {
          unScheduledAssets?.filter((asset) => !asset?.parentId).map(renderUnScheduledRow)
      }
    </>
  );
};

export default React.memo(UnScheduleAssets);
