import { Box, TableCell, TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { addMonths, differenceInCalendarMonths, format, lastDayOfMonth, startOfMonth } from 'date-fns';
import { useAccountProvider } from '../../core/accountContext';
import { getAssetSumForMonth } from './common';
import COLORS from '../../variables/colors';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';
import { PrepaidAsset } from '../../variables/types';
import { DAY_SHORT_FORMAT, FULL_DATE_FORMAT } from '../../variables/constants';

interface Props {
  startDate: Date;
  endDate: Date;
  scheduleDate: string;
  prepaidAssets: Array<PrepaidAsset>
}

const PrepareJETableHeader = ({ startDate, endDate, scheduleDate, prepaidAssets }: Props) => {
  const tableClasses = useTableStyles();
  const { account } = useAccountProvider();

  const renderAmortizationSchedulesHeader = () => {
    if (startDate && endDate) {
      const diffInMonths = differenceInCalendarMonths(lastDayOfMonth(endDate!), startOfMonth(startDate!)) + 1;
      if (diffInMonths > 0) {
        return (
          <>
            {
              Array.from(Array(diffInMonths).keys()).map((index: number) => {
                const dayOfMonth = lastDayOfMonth(addMonths(startDate!, index));
                const currentDate = format(dayOfMonth, DAY_SHORT_FORMAT);
                return (
                  <TableCell
                    className={clsx(tableClasses.amortizationRowCell, {
                      [tableClasses.cellBackground]: index % 2 === 0,
                      [tableClasses.selectedCellBackground]: currentDate === scheduleDate,
                      [tableClasses.selectedCellBackgroundHeaderCell]: currentDate === scheduleDate,
                    })}
                    style={{ borderLeft: currentDate === scheduleDate ? `1px solid ${COLORS.lightGray2}` : 'none' }}
                    align="right"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="stretch"
                      width="100"
                      padding={0}
                      margin={0}
                    >
                      <Box
                        className={tableClasses.amortizationRowCellHeader}
                      >
                        {currencyFormatter.format(getAssetSumForMonth(currentDate, prepaidAssets))}
                      </Box>
                      <Box
                        className={tableClasses.amortizationRowCellHeaderCurrency}
                      >
                        {format(dayOfMonth, FULL_DATE_FORMAT)}
                      </Box>
                    </Box>
                  </TableCell>
                );
              })
            }
          </>
        );
      }
    }
    return null;
  };

  return (
    <TableHead>
      <TableRow
        hover={false}
      >
        <TableCell className={tableClasses.assetCell}>
          JE Date
        </TableCell>
        <TableCell className={tableClasses.descriptionCell}>
          Description
        </TableCell>
        <TableCell className={tableClasses.customerCell}>
          Vendor
        </TableCell>
        <TableCell className={tableClasses.expenseAccountCell}>
          Expense Account
        </TableCell>
        {
          (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
            <TableCell className={tableClasses.classesCell}>
              Class
            </TableCell>
          )
        }
        <TableCell className={clsx(tableClasses.currencyCell)}>
          Remaining Balance
        </TableCell>
        {renderAmortizationSchedulesHeader()}
      </TableRow>
    </TableHead>
  );
};

export default React.memo(PrepareJETableHeader);
