import { styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import COLORS from '../../variables/colors';

export const AutocompleteBox = styled(Autocomplete)({
  fontWeight: 'normal',
  color: COLORS.deepGray,
  minWidth: 81,
  height: 32,
  margin: 0,
  padding: 0,
  borderRadius: 2,
  backgroundColor: COLORS.lightGray3,
  boxShadow: COLORS.selectBoxShadow,
  '&.Mui-error': {
    boxShadow: COLORS.errorBorderShadow,
  },
  '&.Mui-focused': {
    boxShadow: COLORS.focusBoxShadow,
  },
});

export const AutoCompleteTextField = styled(TextField)({
  margin: 0,
  padding: 0,
  paddingLeft: 8,
  marginRight: 8,
});
